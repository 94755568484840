import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { Subscription } from 'rxjs';
import { getLoading } from 'src/app/state/app.selectors';
import { UserConfirmation } from 'src/app/models/user.model';
import { ConfirmationPasswordAction } from 'src/app/state/app.actions';

export interface ConfirmForgotPasswordDialogData {
  username: string;
}

@Component({
  selector: 'eng-confirm-forgot-password-dialog',
  templateUrl: 'confirm-forgot-password-dialog.component.html',
  styleUrls: ['confirm-forgot-password-dialog.component.scss']
})
export class ConfirmForgotPasswordDialogComponent implements OnInit, OnDestroy {
  confirmationPasswordForm: UntypedFormGroup;
  loadingSub: Subscription;
  loading: boolean;
  submitted = false;
  returnUrl: string;
  foundUsername = false;
  showNewPassword: boolean;
  minPw = 6;

  constructor(
    public dialogRef: MatDialogRef<ConfirmForgotPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmForgotPasswordDialogData,
    private _formBuilder: UntypedFormBuilder,
    private _store: Store<AppState>
  ) {}

  ngOnInit() {

    this.confirmationPasswordForm = this._formBuilder.group({
      confirmationCode: [''],
      username: [''],
      newPassword: ['', [Validators.required, Validators.minLength(this.minPw)]],
    });

    if (this.data.username) {
      this.foundUsername = true;
      this.confirmationPasswordForm.controls.username.setValue(this.data.username);
    }

    this.loadingSub = this._store.pipe(select(getLoading)).subscribe(loading => {
      this.loading = loading;
    });
  }

  ngOnDestroy() {
    if (this.loadingSub) {
      this.loadingSub.unsubscribe();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.submitted = true;

    // clean whitespace
    const cleanConfirmationCode = ( this.confirmationPasswordForm.controls.confirmationCode.value as string).replace(' ', '');
    const cleanPassword = ( this.confirmationPasswordForm.controls.newPassword.value as string).replace(' ', '');
    const username = this.confirmationPasswordForm.controls.username.value as string;

    const payload: UserConfirmation = {
      username: username,
      code: cleanConfirmationCode,
      password: cleanPassword
    };

    this._store.dispatch(new ConfirmationPasswordAction(payload));
    this.dialogRef.close();
  }

  get newPassword() { return this.confirmationPasswordForm.get('newPassword'); }
}
