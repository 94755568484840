// import { Injectable } from '@angular/core';
// import { BehaviorSubject, Observable } from 'rxjs';

// @Injectable({
//   providedIn: 'root'
// })
// export class SpinnerService {

//   private count = 0;
//   private spinner$ = new BehaviorSubject<string>('');

//   constructor() { }

//   getSpinnerObserver(): Observable<string> {
//     return this.spinner$.asObservable();
//   }

//   requestStarted() {
//     if (++this.count === 1) {
//       this.spinner$.next('start');
//     }
//   }

//   requestEnded() {
//     if (this.count === 0 || --this.count === 0) {
//       this.spinner$.next('stop');
//     }
//   }

//   resetSpinner() {
//     this.count = 0;
//     this.spinner$.next('stop');
//   }
// }

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  loader: Subject<boolean> = new Subject<boolean>();
  not_loader: Subject<boolean> = new Subject<boolean>();

  constructor() { }

  show() {
    this.loader.next(true);
    this.not_loader.next(false);
  }

  hide() {
    this.loader.next(false);
    this.not_loader.next(true);
  }

}
