import { Component, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { MapConfigService } from './map-config.service';
import { GeoMapStyleType } from './geo-map-style.type';
import { BingGeoMapLayer } from './bing-geo-map-layer.model';

import { takeWhile } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as fromApp from '../../state/app.selectors';
import { AppState } from 'src/app/state/app.state';
import { Tile } from 'ol/layer';
import { BingMaps, OSM } from 'ol/source';
import { Map, Feature, View } from 'ol';
import { Point } from 'ol/geom';
import { FullScreen, Zoom } from 'ol/control';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { defaults } from 'ol/interaction';

let uniqueMapId = 0;

type LayerType = 'Tile' | 'Drawing' | 'Overlay';
class ApoTileLayer extends Tile<OSM> {
  layerType: LayerType;
  constructor(layerType: LayerType, tileOptions: any) {
    super(tileOptions);
    this.layerType = layerType;
  }
}

@Component({
  selector: 'eng-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit, OnDestroy {
  componentActive = true;

  public map!: Map;

  // private _geoSource!: OSM;
  private _geoSource!: any;

  bingGeoMapLayers: BingGeoMapLayer[];

  readonly BING_MAP_KEY =
    'AlTXiEnrvg62ypFIWU_HOwXovo58PvC9L_4cB0eqeOwDr8in5KbW12u6CSkeQQ3T';

  id = `map-${uniqueMapId++}`;

  @Input()
    geoLayer!: GeoMapStyleType;

  tileLayer!: ApoTileLayer;

  _rowSpan = 0;
  @Input()
  set rowSpan(value: number) {
    if (value) {
      this._rowSpan = value;
      setTimeout(() => {
        this.map.updateSize();
      }, 1000);
    }
  }

  constructor(
    private _mapConfigService: MapConfigService,
    private _appState: Store<AppState>
  ) {
    this.bingGeoMapLayers = this._mapConfigService.getBingGeoMapLayers();
  }

  ngAfterViewInit() {
    this.map = new Map({
      interactions: defaults({
        doubleClickZoom: true,
        mouseWheelZoom: true
      }),
      controls: [
        new FullScreen(),
        new Zoom(),
      ],
      target: this.id,
      view: new View({
        center: [0, 0],
        zoom: 2,
        minZoom: 2
      })
    });

    this.applyTileLayer(this._mapConfigService.getBingDefaultLayer().value);

    this._appState
      .pipe(
        takeWhile(() => this.componentActive),
        select(fromApp.getDarkThemeState)
      )
      .subscribe(darkTheme => {
        darkTheme
          ? this.applyTileLayer(this._mapConfigService.getBingDarkLayer().value)
          : this.applyTileLayer(
            this._mapConfigService.getBingDefaultLayer().value
          );
      });
  }

  ngOnDestroy() {
    this.componentActive = false;
  }

  applyTileLayer(imagery: string) {
    if (imagery.includes('http')) {
      this._geoSource.setUrl(imagery);
    } else {
      if (this.tileLayer) {
        this.map.removeLayer(this.tileLayer);
      }

      this._geoSource = new BingMaps({
        key: this.BING_MAP_KEY,
        imagerySet: imagery
      });

      this.tileLayer = new ApoTileLayer('Tile', {
        source: this._geoSource
      });
      // ensure tile layer is inserted at the base of the layer tree
      this.map.getLayers().insertAt(0, this.tileLayer);
    }
  }

  applyMarkers(markers: Array<[number, number]>) {
    const vectorSource = new VectorSource({});

    markers.forEach(marker => {
      vectorSource.addFeature(new Feature({
        geometry: new Point(marker)
      }));
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource
    });

    this.map.addLayer(vectorLayer);
    this.map.updateSize();
  }
}
