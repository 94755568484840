import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PieChartComponent } from './pie-chart.component';
import { RichUiModule } from '@richUi';
import { NoDataModule } from '../no-data/no-data.module';

@NgModule({
  declarations: [PieChartComponent],
  imports: [
    CommonModule,
    RichUiModule,
    NoDataModule,
  ],
  exports: [PieChartComponent]
})
export class PieChartModule { }
