<div #wrapper class="table__wrapper">
  <div *ngIf="data && data.length > 0" class="table-container" [ngClass]="{'row-span-2x': rowSpan === 2}">
    <table mat-table [dataSource]="data" matSort>
      <ng-container
          [matColumnDef]="column"
          *ngFor="let column of displayedColumns"
          >
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column }}</th>
          <td mat-cell *matCellDef="let element">{{ processElement(column, element[column]) }}</td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="displayedColumns.lenght">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>
    <!-- <mat-paginator
        [pageSize]="3"
        [length]="data.length"
        showFirstLastButtons
    ></mat-paginator> -->
  </div>

  <eng-no-data *ngIf="!data || data.length === 0"></eng-no-data>
</div>