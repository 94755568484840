import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  multiFactor,
  MultiFactorAssertion,
  MultiFactorInfo,
  TotpMultiFactorAssertion,
  TotpMultiFactorGenerator,
  TotpSecret,
} from 'firebase/auth';
import { UEAPIService } from 'src/app/services/ue/api.service';
import { SpinnerService } from 'src/app/services/ue/spinner.service';
import { ToasterService } from 'src/app/services/ue/toaster.service';

@Component({
  selector: 'eng-time-out-dialog',
  templateUrl: './time-out-dialog.component.html',
  styleUrls: ['./time-out-dialog.component.scss'],
})
export class TimeOutDialogComponent implements OnInit {
  // private multiFactorResolver: MultiFactorResolver;
  public loginForm!: UntypedFormGroup;
  public auth: any;
  private totpSecret: TotpSecret;

  constructor(
    private toastService: ToasterService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<TimeOutDialogComponent>,
    private apiService: UEAPIService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.spinnerService.hide();
    this.loginForm = this.formBuilder.group({
      verificationCode: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)],
      ],
    });
  }

  public async verifyCode() {
    try {
      const verificationCode = this.loginForm.value.verificationCode;
      const currentUser = this.apiService.auth?.currentUser;

      if (currentUser) {
        const enrolledFactors: MultiFactorInfo[] =
          multiFactor(currentUser).enrolledFactors;

        if (enrolledFactors?.length) {
          const enrolledTotpFactor: MultiFactorInfo | undefined =
            enrolledFactors.find((factor) => factor.factorId === 'totp');

          if (enrolledTotpFactor) {
            const multiFactorAssertion: TotpMultiFactorAssertion =
              TotpMultiFactorGenerator.assertionForSignIn(
                enrolledTotpFactor.uid,
                verificationCode
              );

            const userCredential: any =
              await this.apiService.multiFactorResolver.resolveSignIn(
                multiFactorAssertion
              );

            localStorage.setItem('idToken', userCredential.user.accessToken);
          }
        } else {
          const multiFactorAssertion: TotpMultiFactorAssertion =
            TotpMultiFactorGenerator.assertionForEnrollment(
              this.totpSecret,
              verificationCode
            );

          const userCredential: any = await multiFactor(currentUser).enroll(
            multiFactorAssertion,
            'TOTP MFA'
          );
          localStorage.setItem('idToken', userCredential.user.accessToken);
        }

        this.toastService.showToastr('Login Successful', 200);
        this.close(true);
      } else {
        // Get the TOTP factor from the resolver hints
        const totpFactor: MultiFactorInfo | undefined =
          this.apiService.multiFactorResolver?.hints?.find(
            (factor) => factor?.factorId === 'totp'
          );
        if (totpFactor) {
          const multiFactorAssertion: MultiFactorAssertion =
            TotpMultiFactorGenerator.assertionForSignIn(
              totpFactor.uid,
              verificationCode
            );

          const userCredential: any =
            await this.apiService.multiFactorResolver.resolveSignIn(
              multiFactorAssertion
            );

          localStorage.setItem('idToken', userCredential.user.accessToken);
          this.toastService.showToastr('Login Successful', 200);
          this.close(true);
        } else {
          this.toastService.showToastr('Invalid Verification Code', 400);
        }
      }
    } catch (error) {
      this.toastService.showToastr('Invalid Verification Code', 400);
    }
  }

  public close(isLoggedIn: boolean) {
    this.spinnerService.show();
    this.dialogRef.close(isLoggedIn);
  }
}
