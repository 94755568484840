<div #wrapper class="pie-chart__wrapper">

    <ngx-charts-pie-chart
        *ngIf="data && data.length > 0"
        [view]="view"
        [scheme]="scheme"
        [results]="data"
        [labels]="showLabels"
    >
        <ng-template #tooltipTemplate let-model="model" data-html="true">
            {{tooltipFormat?.labelFormat}} {{model.label}}
            <br>
            {{tooltipFormat?.valueFormat}} {{model.value}}
        </ng-template>
    </ngx-charts-pie-chart>
  
    <eng-no-data *ngIf="data && data.length === 0"></eng-no-data>
</div>