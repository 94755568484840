// import { v4 as uuid } from 'uuid';
import { TreeMapComponent } from '../shared/tree-map';
import { IWidgetInterface } from './IWidgetInterface';
import { PieGridComponent } from '../shared/pie-grid';
import { LineChartComponent } from '../shared/line-chart';
import { PieChartComponent } from '../shared/pie-chart';
import { StackedVerticalChartComponent } from '../shared/stacked-vertical-chart';
import { AreaChartComponent } from '../shared/area-chart';
import { SchemePalette } from '../shared/common-behaviors/scheme';
import { ElasticPropertiesType, MockPropertiesType } from '../services/connection/connection.service';
import { NumberCardComponent } from '../shared/number-card';
import { ElasticQueryType } from '../services/elastic/elastic-query.service';
import { TooltipFormat } from '../models/tooltip-format.model';
import { SourceApiType } from '../models/account.model';
import { BarChartComponent } from '../shared/bar-chart';
import { TableComponent } from '../shared/table/table.component';
import { TimeframeType } from '../models/timeframe-type';
import { AdvancedPieChartComponent } from '../shared/advanced-pie-chart';
import { GroupedVerticalChartComponent } from '../shared/grouped-vertical-chart';
import { bytesTemplate } from '../shared/nx-chart-base/chart.model';
import { MapWidgetComponent } from '../shared/map-widget';

export enum DataSourceType {
    Mock,
    Appsync,
    Elastic
}

export type ChartType = 'tree-map' | 'pie-grid' | 'line' | 'bar' | 'bubble' |
    'number-card' | 'line-2' | 'pie-chart' | 'stacked-vertical-chart' | 'area-chart' |
    'table' | 'advanced-pie-chart' | 'grouped-vertical-chart' | 'map-widget';

// TODO these need to be loaded dynamically to handle the custom dashboard
export type DashboardType = 'bi' | 'user-behavior' | 'security-events' | 'network-activity' |
    'powershell' | 'audit-beat' | 'file-beat' | 'packet-beat' | 'enigmas';

// TODO Mode to another folder
export type ToolType = 'network' | 'discover' | 'alert' | 'log-insights' | 'ike-tool';

export type SizeType = 'small' | 'medium' | 'large' | 'xlarge';
export type rowSpan = 1 | 2;

const defaultTableFields: string[] = [
  'event_type',
  'date',
  'severity',
  'external_ip',
  'hostname',
  'disposition'
];

const defaultWindowLogsTableFields: string[] = [
  '@timestamp',
  'hostname',
  'level',
  'action',
  'module',
  'code',
  'category',
  'outcome',
  'computer_name',
];

const defaultUmbrellaTableFields: string[] = [
  'actionTaken',
  'datetime',
  'categories',
  'destination URL',
  'externalIp',
  'internalIp',
  'originLabel',
  'originType'
];

const defaultCloudFrontTableFields: string[] = [
  'c-ip',
  'c-port',
  'cs(Host)',
  'cs-uri-stem',
  'datetime',
  'ssl-cipher',
  'ssl-protocol'
];

const defaultFileBeatTableFields: string[] = [
  '@timestamp',
  'hostname',
  'level',
  'action',
  'module',
  'code',
  'category',
  'outcome',
  'computer_name',
];

const defaultPacketBeatTableFields: string[] = [
  '@timestamp',
  'hostname',
  'level',
  'action',
  'module',
  'code',
  'category',
  'outcome',
  'computer_name',
];

const mapPacketBeatTableFields: string[] = [
  ...defaultPacketBeatTableFields,
  'destination.geo.location',
  'destination.geo.country_name'
];

export interface WidgetConfig<T> {
    tableFields: string [];
    widgetType: T;
    scheme?: SchemePalette;
    menu: boolean;
    discoverable: boolean;
    defaultTimeframe?: TimeframeType;
    size: SizeType;
    rowSpan: rowSpan;
    tooltipFormat?: TooltipFormat;
    position: [number, number];
    curveType?: string;
    yAxisFunction?: any;
    /** more nx charts config can go in here
     * to see more configurable options
     * check out:
     * src/app/shared/nx-chart-base/nx-chart-base.directive.ts
     */
}

// TODO dashboard config
// When we get to the point of creating custom dashboards
// we will need configuration for such
// export interface DashboardConfig {
//     scheme:
// }

export interface WidgetSource {
    dataSourceType: DataSourceType;
    functionName: MockPropertiesType | ElasticPropertiesType;
    inputData?: any;
    query?: ElasticQueryType | Array<ElasticQueryType>;
    sourceApiType?: SourceApiType;
    isMultipleQueries?: boolean;
}

export interface WidgetModel {
    id: number;
    name: string;
    chartType: ChartType;
    description: string;
    dashboard: DashboardType[];
    widgetSource: WidgetSource;
    // order?: any  // adding locations of widget to be displayed
    config: WidgetConfig<IWidgetInterface>;
}

export const initialWidgetRegistry: WidgetModel[] = [
  {
    id: 6,
    name: 'Cognito Login Attempts',
    chartType: 'pie-chart',
    dashboard: ['bi'],
    description: 'This pie chart will show the breakdown of successful and vs failed cloudfront geolocation to a domain.',
    widgetSource: {
      dataSourceType: DataSourceType.Mock,
      functionName: 'fetchCognitoLoginData'
    },
    config: {
      tableFields: defaultTableFields,
      widgetType: PieChartComponent,
      menu: false,
      discoverable: true,
      scheme: 'ocean',
      size: 'medium',
      rowSpan: 1,
      position: [0, 0]
    }
  },
  {
    id: 7,
    name: 'Cloudfront Edge Locations',
    chartType: 'stacked-vertical-chart',
    dashboard: ['bi'],
    // eslint-disable-next-line max-len
    description: 'This bar chart of the edge location count. Each edge will have a total number of access account attempts in a given time period sampled.',
    widgetSource: {
      dataSourceType: DataSourceType.Mock,
      functionName: 'fetchCloudfrontEdgeLocationsData'
    },
    config: {
      tableFields: defaultTableFields,
      widgetType: StackedVerticalChartComponent,
      menu: false,
      discoverable: true,
      scheme: 'ocean',
      size: 'large',
      rowSpan: 1,
      position: [0, 0]
    }
  },
  {
    id: 8,
    name: 'Cloudfront Core Bundle Miss Count',
    chartType: 'area-chart',
    dashboard: ['bi'],
    description: 'This area chart of cloudfront core bundle miss count',
    widgetSource: {
      dataSourceType: DataSourceType.Mock,
      functionName: 'fetchCloudfrontCoreBundleMissCountData'
    },
    config: {
      tableFields: defaultTableFields,
      widgetType: AreaChartComponent,
      menu: false,
      discoverable: true,
      scheme: 'ocean',
      size: 'large',
      rowSpan: 1,
      position: [0, 0]
    }
  },
  {
    id: 9,
    name: 'Host Online',
    chartType: 'number-card',
    dashboard: ['user-behavior'],
    description: 'The number of unique hosts online sending data',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      query: 'endpointsSendingDataQuery',
      functionName: 'fetchEndpointsSendingData',
      sourceApiType: SourceApiType.WindowLogs
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: NumberCardComponent,
      menu: true,
      discoverable: false,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.Day,
      size: 'small',
      rowSpan: 1,
      position: [1, 1]
    }
  },
  {
    id: 10,
    name: 'Total Events',
    chartType: 'number-card',
    dashboard: ['user-behavior'],
    description: 'This total events',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      query: 'totalEventsQuery',
      functionName: 'fetchTotalEventsData',
      sourceApiType: SourceApiType.WindowLogs
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: NumberCardComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.Day,
      size: 'small',
      rowSpan: 1,
      position: [1, 2]
    }
  },
  {
    id: 11,
    name: 'User Authentication Failures',
    chartType: 'number-card',
    dashboard: ['user-behavior'],
    description: 'This number card of user authentication failures',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      query: 'userAuthFailuresQuery',
      functionName: 'fetchUserAuthFailuresData',
      sourceApiType: SourceApiType.WindowLogs
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: NumberCardComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.Day,
      size: 'small',
      rowSpan: 1,
      position: [1, 3]
    }
  },
  {
    id: 12,
    name: 'Total Remote Commands',
    chartType: 'number-card',
    dashboard: ['user-behavior'],
    description: 'This number card of total remote commands',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      query: 'totalRemoteCommandsQuery',
      functionName: 'fetchTotalRemoteCommandsData',
      sourceApiType: SourceApiType.WindowLogs
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: NumberCardComponent,
      menu: true,
      discoverable: false,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.Day,
      size: 'small',
      rowSpan: 1,
      position: [1, 4]
    }
  },
  {
    id: 13,
    name: 'Top Events',
    chartType: 'pie-chart',
    dashboard: ['user-behavior'],
    description: 'This pie chart of top events',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      query: 'topEventsQuery',
      functionName: 'fetchTopEventsData',
      sourceApiType: SourceApiType.WindowLogs
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: PieChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.Day,
      size: 'medium',
      rowSpan: 1,
      tooltipFormat: {
        labelFormat: 'Event Id: ',
        valueFormat: 'Count: '
      },
      position: [2, 1]
    }
  },
  {
    id: 14,
    name: 'Sources (Provider Names)',
    chartType: 'pie-chart',
    dashboard: ['user-behavior'],
    description: 'This pie chart of sources (Provider Names)',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      query: 'sourcesProviderNamesQuery',
      functionName: 'fetchSourcesProviderNamesData',
      sourceApiType: SourceApiType.WindowLogs
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: PieChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.Day,
      size: 'medium',
      rowSpan: 1,
      position: [2, 2]
    }
  },
  {
    id: 15,
    name: 'Authentication Activity Breakdown',
    chartType: 'pie-chart',
    dashboard: ['user-behavior'],
    description: 'This pie chart of authentication activity breakdown',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      query: 'authActivityBreakdownQuery',
      functionName: 'fetchAuthActivityBreakdownData',
      sourceApiType: SourceApiType.WindowLogs
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: PieChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.Day,
      size: 'medium',
      rowSpan: 1,
      tooltipFormat: {
        labelFormat: 'Authentication: ',
        valueFormat: 'Value: '
      },
      position: [2, 3]
    }
  },
  {
    id: 16,
    name: 'Logon Activity Over Time',
    chartType: 'area-chart',
    dashboard: ['user-behavior'],
    description: 'This area chart of logon activity over time ',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      query: 'logonActivityOverTimeQuery',
      functionName: 'fetchLogonActivityOverTimeData',
      sourceApiType: SourceApiType.WindowLogs
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: AreaChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.Day,
      size: 'large',
      rowSpan: 1,
      position: [3, 1]
    }
  },
  {
    id: 17,
    name: 'Number of Events Over Time By Channel',
    chartType: 'stacked-vertical-chart',
    dashboard: ['user-behavior'],
    description: 'This bar chart of number of events over time by channel.',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      query: 'eventsOverTimeQuery',
      functionName: 'fetchEventsOverTimeData',
      sourceApiType: SourceApiType.WindowLogs
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: StackedVerticalChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.Day,
      size: 'large',
      rowSpan: 1,
      position: [3, 2]
    }
  },
  {
    id: 18,
    name: 'Quarantine Failure',
    chartType: 'number-card',
    dashboard: ['security-events'],
    description: 'Quarantine Failure',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.CiscoAmp,
      functionName: 'fetchEventData',
      query: 'eventQuery',
      inputData: 'Quarantine Failure'
    },
    config: {
      tableFields: defaultTableFields,
      widgetType: NumberCardComponent,
      menu: true,
      discoverable: true,
      scheme: 'flame',
      defaultTimeframe: TimeframeType.ThreeYears,
      size: 'small',
      rowSpan: 1,
      position: [1, 1]
    }
  },
  {
    id: 19,
    name: 'Threat Quarantined',
    chartType: 'number-card',
    dashboard: ['security-events'],
    description: 'This threat quarantined',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.CiscoAmp,
      functionName: 'fetchEventData',
      query: 'eventQuery',
      inputData: 'Threat Quarantined'
    },
    config: {
      tableFields: defaultTableFields,
      widgetType: NumberCardComponent,
      menu: true,
      discoverable: true,
      scheme: 'flame',
      defaultTimeframe: TimeframeType.ThreeYears,
      size: 'small',
      rowSpan: 1,
      position: [1, 2]
    }
  },
  {
    id: 20,
    name: 'Vulnerable Application Detected',
    chartType: 'number-card',
    dashboard: ['security-events'],
    description: 'This number card of vulnerable application detected',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.CiscoAmp,
      functionName: 'fetchEventData',
      query: 'eventQuery',
      inputData: 'Vulnerable Application Detected'
    },
    config: {
      tableFields: defaultTableFields,
      widgetType: NumberCardComponent,
      menu: true,
      discoverable: true,
      scheme: 'flame',
      defaultTimeframe: TimeframeType.ThreeYears,
      size: 'small',
      rowSpan: 1,
      position: [1, 3]
    }
  },
  {
    id: 21,
    name: 'Cloud IOC',
    chartType: 'number-card',
    dashboard: ['security-events'],
    description: 'This number card of cloud IOC',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.CiscoAmp,
      functionName: 'fetchEventData',
      query: 'eventQuery',
      inputData: 'Cloud IOC'
    },
    config: {
      tableFields: defaultTableFields,
      widgetType: NumberCardComponent,
      menu: true,
      discoverable: true,
      scheme: 'flame',
      defaultTimeframe: TimeframeType.ThreeYears,
      size: 'small',
      rowSpan: 1,
      position: [1, 4]
    }
  },
  {
    id: 22,
    name: 'Security Events',
    chartType: 'tree-map',
    dashboard: ['security-events'],
    // eslint-disable-next-line max-len
    description: 'This widget is designed to show events catagorized by severity. The severities are broken down in high, medium, low. The tree map allows to drill down into each severity.',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.CiscoAmp,
      functionName: 'fetchSeveritiesData',
      query: ['severitiesQuery', 'severitiesQuery', 'severitiesQuery'],
      inputData: ['High', 'Medium', 'Low'],
      isMultipleQueries: true
    },
    config: {
      tableFields: defaultTableFields,
      widgetType: TreeMapComponent,
      menu: true,
      discoverable: true,
      scheme: 'flame',
      defaultTimeframe: TimeframeType.ThreeYears,
      size: 'medium',
      rowSpan: 1,
      position: [2, 1]
    }
  },
  {
    id: 23,
    name: 'Top Security Alerts',
    chartType: 'pie-grid',
    dashboard: ['security-events'],
    description: 'This widget breaks down severities into a pie grid of low, medium, high and critical',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.CiscoAmp,
      functionName: 'fetchSeverityHitsData',
      query: 'severityTopHitsQuery',
    },
    config: {
      tableFields: defaultTableFields,
      widgetType: PieGridComponent,
      menu: true,
      discoverable: true,
      scheme: 'flame',
      defaultTimeframe: TimeframeType.ThreeYears,
      size: 'medium',
      rowSpan: 1,
      position: [2, 2]
    }
  },
  {
    id: 24,
    name: 'Top Users Triggering AV Alerts',
    chartType: 'pie-chart',
    dashboard: ['security-events'],
    description: 'This pie chart of top users triggering av alerts',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.CiscoAmp,
      query: 'topUserAlertsQuery',
      functionName: 'fetchTopUserAlertsData'
    },
    config: {
      tableFields: defaultTableFields,
      widgetType: PieChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'flame',
      defaultTimeframe: TimeframeType.ThreeYears,
      size: 'medium',
      rowSpan: 1,
      tooltipFormat: {
        labelFormat: 'User: ',
        valueFormat: 'Value: '
      },
      position: [2, 3]
    }
  },
  {
    id: 26,
    name: 'Antivirus Events Over Time (Cisco AMP)',
    chartType: 'line',
    dashboard: ['security-events'],
    // eslint-disable-next-line max-len
    description: 'This line chart displays the number of events over time. The line chart has a time filter slider at the bottom that can be used to zoom in.',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.CiscoAmp,
      functionName: 'fetchHitsOverTimeAmpData',
      query: 'hitsOverTimeAmpQuery',
      isMultipleQueries: false
    },
    config: {
      tableFields: defaultTableFields,
      widgetType: LineChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'flame',
      defaultTimeframe: TimeframeType.ThreeYears,
      size: 'xlarge',
      rowSpan: 1,
      position: [4, 1]
    }
  },
  {
    id: 25,
    name: 'DNS Trends (Cisco Umbrella)',
    chartType: 'line',
    dashboard: ['security-events'],
    // eslint-disable-next-line max-len
    description: 'This line chart displays the number of events over time. The line chart has a time filter slider at the bottom that can be used to zoom in.',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.CiscoAmp,
      functionName: 'fetchHitsOverTimeUmbrellaData',
      query: 'hitsOverTimeUmbrellaQuery',
      isMultipleQueries: false
    },
    config: {
      tableFields: defaultUmbrellaTableFields,
      widgetType: AreaChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'flame',
      defaultTimeframe: TimeframeType.ThreeYears,
      size: 'xlarge',
      rowSpan: 1,
      position: [3, 1]
    }
  },
  {
    id: 28,
    name: 'Cloudfront Result Type',
    chartType: 'pie-chart',
    dashboard: ['network-activity'],
    description: 'This pie chart of Cloudfront result type',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.CloudfrontLogs,
      query: 'cloudfrontPieChartQuery',
      functionName: 'fetchCloudfrontResultTypeData',
      inputData: 'x-edge-detailed-result-type.keyword',
    },
    config: {
      tableFields: defaultCloudFrontTableFields,
      widgetType: PieChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'vivid',
      defaultTimeframe: TimeframeType.Week,
      size: 'large',
      rowSpan: 1,
      tooltipFormat: {
        labelFormat: 'Field: ',
        valueFormat: 'Value: '
      },
      position: [1, 1]
    }
  },
  {
    id: 29,
    name: 'Cloudfront User Agent',
    chartType: 'pie-chart',
    dashboard: ['network-activity'],
    description: 'This pie chart of Cloudfront user agent',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.CloudfrontLogs,
      query: 'cloudfrontPieChartQuery',
      functionName: 'fetchCloudfrontResultTypeData',
      inputData: 'cs(User-Agent).keyword',
    },
    config: {
      tableFields: defaultCloudFrontTableFields,
      widgetType: PieChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'vivid',
      defaultTimeframe: TimeframeType.Week,
      size: 'large',
      rowSpan: 1,
      tooltipFormat: {
        labelFormat: 'Field: ',
        valueFormat: 'Value: '
      },
      position: [1, 2]
    }
  },
  {
    id: 30,
    name: 'Cloudfront (Total Hits)',
    chartType: 'number-card',
    dashboard: ['network-activity'],
    description: 'Cloudfront (total hits)',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.CloudfrontLogs,
      query: 'cloudfrontTotalHitsQuery',
      functionName: 'fetchCloudfrontTotalHitsData'
    },
    config: {
      tableFields: defaultCloudFrontTableFields,
      widgetType: NumberCardComponent,
      menu: true,
      discoverable: true,
      scheme: 'vivid',
      defaultTimeframe: TimeframeType.Week,
      size: 'small',
      rowSpan: 1,
      position: [2, 1]
    }
  },
  {
    id: 31,
    name: 'Unique IPs Hitting The Firewall',
    chartType: 'number-card',
    dashboard: ['network-activity'],
    description: 'Unique IPs Hitting the firewall',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.CloudfrontLogs,
      query: 'cloudfrontHittingFirewallQuery',
      functionName: 'fetchCloudfrontHittingFirewallData'
    },
    config: {
      tableFields: defaultCloudFrontTableFields,
      widgetType: NumberCardComponent,
      menu: true,
      discoverable: true,
      scheme: 'vivid',
      defaultTimeframe: TimeframeType.Week,
      size: 'small',
      rowSpan: 1,
      position: [2, 2]
    }
  },
  {
    id: 32,
    name: 'Cloudfront Blocked IPs',
    chartType: 'table',
    dashboard: ['network-activity'],
    description: 'Cloudfront Blocked IPs',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.CloudfrontLogs,
      query: 'cloudfrontBlockedIPsQuery',
      functionName: 'fetchCloudfrontBlockedIPsData'
    },
    config: {
      tableFields: defaultCloudFrontTableFields,
      widgetType: TableComponent,
      menu: true,
      discoverable: true,
      scheme: 'vivid',
      defaultTimeframe: TimeframeType.Week,
      size: 'large',
      rowSpan: 1,
      position: [2, 3]
    }
  },
  {
    id: 33,
    name: 'Cloudfront - Hits Per Day',
    chartType: 'bar',
    dashboard: ['network-activity'],
    description: 'cloudfront - hits per day',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.CloudfrontLogs,
      query: 'cloudfrontHitsPerDayQuery',
      functionName: 'fetchCloudfrontHitsPerDayData'
    },
    config: {
      tableFields: defaultCloudFrontTableFields,
      widgetType: BarChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'vivid',
      defaultTimeframe: TimeframeType.Month,
      size: 'xlarge',
      rowSpan: 1,
      position: [3, 1]
    }
  },
  // PowerShell
  {
    id: 34,
    name: 'Started providers [Winlogbeat powershell]',
    chartType: 'pie-chart',
    dashboard: ['powershell'],
    description: 'This pie chart of Started providers [Winlogbeat powershell]',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.WindowLogs,
      query: 'startedProvidersQuery',
      functionName: 'fetchStartedProvidersData',
      inputData: 'powershell.provider.name',
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: PieChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'vivid',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'large',
      rowSpan: 1,
      tooltipFormat: {
        labelFormat: 'Field: ',
        valueFormat: 'Value: '
      },
      position: [1, 1]
    }
  },
  {
    id: 35,
    name: 'Event type [Winlogbeat powershell]',
    chartType: 'pie-chart',
    dashboard: ['powershell'],
    description: 'This pie chart of Event type',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.WindowLogs,
      query: 'startedProvidersQuery',
      functionName: 'fetchStartedProvidersData',
      inputData: 'event.code',
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: PieChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'vivid',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'large',
      rowSpan: 1,
      tooltipFormat: {
        labelFormat: 'Field: ',
        valueFormat: 'Value: '
      },
      position: [1, 2]
    }
  },
  {
    id: 36,
    name: 'Total engine started',
    chartType: 'number-card',
    dashboard: ['powershell'],
    description: 'Total engine started',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.WindowLogs,
      query: 'totalEngineStartedQuery',
      functionName: 'fetchTotalEngineStartedData'
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: NumberCardComponent,
      menu: true,
      discoverable: true,
      scheme: 'vivid',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'small',
      rowSpan: 1,
      position: [2, 1]
    }
  },
  {
    id: 37,
    name: 'Unique hosts',
    chartType: 'number-card',
    dashboard: ['powershell'],
    description: 'Unique hosts',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.WindowLogs,
      query: 'uniqueHostsQuery',
      functionName: 'fetchUniqueHostsData'
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: NumberCardComponent,
      menu: true,
      discoverable: true,
      scheme: 'vivid',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'small',
      rowSpan: 1,
      position: [2, 2]
    }
  },
  {
    id: 38,
    name: 'Top active hosts',
    chartType: 'table',
    dashboard: ['powershell'],
    description: 'Top active hosts',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.WindowLogs,
      query: 'topActiveHostsQuery',
      functionName: 'fetchTopActiveHostsData'
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: TableComponent,
      menu: true,
      discoverable: true,
      scheme: 'vivid',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'large',
      rowSpan: 1,
      position: [2, 3]
    }
  },
  {
    id: 39,
    name: 'Engine and Command started',
    chartType: 'line',
    dashboard: ['powershell'],
    description: 'Engine and Command started',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.WindowLogs,
      query: 'engineAndCommandStartedQuery',
      functionName: 'fetchEngineAndCommandStartedData'
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: LineChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'vivid',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'xlarge',
      rowSpan: 1,
      position: [3, 1],
      curveType: 'Linear'
    }
  },
  {
    id: 40,
    name: 'Most Changed File By Count',
    chartType: 'number-card',
    dashboard: ['audit-beat'],
    description: 'Most changed file by count [Auditbeat File Integrity] ECS',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.LinuxLogs,
      query: 'mostChangedFileQuery',
      functionName: 'fetchMostChangedFileData'
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: NumberCardComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.Week,
      size: 'small',
      rowSpan: 1,
      position: [1, 1]
    }
  },
  {
    id: 41,
    name: 'New User Account Created',
    chartType: 'number-card',
    dashboard: ['audit-beat'],
    description: 'New User Account Created',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.LinuxLogs,
      query: 'newUserAccountCreatedQuery',
      functionName: 'fetchNewUserAccountCreatedQueryData'
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: NumberCardComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.Week,
      size: 'small',
      rowSpan: 1,
      position: [1, 2]
    }
  },
  {
    id: 42,
    name: 'Authentication Failures',
    chartType: 'number-card',
    dashboard: ['audit-beat'],
    description: 'Authentication Failures',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.LinuxLogs,
      query: 'authenticationFailuresQuery',
      functionName: 'fetchAuthenticationFailuresQueryData'
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: NumberCardComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.Week,
      size: 'small',
      rowSpan: 1,
      position: [1, 3]
    }
  },
  {
    id: 43,
    name: 'Password Resets',
    chartType: 'number-card',
    dashboard: ['audit-beat'],
    description: 'Changed Password',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.LinuxLogs,
      query: 'changedPasswordQuery',
      functionName: 'fetchChangedPasswordQueryData'
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: NumberCardComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.Week,
      size: 'small',
      rowSpan: 1,
      position: [1, 4]
    }
  },
  {
    id: 44,
    name: 'OS Distribution',
    chartType: 'advanced-pie-chart',
    dashboard: ['audit-beat'],
    description: 'OS Distribution [Auditbeat System] ECS',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.LinuxLogs,
      query: 'OSDistributionQuery',
      functionName: 'fetchOSDistributionData',
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: AdvancedPieChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.Week,
      size: 'medium',
      rowSpan: 1,
      tooltipFormat: {
        labelFormat: 'Field: ',
        valueFormat: 'Value: '
      },
      position: [2, 1]
    }
  },
  {
    id: 45,
    name: 'Event Categories',
    chartType: 'advanced-pie-chart',
    dashboard: ['audit-beat'],
    description: 'Event Categories [Auditbeat Auditd] ECS',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.LinuxLogs,
      query: 'eventCategoriesQuery',
      functionName: 'fetchEventCategoriesData',
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: AdvancedPieChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.Week,
      size: 'medium',
      rowSpan: 1,
      tooltipFormat: {
        labelFormat: 'Field: ',
        valueFormat: 'Value: '
      },
      position: [2, 1]
    }
  },
  {
    id: 46,
    name: 'Event Actions',
    chartType: 'area-chart',
    dashboard: ['audit-beat'],
    description: 'Event Actions [Auditbeat Auditd] ECS',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.LinuxLogs,
      query: 'eventActionsQuery',
      functionName: 'fetchEventActionsData'
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: AreaChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.HalfWeek,
      size: 'medium',
      rowSpan: 1,
      position: [2, 3]
    }
  },
  {
    id: 47,
    name: 'Total Events Over Time',
    chartType: 'bar',
    dashboard: ['audit-beat'],
    description: 'Total Events Over Time [Auditbeat File Integrity] ECS',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.LinuxLogs,
      query: 'eventsOverTimeECSQuery',
      functionName: 'fetchEventsOverTimeECSData'
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: BarChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'vivid',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'xlarge',
      rowSpan: 1,
      position: [3, 1]
    }
  },
  {
    id: 48,
    name: 'System Event Histogram',
    chartType: 'bar',
    dashboard: ['audit-beat'],
    description: 'System Event Histogram [Auditbeat System] ECS',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.LinuxLogs,
      query: 'systemEventHistogramQuery',
      functionName: 'fetchSystemEventHistogramData'
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: BarChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'vivid',
      defaultTimeframe: TimeframeType.Week,
      size: 'large',
      rowSpan: 1,
      position: [4, 1]
    }
  },
  {
    id: 49,
    name: 'Host List',
    chartType: 'table',
    dashboard: ['audit-beat'],
    description: 'Host List [Auditbeat System] ECS',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.LinuxLogs,
      query: 'hostListQuery',
      functionName: 'fetchHostListQueryData'
    },
    config: {
      tableFields: defaultWindowLogsTableFields,
      widgetType: TableComponent,
      menu: true,
      discoverable: true,
      scheme: 'vivid',
      defaultTimeframe: TimeframeType.Week,
      size: 'large',
      rowSpan: 1,
      position: [4, 2]
    }
  },
  {
    id: 50,
    name: 'Denied Connection Attempts',
    chartType: 'number-card',
    dashboard: ['file-beat'],
    description: 'Unique Denied (Blocked) Connection Attempts',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.FileBeatLinuxLogs,
      query: 'uniqueDeniedQuery',
      functionName: 'fetchUniqueDeniedData'
    },
    config: {
      tableFields: defaultFileBeatTableFields,
      widgetType: NumberCardComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'small',
      rowSpan: 1,
      position: [1, 1]
    }
  },
  {
    id: 51,
    name: 'VPN Activity',
    chartType: 'number-card',
    dashboard: ['file-beat'],
    description: 'VPN Activity',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.FileBeatLinuxLogs,
      query: 'vpnActivityQuery',
      functionName: 'fetchVpnActivityData'
    },
    config: {
      tableFields: defaultFileBeatTableFields,
      widgetType: NumberCardComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'small',
      rowSpan: 1,
      position: [1, 2]
    }
  },
  {
    id: 52,
    name: 'Successful Connections',
    chartType: 'number-card',
    dashboard: ['file-beat'],
    description: 'Successful Connections',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.FileBeatLinuxLogs,
      query: 'successfulConnectionsQuery',
      functionName: 'fetchSuccessfulConnectionsData'
    },
    config: {
      tableFields: defaultFileBeatTableFields,
      widgetType: NumberCardComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'small',
      rowSpan: 1,
      position: [1, 3]
    }
  },
  {
    id: 53,
    name: 'Top ACL by Blocked',
    chartType: 'table',
    dashboard: ['file-beat'],
    description: 'Top ACL by Blocked',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.FileBeatLinuxLogs,
      query: 'topACLBlockedQuery',
      functionName: 'fetchTopACLBlockedData'
    },
    config: {
      tableFields: defaultFileBeatTableFields,
      widgetType: TableComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'small',
      rowSpan: 1,
      position: [1, 4]
    }
  },
  {
    id: 54,
    name: 'Geographic Breakdown - ASA Connection Attempts (Denys)',
    chartType: 'tree-map',
    dashboard: ['file-beat'],
    description: 'Geographic Breakdown - ASA Connection Attempts (Denys)',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.FileBeatLinuxLogs,
      query: 'geographicBreakdownQuery',
      functionName: 'fetchGeographicBreakdownData'
    },
    config: {
      tableFields: defaultFileBeatTableFields,
      widgetType: TreeMapComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'medium',
      rowSpan: 1,
      position: [2, 1]
    }
  },
  {
    id: 55,
    name: 'Top Blocked Source IPs',
    chartType: 'stacked-vertical-chart',
    dashboard: ['file-beat'],
    description: 'Top Blocked Source IPs',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.FileBeatLinuxLogs,
      query: 'topBlockedSourceIPsQuery',
      functionName: 'fetchTopBlockedSourceIPsData'
    },
    config: {
      tableFields: defaultFileBeatTableFields,
      widgetType: StackedVerticalChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'medium',
      rowSpan: 1,
      position: [2, 2]
    }
  },
  {
    id: 56,
    name: 'Top ASA Messages',
    chartType: 'table',
    dashboard: ['file-beat'],
    description: 'Top ASA Messages',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.FileBeatLinuxLogs,
      query: 'topASAMessagesQuery',
      functionName: 'fetchTopASAMessagesData'
    },
    config: {
      tableFields: defaultFileBeatTableFields,
      widgetType: TableComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'xlarge',
      rowSpan: 1,
      position: [3, 1]
    }
  },
  {
    id: 57,
    name: 'ASA Firewall Events Over Time',
    chartType: 'stacked-vertical-chart',
    dashboard: ['file-beat'],
    description: 'ASA Firewall Events Over Time',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.FileBeatLinuxLogs,
      query: 'firewallEventsQuery',
      functionName: 'fetchFirewallEventsData'
    },
    config: {
      tableFields: defaultFileBeatTableFields,
      widgetType: StackedVerticalChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'xlarge',
      rowSpan: 1,
      position: [4, 1]
    }
  },
  {
    id: 58,
    name: 'Top Source Ports Blocked By The ASA',
    chartType: 'table',
    dashboard: ['file-beat'],
    description: 'Top Source Ports Blocked By The ASA',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.FileBeatLinuxLogs,
      query: 'portsBlockedASAQuery',
      functionName: 'fetchPortsBlockedASAData'
    },
    config: {
      tableFields: defaultFileBeatTableFields,
      widgetType: TableComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'xlarge',
      rowSpan: 1,
      position: [5, 1]
    }
  },
  {
    id: 59,
    name: 'Network Events - SIEM Request',
    chartType: 'number-card',
    dashboard: ['packet-beat'],
    description: 'Network Events (from SIEM Request)',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.LinuxLogs,
      query: 'networkEventsQuery',
      functionName: 'fetchNetworkEventsData'
    },
    config: {
      tableFields: defaultPacketBeatTableFields,
      widgetType: NumberCardComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'small',
      rowSpan: 1,
      position: [1, 1]
    }
  },
  {
    id: 60,
    name: 'DNS Queries',
    chartType: 'number-card',
    dashboard: ['packet-beat'],
    description: 'DNS Queries',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.LinuxLogs,
      query: 'dnsQueriesQuery',
      functionName: 'fetchDNSQueriesData'
    },
    config: {
      tableFields: defaultPacketBeatTableFields,
      widgetType: NumberCardComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'small',
      rowSpan: 1,
      position: [1, 2]
    }
  },
  {
    id: 61,
    name: 'Total Number - HTTP Transactions',
    chartType: 'number-card',
    dashboard: ['packet-beat'],
    description: 'Total Number of HTTP Transactions',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.LinuxLogs,
      query: 'httpTransactionsQuery',
      functionName: 'fetchHTTPTransactionsData'
    },
    config: {
      tableFields: defaultPacketBeatTableFields,
      widgetType: NumberCardComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'small',
      rowSpan: 1,
      position: [1, 3]
    }
  },
  {
    id: 62,
    name: 'Total Number - TLS Sessions',
    chartType: 'number-card',
    dashboard: ['packet-beat'],
    description: 'Total Number of TLS Sessions',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.LinuxLogs,
      query: 'tlsSessionsQuery',
      functionName: 'fetchTLSSessionsData'
    },
    config: {
      tableFields: defaultPacketBeatTableFields,
      widgetType: NumberCardComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'small',
      rowSpan: 1,
      position: [1, 4]
    }
  },
  {
    id: 63,
    name: 'Source Traffic Breakdown',
    chartType: 'tree-map',
    dashboard: ['packet-beat'],
    description: 'Source Traffic Breakdown',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.PacketBeat,
      query: 'sourceTrafficBreakdownQuery',
      functionName: 'fetchSourceTrafficBreakdownData'
    },
    config: {
      tableFields: defaultPacketBeatTableFields,
      widgetType: TreeMapComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'large',
      rowSpan: 1,
      position: [2, 1]
    }
  },
  {
    id: 64,
    name: 'Top Destinations',
    chartType: 'pie-chart',
    dashboard: ['packet-beat'],
    description: 'Top Destinations',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.PacketBeat,
      query: 'topDestinationsQuery',
      functionName: 'fetchTopDestinationsData',
    },
    config: {
      tableFields: defaultPacketBeatTableFields,
      widgetType: PieChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'medium',
      rowSpan: 1,
      tooltipFormat: {
        labelFormat: 'Field: ',
        valueFormat: 'Value: '
      },
      position: [2, 2]
    }
  },
  {
    id: 65,
    name: 'Traffic Breakdown (Type)',
    chartType: 'stacked-vertical-chart',
    dashboard: ['packet-beat'],
    description: 'Traffic Breakdown (Type)',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.PacketBeat,
      query: 'trafficBreakdownQuery',
      functionName: 'fetchTrafficBreakdownData'
    },
    config: {
      tableFields: defaultPacketBeatTableFields,
      widgetType: StackedVerticalChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'xlarge',
      rowSpan: 1,
      position: [3, 1]
    }
  },
  {
    id: 66,
    name: 'Connections (Transactions) Over Time',
    chartType: 'area-chart',
    dashboard: ['packet-beat'],
    description: 'Connections (Transactions) Over Time',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.PacketBeat,
      query: 'transactionsOverTimeQuery',
      functionName: 'fetchTransactionsOverTimeData'
    },
    config: {
      tableFields: defaultPacketBeatTableFields,
      widgetType: AreaChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'xlarge',
      rowSpan: 1,
      position: [4, 1]
    }
  },
  {
    id: 67,
    name: 'Top HTTP Requests',
    chartType: 'table',
    dashboard: ['packet-beat'],
    description: 'Top HTTP Requests',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.PacketBeat,
      query: 'topHTTPRequestsQuery',
      functionName: 'fetchTopHTTPRequestsData'
    },
    config: {
      tableFields: defaultPacketBeatTableFields,
      widgetType: TableComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'medium',
      rowSpan: 1,
      position: [5, 1]
    }
  },
  {
    id: 68,
    name: 'DNS Top Requests',
    chartType: 'table',
    dashboard: ['packet-beat'],
    description: 'DNS Top Requests',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.PacketBeat,
      query: 'dnsTopRequestsQuery',
      functionName: 'fetchDNSTopRequestsData'
    },
    config: {
      tableFields: defaultPacketBeatTableFields,
      widgetType: TableComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'medium',
      rowSpan: 1,
      position: [5, 2]
    }
  },
  {
    id: 69,
    name: 'Top Unique Fully Qualified Domain Names (FQDN)',
    chartType: 'grouped-vertical-chart',
    dashboard: ['packet-beat'],
    description: 'Top Unique Fully Qualified Domain Names (FQDN)',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.PacketBeat,
      query: 'uniqueFQDNQuery',
      functionName: 'fetchUniqueFQDNData'
    },
    config: {
      tableFields: defaultPacketBeatTableFields,
      widgetType: GroupedVerticalChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'medium',
      rowSpan: 1,
      position: [6, 2]
    }
  },
  {
    id: 70,
    name: 'Bytes Transferred To and From Domains',
    chartType: 'grouped-vertical-chart',
    dashboard: ['packet-beat'],
    description: 'Bytes Transferred To and From Domains',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.PacketBeat,
      query: 'bytesTransferredQuery',
      functionName: 'fetchBytesTransferredData'
    },
    config: {
      tableFields: defaultPacketBeatTableFields,
      widgetType: GroupedVerticalChartComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'medium',
      rowSpan: 1,
      position: [6, 2],
      yAxisFunction: bytesTemplate
    }
  },
  {
    id: 71,
    name: 'Outbound Connection Attempts',
    chartType: 'map-widget',
    dashboard: ['packet-beat'],
    description: 'Outbound Connection Attempts',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.PacketBeat,
      query: 'outboundConnectionAttemptsDestinationQuery',
      functionName: 'fetchOutboundConnectionAttemptsDestination'
    },
    config: {
      tableFields: mapPacketBeatTableFields,
      widgetType: MapWidgetComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'large',
      rowSpan: 2,
      position: [6, 1]
    }
  },
  {
    id: 72,
    name: 'Network Traffic Between Hosts',
    chartType: 'table',
    dashboard: ['packet-beat'],
    description: 'Network Traffic Between Hosts',
    widgetSource: {
      dataSourceType: DataSourceType.Elastic,
      sourceApiType: SourceApiType.PacketBeat,
      query: 'networkTrafficBtwHostsQuery',
      functionName: 'fetchNetworkTrafficBtwHostsData'
    },
    config: {
      tableFields: defaultPacketBeatTableFields,
      widgetType: TableComponent,
      menu: true,
      discoverable: true,
      scheme: 'ocean',
      defaultTimeframe: TimeframeType.HalfHour,
      size: 'xlarge',
      rowSpan: 2,
      position: [7, 1]
    }
  },
];
