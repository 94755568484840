import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { ChartType } from '@config';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'eng-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements AfterViewInit  {

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataSource: MatTableDataSource<any>;

  chartType: ChartType = 'table';

  @Input()
    rowSpan = 1;

  private _data: any = false;
  get data(): any {
    return this._data;
  }
  @Input()
  set data(value: any) {
    this._data = value;
    if (value && value.length > 0) {
      this.displayedColumns = Object.getOwnPropertyNames(value[0]);
      this.dataSource = new MatTableDataSource(value);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public appState: Store<AppState>
  ) {
    this.dataSource = new MatTableDataSource(new Array<any>());
    this.displayedColumns = new Array<string>();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  processElement(key: string, value: string) {

    if (key === '@timestamp' || key === 'date' || key === 'timestamp' || key === 'datetime') {
      const date = new Date(value);
      return date.toLocaleString('en-US', {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZoneName: 'short',
      });
    }

    return value ?? '-';
  }

  updateView() { }

}
