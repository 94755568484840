import { getTimeNotationFromSplit } from '@utils';
import { SourceApiType } from '../models/account.model';

/** amp queries */
export function eventQuery(event: string) {
  return {
    sourceApiType: SourceApiType.CiscoAmp,
    body: {
      size: 0,
      query: {
        term: {
          'event_type.keyword': event,
        },
      },
    },
  };
}

export function severityTopHitsQuery(timeFilter: string, size: number) {
  return {
    sourceApiType: SourceApiType.CiscoAmp,
    body: {
      size: size,
      query: {
        bool: {
          filter: [
            {
              range: {
                date: {
                  gte: timeFilter,
                  lte: 'now',
                },
              },
            },
          ],
        },
      },
      aggs: {
        top_hits: {
          terms: {
            field: 'severity.keyword',
          },
        },
      },
    },
  };
}

export function hitsOverTimeQuery(
  sourceApiType: SourceApiType,
  timeFilter: string,
  size: number,
  field: string
) {
  return {
    sourceApiType: sourceApiType,
    body: {
      size: size,
      aggs: {
        hits_over_time: {
          date_histogram: {
            field: field,
            interval: getTimeNotationFromSplit(timeFilter),
          },
        },
      },
    },
  };
}

export function severityQuery(
  severity: string,
  timeFilter: string = 'now-1w',
  size: number = 10
) {
  return {
    sourceApiType: SourceApiType.CiscoAmp,
    body: {
      size: size,
      query: {
        bool: {
          filter: [
            {
              term: {
                'severity.keyword': severity,
              },
            },
            {
              range: {
                date: {
                  gte: timeFilter,
                  lte: 'now',
                },
              },
            },
          ],
        },
      },
    },
  };
}

/** Umbrella queries */
export function eventQueryCardinality(event: string) {
  return {
    sourceApiType: SourceApiType.CiscoUmbrella,
    body: {
      size: 0,
      query: {
        bool: {
          filter: [
            {
              range: {
                datetime: {
                  gte: 'now-3M',
                  lte: 'now',
                },
              },
            },
          ],
        },
      },
      aggs: {
        type_count: {
          cardinality: {
            field: `${event}.keyword`,
          },
        },
      },
    },
  };
}

export function eventQueryActionTaken(event: string) {
  return {
    sourceApiType: SourceApiType.CiscoUmbrella,
    body: {
      size: 0,
      query: {
        bool: {
          filter: [
            {
              term: {
                'actionTaken.keyword': event,
              },
            },
          ],
        },
      },
    },
  };
}
