export interface TreeMapModel {
    name: string;
    value: number;
    extra?: string;
}

export interface TreeMapTop {
    name: string;
    children: (TreeMapBottom | TreeMapTop)[];
}

export interface TreeMapBottom {
    name: string;
    size: number;
}


