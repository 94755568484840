import { Injectable } from '@angular/core';
import { Router, CanLoad } from '@angular/router';
import { defer, from, Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { AuthService } from '../services/ue/auth.service';
import { UEAPIService } from '../services/ue/api.service';

@Injectable()
export class AuthGuardLoadService implements CanLoad {
  constructor(
    public authService: AuthService,
    public router: Router,
    public apiService: UEAPIService,
  ) {}

  canLoad(): Observable<boolean> {
    return defer(() => from(this.apiService.getUserProfile())).pipe(
      concatMap((user) => {
        if (!user) {
          return from(this.authService.renewToken()).pipe(
            map((userRenewed) => {
              if (!userRenewed) {
                this.authService.login();
                return false;
              } else {
                window.location.reload();
                return true;
              }
            }),
            catchError(err => {
              console.log('err', err);
              this.authService.login();
              return of(false);
            })
          );
        } else {
          localStorage.setItem('userProfile', JSON.stringify({
            'firstName': user.data.firstName,
            'lastName': user.data.lastName,
            'email': user.data.email,
          }));
          return of(true);
        }
      }),
      catchError(err => {
        console.log('err', err);
        this.authService.login();
        return of(false);
      })
    );
  }
}
