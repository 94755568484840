import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'enumToString'})
export class EnumToStringPipe implements PipeTransform {
  transform(value: string): string {
    value = value.replace('_', ' ');
    value = value.toLowerCase();
    const words = value.split(' ');
    words.forEach((word, index, wordsArr) => {
      wordsArr[index] = value.charAt(0).toUpperCase() + word.slice(1);
    });
    value = words.join(' ');
    return value;
  }
}
