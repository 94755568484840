// export type TimeframeType = 'minute' | 'half-hour' | 'hour' | 'half-day' | 'day' | 'half-week' | 'week' | 'month' | 'quarter' | 'year';

export enum TimeframeType {
    Minute = 'minute',
    HalfHour = 'half-hour',
    Hour = 'hour',
    HalfDay = 'half-day',
    Day = 'day',
    HalfWeek = 'half-week',
    Week = 'week',
    HalfMonth = 'half-month',
    Month = 'month',
    Quarter = 'quarter',
    HalfYear = 'half-year',
    Year = 'year',
    YearAndHalf = 'year-and-half',
    ThreeYears = 'three-years'
}

export const TimeframeChoices = [
  'minute',
  'half-hour',
  'hour',
  'half-day',
  'day',
  'half-week',
  'week',
  'month',
  'quarter',
  'year'
];

export enum CalendarInterval {
    Second = '1s',
    Minute = '1m',
    Hour = '1h',
    Day = '1d',
    Week = '1w',
    Month = '1M'
  }

export type TimeframeTypeOf = keyof typeof TimeframeType;
