import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberCardComponent } from './number-card.component';
import { RichUiModule } from '@richUi';

@NgModule({
  imports: [
    CommonModule,
    RichUiModule
  ],
  declarations: [NumberCardComponent],
  exports: [NumberCardComponent]
})
export class NumberCardModule { }
