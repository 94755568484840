import { Injectable } from '@angular/core';
import { User, UserManager, UserManagerSettings } from 'oidc-client-ts';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userManager: UserManager;

  constructor() {
    const settings: UserManagerSettings = {
      authority: process.env.STS_AUTHORITY as string,
      client_id: process.env.CLIENT_ID as string,
      redirect_uri: `${process.env.CLIENT_ROOT}signin-callback.html`,
      silent_redirect_uri: `${process.env.CLIENT_ROOT}dashboard.html`,
      post_logout_redirect_uri: `${process.env.CLIENT_ROOT}signout-callback`,
      response_type: 'code',
      scope: process.env.CLIENT_SCOPE,
      //loadUserInfo: true,
      resource: process.env.STS_AUTHORITY,
    };
    this.userManager = new UserManager(settings);
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  public renewToken(): Promise<User | null> {
    return this.userManager.signinSilent();
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }
}
