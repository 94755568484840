import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Input } from '@angular/core';
import { SizeType } from '@config';
import { TimeframeType, TimeframeTypeOf } from 'src/app/models/timeframe-type';

interface TimeframeMenuItem {
  value: TimeframeType;
  selected?: boolean;
}

@Component({
  selector: 'eng-dashboard-tile',
  templateUrl: './dashboard-tile.component.html',
  styleUrls: ['./dashboard-tile.component.scss']
})
export class DashboardTileComponent implements OnInit {
  @Input()
    title!: string;

  @Input()
    subtitle!: string;

  @Input()
    size: SizeType = 'medium';

  @Input()
    isGrid = false;

  @Input()
    defaultTimeframe: TimeframeType;

  @Input()
    fitContainer = false;

  @Input()
    smallHeight = false;

  @Input()
    rowSpan = 1;

  @Output()
    timeframe: EventEmitter<TimeframeType> = new EventEmitter();

  @Output()
    discover: EventEmitter<any> = new EventEmitter();

  get isSmall() {
    return this.size === 'small';
  }

  get isMedium() {
    return this.size === 'medium';
  }

  get isLarge() {
    return this.size === 'large';
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input()
    hasMenu = false;

  @Input()
    timeFrameSelection = true;

  @Input()
    discoverable = true;

  @Input()
    discoverButtonText = 'Discover';

  timeframeMenuItems: TimeframeMenuItem[];

  currentTimeframe: TimeframeType;

  @ViewChild('wrapper', {static: true})
    wrapper!: ElementRef;

  constructor() {
    this.timeframeMenuItems = Object.keys(TimeframeType).map((key: TimeframeTypeOf) => {
      return {
        value: TimeframeType[key],
        selected: false
      } as TimeframeMenuItem;
    });
  }

  ngOnInit() {
    this.setSelected(this.defaultTimeframe);
  }

  emitTimeframe(timeframe: TimeframeType) {
    this.timeframe.emit(timeframe);
    this.setSelected(timeframe);
  }

  setSelected(value: TimeframeType) {
    if (value) {
      this.timeframeMenuItems.forEach(item => {
        if ( item.value === value) {
          this.currentTimeframe = value;
          item.selected = true;
        } else {
          item.selected = false;
        }
      });
    }
  }

  emitDiscover() {
    this.discover.emit();
  }
}
