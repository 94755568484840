import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private toastr: ToastrService) { }

  showToastr(message: string, statusCode: number) {
    if (statusCode === 200) {
      this.toastr.success(message);
    } else if (statusCode === 400) {
      this.toastr.error(message);
    }
    else {
      this.toastr.info(message);
    }
  }

}
