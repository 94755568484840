import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PieGridComponent } from './pie-grid.component';
import { RichUiModule } from '@richUi';
import { NoDataModule } from '../no-data/no-data.module';

@NgModule({
  imports: [
    CommonModule,
    RichUiModule,
    NoDataModule
  ],
  declarations: [PieGridComponent],
  exports: [PieGridComponent]
})
export class PieGridModule { }
