import { Component, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TimeFilterService } from './time-filter.service';



@Component({
  selector: 'eng-time-filter',
  templateUrl: './time-filter.component.html',
  styleUrls: ['./time-filter.component.scss']
})

export class TimeFilterComponent implements OnInit {
  timeRangeForm: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    private  _timeFilterService: TimeFilterService) {}

  ngOnInit() {
    this._timeFilterService.currentTimeInvalid.subscribe((message: boolean) => this.changeValidity(message));
    this.timeRangeForm = this.fb.group({
      startTime: this._timeFilterService.currentStartTime,
      endTime: this._timeFilterService.currentEndTime});
  }

  changeValidity(invalid: boolean) {
    if (invalid) {
      this.timeRangeForm.setErrors({'invalid': invalid});
      this.timeRangeForm.controls['startTime'].setErrors({'invalid': invalid});
      this.timeRangeForm.controls['endTime'].setErrors({'invalid': invalid});
    } else {
      this.timeRangeForm.setErrors(null);
      this.timeRangeForm.controls['startTime'].setErrors(null);
      this.timeRangeForm.controls['endTime'].setErrors(null);
    }
  }

  onSubmit() {
    this._timeFilterService.changeTimeFilter(this.timeRangeForm.value);
  }
}
