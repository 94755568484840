import { TimeframeType, CalendarInterval } from 'src/app/models/timeframe-type';


/**
 * Eventually this can be modified to get start time.
 * For now start time is at now
 * @param timeframe
 */
export function getSplitTimeNotation(timeframe: TimeframeType): string {
  switch (timeframe) {
  case TimeframeType.Minute:
    return 'now-1m';
  case TimeframeType.HalfHour:
    return 'now-30m';
  case TimeframeType.Hour:
    return 'now-1h';
  case TimeframeType.HalfDay:
    return 'now-12h';
  case TimeframeType.Day:
    return 'now-1d';
  case TimeframeType.HalfWeek:
    return 'now-3d';
  case TimeframeType.Week:
    return 'now-1w';
  case TimeframeType.HalfMonth:
    return 'now-2w';
  case TimeframeType.Month:
    return 'now-1M';
  case TimeframeType.Quarter:
    return 'now-3M';
  case TimeframeType.Year:
    return 'now-1y';
  case TimeframeType.YearAndHalf:
    return 'now-18M';
  case TimeframeType.ThreeYears:
    return 'now-36M';
  default:
    throw new Error('timeframe not known in get split time notation');
  }
}

export function getTimeNotationFromSplit(timeframe: any): TimeframeType {
  switch (timeframe) {
  case 'now-1m':
  case 'now-5m':
    return TimeframeType.Minute;
  case 'now-30m':
    return TimeframeType.HalfHour;
  case 'now-1h':
  case 'now-5h':
    return TimeframeType.Hour;
  case 'now-12h':
    return TimeframeType.HalfDay;
  case 'now-1d':
  case 'now-2d':
    return TimeframeType.Day;
  case 'now-3d':
    return TimeframeType.HalfWeek;
  case 'now-10d':
  case 'now-1w':
    return TimeframeType.Week;
  case 'now-2w':
  case 'now-3w':
    return TimeframeType.HalfMonth;
  case 'now-1M':
    return TimeframeType.Month;
  case 'now-3M':
    return TimeframeType.Quarter;
  case 'now-6M':
  case 'now-9M':
    return TimeframeType.HalfYear;
  case 'now-18M':
  case 'now-36M':
  case 'now-1y':
  case 'now-3y':
  case 'now-5y':
    return TimeframeType.Year;
  default:
    throw new Error('timeframe not known in get split time notation');
  }
}

export function getReasonableCalendarIntervalFromSplit(timeframe: any): CalendarInterval {
  if (timeframe === 'now-1m') {
    return CalendarInterval.Second;
  } else if (timeframe === 'now-30m') {
    return CalendarInterval.Minute;
  } else if (timeframe === 'now-1h') {
    return CalendarInterval.Minute;
  } else if (timeframe === 'now-12h') {
    return CalendarInterval.Hour;
  } else if (timeframe === 'now-1d') {
    return CalendarInterval.Hour;
  } else if (timeframe === 'now-2d') {
    return CalendarInterval.Hour;
  } else if (timeframe === 'now-3d') {
    return CalendarInterval.Hour;
  } else if (timeframe === 'now-1w') {
    return CalendarInterval.Hour;
  } else if (timeframe === 'now-2w') {
    return CalendarInterval.Hour;
  } else if (timeframe === 'now-1M') {
    return CalendarInterval.Day;
  } else if (timeframe === 'now-3M') {
    return CalendarInterval.Week;
  } else if (timeframe === 'now-1y') {
    return CalendarInterval.Month;
  } else {
    throw new Error('timeframe not known in get reasonable clanedar interval split time notation');
  }
}

export function getDateHistogramInterval(timeframeType: TimeframeType): string {
  switch (timeframeType) {
  case TimeframeType.Minute:
    return '1s';
  case TimeframeType.HalfHour:
    return '30s';
  case TimeframeType.Hour:
    return '1m';
  case TimeframeType.HalfDay:
    return '10m';
  case TimeframeType.Day:
    return '30m';
  case TimeframeType.HalfWeek:
    return '1h';
  case TimeframeType.Week:
    return '3h';
  case TimeframeType.HalfMonth:
  case TimeframeType.Month:
    return '12h';
  case TimeframeType.Quarter:
  case TimeframeType.HalfYear:
    return '1d';
  case TimeframeType.YearAndHalf:
  case TimeframeType.ThreeYears:
  case TimeframeType.Year:
    return '5d';
  }
}
