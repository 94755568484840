import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarChartComponent } from './bar-chart.component';
import { RichUiModule } from '@richUi';
import { NoDataModule } from '../no-data/no-data.module';

@NgModule({
  imports: [
    CommonModule,
    RichUiModule,
    NoDataModule
  ],
  declarations: [BarChartComponent],
  exports: [BarChartComponent]
})
export class BarChartModule { }
