import { Component, Input } from '@angular/core';
import { NxChartBaseDirective } from '../nx-chart-base/nx-chart-base.directive';
import { BarChartModel } from './bar-chart.model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { SchemePalette } from 'src/app/shared/common-behaviors/scheme';
import { ChartType } from 'src/app/config/widget.registry';


@Component({
  selector: 'eng-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent extends NxChartBaseDirective {

  chartType: ChartType = 'bar';

  @Input()
    scheme: SchemePalette;

  @Input()
    data!: BarChartModel[];

  barColor = '#afdf0a';

  constructor(
    public appState: Store<AppState>
  ) {
    super([0, 0]);

    this.showYAxis = true;
    this.showXAxis = false;
  }

  barCustomColors() {
    return this.data.map((item) => {
      return {'name': item.name, 'value': this.barColor};
    });
  }
}
