<div #wrapper class="stacked-vertical-chart__wrapper">

    <ngx-charts-bar-vertical-2d
        *ngIf="data && data.length > 0"
        [view]="view"
        [scheme]="scheme"
        [results]="data"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisTickFormatting]="shortLabel"
        [yAxisTickFormatting]="yAxisFunction"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel">
    </ngx-charts-bar-vertical-2d>

    <eng-no-data *ngIf="!data || data.length === 0"></eng-no-data>
</div>