import { Injectable, OnDestroy } from '@angular/core';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import gql from 'graphql-tag';
import {
  from,
  Observable,
  BehaviorSubject,
  Subscription
} from 'rxjs';
import { map } from 'rxjs/operators';
import { flatten } from 'lodash';
import {
  UpdateAlertWatcherOutcomeInput,
  UpdateAlertWatcherStatusInput,
  Watcher,
  WatcherAlert,
  WatcherAlertEvent,
  WatcherAlertStats,
  WatcherAlertStatus,
} from 'src/app/views/rules/watcher-alerts.model';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import {
  getRuntimeAppConfig
} from 'src/app/state/app.selectors';
import { AppConfig } from 'src/app/config/app.config';
import { AuthService } from '../ue/auth.service';
import { User as AuthUser } from 'oidc-client-ts';

@Injectable({
  providedIn: 'root'
})
export class AppSyncWatcherAlertsService implements OnDestroy {
  client!: AWSAppSyncClient<any>;

  watchers: BehaviorSubject<Watcher | null> =
    new BehaviorSubject<Watcher | null>(null);

  clientReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  user: AuthUser | null;

  private _config!: AppConfig;

  private _appSyncWatcherAlertsSub!: Subscription;

  constructor(
    private _appStore: Store<AppState>,
    private _authService: AuthService
  ) {
    this.initializeClient();
  }

  async initializeClient() {
    this.user = await this._authService.getUser();
    if(this.user) {
      this._appStore.pipe(select(getRuntimeAppConfig)).subscribe((config) => {
        if (config) {
          this._config = config;
          this.client = new AWSAppSyncClient({
            url: this._config.appSyncUrl,
            region: this._config.region,
            auth: {
              type: AUTH_TYPE.OPENID_CONNECT,
              jwtToken: this.user?.access_token as string,
            },
            disableOffline: true
          });
  
          this.clientReady.next(true);
        }
      });
    } else {
      if (this.client) {
        this.client.resetStore();
        this.client.clearStore();
      }
    }
  }

  async mutateWatcherAlertHandler(
    input: UpdateAlertWatcherStatusInput
  ): Promise<Watcher | null> {
    const mutateWatcherAlert = `mutation updateAlertWatcherStatus($input: UpdateAlertWatcherStatusInput!) {
      updateAlertWatcherStatus(input: $input) {
        watcherId
        alertId
        watcherAlertStatus
      }
    }`;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.mutate({
        mutation: gql`
          ${mutateWatcherAlert}
        `,
        variables: {
          input: input
        },
        fetchPolicy: 'no-cache'
      });
      return transactionComplete.data.updateAlertWatcherStatus;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  async mutateWatcherAlertOutcomeHandler(
    input: UpdateAlertWatcherOutcomeInput
  ): Promise<Watcher | null> {
    const mutateWatcherAlert = `mutation updateAlertWatcherOutcome($input: UpdateAlertWatcherOutcomeInput!) {
      updateAlertWatcherOutcome(input: $input) {
        watcherId
        alertId
        watcherOutcome
      }
    }`;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.mutate({
        mutation: gql`
          ${mutateWatcherAlert}
        `,
        variables: {
          input: input
        },
        fetchPolicy: 'no-cache'
      });
      return transactionComplete.data.updateAlertWatcherOutcome;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  getWatcherAlerts(watcherIds: string[]): Observable<(WatcherAlert | null)[]> {
    const watcherAlertsPromise: Promise<WatcherAlert[] | null>[] = [];
    watcherIds.forEach(watcherId => {
      watcherAlertsPromise.push(this.getWatcherAlertsHandler(watcherId));
    });
    return from(Promise.all(watcherAlertsPromise)).pipe(
      map(alert => flatten(alert))
    );
  }

  async getWatcherAlertsHandler(
    watcherId: string
  ): Promise<WatcherAlert[] | null> {
    const getWatcherAlerts = `query getWatcherAlerts($watcherId: ID!) {
      getWatcherAlerts(watcherId: $watcherId) {
        name
        watcherId
        alertId
        description
        executionTime
        payload
        severity
        eventGroupId
        watcherAlertStatus
        watcherOutcome
      }
    }`;

    await this.client?.hydrated();
    try {
      const transactionComplete: any = await this.client.query({
        query: gql`
          ${getWatcherAlerts}
        `,
        variables: {
          watcherId
        },
        fetchPolicy: 'no-cache'
      });
      return transactionComplete.data.getWatcherAlerts;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  getWatcherAlertsByStatuses(
    watcherIds: string[],
    watcherAlertStatuses: WatcherAlertStatus[]
  ): Observable<WatcherAlert[] | null> {
    return from(this.getWatcherAlertsByStatusesHandler(watcherIds, watcherAlertStatuses));
  }

  async getWatcherAlertsByStatusesHandler(
    watcherIds: string[],
    watcherAlertStatuses: WatcherAlertStatus[]
  ): Promise<WatcherAlert[] | null> {
    const getWatcherAlertsByStatuses = `query getWatcherAlertsByStatuses($watcherIds: [ID]!, $watcherAlertStatuses: [WatcherAlertStatus]) {
      getWatcherAlertsByStatuses(
          filter: {
            watcherId: {in : $watcherIds},
            and: { watcherAlertStatus: {in : $watcherAlertStatuses } }
          }
        watcherIds: $watcherIds, watcherAlertStatuses: $watcherAlertStatuses
        ) {
        watcherId
        alertId
        description
        executionTime
        payload
        severity
        eventGroupId
        watcherAlertStatus
      }
    }`;

    await this.client?.hydrated();
    try {
      const transactionComplete: any = await this.client.query({
        query: gql`
          ${getWatcherAlertsByStatuses}
        `,
        variables: {
          watcherIds,
          watcherAlertStatuses
        },
        fetchPolicy: 'no-cache'
      });
      return transactionComplete.data.getWatcherAlertsByStatuses;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  getWatcherAlertStats(
    watcherIds: string[],
    timeFilter: string
  ): Observable<WatcherAlertStats | null> {
    return from(this.getWatcherAlertStatsHandler(watcherIds, timeFilter));
  }

  async getWatcherAlertStatsHandler(
    watcherIds: string[],
    timeFilter: string
  ): Promise<WatcherAlertStats | null> {
    const getWatcherAlertStats = `query getWatcherAlertStats($input: GetWatcherAlertStatsInput) {
      getWatcherAlertStats(input: $input) {
        widgets {
          id
          name
          widgetType
          stats
          description
        }
      }
    }`;

    await this.client?.hydrated();
    try {
      const transactionComplete: any = await this.client.query({
        query: gql`
          ${getWatcherAlertStats}
        `,
        variables: {
          input: {
            watcherIds,
            timeFilter
          }
        },
        fetchPolicy: 'no-cache'
      });
      return transactionComplete.data.getWatcherAlertStats;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  getWatcherAlert(
    watcherId: string,
    alertId: string
  ): Observable<WatcherAlert | null> {
    return from(this.getWatcherAlertHandler(watcherId, alertId));
  }

  async getWatcherAlertHandler(
    watcherId: string,
    alertId: string
  ): Promise<WatcherAlert | null> {
    const getWatcherAlert = `query getWatcherAlert($watcherId: ID!, $alertId: ID!) {
      getWatcherAlert(watcherId: $watcherId, alertId: $alertId) {
        watcherId
        alertId
        description
        executionTime
        payload
        eventGroupId
        name
      }
    }`;

    await this.client?.hydrated();
    try {
      const transactionComplete: any = await this.client.query({
        query: gql`
          ${getWatcherAlert}
        `,
        variables: {
          watcherId,
          alertId
        },
        fetchPolicy: 'no-cache'
      });
      return transactionComplete.data.getWatcherAlert;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  getWatcherAlertEvents(
    watcherId: string,
    alertId: string
  ): Observable<WatcherAlertEvent[] | null> {
    return from(this.getWatcherAlertEventsHandler(watcherId, alertId));
  }

  async getWatcherAlertEventsHandler(
    watcherId: string,
    alertId: string
  ): Promise<WatcherAlertEvent[] | null> {
    if (!watcherId || !alertId) {
      return [];
    }

    const getWatcherAlertEvents = `query getWatcherAlertEvents($watcherId: ID!, $alertId: ID!) {
      getWatcherAlertEvents(watcherId: $watcherId, alertId: $alertId) {
        __typename
        ... on WindowLogsEvent {
          hostName
          hostIp
          hostOsName
          hostOsPlatform
          hostOsVersion
          hostOsKernel
          userName
          userDomain
          eventTime
          eventCategory
          message
          eventAction
          eventCode
          eventOutcome
        }

        ... on PacketbeatFlowEvent {
          eventDataset
          eventTime
          eventAction
          eventType
          hostName
          hostArchitecture
          hostIp
          hostMac
          hostOsName
          hostOsKernel
          hostOsVersion
          networkTransport
          destinationIp
          destinationMac
          destinationPort
          sourceIp
          sourceMac
          sourcePort
          bytesIn
          bytesOut
        }

        ... on PacketbeatHttpEvent {
          eventDataset
          httpRequestMethod
          httpResponseStatusCode
          httpVersion
          networkDirection
          networkProtocol
          networkTransport
          urlFull
          serverGeoContinentName
          serverGeoCityName
          serverIp
          serverPort
        }

        ... on PacketbeatTlsEvent {
          eventDataset
          destinationDomain
          destinationGeoContinentName
          destinationGeoCountryName
          destinationGeoRegionName
        }

        ... on PacketbeatDnsEvent {
          eventDataset
          clientIp
          destinationIp
          destinationPort
          sourceIp
          sourcePort
          status
          dnsOpCode
          dnsQuestionName
          dnsQuestionType
          dnsResolvedIp
          dnsResponseCode
          dnsType
          query
          networkProtocol
          networkTransport
        }

        ... on FilebeatEvent {
          eventTime
          eventDataset
          ciscoAsaRuleName
          ciscoAsaDestinationInterface
          ciscoAsaMessageId
          eventOriginal
          eventCategory
          eventOutcome
          sourceAddress
          sourceAsOrganizationName
          sourceGeoContinentName
          sourceGeoCountryName
          sourcePort
          destinationIp
          destinationPort
          destinationAsOrganizationName
          destinationGeoContinentName
          destinationGeoCountryName
        }

        ... on AuditBeatEvent {
          eventCategory
          eventAction
          hostHostname
          hostIp
          hostMac
          hostOsPlatform
          userName
          filePath
          processArgs
          networkDirection
          sourceIp
          sourcePort
          destinationIp
          destinationPort
          destinationDomain
        }
      }
    }`;

    await this.client?.hydrated();
    try {
      const transactionComplete: any = await this.client.query({
        query: gql`
          ${getWatcherAlertEvents}
        `,
        variables: {
          watcherId,
          alertId
        },
        fetchPolicy: 'no-cache'
      });
      return transactionComplete.data.getWatcherAlertEvents;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  ngOnDestroy() {
    this._appSyncWatcherAlertsSub.unsubscribe();
  }
}
