<div class="loader-container" *ngIf="isLoading | async">
  <eng-progress-spinner></eng-progress-spinner>
</div>
<div class="top-bar" *ngIf="isLoggedIn | async">
  <eng-top-bar></eng-top-bar>
</div>

<div
  class="eng-drawer-container"
  [ngClass]="{ 'eng-drawer-container--logout': !currentUser }"
>
  <div class="main-content">
    <eng-side-nav [open]="open && isLoggedIn | async">
      <eng-alert></eng-alert>
      <router-outlet></router-outlet>
    </eng-side-nav>
  </div>
</div>

<eng-footer
  [ngClass]="{ 'eng-footer--no-sidenav': (hasSidenav | async) !== true }"
  *ngIf="isLoggedIn | async"
></eng-footer>

<!-- <div
  class="found-bug-button-wrapper"
>
  <button
    mat-icon-button
    type="button"
    aria-label="report issue"
    (click)="foundABug()"
    [matTooltip]="'Report A Bug'"
    matTooltipPosition="right"
  >
    <mat-icon [svgIcon]="iconsName.MegaPhone"></mat-icon>
  </button>
</div> -->

<div id="overlay">
  <div class="overlay-text" [ngClass]="{'overlay-text--light': !isDarkTheme}">
    Enigma Glass is optimized for screens above 1100 pixels wide.
    Please resize your screen or view on a higher resolution device.
    <br><br>
    Thank you.
  </div>
</div>

<!-- <eng-progress-spinner></eng-progress-spinner> -->