import { Injectable, OnDestroy } from '@angular/core';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import gql from 'graphql-tag';
import {
  from,
  Observable,
  BehaviorSubject,
  Subscription
} from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { AppConfig } from 'src/app/config/app.config';
import { GatewayService } from '../gateway/gateway.service';
import { AuthService } from '../ue/auth.service';
import { User } from '@services';
import * as AppActions from '../../state/app.actions';

@Injectable({
  providedIn: 'root',
})
export class AppSyncUserService implements OnDestroy {
  client!: AWSAppSyncClient<any>;
  users: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  clientReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _config!: AppConfig;
  private _appSyncUsersSub!: Subscription;
  private _user: User;
  refreshedSub!: Subscription;

  constructor(
    private _appStore: Store<AppState>,
    private _gatewayService: GatewayService,
    private readonly _authService: AuthService
  ) {
    //this.initializeClient();
  }

  initializeClient() {
    this._authService.getUser().then((user) => {
      if(user) {
        this._user = {
          userId: user.profile.sub,
          email: user.profile.email,
          accessToken: user.access_token,
          idToken: user.id_token
        };
        // this._gatewayService.runtimeConfig()
        //   .subscribe((config: AppConfig) => {
        //     if (config) {
        //       this._appStore.dispatch(
        //         new AppActions.LogInSuccessAction({
        //           email: user!.profile.email,
        //           accessToken: user!.access_token,
        //           idToken: user!.id_token,
        //         })
        //       );
        //       this._config = config;
        //       this.client = new AWSAppSyncClient({
        //         url: this._config.appSyncUrl,
        //         region: this._config.region,
        //         auth: {
        //           type: AUTH_TYPE.OPENID_CONNECT,
        //           // Get the currently logged in users credential.
        //           jwtToken: user.access_token,
        //         },
        //         disableOffline: true,
        //       });
      
        //       this.clientReady.next(true);
        //     }
        //   });
      } else {
        if (this.client) {
          this.client.resetStore();
          this.client.clearStore();
        }
      }
    });
  }

  listUsers(): Observable<User[] | null> {
    return from(this.listUsersHandler());
  }

  async listUsersHandler(): Promise<User[] | null> {
    const listUsers = `query ListUsers($limit: Int) {
      listEnigmaGlassUsers(limit: $limit) {
        users {
          userId
          userName
          firstName
          lastName
          email
          theme
          role
          phone
          accounts
        }
      }
    }`;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.query({
        query: gql`
          ${listUsers}
        `,
        variables: {
          limit: 1000, // TODO prob shouldn't have a limit on this
        },
        fetchPolicy: 'no-cache',
      });
      return transactionComplete.data.listEnigmaGlassUsers.users;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  getUser(userId: string): Observable<any> {
    return from(this.getUserHandler(userId));
  }

  async getUserHandler(userId: string): Promise<any> {
    const getUser = `query GetUser($userId: ID!) {
        getEnigmaGlassUser(userId: $userId) {
              userId
              userName
              firstName
              lastName
              email
              theme
              role
              phone
              accounts
            }
          }`;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.query({
        query: gql`
          ${getUser}
        `,
        variables: {
          userId: userId,
        },
        fetchPolicy: 'no-cache',
      });
      return transactionComplete.data.getEnigmaGlassUser;
    } catch (err) {
      console.log(err);
      console.log('Got here maybe we should refresh');
      this.initializeClient();
    }
  }

  createUser(user: User): Observable<any> {
    console.log(user);
    return from(this.createUserHandler(user));
  }

  async updateUserTheme(theme: string): Promise<any> {
    const updateTheme = `mutation updateTheme($themeinput: UpdateEnigmaGlassUserThemeInput!) {
      updateEnigmaGlassUserTheme(input: $themeinput) {
        firstName,
        lastName
      }
    }`;

    const input = { userId: this._user.userId || null, theme: theme };

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.mutate({
        mutation: gql`
          ${updateTheme}
        `,
        variables: {
          themeinput: input,
        },
        fetchPolicy: 'no-cache',
      });
      return transactionComplete.data;
    } catch (err) {
      console.log(err);
    }
  }

  async createUserHandler(user: User): Promise<any> {
    const createUser = `mutation createEngimaGlassUser($createengimaglassuserinput: CreateEngimaGlassUserInput!) {
        updateEnigmaGlassUserTheme(input: $createengimaglassuserinput) {
          userId,
          userName,
          firstName,
          lastName,
          email,
          theme,
          role,
          phone,
          accounts
        }
      }`;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.mutate({
        mutation: gql`
          ${createUser}
        `,
        variables: {
          createengimaglassuserinput: user,
        },
        fetchPolicy: 'no-cache',
      });
      return transactionComplete.data;
    } catch (err) {
      console.log(err);
    }
  }

  subscribeUser(): Observable<any> {
    return from(this.subscriptionUserHandler());
  }

  async subscriptionUserHandler(): Promise<any> {
    const subscriptionUser = `
        subscription onCreateEngimaGlassUser {
            onCreateEngimaGlassUser {
              userId
            }
        }`;

    await this.client.hydrated();
    try {
      const observable = await this.client.subscribe({
        query: gql`
          ${subscriptionUser}
        `,
      });

      const self = this;

      const realtimeResults = function realtimeResultsFunc(data: any) {
        console.log(data);

        // console.log(
        //   '(Realtime Subscription) Subscribing posts -----------> ',
        //   data.data.onCreateEngimaGlassAlerts
        // );
        // self.alertService.success('Real time data coming in...');
        // self.alerts.next(data.data.onCreateEngimaGlassAlerts);
      };

      observable.subscribe({
        next: realtimeResults,
        complete: console.log,
        error: console.log,
      });
      return null;
    } catch (err) {
      console.log(err);
    }
  }

  ngOnDestroy() {
    this._appSyncUsersSub.unsubscribe();
    if (this.refreshedSub) {
      this.refreshedSub.unsubscribe();
    }
  }
}
