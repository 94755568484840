import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { State } from '@ngrx/store';
import { TileModel } from 'src/app/models/tile.model';
import { AppState } from 'src/app/state/app.state';

@Component({
  selector: 'eng-tiles',
  templateUrl: './tiles.component.html',
  styleUrls: ['./tiles.component.scss'],
})
export class TilesComponent implements OnInit {
  private _tiles: TileModel[];
  get tiles(): TileModel[] {
    return this._tiles;
  }
  @Input() set tiles(_tiles: TileModel[]) {
    this._tiles = _tiles ?? new Array<TileModel>();
    this.calcEmptyTiles();
  }

  @Input() isDarkTheme: boolean;

  emptyTiles: number[];

  private screenWidth: number;
  private readonly sideMenuWidth = 64;
  private readonly scrollBarWidth = 18;
  private readonly tileWidth = 324;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = event?.target?.innerWidth ?? window.innerWidth;
    this.calcEmptyTiles();
  }

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.calcEmptyTiles();
  }

  getBackgroundImage(img: string): string {
    return `url(../../../assets/dashboards/${img}${
      !this.isDarkTheme ? '-light' : ''
    }.jpg)`;
  }

  onClick(tile: TileModel) {
    if (!tile.isExternal) {
      this.router.navigate([tile.link]);
    } else {
      window.open(tile.link, '_blank');
    }
  }

  private calcEmptyTiles() {
    let totalMargin = 0;

    // There is gap for these especific resolutions, becuase of big left/right margin
    if (
      this.screenWidth > 1100 &&
      this.screenWidth < 1130 &&
      this.tiles.length === 3
    ) {
      totalMargin = 100;
    }
    if (
      (this.screenWidth > 1370 && this.screenWidth < 1470) ||
      this.screenWidth > 1700
    ) {
      totalMargin = 100;
    }

    // Calc the available width of the tile wrapper
    const areaWidth =
      this.screenWidth - this.sideMenuWidth - this.scrollBarWidth - totalMargin;
    let numberOfEmptyTiles = 0;
    const emptyTilesPerRow = Math.floor(areaWidth / this.tileWidth);
    if (emptyTilesPerRow >= this.tiles.length) {
      numberOfEmptyTiles = 0;
    } else {
      numberOfEmptyTiles =
        emptyTilesPerRow - (this.tiles.length - emptyTilesPerRow);
    }
    this.generateEmptyTiles(numberOfEmptyTiles);
  }

  private generateEmptyTiles(numberOfEmptyTiles: number) {
    this.emptyTiles = new Array<number>();
    for (let i = 0; i < numberOfEmptyTiles; i++) {
      this.emptyTiles.push(i);
    }
  }
}
