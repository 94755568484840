import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCropDialogComponent } from './image-crop-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  declarations: [ImageCropDialogComponent],
  imports: [
    CommonModule,
    MatRippleModule,
    MatDialogModule,
    MatButtonModule,
    ImageCropperModule
  ],
  exports: [ImageCropDialogComponent]
})
export class ImageCropDialogModule { }
