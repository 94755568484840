import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'eng-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss']
})
export class AutoCompleteComponent implements OnChanges {
  @Input() options: string[];
  @Input() placeholder: string;
  @Input() control: UntypedFormControl;
  @Output() optionSelected: EventEmitter<string> = new EventEmitter();
  filteredOptions: Observable<string[]>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control || changes.options) {
      this.filteredOptions = this.control.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value?.toLowerCase();

    return this.options.filter(option =>
      option?.toLowerCase().includes(filterValue)
    );
  }

  emitOptionSelected(option: string) {
    if (this.optionSelected) {
      this.optionSelected.emit(option);
    }
  }
}
