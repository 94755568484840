<div *ngIf="showCard" class="eng-baseball-card" [ngClass]="{
    'eng-baseball-card--transparent': 'transparent'
}"
[style.left.px]="xPosition" [style.top.px]="yPosition">
    <div class="eng-baseball-card__title">
        {{title}}
    </div>
    <ng-content></ng-content>
</div>



