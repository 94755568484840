import { Component, Input, AfterContentInit } from '@angular/core';
import { BubbleChartModel, SeriesModel } from './bubble-chart.model';
import { NxChartBaseDirective } from '../nx-chart-base/nx-chart-base.directive';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { SchemePalette } from '../common-behaviors/scheme';

class NxBubbleSeries {
  name!: string;
  x: any;
  y: any;
  r?: any;
}
class NxBubbleChartModel {
  name!: string;
  series!: NxBubbleSeries[];
}

@Component({
  selector: 'eng-bubble-chart',
  templateUrl: './bubble-chart.component.html',
  styleUrls: ['./bubble-chart.component.scss']
})
export class BubbleChartComponent extends NxChartBaseDirective
  implements AfterContentInit {

  nxBubbleItems: NxBubbleChartModel[] = [];

  @Input()
    scheme: SchemePalette;

  @Input()
  set data(data: BubbleChartModel[]) {
    if (data) {
      this._bubbleData = data;
      this.processData();
    }
  }
  get data(): BubbleChartModel[] {
    return this._bubbleData;
  }
  private _bubbleData!: BubbleChartModel[];

  constructor(
    public appState: Store<AppState>) {
    super([0, 0]);
  }

  processData() {
    this.nxBubbleItems = [];
    const workingChartModel: NxBubbleChartModel[] = [];

    this.data.forEach(node => {
      let workingSeries: SeriesModel[] = this.normalizeSeriesByHour(
        node.series
      );
      workingSeries = this.timeFilterBy12Hours(workingSeries);

      if (workingSeries && workingSeries.length > 1) {
        const workingChartItem: NxBubbleChartModel = {
          // set the ip as the name
          // this may need to be changed since it specific for ip address
          name: node.ip,
          series: this.transformSeries(workingSeries)
        };
        if (this.showDepth) {
          this.calculateBubbleSize(workingChartItem);
        }
        workingChartModel.push(workingChartItem);
      }
    });

    if (workingChartModel.length > 0) {
      this.nxBubbleItems = workingChartModel;
    } else {
    }
  }

  calculateBubbleSize(bubbleChartModel: NxBubbleChartModel) {
    let totalY = 0;
    bubbleChartModel.series.forEach(item => (totalY += item.y));
    bubbleChartModel.series.forEach(item => {
      item.r = (item.y / totalY) * 100;
    });
  }

  normalizeSeriesByHour(series: SeriesModel[]): SeriesModel[] {

    return series.map(item => {
      const date = new Date(item.timeStamp);

      return {
        timeStamp: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours()
        )
      };
    });
  }

  transformSeries(series: SeriesModel[]): NxBubbleSeries[] {
    const newSeries: NxBubbleSeries[] = [];

    newSeries.push({
      name: series[0].timeStamp.getHours().toString(),
      y: series.filter(
        s =>
          s.timeStamp.toLocaleTimeString() ===
          series[0].timeStamp.toLocaleTimeString()
      ).length,
      x: series[0].timeStamp
    });

    series.forEach(item => {
      const found = newSeries.find(
        ns => ns.x.getTime() === item.timeStamp.getTime()
      );
      if (!found) {
        newSeries.push({
          name: item.timeStamp.getHours().toString(),
          y: series.filter(
            s =>
              s.timeStamp.toLocaleTimeString() ===
              item.timeStamp.toLocaleTimeString()
          ).length,
          x: item.timeStamp
        });
      }
    });
    return newSeries;
  }

  timeFilterBy12Hours(series: SeriesModel[]): SeriesModel[] {

    const timeSpan = 12;
    const currentHour = new Date().getHours();
    const currentDay = new Date().getDay();

    return series.filter(item => {
      if (item.timeStamp.getDay() === currentDay) {
        if (
          currentHour - timeSpan < item.timeStamp.getHours() &&
          item.timeStamp.getHours() < currentHour // make sure we dont have future data
        ) {
          return true;
        } else {
          return false;
        }
        // handle data into yesterday
      } else if (item.timeStamp.getDay() === currentDay - 1) {
        if (24 - (currentHour - timeSpan) < item.timeStamp.getHours()) {
          return true;
        } else {
          return false;
        }
      } else {
        // data item is greater than time span
        return false;
      }
    });
  }
}
