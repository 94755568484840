import { OnDestroy, ElementRef, ViewChild, AfterContentInit, Directive, Input } from '@angular/core';
import * as shape from 'd3-shape';
import { CurveFactory } from 'd3-shape';
import { yAxisTemplate } from './chart.model';

export interface CurveType {
  [key: string]: CurveFactory | shape.CurveBundleFactory;
}

export const Curves: CurveType = {
  Basis: shape.curveBasis,
  'Basis Closed': shape.curveBasisClosed,
  Bundle: shape.curveBundle.beta(1),
  Cardinal: shape.curveCardinal,
  'Cardinal Closed': shape.curveCardinalClosed,
  'Catmull Rom': shape.curveCatmullRom,
  'Catmull Rom Closed': shape.curveCatmullRomClosed,
  Linear: shape.curveLinear,
  'Linear Closed': shape.curveLinearClosed,
  'Monotone X': shape.curveMonotoneX,
  'Monotone Y': shape.curveMonotoneY,
  Natural: shape.curveNatural,
  Step: shape.curveStep,
  'Step After': shape.curveStepAfter,
  'Step Before': shape.curveStepBefore,
  default: shape.curveLinear
};

@Directive()
export class NxChartBaseDirective implements OnDestroy, AfterContentInit {

  componentActive = true;

  @Input()
    fitContainer = false;

  view!: number[] | undefined;

  @ViewChild('wrapper', {static: true})
    wrapper!: ElementRef;
  wrapperHeight!: number;
  wrapperWidth!: number;

  @Input()
    animations = true;
  gradient = false;
  showXAxis = true;
  showYAxis = true;
  legendTitle = 'Legend';
  showXAxisLabel = false;
  showYAxisLabel = false;
  autoScale = true;
  tooltipDisabled = false;
  showLegend = false;
  showGridLines = true;
  showDepth = true;
  xAxisLabel = 'x axis';
  yAxisLabel = 'y axis';
  timeline = false;
  rangeFillOpacity = 0.15;
  roundDomains = false;
  barPadding = 8;
  showDataLabel = false;
  roundEdges = true;
  maxRadius = 5;
  minRadius = 2;

  offset: number[] = [0, 0];

  @Input()
    curveType = 'Cardinal';
  curve: any;

  range = false;

  initialOffset: number[] = [0, 0];

  yAxisFunction = yAxisTemplate;

  constructor(initialOffset: number[]) {
    this.initialOffset = initialOffset;
  }

  ngOnDestroy() {
    this.componentActive = false;
  }

  ngAfterContentInit() {
    this.wrapperWidth = this.wrapper.nativeElement.offsetWidth;
    this.wrapperHeight = this.wrapper.nativeElement.offsetHeight;
    this.setView(this.initialOffset);
  }

  setView(offset: number[]) {
    this.offset = offset;
    this.updateView();
  }

  updateView() {
    this.view = !this.fitContainer ? [
      this.wrapper.nativeElement.offsetWidth + this.offset[0],
      this.wrapper.nativeElement.offsetHeight + this.offset[1]
    ] : undefined;
    this.curve = Curves[this.curveType];
  }

  shortLabel(label: string, charMax = 3) {
    return label.length > 5 ? `${label.substr(0, charMax)}...` : label;
  }
}
