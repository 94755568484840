import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupedVerticalChartComponent } from './grouped-vertical-chart.component';
import { RichUiModule } from '@richUi';
import { NoDataModule } from '../no-data/no-data.module';

@NgModule({
  declarations: [GroupedVerticalChartComponent],
  imports: [
    CommonModule,
    RichUiModule,
    NoDataModule,
  ],
  exports: [GroupedVerticalChartComponent]
})
export class GroupedVerticalChartModule { }
