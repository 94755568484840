<div class="dashboard__wrapper">
    <div
        *ngFor="let tile of tiles"
        class="tile__wrapper"
        matRipple
        (click)="onClick(tile)"
        [ngClass]="{'background-dark': isDarkTheme, 'background-light': !isDarkTheme}"
    >
        <div class="tile-header">         
            <div [ngClass]="(!isDarkTheme) ? 'tile-title' : 'tile-title-dark'">
                <mat-icon *ngIf="!tile.isMatIcon; else matIcons" [svgIcon]="tile.icon"></mat-icon>
                <ng-template #matIcons><mat-icon aria-hidden="false">{{ tile.icon }}</mat-icon></ng-template>
                {{ tile.name }}
            </div>
        </div>
        <div class="background-image" [style.background-image]="getBackgroundImage(tile.image)"></div>
  </div>
  <div class="tile__wrapper tile-empty" *ngFor="let emptyTile of emptyTiles"></div>
</div>