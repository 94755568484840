import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';

export interface ImageCropDialogData {
  photoBase64?: string;
  title?: string;
  bound?: boolean;
  imageQuality?: number;
}

@Component({
  selector: 'eng-image-crop-dialog',
  templateUrl: './image-crop-dialog.component.html',
  styleUrls: ['./image-crop-dialog.component.scss']
})
export class ImageCropDialogComponent {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageLoadedInView: boolean;

  constructor(
    public dialogRef: MatDialogRef<ImageCropDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImageCropDialogData
  ) {
    // set defaults
    if (data && data.imageQuality === undefined) {
      this.data.imageQuality = 99;
    }

    // set defaults
    if (data && data.bound === undefined) {
      this.data.bound = true;
    }
  }

  choose() {
    this.dialogRef.close(this.data);
  }

  determineDisabled = (): boolean => {
    return false;
  };

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.data.photoBase64 = event.base64 as string;
  }
  imageLoaded() {
    this.imageLoadedInView = true;
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
}
