import { createFeatureSelector } from '@ngrx/store';
import { createSelector } from '@ngrx/store';
import { AppState } from './app.state';

const getAppState = createFeatureSelector<AppState>('app');

export const getDarkThemeState = createSelector(
  getAppState,
  state => state.darkTheme
);

export const getCheckUsername = createSelector(
  getAppState,
  state => state.checkUsername
);

export const getUser = createSelector(
  getAppState,
  state => state.user
);

export const getIsAuthenticated = createSelector(
  getAppState,
  state => state.isAuthenticated
);

export const getLoading = createSelector(
  getAppState,
  state => state.loading
);

export const getWidgetRegistry = createSelector(
  getAppState,
  state => state.widgetRegistry
);

export const getRefreshedCreds = createSelector(
  getAppState,
  state => state.refreshedCreds
);

export const getActiveAccount = createSelector(
  getAppState,
  state => state.activeAccount
);

export const getAllAccounts = createSelector(
  getAppState,
  state => state.allAccounts
);

export const getUserFeatures = createSelector(
  getAppState,
  state => state.features
);

export const getTableExports = createSelector(
  getAppState,
  state => state.features?.discover?.tableExports ?? false
);

export const getDataSources = createSelector(
  getAppState,
  state => state.dataSources
);

export const getRuntimeAppConfig = createSelector(
  getAppState,
  state => state.runtimeConfig
);

export const getSubdomain = createSelector(
  getAppState,
  state => state.subdomain
);

export const getBugSending = createSelector(
  getAppState,
  state => state.bugSending
);

export const getShowForgotPasswordDialog = createSelector(
  getAppState,
  state => state.showForgotPasswordDialog
);

export const getDiscoverSavedQueries = createSelector(
  getAppState,
  state => state.discoverSavedQueries
);

export const getLoadingDiscoverSavedQueries = createSelector(
  getAppState,
  state => state.loadingDiscoverSavedQueries
);
