import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { AppState } from 'src/app/state/app.state';
import * as AppActions from '../../state/app.actions';
import * as fromAppSelectors from '../../state/app.selectors';
import { fadeEnterAnimation } from 'src/app/animations/enter.animation';
import { AlertService, DialogService } from '@services';
import { UEAPIService } from 'src/app/services/ue/api.service';
import { AdminService } from 'src/app/services/admin/admin.service';

import {
  signInWithEmailAndPassword,
  multiFactor,
  TotpMultiFactorGenerator,
  MultiFactorResolver,
  getMultiFactorResolver,
  TotpSecret,
  User,
  sendPasswordResetEmail,
  MultiFactorAssertion,
  MultiFactorInfo,
  TotpMultiFactorAssertion,
  UserCredential,
} from 'firebase/auth';
import { ToasterService } from 'src/app/services/ue/toaster.service';
import * as CryptoJS from 'crypto-js';
import * as QRCode from 'qrcode';
import { SpinnerService } from 'src/app/services/ue/spinner.service';

export enum UsernameNextActionType {
  SIGNUP = 'SIGNUP',
  CONTINUE = 'CONTINUE',
  LOGIN = 'LOGIN',
  FORGOT = 'FORGOT',
}

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
  animations: [fadeEnterAnimation],
})
export class LoginComponent implements OnInit, OnDestroy {
  UsernameNextActionType = UsernameNextActionType;

  loginForm!: UntypedFormGroup;
  submitted = false;
  returnUrl!: string;

  flowState: UsernameNextActionType | null;

  loading = false;

  loadingSub: Subscription;
  checkUsernameSub: Subscription;
  showForgotPasswordDialogSub: Subscription;
  routeSub: Subscription;
  isLogin: boolean = false;
  public signInSuccess: boolean = false;
  public isEnrolled: boolean = false;
  private totpSecret: TotpSecret;
  private multiFactorResolver: MultiFactorResolver;
  public notVerified: boolean = false;
  public isResetEmailSent: boolean = false;
  public isEmailLimitExceed: boolean = false;
  public showForgetPassword: boolean = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private _activatedRoute: ActivatedRoute,
    private _alertService: AlertService,
    private _dialogService: DialogService,
    private apiService: UEAPIService,
    public adminService: AdminService,
    private toastService: ToasterService,
    private cdr: ChangeDetectorRef,
    private spinnerService: SpinnerService,
    private router: Router,
  ) {
    this.initLoginForm();
    this.listenForRouteWithUN();
    this.loadingListener();
  }

  ngOnInit() {
    const user = localStorage.getItem('userDetails');
    if (user) {
      this.router.navigate(['/dashboard']);
      return;
    }
    this.apiService.initiateAuth();
  }

  ngOnDestroy() {
    if (this.loadingSub) {
      this.loadingSub.unsubscribe();
    }

    if (this.checkUsernameSub) {
      this.checkUsernameSub.unsubscribe();
    }

    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }

    if (this.showForgotPasswordDialogSub) {
      this.showForgotPasswordDialogSub.unsubscribe();
    }
  }

  public async forgetPassword(isReset: boolean) {
    this.loginForm.reset();
    this.showForgetPassword = isReset;
    !isReset && (this.isResetEmailSent = isReset);
    if (!isReset) {
      this.isResetEmailSent = false;
      this.isEmailLimitExceed = false;
    }
    this.loginForm.reset();
    this.cdr.detectChanges();
  }

  public async sendResetMail() {
    try {
      this.loginForm.markAllAsTouched();
      await sendPasswordResetEmail(this.apiService.auth, this.loginForm.value.username);
      this.isResetEmailSent = true;
      this.cdr.detectChanges();
    } catch (error) {
      if (error.code === 'auth/too-many-requests') {
        this.isEmailLimitExceed = true;
        this.isResetEmailSent = true;
        this.cdr.detectChanges();
      }
      console.log('password reset error: ', error);
    }
  }

  public async verifyCode() {
    try {
      const verificationCode = this.loginForm.value.verificationCode;
      const currentUser = this.apiService.auth.currentUser;

      if (currentUser) {
        const enrolledFactors: MultiFactorInfo[] =
          multiFactor(currentUser).enrolledFactors;

        if (enrolledFactors?.length) {
          const enrolledTotpFactor: MultiFactorInfo | undefined =
            enrolledFactors.find((factor) => factor.factorId === 'totp');

          if (enrolledTotpFactor) {
            const multiFactorAssertion: TotpMultiFactorAssertion =
              TotpMultiFactorGenerator.assertionForSignIn(
                enrolledTotpFactor.uid,
                verificationCode
              );

            await this.multiFactorResolver.resolveSignIn(
              multiFactorAssertion
            );
          }
        } else {
          const multiFactorAssertion: TotpMultiFactorAssertion =
            TotpMultiFactorGenerator.assertionForEnrollment(
              this.totpSecret,
              verificationCode
            );

          await multiFactor(currentUser).enroll(
            multiFactorAssertion,
            'TOTP MFA'
          );
        }
        this.onSignIn(currentUser);
      } else {
        // Get the TOTP factor from the resolver hints
        const totpFactor: MultiFactorInfo | undefined =
        this.multiFactorResolver?.hints?.find(
          (factor) => factor?.factorId === 'totp'
        );
        if (totpFactor) {
          const multiFactorAssertion: MultiFactorAssertion =
            TotpMultiFactorGenerator.assertionForSignIn(
              totpFactor.uid,
              verificationCode
            );
  
          const userCredential: UserCredential = await this.multiFactorResolver.resolveSignIn(
            multiFactorAssertion
          );
  
          this.onSignIn(userCredential.user);
        } else {
          this.toastService.showToastr('Invalid Verification Code', 400);
        }
      }
    } catch (error) {
      error.code === 'auth/invalid-mfa-enrollment-id'
        ?
        this.toastService.showToastr('Please Re-Sync MFA to proceed Login', 400)
        :
        this.toastService.showToastr('Invalid Verification Code', 400);
    }
  }

  public async unenrollAllFactors(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.apiService.removeUserMfa(this.loginForm.value.username).subscribe({
        next: (res: any) => {
          console.log(res);
          this.loginForm.controls.verificationCode.setValue('');
          resolve();
        },
        error: (err: any) => {
          reject(err);
        }
      });
    });
  }
  public async generateQRCode(enroll?:boolean): Promise<void> {
    try {
      if(!enroll) {
        await this.apiService.initiateAuth();
        await this.unenrollAllFactors();
      }
      const userDetails = await signInWithEmailAndPassword(
        this.apiService.auth, this.loginForm.value.username, this.loginForm.value.password);
      if(!this.apiService.auth || !userDetails?.user) {
        return;
      }
      const user = userDetails.user as User;
      console.log(this.apiService.auth.currentUser);
      multiFactor(user).getSession().then(async (session: any) => {
        this.totpSecret = await TotpMultiFactorGenerator.generateSecret(session);
        const qrCodeUrl = this.totpSecret.generateQrCodeUrl(user.displayName || '', this.loginForm.value.username);
        const qrCodeImage = await QRCode.toDataURL(qrCodeUrl);
        this.loginForm.controls.qrCodeImage.setValue(qrCodeImage);
        this.signInSuccess = true;
        this.notVerified = false;
        this.isEnrolled = false;
        this.cdr.detectChanges();
      });
    } catch (error) {
      return;
    }
  }

  loadingListener() {
    this.loadingSub = this.store
      .pipe(select(fromAppSelectors.getLoading))
      .subscribe((loading) => {
        this.loading = loading;
      });

    this.showForgotPasswordDialogSub = this.store
      .pipe(select(fromAppSelectors.getShowForgotPasswordDialog))
      .subscribe((show) => {
        if (show) {
          this._dialogService
            .confirmForgotPasswordDialog(this.loginForm.controls.username.value)
            .subscribe(() => { });
        }
      });
  }

  initLoginForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', (Validators.required, Validators.email)],
      password: ['', Validators.required],
      verificationCode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      qrCodeImage: [''],
    });
  }

  checkUsernameFlow() {
    this.checkUsernameSub = this.store
      .pipe(select(fromAppSelectors.getCheckUsername))
      .subscribe((check) => {
        if (check === UsernameNextActionType.CONTINUE) {
          this.flowState = UsernameNextActionType.CONTINUE;
        }
      });
  }

  listenForRouteWithUN() {
    this.routeSub = this._activatedRoute.queryParams.subscribe((params) => {
      const username = params['un'];
      if (username) {
        this.loginForm.controls.username.setValue(username);
        this.runtimeConfiguration(false);
      }
    });
  }

  runtimeConfiguration(checkOnly: boolean) {
    this.loading = true;
    const payload = {
      userName: this.loginForm.controls.username.value,
    };
    // Upon get of config this then calls the UsernameAction
    this.store.dispatch(
      new AppActions.LoginLoadRuntimeAppConfigAction(payload, checkOnly)
    );
  }

  forgotPassword() {
    const message =
      'That you want to reset your password? A confirmation code will be sent to your email';
    this._dialogService.areYouSureDialog(message).subscribe((sure) => {
      if (sure) {
        const username = this.loginForm.controls.username.value;
        if (!username) {
          this._alertService.error(
            'Please provide phone number or email then try again'
          );
          return;
        }

        this.store.dispatch(new AppActions.ForgotPasswordAction(username));
      }
    });
  }

  login() {
    this.isLogin = true;
  }
  async onSubmit() {
    this.loginForm.markAllAsTouched();
    this.isResetEmailSent = false;
    if (this.loginForm.value.username && this.loginForm.value.password) {
      this.spinnerService.show();
      signInWithEmailAndPassword(this.apiService.auth, this.loginForm.value.username, this.loginForm.value.password)
        .then(async (res: any) => {
          this.multiFactorResolver = res as MultiFactorResolver;
          if (res?._tokenResponse?.idToken) {
            localStorage.setItem('idToken', res._tokenResponse.idToken);
            if(!res.user.emailVerified) {
              await this.unenrollAllFactors();
              try {
                await this.sendVerificationEmail();
                this.notVerified = true;
                this.spinnerService.hide();
                this.cdr.detectChanges();
              } catch (error) {
                if (error.code === 'auth/too-many-requests') {
                  this.notVerified = true;
                  this.spinnerService.hide();
                  this.cdr.detectChanges();
                } else {
                  this.spinnerService.hide();
                  this.toastService
                    .showToastr('Your email is not verified. Error while sending verification email.', 400);
                }
                return;
              }
            }
            this.generateQRCode(true);
          } else {
            this.spinnerService.hide();
            this.toastService
              .showToastr('Error while verifying Email and Password.', 400);
          }
        })
        .catch(async (err) => {
          if (err.code == 'auth/multi-factor-auth-required') {
            this.multiFactorResolver = await getMultiFactorResolver(this.apiService.auth, err);
            this.signInSuccess = true;
            this.notVerified = false;
            this.isEnrolled = true;
          } else {
            this.toastService.showToastr('Invalid Username or Password', 400);
          }
          this.cdr.detectChanges();
          return;
        }).finally(() => {
          this.spinnerService.hide();
        });
    }
  }

  onSignIn(user: any) {
    try {
      localStorage.setItem('idToken', user.accessToken);
      this.spinnerService.show();
      this.apiService.checkUser().subscribe({
        next: (res: any) => {
          if (res && res.data) {
            localStorage.setItem('timeZone', 
              JSON.stringify({ 'id': 'Asia/Kolkata', 'name': '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi' }));
            localStorage.setItem('userId', res.data.userDetails?.employeeId);
            localStorage.setItem('userRole', JSON.stringify({ 'organizationId': res.data.userDetails?.customerId }));
            const password: string = CryptoJS.AES.encrypt(this.loginForm.value.password, this.loginForm.value.username).toString();
            localStorage.setItem('userDetails', JSON.stringify({ ...res.data.userDetails, password}));
            this.apiService.login(res.data.userDetails?.email, res.data.userDetails?.employeeId, 
              res.data.userDetails?.customerId, res.data.userDetails?.email?.split('@')?.[0], 
              res.data.userDetails?.email?.split('@')?.[0]).subscribe({
              next: (userDetails: any) => {
                if(userDetails?.data?.userDetails?.[0]?.timeZone) {
                  this.apiService.getTimezoneDetails().subscribe({
                    next: (timeZoneRes: any) => {
                      if (timeZoneRes.data) {
                        const timeZones = timeZoneRes.data;
                        const tz = timeZones.find((dict: any) => dict.id === userDetails.data.userDetails[0].timeZone);
                        localStorage.setItem('timeZone', JSON.stringify(tz));
                      }
                    }
                  });
                }
                    
                
                this.apiService.getUserProfile().subscribe({
                  next: (res: any) => {
                    this.toastService.showToastr('Login Successful', 200);
                    if (res?.data) {
                      localStorage.setItem('userProfile', JSON.stringify({
                        'firstName': res.data.firstName,
                        'lastName': res.data.lastName,
                        'email': res.data.email,
                      }));
                    }
                    this.adminService.isLoginSubject.next(true);
                    const current = localStorage.getItem('currentRoute');
                    localStorage.removeItem('currentRoute');
                    if (current === null) { 
                      this.router.navigate(['/dashboard']);
                    }
                    else {
                      this.router.navigate([`/${current}`]);
                    }
                  },
                  error: () => { 
                    localStorage.clear();
                    sessionStorage.clear();
                    this.apiService.initiateAuth();
                  }, 
                  complete: () => {
                    this.spinnerService.hide();
                  }
                });
              }, 
              error: () => {
                localStorage.clear();
                sessionStorage.clear();
                this.apiService.initiateAuth();
              }
            });
          }
        }, 
        error: () => {
          this.toastService.showToastr('Invalid Username or Password', 400);
          this.spinnerService.hide();
          localStorage.clear();
          sessionStorage.clear();
          this.apiService.initiateAuth();
        }
      });
    } catch (error) {
      this.spinnerService.hide();
      this.toastService.showToastr('Invalid Username or Password', 400);
    }
  }

  private sendVerificationEmail(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.sendVerificationEmail().subscribe({
        next: () => {
          resolve(true);
        },
        error: (err: any) => {
          reject(err);
        }
      });
    });
  }
}
