import { Injectable, OnDestroy } from '@angular/core';
// import { DataSourceType } from '@config';
import { SourceApiType, DataSource } from '../../models/account.model';
import { AppState } from 'src/app/state/app.state';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { getDataSources } from 'src/app/state/app.selectors';
import { DateRange } from 'src/app/models/date-range';
import { getTimeNotationFromSplit, getReasonableCalendarIntervalFromSplit, getDateHistogramInterval } from '@utils';
import { ElasticQuery } from 'src/app/utils/data-sources/discover.data';

export type ElasticQueryType =
  | 'endpointsSendingDataQuery'
  | 'totalEventsQuery'
  | 'userAuthFailuresQuery'
  | 'totalRemoteCommandsQuery'
  | 'topEventsQuery'
  | 'topEventsQuery'
  | 'sourcesProviderNamesQuery'
  | 'logonActivityOverTimeQuery'
  | 'eventsOverTimeQuery'
  | 'authActivityBreakdownQuery'
  | 'severitiesQuery'
  | 'eventQuery'
  | 'severityTopHitsQuery'
  | 'hitsOverTimeQuery'
  | 'hitsOverTimeAmpQuery'
  | 'hitsOverTimeUmbrellaQuery'
  | 'topUserAlertsQuery'
  | 'cloudfrontPieChartQuery'
  | 'cloudfrontTotalHitsQuery'
  | 'cloudfrontHittingFirewallQuery'
  | 'cloudfrontHitsPerDayQuery'
  | 'cloudfrontBlockedIPsQuery'
  | 'startedProvidersQuery'
  | 'totalEngineStartedQuery'
  | 'uniqueHostsQuery'
  | 'topActiveHostsQuery'
  | 'engineAndCommandStartedQuery'
  | 'mostChangedFileQuery'
  | 'newUserAccountCreatedQuery'
  | 'authenticationFailuresQuery'
  | 'changedPasswordQuery'
  | 'eventsOverTimeECSQuery'
  | 'systemEventHistogramQuery'
  | 'hostListQuery'
  | 'eventActionsQuery'
  | 'OSDistributionQuery'
  | 'eventCategoriesQuery'
  | 'uniqueDeniedQuery'
  | 'vpnActivityQuery'
  | 'successfulConnectionsQuery'
  | 'topACLBlockedQuery'
  | 'geographicBreakdownQuery'
  | 'topBlockedSourceIPsQuery'
  | 'topASAMessagesQuery'
  | 'portsBlockedASAQuery'
  | 'firewallEventsQuery'
  | 'networkEventsQuery'
  | 'dnsQueriesQuery'
  | 'httpTransactionsQuery'
  | 'tlsSessionsQuery'
  | 'sourceTrafficBreakdownQuery'
  | 'topDestinationsQuery'
  | 'trafficBreakdownQuery'
  | 'transactionsOverTimeQuery'
  | 'topHTTPRequestsQuery'
  | 'dnsTopRequestsQuery'
  | 'networkTrafficBtwHostsQuery'
  | 'uniqueFQDNQuery'
  | 'bytesTransferredQuery'
  | 'outboundConnectionAttemptsQuery'
  | 'outboundConnectionAttemptsDestinationQuery';

@Injectable()
export class ElasticQueryService implements OnDestroy {
  dataSources: DataSource[];

  subs = new Subscription();

  constructor(private store: Store<AppState>) {
    this.listenForTenant();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  listenForTenant() {
    const dataSourcesSub = this.store
      .select(getDataSources)
      .subscribe((dataSources) => {
        if (dataSources) {
          this.dataSources = dataSources;
        }
      });

    this.subs.add(dataSourcesSub);
  }

  rangeDiscoverQuery(search: string, size: number, range: DateRange, tenant?: string) {
    return tenant
      ? {
        body: {
          size: size,
          query: {
            bool: {
              filter: [
                {
                  match_phrase: {
                    'fields.tenant': tenant,
                  },
                },
                {
                  range: {
                    [`${search}`]: {
                      gte: range.begin,
                      lte: range.end,
                    },
                  },
                },
              ],
            },
          },
        },
      }
      : {
        body: {
          size: size,
          query: {
            bool: {
              filter: [
                {
                  range: {
                    [`${search}`]: {
                      gte: range.begin,
                      lte: range.end,
                    },
                  },
                },
              ],
            },
          },
        },
      };
  }

  rangeDiscoverQueryV2(terms: Array<string>, fields: Array<string>, timeField: string,
    range: DateRange, size: number = 200, index: string, tenant?: string) {
    const query: ElasticQuery = {
      body: {
        size: size,
        track_total_hits: size === 0,
        query: {
          bool: {
            filter: [
              {
                range: {
                  [`${timeField}`]: {
                    gte: range.begin,
                    lte: range.end,
                  },
                },
              },
            ],
          },
        },
      },
      index
    };
    if (tenant) {
      query.body.query.bool.filter.push(
        {
          match_phrase: {
            'fields.tenant': tenant,
          }
        }
      );
    }
    terms.forEach( term => {
      if (!query.body.query.bool.must) {
        if (!query.body.query.bool.must) {
          query.body.query.bool.must = new Array<any>();
        }
        query.body.query.bool.must.push({
          query_string: {
            query: this.formatTerm(term),
            fields,
            default_operator: 'AND',
            lenient: true,
            analyze_wildcard: true
          }
        });
      } else {
        query.body.query.bool.must[0].query_string.query += ` ${this.formatTerm(term)}`;
      }
    });
    return query;
  }

  eventsOverTimeQueryV2(terms: Array<string>, fields: Array<string>, timeField: string,
    range: DateRange, index: string, tenant?: string) {
    const query: ElasticQuery = {
      body: {
        aggs: {
          '2': {
            date_histogram: {
              field: timeField,
              fixed_interval: getDateHistogramInterval(getTimeNotationFromSplit(range.begin)),
              time_zone: 'America/Bogota',
              min_doc_count: 1,
            },
          }
        },
        size: 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                range: {
                  [`${timeField}`]: {
                    gte: range.begin,
                    lte: range.end,
                    format: 'strict_date_optional_time'
                  },
                },
              },
            ],
          },
        },
      },
      index
    };
    if (tenant) {
      query.body.query.bool.filter.push(
        {
          match_phrase: {
            'fields.tenant': tenant,
          }
        }
      );
    }
    terms.forEach( term => {
      if (!query.body.query.bool.must) {
        if (!query.body.query.bool.must) {
          query.body.query.bool.must = new Array<any>();
        }
        query.body.query.bool.must.push({
          query_string: {
            query: this.formatTerm(term),
            fields,
            default_operator: 'AND',
            lenient: true,
            analyze_wildcard: true
          }
        });
      } else {
        query.body.query.bool.must[0].query_string.query += ` ${this.formatTerm(term)}`;
      }
    });
    return query;
  }

  private isNumeric = (num: any) => (typeof(num) === 'number' || typeof(num) === 'string' && num.trim() !== '')
    && !isNaN(num as number)
    && isFinite(num as number);

  private formatTerm(term: string): string {
    term = term.trim();
    return !this.isNumeric(term) ? term.includes(' ') ? `\"${term}\"` : `*${term}*` : `(${term} OR *${term}*)`;
  }

  endpointsSendingDataQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.WindowLogs,
      body: {
        aggs: {
          '1': {
            cardinality: {
              field: 'agent.hostname',
            },
          },
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.WindowLogs
                  )?.tenant,
                },
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                  },
                },
              },
            ],
          },
        },
      },
    };
  }

  totalEventsQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.WindowLogs,
      body: {
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.WindowLogs
                  )?.tenant,
                },
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                  },
                },
              },
            ],
          },
        },
      },
    };
  }

  userAuthFailuresQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.WindowLogs,
      body: {
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'winlog.event_id': 4625,
                },
              },
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.WindowLogs
                  )?.tenant,
                },
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                  },
                },
              },
            ],
          },
        },
      },
    };
  }

  totalRemoteCommandsQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.WindowLogs,
      body: {
        aggs: {
          '2': {
            filters: {
              filters: {
                'Remote commands': {
                  bool: {
                    filter: [
                      {
                        bool: {
                          should: [
                            {
                              match_phrase: {
                                'process.title': 'ServerRemoteHost',
                              },
                            },
                          ],
                          minimum_should_match: 1,
                        },
                      },
                    ],
                  },
                },
              },
            },
          },
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                bool: {
                  should: [
                    {
                      bool: {
                        should: [
                          {
                            match_phrase: {
                              'winlog.provider_name': 'PowerShell',
                            },
                          },
                        ],
                        minimum_should_match: 1,
                      },
                    },
                    {
                      bool: {
                        should: [
                          {
                            match_phrase: {
                              'winlog.provider_name':
                                'Microsoft-Windows-PowerShell',
                            },
                          },
                        ],
                        minimum_should_match: 1,
                      },
                    },
                  ],
                  minimum_should_match: 1,
                },
              },
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.WindowLogs
                  )?.tenant,
                },
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                  },
                },
              },
            ],
          },
        },
      },
    };
  }

  topEventsQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.WindowLogs,
      body: {
        size: size ? 1000 : 0,
        track_total_hits: true,
        aggs: {
          topEventBuckets: {
            terms: {
              field: 'winlog.event_id',
              order: {
                _count: 'desc',
              },
              size: 5,
            },
          },
        },
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.WindowLogs
                  )?.tenant,
                },
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                  },
                },
              },
            ],
          },
        },
      },
    };
  }

  sourcesProviderNamesQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.WindowLogs,
      body: {
        aggs: {
          '2': {
            terms: {
              field: 'winlog.provider_name',
              order: {
                _count: 'desc',
              },
              size: 7,
            },
          },
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.WindowLogs
                  )?.tenant,
                },
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                  },
                },
              },
            ],
          },
        },
      },
    };
  }

  authActivityBreakdownQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.WindowLogs,
      body: {
        aggs: {
          '2': {
            significant_terms: {
              field: 'winlog.event_id',
              size: 2
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                'bool': {
                  minimum_should_match: 1,
                  should: [
                    {
                      match_phrase: {
                        'winlog.event_id': '4624'
                      }
                    },
                    {
                      match_phrase: {
                        'winlog.event_id': '4634'
                      }
                    }
                  ]
                }
              },
              {
                match_phrase: {
                  // Note: In order to get same values from Kibana dashboard, tenant should be equals to 'sn_wlb'
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.WindowLogs
                  )?.tenant,
                },
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                  },
                },
              },
            ],
            must_not: [
              {
                'bool': {
                  minimum_should_match: 1,
                  should: [
                    {
                      match_phrase: {
                        'winlog.event_data.LogonType': '5'
                      }
                    },
                    {
                      match_phrase: {
                        'winlog.event_data.LogonType': '0'
                      }
                    }
                  ]
                }
              }
            ],
          },
        },
      },
    };
  }

  logonActivityOverTimeQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.WindowLogs,
      body: {
        aggs: {
          '2': {
            terms: {
              field: 'host.hostname',
              order: {
                _count: 'desc',
              },
              size: 5,
            },
            aggs: {
              '3': {
                date_histogram: {
                  field: '@timestamp',
                  calendar_interval: '1d',
                  time_zone: 'America/Bogota',
                  min_doc_count: 1,
                },
              },
            },
          },
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'winlog.event_id': '4624',
                },
              },
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.WindowLogs
                  )?.tenant,
                },
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                  },
                },
              },
            ],
          },
        },
      },
    };
  }

  eventsOverTimeQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.WindowLogs,
      body: {
        aggs: {
          '2': {
            date_histogram: {
              field: '@timestamp',
              calendar_interval: '1d',
              time_zone: 'America/Bogota',
              min_doc_count: 1,
            },
            aggs: {
              '3': {
                terms: {
                  field: 'winlog.channel',
                  order: {
                    _count: 'desc',
                  },
                  size: 6,
                },
              },
            },
          },
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.WindowLogs
                  )?.tenant,
                },
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                  },
                },
              },
            ],
          },
        },
      },
    };
  }

  eventQuery(gte = 'now-1M', lte = 'now', size = false, inputData: string = '') {
    return {
      sourceApiType: SourceApiType.CiscoAmp,
      body: {
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                term: {
                  'event_type.keyword': inputData,
                },
              },
              {
                range: {
                  'date': {
                    gte,
                    lte,
                  },
                },
              },
            ],
          },
        },
      },
    };
  }

  severitiesQuery(gte = 'now-1M', lte = 'now', size = false, inputData: string = '') {
    return {
      sourceApiType: SourceApiType.CiscoAmp,
      body: {
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                term: {
                  'severity.keyword': inputData,
                },
              },
              {
                range: {
                  date: {
                    gte,
                    lte,
                  },
                },
              },
            ],
          },
        },
      },
    };
  }

  severityTopHitsQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.CiscoAmp,
      body: {
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                range: {
                  date: {
                    gte,
                    lte,
                  },
                },
              },
            ],
          },
        },
        aggs: {
          top_hits: {
            terms: {
              field: 'severity.keyword',
            },
          },
        },
      },
    };
  }

  hitsOverTimeQuery(gte = 'now-1M', _lte = 'now', size = false, inputData: string = '', sourceApiType = SourceApiType.CiscoUmbrella ) {
    return {
      sourceApiType: sourceApiType,
      body: {
        size: size ? 1000 : 0,
        track_total_hits: true,
        aggs: {
          hits_over_time: {
            date_histogram: {
              field: inputData,
              interval: getTimeNotationFromSplit(gte),
            },
          },
        },
      },
    };
  }

  hitsOverTimeAmpQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.CiscoAmp,
      body: {
        aggs: {
          '2': {
            date_histogram: {
              field: 'date',
              calendar_interval: '1d',
              time_zone: 'America/Bogota',
              min_doc_count: 1
            },
            aggs: {
              '1': {
                cardinality: {
                  field: '_id'
                }
              }
            }
          },
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                range: {
                  date: {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  hitsOverTimeUmbrellaQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.CiscoUmbrella,
      body: {
        aggs: {
          '2': {
            date_histogram: {
              field: 'datetime',
              fixed_interval: '12h',
              time_zone: 'America/Bogota',
              min_doc_count: 1
            },
            aggs: {
              '1': {
                cardinality: {
                  field: '_id'
                }
              }
            }
          },
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                range: {
                  datetime: {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  topUserAlertsQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.CiscoAmp,
      body: {
        aggs: {
          '2': {
            terms: {
              field: 'computer.user.keyword',
              order: {
                '1': 'desc'
              },
              size: 5
            },
            aggs: {
              '1': {
                cardinality: {
                  field: 'file.disposition.keyword'
                }
              }
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                range: {
                  date: {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  cloudfrontPieChartQuery(gte = 'now-1M', lte = 'now', size = false, inputData: string = '') {
    return {
      sourceApiType: SourceApiType.CloudfrontLogs,
      body: {
        aggs: {
          '2': {
            terms: {
              field: inputData,
              order: {
                '_count': 'desc'
              },
              size: 5
            },
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                range: {
                  datetime: {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  cloudfrontTotalHitsQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.CloudfrontLogs,
      body: {
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                range: {
                  datetime: {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  },
                },
              },
            ],
          },
        },
      },
    };
  }

  cloudfrontHittingFirewallQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.CloudfrontLogs,
      body: {
        aggs: {
          '1': {
            cardinality: {
              field: 'c-ip.keyword'
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                range: {
                  datetime: {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  },
                },
              },
            ],
          },
        },
      },
    };
  }

  cloudfrontHitsPerDayQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.CloudfrontLogs,
      body: {
        aggs: {
          '2': {
            date_histogram: {
              field: 'datetime',
              'calendar_interval': '1d',
              'time_zone': 'America/Bogota',
              'min_doc_count': 1
            },
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                range: {
                  datetime: {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  cloudfrontBlockedIPsQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.CloudfrontLogs,
      body: {
        aggs: {
          2: {
            terms: {
              field: 'c-ip.keyword',
              order: {
                _count: 'desc'
              },
              size: 5
            },
            aggs: {
              3: {
                terms: {
                  field: 'x-edge-detailed-result-type.keyword',
                  order: {
                    _count: 'desc'
                  },
                  size: 5
                }
              }
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'x-edge-detailed-result-type.keyword': 'ClientGeoBlocked'
                }
              },
              {
                range: {
                  datetime: {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  startedProvidersQuery(gte = 'now-1M', lte = 'now', size = false, inputData: string = '') {
    return {
      sourceApiType: SourceApiType.WindowLogs,
      body: {
        aggs: {
          '2': {
            terms: {
              field: inputData,
              order: {
                '_count': 'desc'
              },
              size: 10
            },
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                bool: {
                  should: [
                    {
                      bool: {
                        should: [
                          {
                            match_phrase: {
                              'winlog.provider_name': 'PowerShell'
                            }
                          }
                        ],
                        minimum_should_match: 1
                      }
                    },
                    {
                      bool: {
                        should: [
                          {
                            match_phrase: {
                              'winlog.provider_name': 'Microsoft-Windows-PowerShell'
                            }
                          }
                        ],
                        minimum_should_match: 1
                      }
                    }
                  ],
                  minimum_should_match: 1
                }
              },
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.WindowLogs
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  totalEngineStartedQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.WindowLogs,
      body: {
        aggs: {
          2: {
            filters: {
              filters: {
                'event.code: 400': {
                  bool: {
                    filter: [
                      {
                        bool: {
                          should: [
                            {
                              match: {
                                'event.code': 400
                              }
                            }
                          ],
                          minimum_should_match: 1
                        }
                      }
                    ]
                  }
                }
              }
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                bool: {
                  should: [
                    {
                      bool: {
                        should: [
                          {
                            match_phrase: {
                              'winlog.provider_name': 'PowerShell'
                            }
                          }
                        ],
                        minimum_should_match: 1
                      }
                    },
                    {
                      bool: {
                        should: [
                          {
                            match_phrase: {
                              'winlog.provider_name': 'Microsoft-Windows-PowerShell'
                            }
                          }
                        ],
                        minimum_should_match: 1
                      }
                    }
                  ],
                  minimum_should_match: 1
                }
              },
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.WindowLogs
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  uniqueHostsQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.WindowLogs,
      body: {
        aggs: {
          1: {
            cardinality: {
              field: 'host.name'
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                bool: {
                  should: [
                    {
                      bool: {
                        should: [
                          {
                            match_phrase: {
                              'winlog.provider_name': 'PowerShell'
                            }
                          }
                        ],
                        minimum_should_match: 1
                      }
                    },
                    {
                      bool: {
                        should: [
                          {
                            match_phrase: {
                              'winlog.provider_name': 'Microsoft-Windows-PowerShell'
                            }
                          }
                        ],
                        minimum_should_match: 1
                      }
                    }
                  ],
                  minimum_should_match: 1
                }
              },
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.WindowLogs
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  topActiveHostsQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.WindowLogs,
      body: {
        aggs: {
          2: {
            terms: {
              field: 'host.name',
              order: {
                _count: 'desc'
              },
              size: 10
            },
            aggs: {
              3: {
                terms: {
                  field: 'x-edge-detailed-result-type.keyword',
                  order: {
                    _count: 'desc'
                  },
                  size: 5
                }
              }
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                bool: {
                  should: [
                    {
                      bool: {
                        should: [
                          {
                            match_phrase: {
                              'winlog.provider_name': 'PowerShell'
                            }
                          }
                        ],
                        minimum_should_match: 1
                      }
                    },
                    {
                      bool: {
                        should: [
                          {
                            match_phrase: {
                              'winlog.provider_name': 'Microsoft-Windows-PowerShell'
                            }
                          }
                        ],
                        minimum_should_match: 1
                      }
                    }
                  ],
                  minimum_should_match: 1
                }
              },
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.WindowLogs
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  engineAndCommandStartedQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.WindowLogs,
      body: {
        aggs: {
          '2': {
            date_histogram: {
              field: '@timestamp',
              fixed_interval: '30s',
              time_zone: 'America/Bogota',
              min_doc_count: 1
            },
            aggs: {
              '4': {
                filters: {
                  filters: {
                    'Engine started': {
                      bool: {
                        filter: [
                          {
                            bool: {
                              should: [
                                {
                                  match_phrase: {
                                    'event.code': '400'
                                  }
                                }
                              ],
                              minimum_should_match: 1
                            }
                          }
                        ]
                      }
                    },
                    'Command started': {
                      bool: {
                        filter: [
                          {
                            bool: {
                              should: [
                                {
                                  match_phrase: {
                                    'event.code': '4105'
                                  }
                                }
                              ],
                              minimum_should_match: 1
                            }
                          }
                        ]
                      }
                    }
                  }
                }
              }
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                bool: {
                  should: [
                    {
                      bool: {
                        should: [
                          {
                            match_phrase: {
                              'winlog.provider_name': 'PowerShell'
                            }
                          }
                        ],
                        minimum_should_match: 1
                      }
                    },
                    {
                      bool: {
                        should: [
                          {
                            match_phrase: {
                              'winlog.provider_name': 'Microsoft-Windows-PowerShell'
                            }
                          }
                        ],
                        minimum_should_match: 1
                      }
                    }
                  ],
                  minimum_should_match: 1
                }
              },
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.WindowLogs
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  // Audit Beat Queries
  mostChangedFileQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.LinuxLogs,
      body: {
        aggs: {
          2: {
            terms: {
              field: 'file.path',
              order: {
                _count: 'desc'
              },
              size: 1
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'file.type': {
                    query: 'file'
                  }
                }
              },
              {
                match_phrase: {
                  'event.module': {
                    query: 'file_integrity'
                  }
                }
              },
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.LinuxLogs
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  newUserAccountCreatedQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.LinuxLogs,
      body: {
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'event.action': 'user_added'
                }
              },
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.LinuxLogs
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  authenticationFailuresQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.LinuxLogs,
      body: {
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'event.category': 'authentication'
                }
              },
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.LinuxLogs
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ],
            'must_not': [
              {
                match_phrase: {
                  'event.outcome': 'success'
                }
              }
            ]
          }
        }
      },
    };
  }

  changedPasswordQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.LinuxLogs,
      body: {
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'event.action': 'changed-password'
                }
              },
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.LinuxLogs
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  eventsOverTimeECSQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.LinuxLogs,
      body: {
        aggs: {
          '2': {
            date_histogram: {
              field: '@timestamp',
              fixed_interval: getDateHistogramInterval(getTimeNotationFromSplit(gte)),
              time_zone: 'America/Bogota',
              min_doc_count: 1,
            },
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.LinuxLogs
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  },
                },
              },
            ],
          },
        },
      },
    };
  }

  systemEventHistogramQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.LinuxLogs,
      body: {
        aggs: {
          '2': {
            date_histogram: {
              field: '@timestamp',
              'calendar_interval': '1w',
              'time_zone': 'America/Bogota',
              'min_doc_count': 1
            },
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'event.module': {
                    query: 'system'
                  }
                }
              },
              {
                match_phrase: {
                  'event.kind': {
                    query: 'event'
                  }
                }
              },
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.LinuxLogs
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  hostListQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.LinuxLogs,
      body: {
        aggs: {
          2: {
            terms: {
              field: 'system.audit.host.hostname',
              order: {
                _key: 'desc'
              },
              size: 5
            },
            'aggs': {
              '1': {
                'top_hits': {
                  'docvalue_fields': [
                    {
                      field: 'system.audit.host.id',
                      format: 'use_field_mapping'
                    }
                  ],
                  _source: 'system.audit.host.id',
                  size: 1,
                  sort: [
                    {
                      '@timestamp': {
                        order: 'desc'
                      }
                    }
                  ]
                }
              },
              3: {
                top_hits: {
                  docvalue_fields: [
                    {
                      field: 'system.audit.host.os.name',
                      format: 'use_field_mapping'
                    }
                  ],
                  _source: 'system.audit.host.os.name',
                  size: 1,
                  sort: [
                    {
                      '@timestamp': {
                        order: 'desc'
                      }
                    }
                  ]
                }
              },
              4: {
                top_hits: {
                  docvalue_fields: [
                    {
                      field: 'system.audit.host.os.version',
                      format: 'use_field_mapping'
                    }
                  ],
                  _source: 'system.audit.host.os.version',
                  size: 1,
                  sort: [
                    {
                      '@timestamp': {
                        order: 'desc'
                      }
                    }
                  ]
                }
              },
              5: {
                top_hits: {
                  docvalue_fields: [
                    {
                      field: 'system.audit.host.uptime',
                      format: 'use_field_mapping'
                    }
                  ],
                  _source: 'system.audit.host.uptime',
                  size: 1,
                  sort: [
                    {
                      '@timestamp': {
                        order: 'desc'
                      }
                    }
                  ]
                }
              },
              6: {
                top_hits: {
                  _source: 'message',
                  size: 1,
                  sort: [
                    {
                      '@timestamp': {
                        order
                        : 'desc'
                      }
                    }
                  ]
                }
              }
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'event.dataset': {
                    query: 'host'
                  }
                }
              },
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.LinuxLogs
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  eventActionsQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.LinuxLogs,
      body: {
        aggs: {
          2: {
            date_histogram: {
              field: '@timestamp',
              calendar_interval: getReasonableCalendarIntervalFromSplit(gte),
              time_zone: 'America/Bogota',
              min_doc_count: 1
            },
            aggs: {
              3: {
                terms: {
                  field: 'event.action',
                  order: {
                    _count: 'desc'
                  },
                  size: 1
                }
              }
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.LinuxLogs
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  OSDistributionQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.LinuxLogs,
      body: {
        aggs: {
          '2': {
            terms: {
              field: 'system.audit.host.os.name',
              'order': {
                '1': 'desc'
              },
              size: 5
            },
            'aggs': {
              '1': {
                'cardinality': {
                  field: 'system.audit.host.id'
                }
              },
              '3': {
                terms: {
                  field: 'system.audit.host.os.version',
                  'order': {
                    '1': 'desc'
                  },
                  size: 5
                },
                'aggs': {
                  '1': {
                    'cardinality': {
                      field: 'system.audit.host.id'
                    }
                  }
                }
              }
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'event.dataset': {
                    'query': 'host'
                  }
                }
              },
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.LinuxLogs
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  eventCategoriesQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.LinuxLogs,
      body: {
        aggs: {
          '2': {
            terms: {
              field: 'event.category',
              'order': {
                '_count': 'desc'
              },
              size: 5
            },
            'aggs': {
              '3': {
                terms: {
                  field: 'event.action',
                  'order': {
                    '_count': 'desc'
                  },
                  size: 20
                }
              }
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'event.module': {
                    'query': 'auditd'
                  }
                }
              },
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.LinuxLogs
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  // File Beat Queries
  uniqueDeniedQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.FileBeatLinuxLogs,
      body: {
        aggs: {
          '38796693-38c0-49f5-8f9e-d5b8474bedc9': {
            cardinality: {
              field: 'source.geo.region_name'
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.FileBeatLinuxLogs
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ],
            must_not: [
              {
                match_phrase: {
                  'source.geo.country_name': 'United States'
                }
              }
            ]
          }
        }
      },
    };
  }

  vpnActivityQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.FileBeatLinuxLogs,
      body: {
        aggs: {},
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'event.code': '722037'
                }
              },
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.FileBeatLinuxLogs
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  successfulConnectionsQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.FileBeatLinuxLogs,
      body: {
        aggs: {
          '6279c2d8-d9c9-4ddd-b196-b1257e74dce0': {
            cardinality: {
              field: 'event.outcome'
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.FileBeatLinuxLogs
                  )?.tenant
                }
              },
              {
                match_phrase: {
                  'event.outcome': 'allow'
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ],
            must_not: [
              {
                bool: {
                  should: [
                    {
                      match_phrase: {
                        'destination.geo.country_iso_code': 'US'
                      }
                    }
                  ],
                  minimum_should_match: 1
                }
              },
              {
                match_phrase: {
                  'source.geo.country_name': 'United States'
                }
              }
            ]
          }
        }
      },
    };
  }

  topACLBlockedQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.FileBeatLinuxLogs,
      body: {
        aggs: {
          2: {
            terms: {
              field: 'cisco.asa.rule_name',
              order: {
                _count: 'desc'
              },
              size: 5
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                bool: {
                  should: [
                    {
                      match_phrase: {
                        'event.outcome': 'deny'
                      }
                    }
                  ],
                  minimum_should_match: 1
                }
              },
              {
                bool: {
                  filter: [
                    {
                      bool: {
                        should: [
                          {
                            exists: {
                              field: 'cisco.asa.message_id'
                            }
                          }
                        ],
                        'minimum_should_match': 1
                      }
                    },
                    {
                      bool: {
                        should: [
                          {
                            match_phrase: {
                              'event.action': 'firewall-rule'
                            }
                          }
                        ],
                        minimum_should_match: 1
                      }
                    }
                  ]
                }
              },
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.FileBeatLinuxLogs
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  geographicBreakdownQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.FileBeatLinuxLogs,
      body: {
        aggs: {
          '2': {
            terms: {
              field: 'source.geo.continent_name',
              order: {
                _count: 'desc'
              },
              size: 3
            },
            aggs: {
              '3': {
                terms: {
                  field: 'source.geo.region_name',
                  order: {
                    _count: 'desc'
                  },
                  size: 3
                }
              }
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.FileBeatLinuxLogs
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ],
          },
        },
      },
    };
  }

  topBlockedSourceIPsQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.FileBeatLinuxLogs,
      body: {
        aggs: {
          2: {
            terms: {
              field: 'source.ip',
              order: {
                _count: 'desc'
              },
              size: 9
            },
            aggs: {
              '3': {
                terms: {
                  field: 'source.geo.country_name',
                  order: {
                    _count: 'desc'
                  },
                  size: 3
                }
              }
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'event.outcome': 'deny'
                }
              },
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.FileBeatLinuxLogs
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  topASAMessagesQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.FileBeatLinuxLogs,
      body: {
        aggs: {
          2: {
            terms: {
              field: 'cisco.asa.message_id',
              order: {
                _key: 'desc'
              },
              size: 15
            },
            aggs: {
              1: {
                top_hits: {
                  docvalue_fields: [
                    {
                      field: 'event.original'
                    }
                  ],
                  _source: 'event.original',
                  size: 1,
                  sort: [
                    {
                      '@timestamp': {
                        order: 'desc'
                      }
                    }
                  ]
                }
              },
              4: {
                top_hits: {
                  docvalue_fields: [
                    {
                      field: 'log.level'
                    }
                  ],
                  _source: 'log.level',
                  size: 1,
                  sort: [
                    {
                      '@timestamp': {
                        order: 'desc'
                      }
                    }
                  ]
                }
              }
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                bool: {
                  should: [
                    {
                      exists: {
                        field: 'cisco.asa.message_id'
                      }
                    }
                  ],
                  minimum_should_match: 1
                }
              },
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.FileBeatLinuxLogs
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  portsBlockedASAQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.FileBeatLinuxLogs,
      body: {
        aggs: {
          'd4f9cbe6-6354-479b-ba6d-08661f94e158': {
            terms: {
              field: 'source.geo.region_iso_code',
              order: {
                'b52d8cb2-86c1-4d96-9329-b769f3ca6aa1-bucket': 'desc'
              },
              size: 3
            },
            aggs: {
              'b52d8cb2-86c1-4d96-9329-b769f3ca6aa1-bucket': {
                filter: {
                  bool: {
                  }
                }
              },
              '8871d98e-6dce-4d11-ac4f-6e6795fe8d53': {
                terms: {
                  field: 'source.ip',
                  order: {
                    'b52d8cb2-86c1-4d96-9329-b769f3ca6aa1-bucket': 'desc'
                  },
                  size: 3
                },
                aggs: {
                  'b52d8cb2-86c1-4d96-9329-b769f3ca6aa1-bucket': {
                    filter: {
                      bool: {
                      }
                    }
                  },
                  '59b93652-c316-461c-95c9-bc42933b1e7e': {
                    histogram: {
                      field: 'source.port',
                      interval: 2000,
                      min_doc_count: 1
                    },
                    aggs: {
                      '0045f2b4-a198-4090-a316-efcfe5d4d07e': {
                        terms: {
                          field: 'network.transport',
                          order: {
                            'b52d8cb2-86c1-4d96-9329-b769f3ca6aa1-bucket': 'desc'
                          },
                          size: 3
                        },
                        aggs: {
                          'b52d8cb2-86c1-4d96-9329-b769f3ca6aa1-bucket': {
                            filter: {
                              bool: {
                              }
                            }
                          },
                          'a3cd5a25-7ff6-4bb7-8a8f-12949e954c02': {
                            terms: {
                              field: 'event.outcome',
                              order: {
                                'b52d8cb2-86c1-4d96-9329-b769f3ca6aa1-bucket': 'desc'
                              },
                              size: 3
                            },
                            aggs: {
                              'b52d8cb2-86c1-4d96-9329-b769f3ca6aa1-bucket': {
                                filter: {
                                  bool: {
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.FileBeatLinuxLogs
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ],
            must_not: [
              {
                match_phrase: {
                  'source.geo.country_name': 'United States'
                }
              }
            ]
          }
        }
      },
    };
  }

  firewallEventsQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.FileBeatLinuxLogs,
      body: {
        'aggs': {
          '2': {
            terms: {
              field: 'event.outcome',
              order: {
                _count: 'desc'
              },
              size: 5
            },
            aggs: {
              '3': {
                date_histogram: {
                  field: '@timestamp',
                  fixed_interval: getReasonableCalendarIntervalFromSplit(gte),
                  time_zone: 'America/Bogota',
                  min_doc_count: 1
                }
              }
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                bool: {
                  filter: [
                    {
                      bool: {
                        should: [
                          {
                            exists: {
                              field: 'cisco.asa.message_id'
                            }
                          }
                        ],
                        minimum_should_match: 1
                      }
                    },
                    {
                      bool: {
                        should: [
                          {
                            match_phrase: {
                              'event.action': 'firewall-rule'
                            }
                          }
                        ],
                        minimum_should_match: 1
                      }
                    }
                  ]
                }
              },
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.FileBeatLinuxLogs
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  // Packet Beat Queries
  networkEventsQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.PacketBeat,
      body: {
        aggs: {},
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.PacketBeat
                  )?.tenant
                }
              },
              {
                exists: {
                  field: 'tls.established'
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  dnsQueriesQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.PacketBeat,
      body: {
        aggs: {},
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.PacketBeat
                  )?.tenant
                }
              },
              {
                exists: {
                  field: 'tls.established'
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  httpTransactionsQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.PacketBeat,
      body: {
        aggs: {},
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.PacketBeat
                  )?.tenant
                }
              },
              {
                exists: {
                  field: 'http'
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  tlsSessionsQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.PacketBeat,
      body: {
        aggs: {},
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.PacketBeat
                  )?.tenant
                }
              },
              {
                exists: {
                  field: 'http'
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  sourceTrafficBreakdownQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.PacketBeat,
      body: {
        aggs: {
          'c731c5d7-e3b9-4c5c-9971-00ea8b19b5a4': {
            terms: {
              field: 'source.geo.city_name',
              order: {
                '_count': 'desc'
              },
              size: 5
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.PacketBeat
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ],
          },
        },
      },
    };
  }

  topDestinationsQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.PacketBeat,
      body: {
        aggs: {
          '672e8cf6-0ea7-4a54-ad05-3fec1434e844': {
            terms: {
              field: 'destination.geo.region_iso_code',
              order: {
                '_count': 'desc'
              },
              size: 5
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.PacketBeat
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ],
          },
        },
      },
    };
  }

  trafficBreakdownQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.PacketBeat,
      body: {
        aggs: {
          '9a1bbd07-4e94-46f2-b4e0-57e6dc340a91': {
            terms: {
              field: 'event.dataset',
              order: {
                '_count': 'desc'
              },
              size: 5
            },
            aggs: {
              '148f8c9e-dc97-4cfc-984e-3f926ff2bdaa': {
                date_histogram: {
                  field: '@timestamp',
                  fixed_interval: getDateHistogramInterval(getTimeNotationFromSplit(gte)),
                  time_zone: 'America/Bogota'
                }
              }
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.PacketBeat
                  )?.tenant
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ],
            must_not: [
              {
                match_phrase: {
                  'event.dataset': 'flow'
                }
              }
            ]
          }
        }
      },
    };
  }

  transactionsOverTimeQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.PacketBeat,
      body: {
        aggs: {
          '2': {
            date_histogram: {
              field: '@timestamp',
              fixed_interval: getDateHistogramInterval(getTimeNotationFromSplit(gte)),
              time_zone: 'America/Bogota',
              min_doc_count: 1
            },
            aggs: {
              '1': {
                cardinality: {
                  field: 'flow.id'
                }
              }
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.PacketBeat
                  )?.tenant
                }
              },
              {
                bool: {
                  should: [
                    {
                      match: {
                        type: 'flow'
                      }
                    }
                  ],
                  minimum_should_match: 1
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                  },
                },
              },
            ],
          },
        },
      },
    };
  }

  topHTTPRequestsQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.PacketBeat,
      body: {
        aggs: {
          '3': {
            terms: {
              field: 'url.full',
              order: {
                '_count': 'desc'
              },
              size: 5
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.PacketBeat
                  )?.tenant
                }
              },
              {
                match_phrase: {
                  'network.protocol': {
                    query: 'http'
                  }
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ],
          },
        },
      },
    };
  }

  dnsTopRequestsQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.PacketBeat,
      body: {
        aggs: {
          '2': {
            terms: {
              field: 'dns.question.name',
              order: {
                '_count': 'desc'
              },
              size: 30
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.PacketBeat
                  )?.tenant
                }
              },
              {
                match_phrase: {
                  status: {
                    query: 'OK'
                  }
                }
              },
              {
                match_phrase: {
                  'network.protocol': {
                    query: 'dns'
                  }
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ],
          },
        },
      },
    };
  }

  networkTrafficBtwHostsQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.PacketBeat,
      body: {
        aggs: {
          '3': {
            terms: {
              field: 'source.ip',
              order: {
                '1': 'desc'
              },
              size: 5
            },
            aggs: {
              '1': {
                sum: {
                  field: 'source.bytes'
                }
              },
              '4': {
                terms: {
                  field: 'destination.ip',
                  order: {
                    '1': 'desc'
                  },
                  size: 5
                },
                aggs: {
                  '1': {
                    sum: {
                      field: 'source.bytes'
                    }
                  },
                  '2': {
                    sum: {
                      field: 'destination.bytes'
                    }
                  }
                }
              }
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.PacketBeat
                  )?.tenant
                }
              },
              {
                bool: {
                  should: [
                    {
                      match: {
                        type: 'flow'
                      }
                    }
                  ],
                  minimum_should_match: 1
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ],
          },
        },
      },
    };
  }

  uniqueFQDNQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.PacketBeat,
      body: {
        aggs: {
          2: {
            terms: {
              field: 'dns.question.etld_plus_one',
              order: {
                '1': 'desc'
              },
              size: 20
            },
            aggs: {
              '1': {
                cardinality: {
                  field: 'dns.question.name'
                }
              }
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.PacketBeat
                  )?.tenant
                }
              },
              {
                match_phrase: {
                  'network.protocol': {
                    query: 'dns'
                  }
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  bytesTransferredQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.PacketBeat,
      body: {
        aggs: {
          '2': {
            terms: {
              field: 'dns.question.etld_plus_one',
              order: {
                '1': 'desc'
              },
              size: 20
            },
            aggs: {
              '1': {
                sum: {
                  field: 'destination.bytes'
                }
              },
              '3': {
                sum: {
                  field: 'source.bytes'
                }
              }
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.PacketBeat
                  )?.tenant
                }
              },
              {
                match_phrase: {
                  'network.protocol': {
                    query: 'dns'
                  }
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  outboundConnectionAttemptsQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.PacketBeat,
      body: {
        aggs: {
          destSplit: {
            terms: {
              script: {
                source: 'doc[\'destination.geo.location\'].value.toString()',
                lang: 'painless'
              },
              order: {
                _count: 'desc'
              },
              size: 100
            },
            aggs: {
              sourceGrid: {
                geotile_grid: {
                  field: 'source.geo.location',
                  precision: 4,
                  size: 500
                },
                aggs: {
                  sourceCentroid: {
                    geo_centroid: {
                      field: 'source.geo.location'
                    }
                  },
                  'sum_of_source.bytes': {
                    sum: {
                      field: 'source.bytes'
                    }
                  },
                  'sum_of_destination.bytes': {
                    sum: {
                      field: 'destination.bytes'
                    }
                  }
                }
              }
            }
          }
        },
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.PacketBeat
                  )?.tenant
                }
              },
              {
                geo_bounding_box: {
                  'destination.geo.location': {
                    top_left: [
                      -180,
                      89
                    ],
                    bottom_right: [
                      180,
                      -66.51326
                    ]
                  }
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

  outboundConnectionAttemptsDestinationQuery(gte = 'now-1M', lte = 'now', size = false) {
    return {
      sourceApiType: SourceApiType.PacketBeat,
      body: {
        aggs: {
          totalEntities: {
            cardinality: {
              precision_threshold: 1,
              field: 'destination.ip'
            }
          },
          entitySplit: {
            terms: {
              size: 65535,
              shard_size: 65535,
              field: 'destination.ip'
            },
            aggs: {
              entityHits: {
                top_hits: {
                  size: 1,
                  script_fields: {},
                  docvalue_fields: [
                    'destination.geo.location'
                  ],
                  _source: false
                }
              }
            }
          }
        },
        fields: [
          {
            field: '@timestamp',
            format: 'date_time'
          },
          {
            field: 'event.created',
            format: 'date_time'
          },
          {
            field: 'event.end',
            format: 'date_time'
          },
          {
            field: 'event.ingested',
            format: 'date_time'
          },
          {
            field: 'host.name'
          },
          {
            field: 'destination.ip'
          },
          {
            field: 'destination.geo.country_iso_code'
          },
          {
            field: 'destination.domain'
          }
        ],
        size: size ? 1000 : 0,
        track_total_hits: true,
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  'fields.tenant': this.dataSources.find(
                    (dataSource) => dataSource.type === SourceApiType.PacketBeat
                  )?.tenant
                }
              },
              {
                geo_bounding_box: {
                  'destination.geo.location': {
                    top_left: [
                      -180,
                      89
                    ],
                    bottom_right: [
                      180,
                      -66.51326
                    ]
                  }
                }
              },
              {
                range: {
                  '@timestamp': {
                    gte,
                    lte,
                    format: 'strict_date_optional_time'
                  }
                }
              }
            ]
          }
        }
      },
    };
  }

}
