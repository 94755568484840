<h1 mat-dialog-title>{{ data && data.title ? data.title : 'Image Crop' }}</h1>
<div class="image-crop__wrapper" mat-dialog-content>
  <input
    hidden
    (change)="fileChangeEvent($event)"
    #fileInput
    type="file"
    id="file"
  />
  <image-cropper
    [ngClass]="{'cdk-visually-hidden': !imageLoadedInView}"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="data.bound"
    format="jpeg"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
    [imageQuality]="data.imageQuality"
  ></image-cropper>

  <div *ngIf="!imageLoadedInView" class="placeholder-img"><span>Upload image here</span></div>
</div>

<div mat-dialog-actions>
  <button type="button" mat-raised-button (click)="fileInput.click()">
    Upload Photo
  </button>
  <button mat-raised-button color="primary" (click)="choose()" [disabled]="!croppedImage">
    Select Crop
  </button>
</div>
