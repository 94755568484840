import { Component, Input } from '@angular/core';
import { ChartType } from '@config';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { SchemePalette } from 'src/app/shared/common-behaviors/scheme';
import { NxChartBaseDirective } from '../nx-chart-base/nx-chart-base.directive';

@Component({
  selector: 'eng-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.scss']
})
export class AreaChartComponent extends NxChartBaseDirective {

  chartType: ChartType = 'area-chart';

  @Input()
    scheme: SchemePalette;

  @Input()
    data: any[];

  constructor(
    public appState: Store<AppState>
  ) {
    super([20, 0]);
  }

}
