import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TilesComponent } from './tiles.component';
import { RouterModule } from '@angular/router';

const MATERIAL = [
  MatIconModule,
];

@NgModule({
  declarations: [TilesComponent],
  imports: [
    CommonModule,
    RouterModule,
    ...MATERIAL
  ],
  exports: [TilesComponent]
})
export class TilesModule { }
