import { Map, Feature } from 'ol';
import { unByKey } from 'ol/Observable';
import { Point } from 'ol/geom';
import { easeOut } from 'ol/easing';
import { fromLonLat } from 'ol/proj';
import { Style, Stroke, Circle } from 'ol/style';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { cloneDeep } from 'lodash';

export interface DataPoint {
  lat: number;
  lon: number;
  id: string;
}

export class PointCapabilities {
  pointSource: VectorSource;

  constructor(public map: Map) {
    this.pointSource = new VectorSource({
      wrapX: false
    });

    const vector = new VectorLayer({
      source: this.pointSource
    });

    this.map.addLayer(vector);
  }

  addPoint(data: DataPoint) {
    const geom = new Point(fromLonLat([data.lon, data.lat]));
    const feature = new Feature(geom);
    feature.setId(data.id);

    this.pointSource.addFeature(feature);
  }

  removePoints() {
    this.pointSource.clear();
  }

  flash(feature: Feature) {
    const duration = 3000;
    const start = new Date().getTime();
    const listenerKey = this.map.on('postcompose', (event: any) => {
      const vectorContext = event.vectorContext;
      const frameState = event.frameState;
      const flashGeom = cloneDeep(feature.getGeometry());
      const elapsed = frameState.time - start;
      const elapsedRatio = elapsed / duration;
      // radius will be 5 at start and 30 at end.
      const radius = easeOut(elapsedRatio) * 25 + 5;
      const opacity = easeOut(1 - elapsedRatio);

      const style = new Style({
        image: new Circle({
          radius: radius,
          stroke: new Stroke({
            color: 'rgba(255, 0, 0, ' + opacity + ')',
            width: 0.25 + opacity
          })
        })
      });

      vectorContext.setStyle(style);
      vectorContext.drawGeometry(flashGeom);
      if (elapsed > duration) {
        unByKey(listenerKey);
        return;
      }
      // tell OpenLayers to continue postcompose animation
      this.map.render();
    });
  }
}
