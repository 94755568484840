import { createAction, props } from '@ngrx/store';
import { TileModel } from 'src/app/models/tile.model';

export const LOAD_PAGE = '[LandingView] Load Page';
export const LOAD_PAGE_SUCCESS = '[LandingView] Load Page Success';
export const LOAD_PAGE_FAILURE = '[LandingView] Load Page Fail';

export const LOAD_INSIGHT_TILES = '[LandingView] Load Insight Tiles';

export const LoadPage = createAction(LOAD_PAGE);
export const LoadPageSuccess = createAction(LOAD_PAGE_SUCCESS);
export const LoadPageFailure = createAction(LOAD_PAGE_FAILURE,
  props<{error: string}>());

export const LoadInsightTiles = createAction(LOAD_PAGE_SUCCESS,
  props<{insightTiles: TileModel[]}>());
