export enum Greetings {
    MORNING = 'Good Morning',
    AFTERNOON = 'Good Afternoon',
    EVENING = 'Good Evening',
    DEFAULT = 'Hello'
}

export function getGreeting(): Greetings {
  const today = new Date();

  const localHour = today.getHours();

  if (localHour >= 5 && localHour < 12) {
    return Greetings.MORNING;
  } else if (localHour >= 12 && localHour < 18) {
    return Greetings.AFTERNOON;
  } else if (localHour >= 18 && localHour <= 24) {
    return Greetings.EVENING;
  } else {
    return Greetings.DEFAULT;
  }


}
