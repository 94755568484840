import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeMapComponent } from './tree-map.component';
import { RichUiModule } from '@richUi';
import { NoDataModule } from '../no-data/no-data.module';

@NgModule({
  imports: [
    CommonModule,
    RichUiModule,
    NoDataModule
  ],
  declarations: [TreeMapComponent],
  exports: [TreeMapComponent]
})
export class TreeMapModule { }
