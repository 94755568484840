import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { from, Observable, timer } from 'rxjs';
import { getRuntimeAppConfig } from 'src/app/state/app.selectors';
import { AppState } from 'src/app/state/app.state';
import gql from 'graphql-tag';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { AppConfig } from 'src/app/config/app.config';
import { User as AuthUser } from 'oidc-client-ts';
import { AuthService } from '../ue/auth.service';
import { filter, switchMap, take } from 'rxjs/operators';

export type CompanyRiskScoreHistory = {
  id: string;
  name: string;
  address: string;
};

@Injectable({
  providedIn: 'root',
})
export class USecureMockService {
  client!: AWSAppSyncClient<any>;
  user: AuthUser | null;
  private _config!: AppConfig;

  constructor(
    private _appStore: Store<AppState>,
    private _authService: AuthService
  ) {
    this.setupClient();
  }

  async setupClient() {
    this.user = await this._authService.getUser();
    if (this.user) {
      this._appStore.pipe(select(getRuntimeAppConfig)).subscribe((config) => {
        if (config) {
          this._config = config;
          this.client = new AWSAppSyncClient({
            url: this._config.appSyncUrl,
            region: this._config.region,
            auth: {
              type: AUTH_TYPE.OPENID_CONNECT,
              jwtToken: this.user?.access_token as string,
            },
            disableOffline: true,
          });
        }
      });
    } else {
      if (this.client) {
        this.client.resetStore();
        this.client.clearStore();
      }
    }
  }

  getEnigmaUMockData(fileName: string): Observable<any> {
    return from(this.getEnigmaUMockDataHandler(fileName));
  }

  async getEnigmaUMockDataHandler(fileName: string): Promise<any> {
    const getEnigmaUMockData = `query getEnigmaUMockData($fileName: String!) {
      getEnigmaUMockData(fileName: $fileName) {
        data
      }
    }`;
    return timer(0, 1000)
      .pipe(
        filter((_) => !!this.client),
        take(1),
        switchMap(async (_) => {
          await this.client?.hydrated();
          try {
            const transactionComplete: any = await this.client.query({
              query: gql`
                ${getEnigmaUMockData}
              `,
              variables: {
                fileName,
              },
              fetchPolicy: 'no-cache',
            });
            return { data: JSON.parse(transactionComplete.data.getEnigmaUMockData.data) };
          } catch (err) {
            console.log(err);
          }
        })
      )
      .toPromise();
  }
}
