<div #wrapper class="pie-grid__wrapper">

  <ngx-charts-pie-grid
    *ngIf="data && data.length > 0"
    [scheme]="scheme"
    [view]="view"
    [results]="data"
    [animations]="animations"
    [tooltipDisabled]="tooltipDisabled"
    [tooltipText]="pieTooltipText"
  >
  </ngx-charts-pie-grid>

  <eng-no-data *ngIf="data && data.length === 0"></eng-no-data>
</div>