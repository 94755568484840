import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'eng-menu-item-button',
  templateUrl: './menu-item-button.component.html',
  styleUrls: ['./menu-item-button.component.scss']
})
export class MenuItemButtonComponent {

  @Input()
    id: string;

  @Input()
    menutText: string;

  @Input()
    matIcon = 'delete';

  @Input()
    trigger: MatMenuTrigger;

  @Output()
    btnClicked = new EventEmitter<string>();

  @Output()
    iconClicked = new EventEmitter<string>();

  constructor() { }

  emitBtnClick() {
    this.btnClicked.emit(this.id);
  }

  emitIconClick($event: any) {
    $event.stopPropagation();
    this.iconClicked.emit(this.id);
  }

}
