<mat-sidenav-container  #sidenav [hasBackdrop]="false" (backdropClick)="toogleSideNav(false)">
  <mat-sidenav class="container" mode="over" [ngClass]="{'collapsed-width': !isOpen}">
    <div *ngIf="isOpen" class="eng-sidenav__wrapper">
      <span class="eng-sidenav__header">
        MENU
        <button class="close-button" mat-button (click)="toogleSideNav(false)">
          <mat-icon class="menu-icons">keyboard_arrow_left</mat-icon>
        </button>
      </span>
      <mat-divider class="eng-sidenav__divider"></mat-divider>
      <mat-accordion>
        <mat-expansion-panel *ngIf=" 1 || features?.mainMenu?.showAnalyst || 
          features?.mainMenu?.showUserBehaviorMonitoring ||
          features?.mainMenu?.showSecurityEvents || 
          features?.mainMenu?.showNetworkActivityDashboard || 
          features?.mainMenu?.showPowershell || 
          features?.mainMenu?.showAuditBeat || 
          features?.mainMenu?.showFileBeat || 
          features?.mainMenu?.showPacketBeat ||  
          features?.mainMenu?.showEnigmasDB ||  
          features?.mainMenu?.showEnigmaU"
          [expanded]="true" [class.mat-elevation-z0]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="menu-icons">dashboard</mat-icon>
              Dashboards
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="eng-sidenav-submenu-items">


            <!-- <a *ngIf=" 1 || features.mainMenu.showSecurityEvents" mat-button routerLink="/security-events" (click)="toogleSideNav(false)">
              <mat-icon class="menu-icons" [svgIcon]="iconsName.BarChart"></mat-icon>
              Security Events
            </a>
            <a *ngIf=" 1 || features.mainMenu.showAnalyst" mat-button routerLink="/analyst-dashboard" (click)="toogleSideNav(false)">
              <mat-icon class="menu-icons" [svgIcon]="iconsName.PieChart"></mat-icon>
              Analyst Dashboard
            </a>
            <a *ngIf=" 1 || features.mainMenu.showUserBehaviorMonitoring"  mat-button routerLink="/user-behavior-dashboard" (click)="toogleSideNav(false)">
              <mat-icon class="menu-icons" [svgIcon]="iconsName.UserMonitoring"></mat-icon>
              User Behavior Monitoring
            </a>
            <a *ngIf=" 1 || features.mainMenu.showNetworkActivityDashboard"  mat-button routerLink="/network-activity" (click)="toogleSideNav(false)">
              <mat-icon class="menu-icons" [svgIcon]="iconsName.NetworkActivity"></mat-icon>
              Network Activity Dashboard
            </a>
            <a *ngIf=" 1 || features.mainMenu.showPowershell"  mat-button routerLink="/powershell" (click)="toogleSideNav(false)">
              <mat-icon class="menu-icons" [svgIcon]="iconsName.Powershell"></mat-icon>
              Powershell Dashboard
            </a>
            <a *ngIf=" 1 || features.mainMenu.showAuditBeat"  mat-button routerLink="/linux" (click)="toogleSideNav(false)">
              <mat-icon class="menu-icons" [svgIcon]="iconsName.AuditBeat"></mat-icon>
              Linux Dashboard
            </a>
            <a *ngIf=" 1 || features.mainMenu.showFileBeat"  mat-button routerLink="/file-beat" (click)="toogleSideNav(false)">
              <mat-icon class="menu-icons" [svgIcon]="iconsName.FileBeat"></mat-icon>
              Firewall Activity
            </a>
            <a *ngIf=" 1 || features.mainMenu.showPacketBeat"  mat-button routerLink="/packet-beat" (click)="toogleSideNav(false)">
              <mat-icon class="menu-icons" [svgIcon]="iconsName.PacketBeat"></mat-icon>
              What Are My Resources Connecting To?
            </a>
            <a *ngIf=" 1 || features.mainMenu.showEnigmasDB"  mat-button routerLink="/enigmas-db" (click)="toogleSideNav(false)">
              <mat-icon class="menu-icons" aria-hidden="false">{{ iconsName.EnigmasDB }}</mat-icon>
              Enigmas Dashboard
            </a> -->



            <a *ngIf=" 1 || features.mainMenu.showFileBeat"  mat-button routerLink="/ai-insights" (click)="toogleSideNav(false)">
              <mat-icon class="menu-icons" [svgIcon]="iconsName.AIInsights"></mat-icon>
              AI Insights
            </a>
            <a *ngIf=" 1 || features.mainMenu.showFileBeat"  mat-button routerLink="/ai-label" (click)="toogleSideNav(false)">
              <mat-icon class="menu-icons" [svgIcon]="iconsName.LabelJob"></mat-icon>
              Source Subnet Profiling
            </a>
            <a *ngIf=" 1 || features.mainMenu.showFileBeat"  mat-button routerLink="/destination-jobs" (click)="toogleSideNav(false)">
              <mat-icon class="menu-icons" style="fill: black;" [svgIcon]="iconsName.DestinationJob"></mat-icon>
              Destination System Labeling Jobs
            </a>            
          </div>
        </mat-expansion-panel>
      </mat-accordion>



      <!-- <mat-accordion>
        <mat-expansion-panel *ngIf=" 1 || features?.mainMenu?.showNetworkActivity || features?.mainMenu?.showLogonActivity" 
          [expanded]="false" [class.mat-elevation-z0]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="menu-icons">gps_fixed</mat-icon>
              Threat Maps
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="eng-sidenav-submenu-items">
            <a *ngIf=" 1 || features?.mainMenu?.showNetworkActivity" mat-button routerLink="/attack-map" (click)="toogleSideNav(false)">
              <mat-icon class="menu-icons" [svgIcon]="iconsName.Networking"></mat-icon>
              Network Activity
            </a>
            <a *ngIf=" 1 || features?.mainMenu?.showLogonActivity" mat-button routerLink="/threat-map" (click)="toogleSideNav(false)">
              <mat-icon class="menu-icons" [svgIcon]="iconsName.Earth"></mat-icon>
              Logon Activity
            </a>
          </div>
        </mat-expansion-panel>
      </mat-accordion>  -->
      <div class="eng-sidenav-menu-items">
        <a *ngIf=" 1 || features.mainMenu.showFileBeat"  mat-button routerLink="/discover" (click)="toogleSideNav(false)">
          <mat-icon class="menu-icons" [svgIcon]="iconsName.Analysis"></mat-icon>
          Discover
        </a>
        <!-- <a *ngIf=" 1 || features.mainMenu.showFileBeat"  mat-button routerLink="/notification" (click)="toogleSideNav(false)">
          <mat-icon class="menu-icons" [svgIcon]="iconsName.Rules"></mat-icon>
          Notification
        </a> -->
        <!-- <a *ngIf=" 1 || features?.mainMenu?.showDiscover" mat-button routerLink="/discover" (click)="toogleSideNav(false)">
          <mat-icon class="menu-icons" [svgIcon]="iconsName.Analysis"></mat-icon>
          Discover
        </a>

        <a *ngIf=" 1 || features?.mainMenu?.showReports" mat-button routerLink="/reports" (click)="toogleSideNav(false)">
          <mat-icon class="menu-icons" [svgIcon]="iconsName.ClipBoard"></mat-icon>
          Reports
        </a>
        <a *ngIf=" 1 || features?.mainMenu?.showAlerts" mat-button routerLink="/alerts" (click)="toogleSideNav(false)">
          <mat-icon class="menu-icons" [svgIcon]="iconsName.Alert"></mat-icon>
          Alerts
        </a>
        <a *ngIf=" 1 || features?.mainMenu?.showLogInsight" mat-button routerLink="/log-insights" (click)="toogleSideNav(false)">
          <mat-icon class="menu-icons" [svgIcon]="iconsName.LogInsight"></mat-icon>
          Log Insight
        </a>
        <a *ngIf=" 1 || features?.mainMenu?.showIkeTool" mat-button routerLink="/ike-tool" (click)="toogleSideNav(false)">
          <mat-icon class="menu-icons" [svgIcon]="iconsName.Ike"></mat-icon>
          Ike Tool
        </a>
        <a *ngIf=" 1 || features?.mainMenu?.showRules" mat-button routerLink="/rules" (click)="toogleSideNav(false)">
          <mat-icon class="menu-icons" [svgIcon]="iconsName.Rules"></mat-icon>
          Rules
        </a>
        <a *ngIf=" 1 || features?.mainMenu?.showEnigmas" mat-button routerLink="/enigmas" (click)="toogleSideNav(false)">
          <mat-icon class="menu-icons" [svgIcon]="iconsName.Enigmas"></mat-icon>
          Enigmas
        </a>
        <a *ngIf=" 1 || features.mainMenu.showEnigmaU"  mat-button routerLink="/enigma-u" (click)="toogleSideNav(false)">
          <mat-icon class="menu-icons" aria-hidden="false">{{ iconsName.EnigmaU }}</mat-icon>
          Enigma U
        </a> -->
      </div>



      <span class="eng-sidenav__header">MORE</span>
      <mat-divider class="eng-sidenav__divider"></mat-divider>
      <div class="eng-sidenav-menu-items">



        <!-- <a *ngIf=" 1 || features?.mainMenu?.showEnigmaApps" mat-button routerLink="/enigma-apps" (click)="toogleSideNav(false)">
          <mat-icon class="menu-icons" [svgIcon]="iconsName.App"></mat-icon>
          Enigma Modules
        </a>
        <button  *ngIf=" 1 || features?.mainMenu?.showDataManagement" mat-button disabled>
          <mat-icon class="menu-icons">storage</mat-icon>
          Data Management
        </button> -->
        <a mat-button routerLink="/settings" (click)="toogleSideNav(false)">
          <mat-icon class="menu-icons" [svgIcon]="iconsName.Settings"></mat-icon>
          Settings
        </a>



        <button mat-button (click)="logout()">
          <mat-icon class="menu-icons">keyboard_return</mat-icon>
          Logout
        </button>
      </div>
    </div>
    <div *ngIf="!isOpen" class="eng-sidenav__wrapper-collapsed">
        <button mat-button (click)="toogleSideNav(true)" [matTooltip]="'Menu'" matTooltipPosition="right">
          <mat-icon class="menu-icons">menu</mat-icon>
        </button>
        <mat-divider class="eng-sidenav__divider eng-sidenav__divider-collapsed"></mat-divider>



        <!-- <div class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showSecurityEvents"
          [ngClass]="{'background-dark': ((darkThemeSub$ | async) && 0), 'background-light': ((darkThemeSub$ | async) || 1)}">
          <a mat-button routerLink="/security-events">
            <mat-icon class="menu-icons" [svgIcon]="iconsName.BarChart"></mat-icon>
            <span class="sidenav-menu__label">
              Security Events
            </span>
          </a>
        </div>
        <div class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showUserBehaviorMonitoring" 
          [ngClass]="{'background-dark': ((darkThemeSub$ | async) && 0), 'background-light': ((darkThemeSub$ | async) || 1)}">
          <a mat-button routerLink="/user-behavior-dashboard">
            <mat-icon class="menu-icons" [svgIcon]="iconsName.UserMonitoring"></mat-icon>
            <span class="sidenav-menu__label">
              User Behavior Monitoring
            </span>
          </a>
        </div>
        <div class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showNetworkActivityDashboard" 
          [ngClass]="{'background-dark': ((darkThemeSub$ | async) && 0), 'background-light': ((darkThemeSub$ | async) || 1)}">
          <a mat-button routerLink="/network-activity">
            <mat-icon class="menu-icons" [svgIcon]="iconsName.NetworkActivity"></mat-icon>
            <span class="sidenav-menu__label">
              Network Activity Dashboard
            </span>
          </a>
        </div>
        <div class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showPowershell" 
          [ngClass]="{'background-dark': ((darkThemeSub$ | async) && 0), 'background-light': ((darkThemeSub$ | async) || 1)}">
          <a mat-button routerLink="/powershell">
            <mat-icon class="menu-icons" [svgIcon]="iconsName.Powershell"></mat-icon>
            <span class="sidenav-menu__label">
              Powershell Dashboard
            </span>
          </a>
        </div>
        <div class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showAuditBeat" 
          [ngClass]="{'background-dark': ((darkThemeSub$ | async) && 0), 'background-light': ((darkThemeSub$ | async) || 1)}">
          <a mat-button routerLink="/linux">
            <mat-icon class="menu-icons" [svgIcon]="iconsName.AuditBeat"></mat-icon>
            <span class="sidenav-menu__label">
              Linux Dashboard
            </span>
          </a>
        </div>
        <div class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showFileBeat" 
          [ngClass]="{'background-dark': ((darkThemeSub$ | async) && 0), 'background-light': ((darkThemeSub$ | async) || 1)}">
          <a mat-button routerLink="/file-beat">
            <mat-icon class="menu-icons" [svgIcon]="iconsName.FileBeat"></mat-icon>
            <span class="sidenav-menu__label">
              Firewall Activity
            </span>
          </a>
        </div>
        <div class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showPacketBeat" 
          [ngClass]="{'background-dark': ((darkThemeSub$ | async) && 0), 'background-light': ((darkThemeSub$ | async) || 1)}">
          <a mat-button routerLink="/packet-beat">
            <mat-icon class="menu-icons" [svgIcon]="iconsName.PacketBeat"></mat-icon>
            <span class="sidenav-menu__label">
              What Are My Resources Connecting To?
            </span>
          </a>
        </div>
        <div class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showEnigmasDB" 
          [ngClass]="{'background-dark': ((darkThemeSub$ | async) && 0), 'background-light': ((darkThemeSub$ | async) || 1)}">
          <a mat-button routerLink="/enigmas-db">
            <mat-icon class="menu-icons" aria-hidden="false">{{ iconsName.EnigmasDB }}</mat-icon>
            <span class="sidenav-menu__label">
              Enigmas Dashboard
            </span>
          </a>
        </div> -->



        <div mat-cell class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showFileBeat"
          [ngClass]="{'background-dark': (isDark), 'background-light': (!isDark)}">
          <a mat-button routerLink="/ai-insights" [matTooltip]="'AI Insights'" matTooltipPosition="right">
            <mat-icon class="menu-icons" [svgIcon]="iconsName.AIInsights"></mat-icon>
            <!-- <span class="sidenav-menu__label">
              Enigma AI Insights
            </span> -->
          </a>
        </div>
        <div class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showFileBeat" 
          [ngClass]="{'background-dark': (isDark), 'background-light': (!isDark)}">
          <a mat-button routerLink="/ai-label" [matTooltip]="'Source Subnet Profiling'" matTooltipPosition="right">
            <mat-icon class="menu-icons" [svgIcon]="iconsName.LabelJob"></mat-icon>
            <!-- <span class="sidenav-menu__label">
              Enigma AI Labeling Jobs
            </span> -->
          </a>
        </div>
        <div class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showFileBeat" 
          [ngClass]="{'background-dark': (isDark), 'background-light': (!isDark)}">
          <a mat-button routerLink="/destination-jobs" [matTooltip]="'Destination System Labeling Jobs'" matTooltipPosition="right">
            <mat-icon class="menu-icons" [svgIcon]="iconsName.DestinationJob"></mat-icon>
            <!-- <span class="sidenav-menu__label">
              Destination Jobs
            </span> -->
          </a>
        </div>
        <div class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showFileBeat"
          [ngClass]="{'background-dark': (isDark), 'background-light': (!isDark)}">
          <a mat-button routerLink="/discover" [matTooltip]="'Discover'" matTooltipPosition="right">
            <mat-icon class="menu-icons" [svgIcon]="iconsName.Analysis"></mat-icon>
          </a>
        </div>
        <!-- <div class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showFileBeat"
          [ngClass]="{'background-dark': (isDark), 'background-light': (!isDark)}">
          <a mat-button routerLink="/notification" [matTooltip]="'Notification'" matTooltipPosition="right">
            <mat-icon class="menu-icons" [svgIcon]="iconsName.Rules"></mat-icon>
          </a>
        </div> -->


        <!-- <div class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showNetworkActivity"
          [ngClass]="{'background-dark': ((darkThemeSub$ | async) && 0), 'background-light': ((darkThemeSub$ | async) || 1)}">
          <a mat-button routerLink="/attack-map">
            <mat-icon class="menu-icons" [svgIcon]="iconsName.Networking"></mat-icon>
            <span class="sidenav-menu__label">
              Network Activity
            </span>
          </a>
        </div>
        <div class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showLogonActivity"
          [ngClass]="{'background-dark': ((darkThemeSub$ | async) && 0), 'background-light': ((darkThemeSub$ | async) || 1)}">
          <a mat-button routerLink="/threat-map">
            <mat-icon class="menu-icons" [svgIcon]="iconsName.Earth"></mat-icon>
            <span class="sidenav-menu__label">
              Logon Activity
            </span>
          </a>
        </div>
        <div class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showDiscover"
          [ngClass]="{'background-dark': ((darkThemeSub$ | async) && 0), 'background-light': ((darkThemeSub$ | async) || 1)}">
          <a mat-button routerLink="/discover">
            <mat-icon class="menu-icons" [svgIcon]="iconsName.Analysis"></mat-icon>
            <span class="sidenav-menu__label">
              Discover
            </span>
          </a>
        </div>
        <div class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showReports"
          [ngClass]="{'background-dark': ((darkThemeSub$ | async) && 0), 'background-light': ((darkThemeSub$ | async) || 1)}">
          <a mat-button routerLink="/reports">
            <mat-icon class="menu-icons" [svgIcon]="iconsName.ClipBoard"></mat-icon>
            <span class="sidenav-menu__label">
              Reports
            </span>
          </a>
        </div>
        <div class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showAlerts"
          [ngClass]="{'background-dark': ((darkThemeSub$ | async) && 0), 'background-light': ((darkThemeSub$ | async) || 1)}">
          <a mat-button routerLink="/alerts">
            <mat-icon class="menu-icons" [svgIcon]="iconsName.Alert"></mat-icon>
            <span class="sidenav-menu__label">
              Alerts
            </span>
          </a>
        </div>
        <div class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showLogInsight"
          [ngClass]="{'background-dark': ((darkThemeSub$ | async) && 0), 'background-light': ((darkThemeSub$ | async) || 1)}">
          <a mat-button routerLink="/log-insights">
            <mat-icon class="menu-icons" [svgIcon]="iconsName.LogInsight"></mat-icon>
            <span class="sidenav-menu__label">
              Log Insight
            </span>
          </a>
        </div>
        <div class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showIkeTool"
          [ngClass]="{'background-dark': ((darkThemeSub$ | async) && 0), 'background-light': ((darkThemeSub$ | async) || 1)}">
          <a mat-button routerLink="/ike-tool">
            <mat-icon class="menu-icons" [svgIcon]="iconsName.Ike"></mat-icon>
            <span class="sidenav-menu__label">
              Ike Tool
            </span>
          </a>
        </div>
        <div class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showRules"
          [ngClass]="{'background-dark': ((darkThemeSub$ | async) && 0), 'background-light': ((darkThemeSub$ | async) || 1)}">
          <a mat-button routerLink="/rules">
            <mat-icon class="menu-icons" [svgIcon]="iconsName.Rules"></mat-icon>
            <span class="sidenav-menu__label">
              Rules
            </span>
          </a>
        </div>
        <div class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showEnigmas"
          [ngClass]="{'background-dark': ((darkThemeSub$ | async) && 0), 'background-light': ((darkThemeSub$ | async) || 1)}">
          <a mat-button routerLink="/enigmas">
            <mat-icon class="menu-icons" [svgIcon]="iconsName.Enigmas"></mat-icon>
            <span class="sidenav-menu__label">
              Enigmas
            </span>
          </a>
        </div>
        <div class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showEnigmaU" 
          [ngClass]="{'background-dark': ((darkThemeSub$ | async) && 0), 'background-light': ((darkThemeSub$ | async) || 1)}">
          <a mat-button routerLink="/enigma-u">
            <mat-icon class="menu-icons" aria-hidden="false">{{ iconsName.EnigmaU }}</mat-icon>
            <span class="sidenav-menu__label">
              Enigma U
            </span>
          </a>
        </div> -->


        
        <br>
        <mat-divider class="eng-sidenav__divider eng-sidenav__divider-collapsed"></mat-divider>
        <br>



        <!-- <div class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showEnigmaApps"
          [ngClass]="{'background-dark': ((darkThemeSub$ | async) && 0), 'background-light': ((darkThemeSub$ | async) || 1)}">
          <a mat-button routerLink="/enigma-apps">
            <mat-icon class="menu-icons" [svgIcon]="iconsName.App"></mat-icon>
            <span class="sidenav-menu__label">
              Enigma Modules
            </span>
          </a>
        </div>
        <div class="list-item-container" *ngIf=" 1 || features?.mainMenu?.showDataManagement"
          [ngClass]="{'background-dark': ((darkThemeSub$ | async) && 0), 'background-light': ((darkThemeSub$ | async) || 1)}">
          <button mat-button disabled>
            <mat-icon class="menu-icons">storage</mat-icon>
            <span class="sidenav-menu__label">
              Data Management
            </span>
          </button>
        </div> -->
        <div class="list-item-container"
          [ngClass]="{'background-dark': (isDark), 'background-light': (!isDark)}">
          <a mat-button routerLink="/settings" [matTooltip]="'Settings'" matTooltipPosition="right">
            <mat-icon class="menu-icons" [svgIcon]="iconsName.Settings"></mat-icon>
            <!-- <span class="sidenav-menu__label">
              Settings
            </span> -->
          </a>
        </div>



        <div class="list-item-container"
          [ngClass]="{'background-dark': (isDark), 'background-light': (!isDark)}">
          <button mat-button (click)="logout()" [matTooltip]="'Logout'" matTooltipPosition="right">
            <mat-icon class="menu-icons">keyboard_return</mat-icon>
            <!-- <span class="sidenav-menu__label">
              Logout
            </span> -->
          </button>
        </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{ 'mat-sidenav-content': _open }">
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
