import { MatchPhraseOperation } from 'src/app/views/rules/watcher-alerts.model';

export function removeTypename(obj: unknown) {
  return filterObject(obj, '__typename');
}

export function filterObject(obj: any, key: string) {
  for (const i in obj) {
    if (!obj.hasOwnProperty(i)) {
      continue;
    }
    if (obj[i] && typeof obj[i] === 'object') {
      filterObject(obj[i], key);
    } else if (i && i === key) {
      delete obj[i];
    }
  }
  return obj;
}

export function removeNullValuesInObject(obj: any) {
  Object.keys(obj).forEach(key => {
    if (obj[key] === null) {
      delete obj[key];
    }
  });

  return obj;
}

export function convertToString(value: any) {
  if (typeof value === 'object') {
    if (value.length > 0) {
      let newValue = '';
      value.map((v: any) => {
        newValue += `${v.name.split('.').pop()} ${operatorToLabel(v.operation)} ${v.value.split('.').pop()}; `;
      });
      value = newValue;
    }
  }
  return value;
}

export function operatorToLabel(operator: MatchPhraseOperation) {
  switch (operator) {
  case MatchPhraseOperation.Equals:
    return 'equals';
  case MatchPhraseOperation.DoesNotEqual:
    return 'not equals';
  case MatchPhraseOperation.Contains:
    return 'contains';
  case MatchPhraseOperation.BeginsWith:
    return 'begin with';
  default:
    return '';
  }
}
