<div class="found-a-bug-dialog__wrapper">
  <div mat-dialog-title>
    {{ usersName | greeting }}
  </div>
  <div class="found-a-bug-dialog__content" mat-dialog-content>
    <span>
      Found a bug? We greatly appreciate your help. Please let us know what you
      found.
    </span>
    <form
      class="found-a-bug-form__wrapper"
      [formGroup]="foundABugForm"
      (ngSubmit)="submitBug()"
    >
      <mat-form-field *ngIf="!user">
        <input
          class="change-found-a-bug-formfield"
          matInput
          [type]="email"
          name="email"
          formControlName="email"
          placeholder="What's your email?"
        />
        <mat-hint align="start">Not required, but we would like to thank you</mat-hint>
      </mat-form-field>

      <mat-form-field>
        <textarea
          class="change-found-a-bug-formfield textarea"
          matInput
          name="description"
          formControlName="description"
          placeholder="What went wrong?"
          required
          #description
        ></textarea>
        <mat-hint align="start">Min length 12 characters</mat-hint>
        <mat-hint align="end">{{ description.value.length }} / 256</mat-hint>
      </mat-form-field>

      <div class="bug-photo__wrapper">
        <span>Do you have a screenshot?</span>
        <ng-container *ngIf="bugPhotoBase64">
          <img class="bug-img" [src]="bugPhotoBase64" alt="bug image" />
        </ng-container>
        <button
          mat-icon-button
          type="button"
          aria-label="Add a screenshot of the bug"
          (click)="addPhoto()"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </form>
    <div mat-dialog-actions>
      <button
        mat-raised-button
        color="primary"
        [disabled]="!foundABugForm.valid"
        (click)="submitBug()"
        cdkFocusInitial
        class="submit-button"
      >
        <span *ngIf="!bugSending">Submit bug</span>
        <mat-progress-spinner
          *ngIf="bugSending"
          mode="indeterminate"
          diameter="26"
          strokeWidth="2"
        >
        </mat-progress-spinner>
      </button>
      <button mat-button matDialogClose>
        Close
      </button>
    </div>
  </div>
</div>
