import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../services/ue/auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    public authService: AuthService,
    public router: Router
  ) {}
  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.authService
        .getUser()
        .then(async (user) => {
          if (!user) {
            try {
              return this.authService.renewToken()
                .then((userRenewed) => {
                  if (!userRenewed) {
                    this.authService.login();
                    resolve(false);
                  } else {
                    window.location.reload();
                    resolve(true);
                  }
                })
                .catch((err) => {
                  console.log('err', err);
                  this.authService.login();
                  reject(err);
                });
            } catch (error) {
              this.authService.login();
              resolve(false);
            }
          } else {
            resolve(true);
          }
        })
        .catch((err) => {
          console.log('err', err);
          this.authService.login();
          reject(err);
        });
    });
  }
}
