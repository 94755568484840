import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'eng-baseball-card',
  templateUrl: './baseball-card.component.html',
  styleUrls: ['./baseball-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BaseballCardComponent {

  @Input()
    title!: string;

  @Input()
    xPosition!: number;

  @Input()
    yPosition!: number;

  @Input()
  get showCard(): boolean {
    return this._showCard;
  }
  set showCard(show: boolean) {
    this._showCard = show;
  }
  private _showCard = false;

  @Input()
  get transparent(): boolean {
    return this._transparent;
  }
  set transparent(bool: boolean) {
    this._transparent = bool;
  }
  private _transparent = false;

  constructor() { }

  setPosition(xPos: number, yPos: number): void {
    this.xPosition = xPos;
    this.yPosition = yPos;
  }

  toggleCard() {
    this.showCard = !this.showCard;
  }

}
