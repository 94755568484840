import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class TimeFilterService {
  private timeFilterValueSource = new Subject();
  private timeInvalidValueSource = new Subject();
  currentStartTime = '00:00';
  currentEndTime = '23:59';
  currentTimeFilter = this.timeFilterValueSource.asObservable();
  currentTimeInvalid = this.timeInvalidValueSource.asObservable();
  constructor() { }

  changeTimeFilter(timeFilterValues: any) {
    this.currentStartTime = timeFilterValues.startTime;
    this.currentEndTime = timeFilterValues.endTime;
    this.timeFilterValueSource.next(timeFilterValues);
  }

  changeTimeInvalid(timeValidity: boolean) {
    this.timeInvalidValueSource.next(timeValidity);
  }
}
