import { Component, Input } from '@angular/core';
import { ChartType } from '@config';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { SchemePalette } from 'src/app/shared/common-behaviors/scheme';
import { NxChartBaseDirective } from '../nx-chart-base/nx-chart-base.directive';
import { TooltipFormat } from 'src/app/models/tooltip-format.model';

@Component({
  selector: 'eng-advanced-pie-chart',
  templateUrl: './advanced-pie-chart.component.html',
  styleUrls: ['./advanced-pie-chart.component.scss']
})
export class AdvancedPieChartComponent extends NxChartBaseDirective {

  chartType: ChartType = 'advanced-pie-chart';

  showLabels = true;

  tooltipFormat: TooltipFormat;

  @Input()
    scheme: SchemePalette;

  @Input()
    data: any[];

  constructor(
    public appState: Store<AppState>
  ) {
    super([20, 0]);
  }

}
