<h1 mat-dialog-title>Are you sure?</h1>
<div mat-dialog-content>
  <p *ngIf="data.message">{{ data.message }}</p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="sure()">
    Sure
  </button>
  <button mat-button (click)="no()" cdkFocusInitial>No</button>
</div>
