import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StackedVerticalChartComponent } from './stacked-vertical-chart.component';
import { RichUiModule } from '@richUi';
import { NoDataModule } from '../no-data/no-data.module';

@NgModule({
  declarations: [StackedVerticalChartComponent],
  imports: [
    CommonModule,
    RichUiModule,
    NoDataModule,
  ],
  exports: [StackedVerticalChartComponent]
})
export class StackedVerticalChartModule { }
