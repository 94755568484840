import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FoundABugDialogComponent } from './found-a-bug-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropDialogComponent, ImageCropDialogModule } from '../image-crop-dialog';
import { PhotoService } from '../../../services/photo/photo.service';
import { PipesModule } from 'src/app/utils/pipes/pipes.module';

@NgModule({
  declarations: [FoundABugDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    PipesModule,
    MatIconModule,
    MatDialogModule,
    ImageCropDialogModule,
    MatProgressSpinnerModule
  ],
  exports: [FoundABugDialogComponent],
  providers: [PhotoService]
})
export class FoundABugDialogModule {}
