import { ChangeDetectorRef, Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerService } from 'src/app/services/ue/spinner.service';

@Component({
  selector: 'eng-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss']
})
export class ProgressSpinnerComponent {
  // showSpinner = false;
  // private readonly subscriptions: Subscription[] = [];

  constructor(private spinnerService: SpinnerService) { }
  // ngOnInit() {
  //   this.init();
  // }

  // init() {
  //   const spinSub = this.spinnerService.getSpinnerObserver().subscribe((status:any) => {
  //     this.showSpinner = (status === 'start');
  //     this.cdRef.detectChanges();
  //   });
  //   this.subscriptions.push(spinSub);
  // }

  // ngOnDestroy() {
  //   if (this.subscriptions.length) {
  //     this.subscriptions.forEach(subscription => {
  //       if(subscription){
  //         subscription.unsubscribe();
  //       }
  //     })
  //   }
  // }

}
