import { Component, Input, OnInit } from '@angular/core';
import { ChartType } from '@config';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { SchemePalette } from 'src/app/shared/common-behaviors/scheme';
import { NxChartBaseDirective } from '../nx-chart-base/nx-chart-base.directive';

@Component({
  selector: 'eng-grouped-vertical-chart',
  templateUrl: './grouped-vertical-chart.component.html',
  styleUrls: ['./grouped-vertical-chart.component.scss']
})
export class GroupedVerticalChartComponent extends NxChartBaseDirective implements OnInit {

  chartType: ChartType = 'grouped-vertical-chart';

  @Input()
    scheme: SchemePalette;

  @Input()
    data: any[];

  constructor(
    public appState: Store<AppState>
  ) {
    super([20, 0]);
  }

  ngOnInit(): void {
    this.showLegend = true;
    this.showXAxisLabel = true;
    // TODO: receive this value by input
    this.xAxisLabel = 'Domains';
  }

}
