import { Component, Input } from '@angular/core';
import { BreadCrumbItem } from 'src/app/models/bread-crumb-item';

@Component({
  selector: 'eng-bread-crumb-item',
  templateUrl: './bread-crumb-item.component.html',
  styleUrls: ['./bread-crumb-item.component.scss']
})
export class BreadCrumbItemComponent {

  @Input()
    item: BreadCrumbItem;

  constructor() { }

}
