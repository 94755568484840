import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullscreenDirective } from './fullscreen.directive';
import { ThemeDirective } from './theme.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [FullscreenDirective, ThemeDirective],
  exports: [FullscreenDirective, ThemeDirective]
})
export class StyleModule { }
