import { Pipe, PipeTransform } from '@angular/core';
import { getGreeting } from '../../utils/time/greeting';

@Pipe({ name: 'greeting' })
export class GreetingPipe implements PipeTransform {
  transform(name: string): string {
    const firstName = name ? name.split(' ')[0] : '';
    const greeting = getGreeting();
    return `${greeting} ${firstName}`;
  }
}
