import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { getUserFeatures } from '../state/app.selectors';
import { AppState } from '../state/app.state';

export enum RoutesFeatures {
  Alerts = '/alerts',
  Discover = '/discover',
  LogonActivity = '/threat-map',
  NetworkActivity = '/attack-map',
  Reports = '/reports',
  DataManagement = '/...',
  EnigmaApps = '/enigma-apps',
  LogInsight = '/log-insights',
  UserBehavior = '/user-behavior-dashboard',
  IkeTool = '/ike-tool',
  SecurityEvents = '/security-events',
  NetworkActivityDashboard = '/network-activity',
  Powershell = '/powershell',
  AuditBeat = '/linux',
  FileBeat = '/file-beat',
  PacketBeat = '/packet-beat',
  Rules = '/rules',
  Enigmas = '/enigmas',
  EnigmasDB = '/enigmas-db',
  EnigmaU = '/enigma-u'
}

@Injectable({
  providedIn: 'root'
})
export class FeaturesGuard implements CanActivate {
  constructor(private store: Store<AppState>,
    private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.store.pipe(
      select(getUserFeatures),
      filter(features => !!features),
      map((features) => {
        let url = state.url;
        if (route?.routeConfig?.path) {
          url = `/${route.routeConfig.path}`;
        }
        if (features) {
          switch (url) {
          case RoutesFeatures.NetworkActivity:
            return features?.mainMenu?.showNetworkActivity ?? false;
          case RoutesFeatures.LogonActivity:
            return features?.mainMenu?.showLogonActivity ?? false;
          case RoutesFeatures.Discover:
            return features?.mainMenu?.showDiscover ?? false;
          case RoutesFeatures.Alerts:
            return features?.mainMenu?.showAlerts ?? false;
          case RoutesFeatures.Reports:
            return features?.mainMenu?.showReports ?? false;
            // case RoutesFeatures.DataManagement:
            //   return features?.mainMenu?.showDataManagement ?? false;
          case RoutesFeatures.EnigmaApps:
            return features?.mainMenu?.showEnigmaApps ?? false;
          case RoutesFeatures.LogInsight:
            return features?.mainMenu?.showLogInsight ?? false;
          case RoutesFeatures.UserBehavior:
            return features?.mainMenu?.showUserBehaviorMonitoring ?? false;
          case RoutesFeatures.IkeTool:
            return features?.mainMenu?.showIkeTool ?? false;
          case RoutesFeatures.SecurityEvents:
            return features?.mainMenu?.showSecurityEvents ?? false;
          case RoutesFeatures.NetworkActivityDashboard:
            return features?.mainMenu?.showNetworkActivityDashboard ?? false;
          case RoutesFeatures.Powershell:
            return features?.mainMenu?.showPowershell ?? false;
          case RoutesFeatures.AuditBeat:
            return features?.mainMenu?.showAuditBeat ?? false;
          case RoutesFeatures.FileBeat:
            return features?.mainMenu?.showFileBeat ?? false;
          case RoutesFeatures.PacketBeat:
            return features?.mainMenu?.showPacketBeat ?? false;
          case RoutesFeatures.Rules:
            return features?.mainMenu?.showRules ?? false;
          case RoutesFeatures.Enigmas:
            return features?.mainMenu?.showEnigmas ?? false;
          case RoutesFeatures.EnigmasDB:
            return features?.mainMenu?.showEnigmasDB ?? false;
          case RoutesFeatures.EnigmaU:
            return features?.mainMenu?.showEnigmaU ?? false;
          default:
            return false;
          }
        } else {
          this.router.navigateByUrl('/');
          return false;
        }

      })
    );
  }

}
