import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnumToStringPipe } from './enum-to-string.pipe';
import { NameToInitialsPipe } from './name-to-initials';
import { DisplayToValuePipe } from './display-to-value.pipe';
import { ValueToDisplayPipe } from './value-to-display.pipe';
import { GreetingPipe } from './greeting.pipe';
import { SplitByRowPipe } from './split-by-row.pipe';
import { ChangeCasePipe } from './change-case.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { DataSourceNamePipe } from './data-source-name.pipe';
import { SplitByColumnPipe } from './split-by-column.pipe';
import { LearnerCardsSortPipe } from './learner-cards-sort.pipe';

const PIPES = [
  EnumToStringPipe,
  NameToInitialsPipe,
  DisplayToValuePipe,
  ValueToDisplayPipe,
  GreetingPipe,
  SplitByRowPipe,
  SplitByColumnPipe,
  ChangeCasePipe,
  DateFormatPipe,
  DataSourceNamePipe,
  LearnerCardsSortPipe,
];

@NgModule({
  declarations: [...PIPES],
  imports: [CommonModule],
  exports: [...PIPES],
})
export class PipesModule {}
