import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { IFrameDetail } from 'src/app/models/iframe-detail.model';

@Component({
  selector: 'eng-iframe-dialog',
  templateUrl: './iframe-dialog.component.html',
  styleUrls: ['./iframe-dialog.component.scss']
})
export class IFrameDialogComponent {

  safeUrl: any;

  constructor(
    private dialogRef: MatDialogRef<IFrameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IFrameDetail,
    private _sanitizer: DomSanitizer
  ) {
    if (data && data.link) {
      this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(data.link);
    }
  }
}
