import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import {
  PieChartModule,
  BubbleChartModule,
  NumberCardModule,
  TreeMapModule,
  LineChartModule,
  BarChartModule,
  AreaChartModule
} from '@swimlane/ngx-charts';
import { StyleModule } from './style/style/style.module';



@NgModule({
  imports: [StyleModule],
  exports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    StyleModule,
    MatCardModule,
    PieChartModule,
    BubbleChartModule,
    NumberCardModule,
    TreeMapModule,
    LineChartModule,
    BarChartModule,
    AreaChartModule
  ],
})
export class RichUiModule { }
