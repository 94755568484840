<mat-toolbar color="primary">
  <mat-toolbar-row>
    <div class="eng-topbar-logo__wrapper" routerLink="/dashboard">
      <div class="eng-topbar-image__container">
        <img src="assets/images/enigma-AI-logo.png" alt="enigma icon" />
      </div>
    </div>

    <span class="topbar-spacer"></span>

    <mat-form-field class="eng-topbar-accounts-select" *ngIf="accounts">
      <mat-select [value]="activeAccount" (selectionChange)="changeAccount($event)">
        <mat-option matNativeControl *ngFor="let account of accounts" [value]="account">
          {{account}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <span class="topbar-menu__user">
      <mat-menu #menu="matMenu">
        <!-- <button mat-menu-item routerLink="/settings/user-profile">
          Profile
        </button> -->
        <!-- TODO bring theme back, align with angular material theme -->
        <!-- <button mat-menu-item (click)="themeChange()">
          {{ nextTheme }} Theme
        </button> -->
        <!-- <button mat-menu-item (click)="foundABug()">Report Issue</button>
        <a mat-menu-item href="https://desk.zoho.com/portal/enigmanetworkz/signin" target="_blank">Help</a> -->
        <button mat-menu-item (click)="logout()">Logout</button>
      </mat-menu>

      <button mat-mini-fab [matMenuTriggerFor]="menu" color="accent">
        <span class="initial-wrapper">{{initials}}</span>
      </button>
    </span>
  </mat-toolbar-row>
</mat-toolbar>
