import { User } from '@services';
import { WidgetModel } from '../config/widget.registry';
import { UsernameNextActionType } from '../views/login/login.component';
import { AppConfig } from '../config/app.config';
import { Account, DataSource, DiscoverSavedQuery, UserFeatures } from '../models/account.model';

export interface AppState {
    darkTheme: boolean;
    sub: string;
    user: User | null;
    isAuthenticated: boolean;
    activeAccount: string;
    features: UserFeatures | null;
    dataSources: DataSource[];
    errorMessage: string | null;
    loading: boolean;
    refreshedCreds: boolean;
    widgetRegistry: WidgetModel[];
    checkUsername: UsernameNextActionType | null;
    runtimeConfig: AppConfig | null;
    subdomain: string;
    bugSending: boolean;
    showForgotPasswordDialog: boolean;
    discoverSavedQueries: DiscoverSavedQuery[];
    loadingDiscoverSavedQueries: boolean;
    allAccounts: Account[];
}

export const initialAppState: AppState = {
  darkTheme: false,
  user: null,
  sub: '',
  isAuthenticated: false,
  dataSources: [],
  activeAccount: '',
  features: null,
  errorMessage: null,
  loading: false,
  refreshedCreds: false,
  widgetRegistry: [],
  checkUsername: null,
  runtimeConfig: null,
  subdomain: '',
  bugSending: false,
  showForgotPasswordDialog: false,
  discoverSavedQueries: [],
  loadingDiscoverSavedQueries: false,
  allAccounts: []
};
