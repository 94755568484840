import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BreadCrumbComponent } from './bread-crumb.component';
import { RichUiModule } from '@richUi';
import { BreadCrumbItemComponent } from './bread-crumb-item/bread-crumb-item.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    RichUiModule
  ],
  declarations: [BreadCrumbComponent, BreadCrumbItemComponent],
  exports: [BreadCrumbComponent, BreadCrumbItemComponent]
})
export class BreadCrumbModule { }
