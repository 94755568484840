import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { WidgetModel } from '@config';

export interface DialogData {
  selectedWidget: WidgetModel;
  password: string;
  firstName: string;
}

@Component({
  selector: 'eng-password-dialog',
  templateUrl: 'password-dialog.component.html',
  styleUrls: ['password-dialog.component.scss']
})
export class PasswordDialogComponent implements OnInit {
  changePasswordForm!: UntypedFormGroup;
  submitted = false;
  returnUrl!: string;

  constructor(
    public dialogRef: MatDialogRef<PasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      password: ['']
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.submitted = true;
    this.data.password = this.changePasswordForm.controls.password.value;
    this.dialogRef.close(this.data);
  }
}
