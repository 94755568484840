import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { NxChartBaseDirective } from 'src/app/shared/nx-chart-base/nx-chart-base.directive';
import { ChartModel } from 'src/app/shared/nx-chart-base/chart.model';
import { AppState } from 'src/app/state/app.state';
import { SchemePalette, SchemeType } from 'src/app/shared/common-behaviors/scheme';
import { ChartType } from '@config';

@Component({
  selector: 'eng-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent extends NxChartBaseDirective {
  @Input()
    scheme: SchemePalette;

  @Input()
    schemeType: SchemeType = 'ordinal';

  @Input()
    data!: ChartModel[];

  chartType: ChartType = 'line';

  chartId!: number;

  constructor(
    public appState: Store<AppState>
  ) {
    super([0, 0]);
    this.timeline = true;
  }
}
