import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataSourceName'
})
export class DataSourceNamePipe implements PipeTransform {

  transform(displayName: string): string {
    // Mapping object to look up display names for data sources
    switch (displayName) {
    case 'File Beat Linux Logs':
      return 'Firewall Logs - FB';
    case 'Packet Beat':
      return 'Network Traffic';
    default:
      return displayName;
    }
  }

}
