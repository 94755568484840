<h1 mat-dialog-title>Hi {{data.firstName}}</h1>
<div mat-dialog-content>
  <p>Please enter your new password</p>
  <form
    class="password-form__wrapper"
    [formGroup]="changePasswordForm"
    (ngSubmit)="onSubmit()"
  >
    <mat-form-field>
      <input
        class="change-password-formfield"
        matInput
        [type]="showPassword.checked ? 'text' : 'password'"
        name="password"
        formControlName="password"
        placeholder="Password"
        required
      />
    </mat-form-field>
    <mat-checkbox #showPassword>Show password</mat-checkbox>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button [disabled]="!changePasswordForm.valid" (click)="onSubmit()" cdkFocusInitial>
    Submit
  </button>
  <button mat-button (click)="onNoClick()"></button>
</div>
