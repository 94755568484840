import { Injectable } from '@angular/core';
import { from, Observable, throwError, Subscription, BehaviorSubject, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AWSUser } from './aws-user.model';
import { AppState } from '../../state/app.state';
import { Store, select } from '@ngrx/store';
import { getRuntimeAppConfig } from '../../state/app.selectors';
import { UEUser } from '../ue/api.service';
import { UEAPIService } from '../ue/api.service';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import gql from 'graphql-tag';
import { AuthService } from '../ue/auth.service';
import { User } from '../../services';
import { User as AuthUser } from 'oidc-client-ts';

@Injectable({ providedIn: 'root' })
export class AdminService {
  apiGatewayUrl!: string;
  client: AWSAppSyncClient<any>;
  user: AuthUser | null;
  isLoginSubject  = new BehaviorSubject<boolean>(this.hasToken());
  initialsSubject = new Subject<any>();
  
  constructor(
    private _appStore: Store<AppState>,
    private _http: HttpClient,
    private _ueApiService: UEAPIService,
    private _authService: AuthService
  ) {
    this._authService.getUser().then((user) => {
      if(user) {
        this.user = user;
        const configSubscription : Subscription = this._appStore.pipe(select(getRuntimeAppConfig)).subscribe((config) => {
          if (config) {
            this.apiGatewayUrl = config.apiGatewayUrl;
    
            this.client = new AWSAppSyncClient({
              url: config.appSyncUrl,
              region: config.region,
              auth: {
                type: AUTH_TYPE.OPENID_CONNECT,
                // Get the currently logged in users credential.
                jwtToken: this.user?.access_token as string,
              },
              disableOffline: true,
            });
          }
        });
        configSubscription.unsubscribe();
      }
    });
    
  }

  adminAllUsers(): Observable<AWSUser[]> {
    const url = `${this.apiGatewayUrl}/listusers`;
    return this._http.get<any>(url, this._httpOptions).pipe(
      map((apiResponse: any) => {
        if (
          apiResponse &&
          apiResponse.status &&
          apiResponse.status === 200 &&
          apiResponse.body &&
          apiResponse.body.users
        ) {
          return JSON.parse(apiResponse.body.users).Users;
        } else {
          throw new Error(
            `bad status code of ${apiResponse.statusCode} from admin list all users`
          );
        }
      }),
      catchError(this._handleError)
    );
  }

  adminCreateUser(user: User): Observable<any> {
    return from(this.handleAdminCreateUser(user));
  }

  handleAdminCreateUser(user: User): Promise<any> {
    return this.createUeUser(user).then((response: any) => {
      const ueUser: UEUser = response.data;

      if (ueUser && ueUser.id) {
        return this.createEnigmaUser(ueUser.id, user);
      }
    });
  }

  createUeUser(user: any): Promise<any> {
    const ueNewUser: UEUser = {
      username: user.email,
      email: user.email,
      password: user.temporaryPassword,
    };

    return this._ueApiService.createUser(ueNewUser);
  }

  createEnigmaUser(userId: string, user: any): Promise<any> {
    const enigmaUser = {
      userId: userId,
      userName: user.email,
      accounts: user.accounts,
      role: user.role,
      email: user.email,
      phone: user.phone,
      firstName: user.firstName,
      lastName: user.lastName,
      theme: user.theme,
    };

    const createUserMutation = (): string => {
      return `mutation createEnigmaGlassUser($input: CreateEnigmaGlassUserInput!) {
        createEnigmaGlassUser(input: $input) {
                userId
                userName
                accounts
                role
                email
                phone
                firstName
                lastName
                theme
              }
            }`;
    };

    return this.client.hydrated().then(() => {
      try {
        this.client
          .mutate({
            mutation: gql`
              ${createUserMutation()}
            `,
            variables: {
              input: enigmaUser,
            },
            fetchPolicy: 'no-cache',
          })
          .then((transactionComplete: any) => {
            return transactionComplete.data.createEnigmaGlassUser;
          });
      } catch (err) {
        console.log(err);
        throw new Error(err);
      }
    });
  }

  adminDeleteUser(user: User): Observable<string> {
    if (user.userId) {
      return from(this._ueApiService.deleteUser(user.userId));
    } else {
      throw throwError('userId required');
    }
  }

  adminDisableUser(user: User): Observable<string> {
    if (user.userId) {
      return from(this._ueApiService.disableUser(user.userId));
    } else {
      throw throwError('userId required');
    }
  }

  adminListAccounts(): Observable<string[]> {
    const url = `${this.apiGatewayUrl}/listaccounts`;

    return this._http.get<any>(url, this._httpOptions).pipe(
      map((res: any) => {
        // TODO(api) probably need a schema for this to understand the response type
        const accounts: string[] = res.body.Items.map((item: any) => {
          return item.accountName['S'];
        });
        return accounts;
      }),
      catchError(this._handleError)
    );
  }

  private _handleError(error: any) {
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  /**
   * Remember to enable CORS in api gateway
   * https://docs.aws.amazon.com/apigateway/latest/developerguide/how-to-cors.html
   */
  private get _httpOptions(): { headers: HttpHeaders; observe: any } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        authorization: `${this.user?.id_token}`,
      }),
      observe: 'response',
    };
  }  

  isLoggedIn() : Observable<boolean> {
    return this.isLoginSubject.asObservable();
  }

  private hasToken (): boolean {
    return !!localStorage.getItem('userId');
  }
}
