<div class="no-data__wrapper" [ngClass]="{'no-data-quick-padding--top': paddingTop}">
  <ng-container *ngIf="parenthesis">(</ng-container>
  {{ noDataMessage }}
  <ng-container>
    <mat-icon
      *ngIf="showTooltip"
      [matTooltip]="tooltipText"
      aria-label="this is a tooltip"
      inline="true"
      >info</mat-icon
    >
  </ng-container>

  <ng-container *ngIf="parenthesis">)</ng-container>
</div>
