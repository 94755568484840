import { Router, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { User, UserRole } from '@services';
import { AppState } from 'src/app/state/app.state';
import { getUser } from 'src/app/state/app.selectors';
import { map } from 'rxjs/operators';

@Injectable()
export class AdminGuardService implements CanActivate {
  currentUserSub!: Subscription;
  currentUser!: User;

  constructor(public router: Router, private appStore: Store<AppState>) {}

  canActivate(): Observable<boolean> {
    return this.appStore.pipe(
      select(getUser),
      map(user => {
        if (
          user &&
          (user.role === UserRole.ADMIN || user.role === UserRole.SUPER_ADMIN)
        ) {
          return true;
        } else {
          // TODO change this to last route
          this.router.navigateByUrl('/settings');
          return false;
        }
      })
    ) as Observable<boolean>;
  }
}
