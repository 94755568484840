import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getActiveAccount, getAllAccounts } from '../state/app.selectors';
import { AppState } from '../state/app.state';

@Injectable({
  providedIn: 'root',
})
export class AccountGuard implements CanActivate {
  constructor(public router: Router, private appStore: Store<AppState>) {}
  canActivate(): Observable<boolean | UrlTree> {
    const appConfig = this.appStore.pipe(select(getAllAccounts));
    const dataSources = this.appStore.pipe(select(getActiveAccount));
    return combineLatest(([appConfig, dataSources]))
      .pipe(
        map(([accounts, activeAccount]) => {
          const currentAccount = accounts.find(x => x.accountName === activeAccount);
          if (currentAccount?.enigmaUSettingsAndConfig?.enigmaUID) {
            return true;
          } else {
            console.log('The active account does not contain a value for enigmaUID. Returning back to home...');
            this.router.navigateByUrl('/');
            return false;
          }
        })
      ) as Observable<boolean>;
  }
}
