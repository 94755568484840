import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryResponse } from '@config';
import { Observable, of, range, throwError } from 'rxjs';
import { catchError, delay, map, switchMap } from 'rxjs/operators';

@Injectable()
export class MockDataService {

  protected localApiUrl: string;
  protected localMockPath: string;
  protected httOptions: object;

  protected defaultHttpOptions = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(
        private _http: HttpClient
  ) {
    // TODO: read this info from config service or environment variables
    this.localApiUrl = 'http://localhost:4200';
    this.localMockPath = 'assets/data';
  }

  protected getEndPoint = (file: string): string => `${this.localApiUrl}/${this.localMockPath}/${file}`;

  protected getHttpOptions(): object {
    return {
      headers: this.defaultHttpOptions,
      params: new HttpParams(),
    };
  }

  fetchCognitoLoginData(): Observable<Array<any>> {
    return this._http
      .get(this.getEndPoint('cognito-login-data.mock.json'), this.getHttpOptions())
      .pipe(
        map((data: Array<any>) => data),
        catchError(this.handleError<Array<any>>('fetchCognitoLoginData'))
      );
  }

  fetchCloudfrontEdgeLocationsData(): Observable<Array<any>> {
    return this._http
      .get(this.getEndPoint('cloudfront-edge-locations-data.mock.json'), this.getHttpOptions())
      .pipe(
        map((data: Array<any>) => data),
        catchError(this.handleError<Array<any>>('fetchCloudfrontEdgeLocationsData'))
      );
  }

  fetchCloudfrontCoreBundleMissCountData(): Observable<Array<any>> {
    return this._http
      .get(this.getEndPoint('cloudfront-core-bundle-miss-count-data.mock.json'), this.getHttpOptions())
      .pipe(
        map((data: Array<any>) => data),
        catchError(this.handleError<Array<any>>('fetchCloudfrontCoreBundleMissCountData'))
      );
  }

  // Mock Number Cards Data
  fetchEndpointsBeingMonitoredData(name?: string): Observable<QueryResponse> {
    return this.returnRandomNumber(name);
  }

  fetchUniqueIpsData(name?: string): Observable<QueryResponse> {
    return this.returnRandomNumber(name);
  }

  fetchUserAuthFailuresData(name?: string): Observable<QueryResponse> {
    return this.returnRandomNumber(name);
  }

  fetchExcessiveLoginsData(name?: string): Observable<QueryResponse> {
    return this.returnRandomNumber(name);
  }

  // Mock Enigma U data
  getCompanyRiskScoreHistory(): Observable<Array<any>> {
    return this._http
      .get(this.getEndPoint('enigma-u/company-risk-score-history.json'), this.getHttpOptions())
      .pipe(
        map((data: Array<any>) => data),
        catchError(this.handleError<Array<any>>('getCompanyRiskScoreHistory'))
      );
  }

  getCourseParticipation(): Observable<Array<any>> {
    return this._http
      .get(this.getEndPoint('enigma-u/course-participation.json'), this.getHttpOptions())
      .pipe(
        map((data: Array<any>) => data),
        catchError(this.handleError<Array<any>>('getCourseParticipation'))
      );
  }

  getLearnerPolicy(): Observable<Array<any>> {
    return this._http
      .get(this.getEndPoint('enigma-u/learner-policy.json'), this.getHttpOptions())
      .pipe(
        map((data: Array<any>) => data),
        catchError(this.handleError<Array<any>>('getLearnerPolicy'))
      );
  }

  getLearners(): Observable<Array<any>> {
    return this._http
      .get(this.getEndPoint('enigma-u/learners.json'), this.getHttpOptions())
      .pipe(
        map((data: Array<any>) => data),
        catchError(this.handleError<Array<any>>('getLearners'))
      );
  }

  getLearnerById(learnerId: string): Observable<Array<any>> {
    return this._http
      .get(this.getEndPoint(`enigma-u/learner-${learnerId}.json`), this.getHttpOptions())
      .pipe(
        map((data: Array<any>) => data),
        catchError(this.handleError<Array<any>>('getLearners'))
      );
  }


  private returnRandomNumber(name?: string): Observable<QueryResponse> {
    return range(1, 50).pipe(
      delay(1000 + (Math.random() * 4000)),
      switchMap(_ => of({
        name: name,
        value: Math.floor(Math.random() * 50)
      } as QueryResponse
      ))
    );
  }

  private handleError<T>(actionName  = 'actionName') {
    return (error: any): Observable<T> => {
      // TODO: log error
      return throwError(`Server Error: ${error}. Action Name: ${actionName}`);
    };
  }

}
