export interface User {
  userId?: string;
  userName?: string;
  email?: string;
  phone?: string;
  password?: string;
  temporaryPassword?: string;
  firstName?: string;
  lastName?: string;
  accessToken?: string;
  idToken?: string;
  role?: UserRole;
  userConfirmed?: boolean;
  accounts?: [string];
  theme?: string;
  sub?: string;
}

export enum UserRole {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export enum UserTheme {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}

export interface UserConfirmation {
  username: string;
  code: string;
  password: string;
}
