import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { getActiveAccount, getAllAccounts } from 'src/app/state/app.selectors';
import { AppState } from 'src/app/state/app.state';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { HttpHeaders } from '@angular/common/http';
import gql from 'graphql-tag';

export type CompanyRiskScoreHistory = {
  id: string;
  name: string;
  address: string;
};

@Injectable({
  providedIn: 'root',
})
export class USecureService {
  private uSecureAPIKey: string;
  private uSecureEndpoint = 'https://public-api.getusecure.com/graphql';

  constructor(
    private appStore: Store<AppState>,
    private apollo: Apollo,
    private httpLink: HttpLink
  ) {
    const appConfig = this.appStore.pipe(select(getAllAccounts));
    const dataSources = this.appStore.pipe(select(getActiveAccount));
    combineLatest(appConfig, dataSources).subscribe(
      ([accounts, activeAccount]) => {
        if (
          !this.uSecureAPIKey ||
          this.uSecureAPIKey !==
            accounts.find((x) => x.accountName === activeAccount)
              ?.enigmaUSettingsAndConfig?.uSecureAPIKey
        ) {
          const currentAccount = accounts.find(
            (x) => x.accountName === activeAccount
          );
          if (currentAccount?.enigmaUSettingsAndConfig?.uSecureAPIKey) {
            this.uSecureAPIKey =
              currentAccount.enigmaUSettingsAndConfig?.uSecureAPIKey;
            const headers = new HttpHeaders().set(
              'x-api-key',
              this.uSecureAPIKey
            ); //.set('content-type', 'application/json')
            apollo.create({
              link: httpLink.create({
                uri: this.uSecureEndpoint,
                headers: headers,
              }),
              cache: new InMemoryCache(),
            });
          } else {
            console.log(
              `The U Secure key was not found for account ${currentAccount?.accountName}`
            );
          }
        }
      }
    );
  }

  getCompanyRiskScoreHistory(companyId: string): Observable<any> {
    return this.apollo.query({
      query: gql`
        query getCompany($companyId: ID) {
          companyRiskScoreHistory(companyId: $companyId)
        }
      `,
      variables: {
        companyId,
      },
    });
  }

  getCourseParticipation(companyId: string): Observable<any> {
    return this.apollo.query({
      query: gql`
        query getCompany {
          courseParticipation {
            learners {
              id
              name
              courseResults
            }
          }
        }
      `,
      variables: {
        companyId,
      },
    });
  }

  getLearnerPolicy(companyId: string): Observable<any> {
    return this.apollo.query({
      query: gql`
        query getCompany {
          learners {
            name
            policyResults {
              policyId
              signed
            }
          }
        }
      `,
      variables: {
        companyId,
      },
    });
  }

  getLearners(companyId: string): Observable<any> {
    return this.apollo.query({
      query: gql`
        query getCompany {
          learners {
            name
            id
            courseResults {
              course {
                name
                difficulty
              }
              grade
            }
            policyResults {
              policyId
              signed
            }
            riskScoreLevel
          }
        }
      `,
      variables: {
        companyId,
      },
    });
  }

  getLearnerById(learnerId: string): Observable<any> {
    return this.apollo.query({
      query: gql`
        query getLearner($learnerId: ID!) {
          learner(id: $learnerId) {
            name
            id
            email
            courseResults {
              course {
                displayName
                difficulty
              }
              courseId
              grade
              score
              startDate
              enrollDate
              finishDate
            }
            gapAnalysisResults {
              score
              course {
                displayName
              }
            }
          }
        }
      `,
      variables: {
        learnerId,
      },
    });
  }
}
