// eslint-disable-next-line max-len
import { eventQuery, severityQuery, hitsOverTimeQuery, severityTopHitsQuery, eventQueryActionTaken, eventQueryCardinality } from './elk-queries';
import { SourceApiType } from '../models/account.model';

export interface ElkConfigOptions {
    size: number;
    timeFilter: string;
}

export const elkConfig = (options: ElkConfigOptions): ElkConfigType => {
  return {
    totalHits: {
      event_types: [
        {
          name: 'Quarantine Failure',
          query: eventQuery('Quarantine Failure')
        },
        {
          name: 'Threat Quarantined',
          query: eventQuery('Threat Quarantined')
        },
        {
          name: 'Vulnerable Application Detected',
          query: eventQuery('Vulnerable Application Detected')
        },
        {
          name: 'Cloud IOC',
          query: eventQuery('Cloud IOC')
        }
      ]
    },
    totalHitsUmbrella: {
      event_types: [
        {
          name: 'Unique Destinations',
          query: eventQueryCardinality('destination')
        },
        {
          name: 'Blocked Events',
          query: eventQueryActionTaken('BLOCKED')
        }
      ]
    },
    severityHits: {
      event_types: [
        {
          name: 'Top Security Alerts',
          time: options.timeFilter,
          query: severityTopHitsQuery(options.timeFilter, options.size)
        }
      ]
    },
    hitsOverTime: {
      event_types: [
        {
          name: 'Security Alert Trends',
          time: options.timeFilter,
          query: hitsOverTimeQuery(SourceApiType.CiscoAmp, options.timeFilter, options.size, 'date')
        }
      ]
    },
    hitsOverTimeUmbrella: {
      event_types: [
        {
          name: 'Security Alert Trends Amp',
          time: options.timeFilter,
          query: hitsOverTimeQuery(SourceApiType.CiscoUmbrella, options.timeFilter, options.size, 'date')
        },
        {
          name: 'Security Alert Trends Umbrella',
          time: options.timeFilter,
          query: hitsOverTimeQuery(SourceApiType.CiscoUmbrella, options.timeFilter, options.size, 'datetime')
        }
      ]
    },
    severities: {
      event_types: [
        {
          name: 'High Severity',
          time: options.timeFilter,
          query: severityQuery( 'High', options.timeFilter, options.size)
        },
        {
          name: 'Medium Severity',
          time: options.timeFilter,
          query: severityQuery( 'Medium', options.timeFilter, options.size)
        },
        {
          name: 'Low Severity',
          time: options.timeFilter,
          query: severityQuery( 'Low', options.timeFilter, options.size)
        }
      ]
    }
  };
};

export const execElkConfig = elkConfig({
  size: 1000,
  timeFilter: 'now-3M'
});

export interface ElkConfigType {
    /** totalhits and totalhitsumbrella will be deprecated once total hits goes into the registry */
    totalHits?: {
        event_types: QueryRequest[]
    };
    totalHitsUmbrella?:  {
        event_types: QueryRequest[]
    };
    severityHits?: {
        event_types: TimeQueryRequest[]
    };
    hitsOverTime?: {
        event_types: TimeQueryRequest[]
    };
    hitsOverTimeUmbrella?: {
        event_types: TimeQueryRequest[]
    };
    severities?: {
        event_types: TimeQueryRequest[]
    };
    uniqueDestinations?: {
        event_types: TimeQueryRequest[]
    };
}

export interface QueryRequest {
    name: string;
    query: any;
}

export interface QueryResponse {
    name: string;
    value: number;
}


export interface TimeQueryRequest {
    name: string;
    time: string;
    query: any;
}

export interface TimeQueryResponse {
    name: string;
    time: string;
    body: any;
}


