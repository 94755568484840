<h1 mat-dialog-title>Hi</h1>
<div mat-dialog-content>
  <p>Please enter your new password</p>
  <form [formGroup]="confirmationPasswordForm" (ngSubmit)="onSubmit()">
    <div class="confirmation-password__wrapper">
      <mat-form-field *ngIf="!foundUsername">
        <input
          class="confirmation-password-formfield"
          matInput
          type="phone"
          name="username"
          formControlName="username"
          placeholder="Phone number"
          required
        />
      </mat-form-field>

      <mat-form-field>
        <input
          class="confirmation-password-formfield"
          matInput
          type="text"
          name="confirmation"
          formControlName="confirmationCode"
          placeholder="Confirmation code"
          required
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>New password</mat-label>
        <input
          class="update-password-formfield"
          matInput
          [type]="showNewPassword ? 'text' : 'password'"
          name="New password"
          formControlName="newPassword"
          required
        />
        <mat-error *ngIf="newPassword && newPassword.hasError('required')"
          >Password is required</mat-error
        >
        <mat-error *ngIf="newPassword && newPassword.hasError('minlength')"
          >Password must have at least {{ minPw }} characters</mat-error
        >
        <button
          matSuffix
          type="button"
          mat-icon-button
          aria-label="hide password"
          (click)="showNewPassword = !showNewPassword"
        >
          <span class="password-suffix"
            >{{ showNewPassword ? "hide" : "show" }}
          </span>
        </button>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions class="confirmation-password-button__wrapper">
  <button
    class="confirmation-password-button"
    mat-stroked-button
    color="primary"
    [disabled]="!confirmationPasswordForm.valid"
    class="confirm-button"
    (click)="onSubmit()"
  >
    <span *ngIf="!loading">Confirm New Password</span>
    <mat-progress-spinner
      *ngIf="loading"
      mode="indeterminate"
      diameter="26"
      strokeWidth="2"
    >
    </mat-progress-spinner>
  </button>
</div>
