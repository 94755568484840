import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { select, State, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UserFeatures } from '../../models/account.model';
import { UserRole } from '../../models/user.model';
import { AppState } from '../../state/app.state';
import * as fromAppSelectors from '../../state/app.selectors';
import { filter, takeWhile } from 'rxjs/operators';
import { IconsName } from '../../models/enums/icons-name.enum';
import { NavigationEnd, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin/admin.service';

@Component({
  selector: 'eng-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('sidenav', { static: true }) sidenav!: MatSidenav;

  _open: boolean;
  private userSubscription: Subscription;
  private featureSubscription: Subscription;
  private openSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Input()
  set open(value: boolean) {
    this._open = value;
    this.openSubject.next(value);
  }

  isOpen = false;
  hovering = false;
  componentActive = true;
  userRole!: UserRole;
  features: UserFeatures = {
    mainMenu: {
      showAlerts: false,
      showDiscover: false,
      showLogonActivity: false,
      showNetworkActivity: false,
      showReports: false,
      showDataManagement: false,
      showEnigmaApps: false,
      showLogInsight: false,
      showUserBehaviorMonitoring: false,
      showIkeTool: false,
      showSecurityEvents: false,
      showNetworkActivityDashboard: false,
      showPowershell: false,
      showAuditBeat: false,
      showWatcherAlerts: false,
      showFileBeat: false,
      showPacketBeat: false,
      showRules: false,
      showEnigmas: false,
      showUSecure: false,
      showEnigmasDB: false,
      showEnigmaU: false,
    },
  };

  animationStartSub$: Subscription;
  animationEndSub$: Subscription;

  darkThemeSub$: Observable<boolean>;
  isDark: boolean;

  iconsName = IconsName;

  constructor(private store: Store<AppState>, private router: Router, public adminService: AdminService,
    private state: State<AppState>,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.userSubscription = this.store
      .pipe(
        takeWhile(() => this.componentActive),
        select(fromAppSelectors.getUser)
      )
      .subscribe((currentUser) => {
        if (currentUser && currentUser.role) {
          this.userRole = currentUser.role;
        }
      });

    this.featureSubscription = this.store
      .pipe(
        takeWhile(() => this.componentActive),
        select(fromAppSelectors.getUserFeatures)
      )
      .subscribe((features) => {
        if (features) {
          this.features = features;
        }
      });
    this.darkThemeSub$ = this.store.pipe(
      select(fromAppSelectors.getDarkThemeState)
    );
    this.isDark = this.state.value.app.darkTheme;
  }

  ngAfterViewInit(): void {
    this.openSubject.subscribe((isOpen: boolean) => {
      isOpen ? this.sidenav?.open() : this.sidenav?.close();
    });

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const currentRoute = event.urlAfterRedirects || event.url;
        if (currentRoute === '/logout' || currentRoute === '/login') {
          this._open = false;
          this.openSubject.next(false);
        } else if ( currentRoute !== '/' && currentRoute !== '/login') {
          this._open = true;
          this.openSubject.next(true);
        }
        this.cdr.detectChanges();
      });
  }

  toogleSideNav(expandSideNav: boolean): void {
    this.isOpen = expandSideNav;
  }

  logout() {
    this.router.navigate(['logout']);
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    this.featureSubscription.unsubscribe();
  }
}
