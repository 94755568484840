export enum WatcherInterval {
  OneMinute = '1m',
  FiveMinutes = '5m',
  TenMinutes = '10m',
  ThirtyMinutes = '30m',
  OneHour = '60m',
  TwelveHours = '12h',
  OneDay = '24h'
}

export enum WatcherStatus {
  Activated = 'Activated',
  Deactivated = 'Deactivated'
}

export enum WatcherAlertStatus {
  New = 'New',
  InProgress = 'InProgress',
  Closed = 'Closed'
}

export enum WatcherSeverity {
  Critical = 'Critical',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low'
}

export enum WatcherOutcome {
  ActualSecurityEvent = 'ActualSecurityEvent',
  BenignEvent = 'BenignEvent',
  OperationalEvent = 'OperationalEvent'
}

export enum MatchPhraseOperation {
  Equals = 'Equals',
  DoesNotEqual = 'DoesNotEqual',
  Contains = 'Contains',
  BeginsWith = 'BeginsWith'
}

export interface MatchPhrase {
  name: string;
  value: string;
  operation: MatchPhraseOperation;
}

export interface Watcher {
  name: string;
  severity: WatcherSeverity;
  watcherId: string;
  accountName: string;
  description: string;
  index: string;
  matchPhrases: MatchPhrase[];
  watcherStatus: WatcherStatus;
  scheduledInterval: WatcherInterval;
  tenant: string;
  threshold: number;
  slackWebhookUrl: string;
  emailRecipients: string[];
  ikeToggled: boolean;
  createdAt: string;
  modifiedDate: string;
}

export interface WatcherAlert {
  name: string;
  severity: WatcherSeverity;
  alertId: string;
  watcherId: string;
  executionTime: string;
  description: string;
  payload: string;
  eventGroupId: number;
  watcherAlertStatus: WatcherAlertStatus;
  watcherOutcome: WatcherOutcome | null;
}

export enum WidgetType {
  NumberCard = 'NumberCard',
  LineChart = 'LineChart',
  PieChart = 'PieChart'
}

export interface WatcherAlertStatsWidget<T> {
  id: number;
  name: string;
  description?: string;
  widgetType: WidgetType;
  stats: T;
}

export interface WidgetStatsModel {
  count: number;
  alertCount: number;
}

export interface WatcherAlertStats {
  widgets: WatcherAlertStatsWidget<any>[];
}

export interface UpdateWatcherAlertInput {
  watcherId: string;
  alertId: string;
  eventGroupId: number;
}

export interface UpdateAlertWatcherStatusInput {
  watcherId: string;
  alertId: string;
  watcherAlertStatus: WatcherAlertStatus;
}

export interface UpdateAlertWatcherOutcomeInput {
  watcherId: string;
  alertId: string;
  watcherOutcome: WatcherOutcome;
}

export enum DataSources {
  WindowLogs = 'WindowLogs',
  LinuxLogs = 'LinuxLogs',
  FileBeatLinuxLogs = 'FileBeatLinuxLogs',
  PacketBeat = 'PacketBeat'
}

export enum WatcherMutationOperator {
  ACTIVATE = 'ACTIVATE',
  DEACTIVATE = 'DEACTIVATE',
  CREATE = 'CREATE',
  CREATE_CUSTOM = 'CREATE_CUSTOM',
  DELETE = 'DELETE'
}

export enum CreateWatcherType {
  FailedLogins = 'FailedLogins',
  Custom = 'Custom'
}

export interface DefinedWatcherModel {
  type: CreateWatcherType;
  description: string;
  dataSources: DataSources[];
  matchPhrases: MatchPhrase[];
  scheduledInterval: WatcherInterval;
  threshold: number;
}

export interface CreateWatcherInput {
  name?: string;
  severity?: WatcherSeverity;
  watcherId?: string;
  accountName?: string;
  tenant?: string;
  description?: string;
  index?: string;
  matchPhrases?: MatchPhrase[];
  scheduledInterval?: string;
  threshold?: number;
}

export interface WatcherMutationEvent {
  operation: WatcherMutationOperator;
  createWatcherInput: CreateWatcherInput;
  createWatcherType?: CreateWatcherType;
}

export interface UpdateSlackWebhookUrlInput {
  accountName: string;
  watcherId: string;
  slackWebhookUrl: string;
}

export interface UpdateMultipleSlackWebhookUrlInput {
  accountName: string;
  watcherIds: string[];
  slackWebhookUrl: string;
}

export interface UpdateEmailRecipientsInput {
  accountName: string;
  watcherId: string;
  emailRecipients: string[];
}

export interface UpdateMultipleEmailRecipientsInput {
  accountName: string;
  watcherIds: string[];
  emailRecipients: string[];
}

export interface UpdateIkeToggledInput {
  accountName: string;
  watcherId: string;
  ikeToggled: boolean;
}

export interface WindowLogsEvent {
  hostName: string;
  hostIp: string;
  hostOsName: string;
  hostOsPlatform: string;
  hostOsVersion: string;
  hostOsKernel: string;
  userName: string;
  userDomain: string;
  eventTime: string;
  eventCategory: string;
  message: string;
  eventAction: string;
  eventCode: string;
  eventOutcome: string;
}

export interface PacketbeatFlowEvent {
  eventDataset: string;
  eventTime: string;
  eventAction: string;
  eventType: string;
  hostName: string;
  hostArchitecture: string;
  hostIp: string;
  hostMac: string;
  hostOsName: string;
  hostOsKernel: string;
  hostOsVersion: string;
  networkTransport: string;
  destinationIp: string;
  destinationMac: string;
  destinationPort: string;
  sourceIp: string;
  sourceMac: string;
  sourcePort: string;
  bytesIn: string;
  bytesOut: string;
}

export interface PacketbeatHttpEvent {
  eventDataset: string;
  httpRequestMethod: string;
  httpResponseStatusCode: string;
  httpVersion: string;
  networkDirection: string;
  networkProtocol: string;
  networkTransport: string;
  urlFull: string;
  serverGeoContinentName: string;
  serverGeoCityName: string;
  serverIp: string;
  serverPort: string;
}

export interface PacketbeatTlsEvent {
  eventDataset: string;
  destinationDomain: string;
  destinationGeoContinentName: string;
  destinationGeoCountryName: string;
  destinationGeoRegionName: string;
}

export interface PacketbeatDnsEvent {
  eventDataset: string;
  clientIp: string;
  destinationIp: string;
  destinationPort: string;
  sourceIp: string;
  sourcePort: string;
  status: string;
  dnsOpCode: string;
  dnsQuestionName: string;
  dnsQuestionType: string;
  dnsResolvedIp: string;
  dnsResponseCode: string;
  dnsType: string;
  query: string;
  networkProtocol: string;
  networkTransport: string;
}

export interface FilebeatEvent {
  eventTime: string;
  eventDataset: string;
  ciscoAsaRuleName: string;
  ciscoAsaDestinationInterface: string;
  ciscoAsaMessageId: string;
  eventOriginal: string;
  eventCategory: string;
  eventOutcome: string;
  sourceAddress: string;
  sourceAsOrganizationName: string;
  sourceGeoContinentName: string;
  sourceGeoCountryName: string;
  sourcePort: string;
  destinationIp: string;
  destinationPort: string;
  destinationAsOrganizationName: string;
  destinationGeoContinentName: string;
  destinationGeoCountryName: string;
}

export interface AuditBeatEvent {
  eventCategory: String;
  eventAction: String;
  hostHostname: String;
  hostIp: String;
  hostMac: String;
  hostOsPlatform: String;
  userName: String;
  filePath: String;
  processArgs: String;
  networkDirection: String;
  sourceIp: String;
  sourcePort: String;
  destinationIp: String;
  destinationPort: String;
  destinationDomain: String;
}

export type WatcherAlertEvent = (
  | WindowLogsEvent
  | PacketbeatFlowEvent
  | PacketbeatHttpEvent
  | PacketbeatTlsEvent
  | PacketbeatDnsEvent
  | FilebeatEvent
  | AuditBeatEvent
);

