import { Injectable, OnDestroy } from '@angular/core';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import gql from 'graphql-tag';
import {
  from,
  Observable,
  BehaviorSubject,
  Subscription,
} from 'rxjs';
import { AlertModel } from 'src/app/views/alerts-dashboard/alerts.model';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import {
  getRuntimeAppConfig
} from 'src/app/state/app.selectors';
import { AlertService } from '../alert/alert.service';
import { AppConfig } from 'src/app/config/app.config';
import { LogAlert } from 'src/app/views/log-insights/log-alerts.model';
import { AuthService } from '../ue/auth.service';
import { User as AuthUser } from 'oidc-client-ts';

@Injectable({
  providedIn: 'root',
})
export class AppSyncAlertsService implements OnDestroy {
  client!: AWSAppSyncClient<any>;

  alerts: BehaviorSubject<AlertModel | null> =
    new BehaviorSubject<AlertModel | null>(null);

  clientReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  user: AuthUser | null;

  private _config!: AppConfig;

  private _appSyncAlertsSub!: Subscription;

  constructor(
    private alertService: AlertService,
    private _appStore: Store<AppState>,
    private _authService: AuthService
  ) {
    this.initializeClient();
  }

  async initializeClient() {
    this.user = await this._authService.getUser();
    if(this.user) {
      this._appStore.pipe(select(getRuntimeAppConfig)).subscribe((config) => {
        if (config) {
          this._config = config;
          // console.log('we are authencticated and have config: ', authenticated, this._config);
          this.client = new AWSAppSyncClient({
            url: this._config.appSyncUrl,
            region: this._config.region,
            auth: {
              type: AUTH_TYPE.OPENID_CONNECT,
              // Get the currently logged in users credential.
              jwtToken: this.user?.access_token as string,
            },
            disableOffline: true,
          });
  
          this.clientReady.next(true);
  
          this.subscribeAlerts().subscribe();
        }
      });
    } else {
      if (this.client) {
        // console.log('we have client and are no longer authenticated, reset and clear app sync client: ', authenticated, config);
        this.client.resetStore();
        this.client.clearStore();
      }
    }
  }

  getAlert(alertId: string): Observable<any> {
    return from(this.getAlertHandler(alertId));
  }

  async getAlertHandler(alertId: string): Promise<any> {
    const getAlert = `query GetAlert($id: String!) {
            getEngimaGlassAlerts(id: $id) {
              id,
              message,
              status,
              sourceId,
              createDate,
              classification
            }
          }`;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.query({
        query: gql`
          ${getAlert}
        `,
        variables: {
          id: alertId,
        },
        fetchPolicy: 'no-cache',
      });
      return transactionComplete.data;
    } catch (err) {
      console.log(err);
    }
  }

  updateAlerts(alert: AlertModel): Observable<AlertModel | null> {
    return from(this.updateAlertHandler(alert));
  }

  async updateAlertHandler(alert: AlertModel): Promise<AlertModel | null> {
    const updateAlert = `mutation updateEngimaGlassAlerts($updateengimaglassalertsinput: UpdateEngimaGlassAlertsInput!) {
      updateEngimaGlassAlerts(input: $updateengimaglassalertsinput) {
        id
        createDate
        message
        sourceId
        status
        classification
      }
    }`;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.mutate({
        mutation: gql`
          ${updateAlert}
        `,
        variables: {
          updateengimaglassalertsinput: alert,
        },
        fetchPolicy: 'no-cache',
      });
      return transactionComplete.data.updateEngimaGlassAlerts;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  listAlerts(filter: any): Observable<AlertModel[] | null> {
    return from(this.listAlertsHandler(filter));
  }

  async listAlertsHandler(filter: any): Promise<AlertModel[] | null> {
    const listAlerts = `query listEngimaGlassAlerts($listenigmaglassalertsfilter: TableEnigmaGlassAlertsFilterInput!) {
      listEnigmaGlassAlerts(filter: $listenigmaglassalertsfilter) {
        items {
          id
          alertRuleId
          createDate
          message
          sourceId
          status
          classification
          events
          alertRange {
            begin
            end
          }
          accountName
          alertRule {
            accountName
            alertRuleId
            name
            sourceIndex
            description
            field
            value
            occurrences
            timeframe
            limit
            limitExpiration
          }
        }
      }
    }`;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.query({
        query: gql`
          ${listAlerts}
        `,
        variables: {
          listenigmaglassalertsfilter: filter,
        },
        fetchPolicy: 'no-cache',
      });
      return transactionComplete.data.listEnigmaGlassAlerts.items;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  createAlerts(alert: AlertModel): Observable<any> {
    return from(this.createAlertHandler(alert));
  }

  async createAlertHandler(alert: AlertModel): Promise<any> {
    const createAlert = `mutation createEngimaGlassAlerts($createengimaglassalertsinput: CreateEngimaGlassAlertsInput!) {
        createEngimaGlassAlerts(input: $createengimaglassalertsinput) {
          id
          createDate
          message
          sourceId
          status
          classification
        }
      }`;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.mutate({
        mutation: gql`
          ${createAlert}
        `,
        variables: {
          createengimaglassalertsinput: alert,
        },
        fetchPolicy: 'no-cache',
      });
      return transactionComplete.data;
    } catch (err) {
      console.log(err);
    }
  }

  deleteAlert(alertId: string): Observable<any> {
    return from(this.deleteAlertHandler(alertId));
  }

  async deleteAlertHandler(alertId: string): Promise<any> {
    const deleteAlert = `mutation deleteEngimaGlassAlerts($deleteengimaglassalertsinput: DeleteEngimaGlassAlertsInput!) {
      deleteEngimaGlassAlerts(input: $deleteengimaglassalertsinput) {
        id
      }
    }`;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.mutate({
        mutation: gql`
          ${deleteAlert}
        `,
        variables: {
          deleteengimaglassalertsinput: {
            id: alertId,
          },
        },
        fetchPolicy: 'no-cache',
      });
      return transactionComplete.data;
    } catch (err) {
      console.log(err);
    }
  }

  subscribeAlerts(): Observable<any> {
    return from(this.subscriptionAlertsHandler());
  }
  async subscriptionAlertsHandler(): Promise<any> {
    const subscriptionAlert = `
        subscription onCreateEngimaGlassAlerts {
            onCreateEngimaGlassAlerts {
                id
                sourceId
                status
                classification
            }
        }`;

    await this.client.hydrated();
    try {
      const observable = await this.client.subscribe({
        query: gql`
          ${subscriptionAlert}
        `,
      });

      const self = this;

      const realtimeResults = function realtimeResultsFunc(data: any) {
        // console.log(
        //   '(Realtime Subscription) Subscribing posts -----------> ',
        //   data.data.onCreateEngimaGlassAlerts
        // );
        self.alertService.success('Real time data coming in...');
        self.alerts.next(data.data.onCreateEngimaGlassAlerts);
      };

      observable.subscribe({
        next: realtimeResults,
        complete: console.log,
        error: console.log,
      });
      return null;
    } catch (err) {
      console.log(err);
    }
  }

  listLogAlerts(filter: any): Observable<LogAlert[]> {
    return from(this.listLogAlertsHandler(filter));
  }

  async listLogAlertsHandler(filter: any): Promise<LogAlert[]> {
    const listAlertsQuery = gql`
      query ListLogAlerts {
        listLogAlerts {
          items {
            accountName
            timestamp
            ruleId
            capturePoints {
              name
              value
            }
            data
            message
            alertId
            alertRule {
              ruleId
              source
              name
              description
            }
          }
        }
      }
    `;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.query({
        query: listAlertsQuery,
        variables: {
          filter: filter,
        },
        fetchPolicy: 'no-cache',
      });
      return transactionComplete.data.listLogAlerts.items;
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  ngOnDestroy() {
    this._appSyncAlertsSub.unsubscribe();
  }
}
