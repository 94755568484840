<div class="eng-login__wrapper">
  <div *ngIf="!isLogin">
    <div class="eng-login-logo__wrapper">
      <div class="eng-login-image__container">
        <img src="assets/images/logo.png" alt="enigma icon" />
      </div>
      <span class="header-content">Enigma Networks</span>
    </div>
    <div class="eng-login-form__wrapper">
      <div class="eng-form-button__wrapper">
        <button type="button" class="eng-login-button" mat-stroked-button (click)="login()">
          <span>Login</span>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="isLogin">
    <div class="panel middle-panel width-auto">
      <img class="logo" src="assets/enigmalogo.png" alt="enigma icon">
      <strong class="org-name">Enigma Networks</strong>
      <div class="product-name">Enigma AI</div>
      <div class="prompt-info" *ngIf="notVerified && !isResetEmailSent">Your email is not verified. Please verify your email by clicking on the link sent to your email and Sign In again...</div>
      <ng-container *ngIf="isResetEmailSent || isEmailLimitExceed">
        <div *ngIf="!isEmailLimitExceed" class="prompt-info">If you have enabled MFA, a password reset link has been sent to your email. Please check your inbox to reset your password...</div>
        <div *ngIf="isEmailLimitExceed" class="prompt-info">If you have enabled MFA, a password reset link has already been sent to your email. Please check your inbox to reset your password...</div>
        <div class="reset-password signin">
          <span class="action-text signin" (click)="forgetPassword(false)">Back to Sign In?</span>
        </div>
      </ng-container>
      <div class="credential-container" *ngIf="!isResetEmailSent && !isEmailLimitExceed">
        <div class="sign-in">{{signInSuccess ? 'MFA Verification Code' : showForgetPassword ? 'Password Reset' : 'Sign in'}}</div>
        <div>
          <form [formGroup]="loginForm">

            <!-- Sign-In Section -->
            <ng-container *ngIf="!signInSuccess && !showForgetPassword">
              <mat-form-field appearance="outline" class="login-field">
                <mat-label class="label">Username</mat-label>
                <input matInput type="email" name="username" id="username" placeholder="Username" formControlName="username" required>
                <mat-error *ngIf="loginForm.get('username').hasError('required') && loginForm.get('username').touched">
                  Username is required
                </mat-error>
                <mat-error *ngIf="loginForm.get('username').hasError('email') && loginForm.get('username').touched && !loginForm.get('username').hasError('required')">
                  Please enter a valid email address
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="login-field">
                <mat-label class="label">Password</mat-label>
                <input matInput type="password" name="password" id="password" placeholder="password" formControlName="password" required>
                <mat-error *ngIf="loginForm.get('password').hasError('required') && loginForm.get('password').touched">
                  Password is required
                </mat-error>
              </mat-form-field>
              <button type="button" class="btn-custom" mat-stroked-button (click)="onSubmit()" >
                <span>Sign in</span>
              </button>
              <div class="reset-password">
                <span class="action-text" (click)="forgetPassword(true)">Forget Password?</span>
              </div>
            </ng-container>

            <!-- OTP Verification Section -->
            <ng-container *ngIf="signInSuccess">
              <div *ngIf="loginForm.get('qrCodeImage')?.value" class="welcome-class-two">
                <img [src]="loginForm.get('qrCodeImage')?.value" alt="QR Code">
              </div>
              <ng-otp-input class="custom-otp" [formCtrl]="loginForm.get('verificationCode')"  [config]="{length:6}"></ng-otp-input>
              <button type="button" class="btn-custom re-sync" mat-stroked-button (click)="verifyCode()">
                Verify Code
              </button>
              <span class="action-text" (click)="generateQRCode()">Re-Sync MFA</span>
            </ng-container>

            <!-- Forget Password Scenario -->
            <ng-container *ngIf="showForgetPassword && !isResetEmailSent">
              <mat-form-field appearance="outline" class="login-field">
                <mat-label class="label">Email</mat-label>
                <input matInput type="email" name="username" id="username" placeholder="Username" formControlName="username" required>
                <mat-error *ngIf="loginForm.get('username').hasError('required') && loginForm.get('username').touched">
                  Email is required
                </mat-error>
                <mat-error *ngIf="loginForm.get('username').hasError('email') && loginForm.get('username').touched && !loginForm.get('username').hasError('required')">
                  Please enter a valid email address
                </mat-error>
              </mat-form-field>
              <div class="reset-password">
                <button type="button" class="btn-custom" mat-stroked-button (click)="sendResetMail()">
                  Send Reset Mail
                </button>
                <button type="button" class="btn-custom" mat-stroked-button (click)="forgetPassword(false)">
                  Back to Sign In
                </button>
              </div>
            </ng-container>
          </form>
        </div>
      </div>
      <div class="footer-content">
        <a href="https://www.getenigma.ai/terms-of-use" target="_blank">Terms of Service</a>
        <a href="https://www.getenigma.ai/privacy-policy" target="_blank">Privacy Policy</a>
      </div>
    </div>
  </div>
</div>