import { TreeMapTop } from './tree-map.model';
import { TimeQueryResponse } from '@config';

export function normalizeTreeMap(treeNodes: TimeQueryResponse[]) {
  let treemap: TreeMapTop = {
    name: 'Events',
    children: []
  };

  if (treeNodes.length > 1) {
    treeNodes.forEach((node: any) => {
      // check that a severity has any data to display
      if (node.body.tree.children.length > 0) {
        treemap.children.push(node.body.tree);
      }
    });
  } else if (treeNodes.length === 1) {
    treemap = treeNodes[0].body.tree;
  }

  return [{ treemap }];
}

export function normalizeHitsTable(nodes: TimeQueryResponse[], fieldsToFilter: string[]) {
  let tableData: any[] = [];
  let fieldsNotFound: string[] = [];

  nodes.forEach(node => {
    if (node.body.table) {
      tableData = [...tableData, ...node.body.table.map((item: any) => {
        const obj: any = {};
        fieldsToFilter.forEach(field => {
          const result = findFieldInNestObject(item._source, field);
          if (result) {
            obj[field] = result;
            if (fieldsNotFound.some(f => f === field)) {
              // if refound remove from list
              fieldsNotFound = fieldsNotFound.filter(f => f !== field );
            }
          } else {
            if (!fieldsNotFound.some(f => f === field)) {
              // if not found add to list
              fieldsNotFound.push(field);
            }
          }
        });
        return obj;
      })];
    }
  });

  // Turn on to debug
  // if (fieldsNotFound.length > 1) {
  //   console.table(fieldsNotFound);
  //   throw new Error('these fields above in table are not found for normalizing tree table');
  // }



  return tableData;
}

function findFieldInNestObject(object: any, field: string): string | null {
  if (object.hasOwnProperty(field)) {
    return object[field];
  }

  for (let i = 0; i < Object.keys(object).length; i++) {
    if (typeof object[Object.keys(object)[i]] === 'object') {
      const o: any = findFieldInNestObject(object[Object.keys(object)[i]], field);
      if (o != null) {
        return o;
      }
    }
  }
  return null;
}
