<mat-form-field>
  <input
    type="text"
    [placeholder]="placeholder"
    [formControl]="control"
    matInput
    [matAutocomplete]="auto"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="emitOptionSelected($event.option.value)"
  >
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{ option }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
