import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../state/app.state';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  ImageCropDialogComponent,
  ImageCropDialogData
} from '../image-crop-dialog';
import { PhotoModel } from '../../../models/photo.model';
import { PhotoService } from '../../../services/photo/photo.service';
import { BugModel } from '../../../models/bug.model';
import { AlertService } from '../../../services/alert/alert.service';
import { User } from '@services';
import { getUser, getBugSending } from 'src/app/state/app.selectors';
import { ReportBugAction, SaveBugPhotoAction } from 'src/app/state/app.actions';

@Component({
  selector: 'eng-found-a-bug-dialog',
  templateUrl: 'found-a-bug-dialog.component.html',
  styleUrls: ['found-a-bug-dialog.component.scss']
})
export class FoundABugDialogComponent implements OnInit, OnDestroy {
  foundABugForm!: UntypedFormGroup;
  returnUrl!: string;
  userSub: Subscription;
  user: User;
  usersName = '';
  bugPhotoBase64: string;
  bugSending: boolean;
  bugSendingSub: Subscription;
  sentClicked: boolean;

  constructor(
    public foundABugDialogRef: MatDialogRef<FoundABugDialogComponent>,
    public imageCropDialogRef: MatDialogRef<ImageCropDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private _appStore: Store<AppState>,
    private _dialog: MatDialog,
    private _photoService: PhotoService,
    private _alertService: AlertService
  ) {}

  ngOnInit() {
    this.foundABugForm = this.formBuilder.group({
      description: ['', Validators.minLength(12)],
      email: ['', Validators.email]
    });

    this._appStore
      .pipe(
        take(1),
        select(getUser)
      )
      .subscribe(user => {
        if (user && user.firstName) {
          this.user = user;
          this.usersName = user.firstName;
        }
      });

    this.bugSendingSub = this._appStore
      .pipe(select(getBugSending))
      .subscribe(sending => {
        this.bugSending = sending;
        if (this.sentClicked && !sending) {
          this._alertService.success('Thanks for reporting!');
          this.foundABugDialogRef.close();
        }
      });
  }

  ngOnDestroy() {
    if (this.bugSendingSub) {
      this.bugSendingSub.unsubscribe();
    }
  }

  submitBug() {
    this.sentClicked = true;
    let hashOfPhoto = '';
    const folder = 'bugs';

    if (this.bugPhotoBase64) {
      hashOfPhoto = this._photoService.makePhotoHash(this.bugPhotoBase64);
      const photo: PhotoModel = {
        photoBase64: this.bugPhotoBase64,
        folder: folder,
        hashOfPhoto: hashOfPhoto
      };

      this._appStore.dispatch(new SaveBugPhotoAction(photo));
    }

    const newDate = new Date();

    const bug: BugModel = {
      description: this.foundABugForm.controls.description.value,
      createdAt: `Local: ${newDate.toLocaleString()}. UTC: ${newDate.toUTCString()}.`,
      createdBy:
        this.user && this.user.firstName ? this.user.firstName : '',
      createdById:
        this.user && this.user.userId
          ? this.user.userId
          : '',
      photoRef: hashOfPhoto ? `${folder}/${hashOfPhoto}` : '',
      email: this.foundABugForm.controls.email.value ? this.foundABugForm.controls.email.value : null,
    };

    this._appStore.dispatch(new ReportBugAction(bug));
  }

  addPhoto() {
    const data = {
      title: 'Upload and crop your bug screenshot',
      bound: false
    };

    const ref = this._dialog.open(ImageCropDialogComponent, {
      maxWidth: '94vw',
      data: data
    });

    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe((returnedData: ImageCropDialogData) => {
        if (returnedData && returnedData.photoBase64) {
          this.bugPhotoBase64 = returnedData.photoBase64;
        }
      });
  }
}
