import { Injectable } from '@angular/core';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import gql from 'graphql-tag';
import { from, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import {
  getRuntimeAppConfig,
} from 'src/app/state/app.selectors';
import { AppConfig } from 'src/app/config/app.config';
import {
  CreateIkeIframeInput,
  IkeIFrameCreator,
} from 'src/app/models/iframe-detail.model';
import { AuthService } from '../ue/auth.service';
import { User as AuthUser } from 'oidc-client-ts';

@Injectable({
  providedIn: 'root',
})
export class AppSyncIkeService {
  client!: AWSAppSyncClient<any>;

  user: AuthUser | null;

  private _config!: AppConfig;

  constructor(
    private _appStore: Store<AppState>,
    private _authService: AuthService
  ) {
    this.initializeClient();
  }

  async initializeClient() {
    this.user = await this._authService.getUser();
    if(this.user) {
      this._appStore.pipe(select(getRuntimeAppConfig)).subscribe((config) => {
        if (config) {
          this._config = config;
          this.client = new AWSAppSyncClient({
            url: this._config.appSyncUrl,
            region: this._config.region,
            auth: {
              type: AUTH_TYPE.OPENID_CONNECT,
              // Get the currently logged in users credential.
              jwtToken: this.user?.id_token as string,
            },
            disableOffline: true,
          });
        }
      });
    } else {
      if (this.client) {
        // console.log('we have client and are no longer authenticated, reset and clear app sync client: ', authenticated, config);
        this.client.resetStore();
        this.client.clearStore();
      }
    }
  }

  ikeIframeCreator(input: CreateIkeIframeInput): Observable<IkeIFrameCreator> {
    return from(this.ikeIframeCreatorHandler(input));
  }

  async ikeIframeCreatorHandler(input: CreateIkeIframeInput): Promise<any> {
    const createIkeIframeQuery = `query CreateIkeIFrame($context: String, $params: AWSJSON, $sessionId: String) {
      createIkeIframe(context: $context, params: $params, sessionId: $sessionId) {
          iFrameUrl
          iFrameWidth
          iFrameHeight
        }
      }`;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.query({
        query: gql`
          ${createIkeIframeQuery}
        `,
        variables: {
          context: input.context,
          params: JSON.stringify(input.params),
          sessionId: input.sessionId,
        },
        fetchPolicy: 'no-cache',
      });
      console.log('transactionComplete.data', transactionComplete.data);
      return transactionComplete.data.createIkeIframe;
    } catch (err) {
      console.log(err);
    }
  }
}
