import { Action } from '@ngrx/store';
import { User } from '@services';
import { AppConfig } from '../config/app.config';
import { UsernameNextActionType } from '../views/login/login.component';
import { BugModel } from '../models/bug.model';
import { PhotoModel } from '../models/photo.model';
import { Account, DataSource, DiscoverSavedQuery, UserFeatures, EnigmaUSettingsAndConfig } from '../models/account.model';
import { UserConfirmation } from '../models/user.model';

export enum AppActionEnums {
  ToggleDarkTheme = '[app] Toggle Dark Theme',
  Username = '[auth] Login',
  UsernameResponse = '[auth] Username Response',
  UsernameNext = '[auth] Username Next',
  Login = '[auth] Login',
  LoginSuccess = '[auth] Login Success',
  LoginFailure = '[auth] Login Failure',
  Logout = '[auth] Logout',
  LogoutSuccess = '[auth] Logout success',
  RefreshCreds = '[auth] Refresh creds',
  RefreshCredsReset = '[auth] Refresh creds reset',
  RefreshCredsSuccess = '[auth] Refresh creds success',
  RefreshCredsFailure = '[auth] Refresh creds failure',
  Users = '[user] Get all users',
  UEUser = '[user] Get United Effects user',
  UEUserSuccess = '[user] United Effects user success',
  UEUserFailure = 'No United Effects user failure',
  CurrentDynamoUser = '[user] Get dynamo current user',
  CurrentDynamoUserSuccess = '[user] Get dynamo current user success',
  CurrentDynamoUserFailure = '[user] Get dynamo current user failure',
  ActiveAccount = '[user] active account',
  AllAccounts = '[user] all accounts',
  UserFeatures = '[user] user features',
  DataSources = '[user] Get data sources',
  DataSourcesSuccess = '[user] Data sources success',
  DataSourcesFailure = '[user] Data sources failure',
  LoadWidgetRegistry = '[app] Load Widget Registry',
  LoadRuntimeAppConfig = '[config] Load runtime app config',
  LoadRuntimeAppConfigSuccess = '[config] Load runtime app config success',
  LoadRuntimeAppConfigFailure = '[config] Load runtime app config failure',
  LoginLoadRuntimeAppConfig = '[config] Load runtime app config upon login ',
  LoginLoadRuntimeAppConfigSuccess = '[config] Load runtime app config upon login success',
  LoginLoadRuntimeAppConfigCheckOnlySuccess = '[config] Load runtime app config upon login check only success',
  LoginLoadRuntimeAppConfigFailure = '[config] Load runtime app config upon login failure',
  SaveBugPhoto = '[appRoot] save photo',
  SaveBugPhotoSuccess = '[appRoot] save photo success',
  SaveBugPhotoFailure = '[appRoot] save photo failure',
  ReportBug = '[appRoot] report',
  ReportBugSuccess = '[appRoot] report success',
  ReportBugFailure = '[appRoot] report failure',
  ForgotPassword = '[auth] Forgot password',
  ForgotPasswordSuccess = '[auth] Forgot password success',
  ForgotPasswordFailure = '[auth] Forgot password failure',
  ConfirmationPassword = '[register] Confirmation Password',
  ConfirmationPasswordSuccess = '[register] Confirmation Password Success',
  ConfirmationPasswordFailure = '[register] Confirmation Password Failure',
  DiscoverSavedQueries = '[account] Get discover saved queries',
  DiscoverSavedQueriesSuccess = '[account] Discover saved queries success',
  DiscoverSavedQueriesFailure = '[account] Discover saved queries failure',
  UpdateDiscoverSavedQueries = '[account] Update discover saved queries',
  UpdateDiscoverSavedQueriesSuccess = '[account] Update discover saved queries success',
  UpdateDiscoverSavedQueriesFailure = '[account] Update discover saved queries failure',
  UpdateEnigmaUWeeklyReportRecipients = '[account] Update EnigmaU weekly report recipients',
  UpdateEnigmaUWeeklyReportRecipientsSuccess = '[account] Update EnigmaU weekly report recipients success',
  UpdateEnigmaUWeeklyReportRecipientsFailure = '[account] Update EnigmaU weekly report recipients failure',
  GetEnigmaUWeeklyReportRecipients = '[account] Get EnigmaU weekly report recipients',
  GetEnigmaUWeeklyReportRecipientsSuccess = '[account] Get EnigmaU weekly report recipients success',
  GetEnigmaUWeeklyReportRecipientsFailure = '[account] Get EnigmaU weekly report recipients failure',
  UpdateEnigmaURemindersSent = '[account] Update EnigmaU reminders sent',
  UpdateEnigmaURemindersSentSuccess = '[account] Update EnigmaU reminders sent success',
  UpdateEnigmaURemindersSentFailure = '[account] Update EnigmaU reminders sent failure',
}

export class UsernameAction implements Action {
  readonly type = AppActionEnums.Username;

  constructor(public payload: User) {}
}

export class UsernameResponseAction implements Action {
  readonly type = AppActionEnums.UsernameResponse;
  constructor(public payload: any) {}
}

export class UsernameNextAction implements Action {
  readonly type = AppActionEnums.UsernameNext;
  constructor(public next: UsernameNextActionType | null) {}
}

export class LoadRuntimeAppConfigAction implements Action {
  readonly type = AppActionEnums.LoadRuntimeAppConfig;
}

export class LoadRuntimeAppConfigSuccessAction implements Action {
  readonly type = AppActionEnums.LoadRuntimeAppConfigSuccess;
  constructor(public config: AppConfig) {}
}

export class LoadRuntimeAppConfigFailureAction implements Action {
  readonly type = AppActionEnums.LoadRuntimeAppConfigFailure;
  constructor(public payload: any) {}
}


export class LoginLoadRuntimeAppConfigAction implements Action {
  readonly type = AppActionEnums.LoginLoadRuntimeAppConfig;
  constructor(public user: User, public checkOnly?: boolean) {}
}


export class LoginLoadRuntimeAppConfigSuccessAction implements Action {
  readonly type = AppActionEnums.LoginLoadRuntimeAppConfigSuccess;
  constructor(public config: AppConfig, public user: User) {}
}

export class LoginLoadRuntimeAppConfigCheckOnlySuccessAction implements Action {
  readonly type = AppActionEnums.LoginLoadRuntimeAppConfigCheckOnlySuccess;
  constructor(public config: AppConfig, public user: User) {}
}

export class LoginLoadRuntimeAppConfigFailureAction implements Action {
  readonly type = AppActionEnums.LoginLoadRuntimeAppConfigFailure;
  constructor(public payload: any) {}
}

export class ToggleDarkThemeAction implements Action {
  readonly type = AppActionEnums.ToggleDarkTheme;

  constructor(public payload: boolean) {}
}

export class LoginAction implements Action {
  readonly type = AppActionEnums.Login;

  constructor(public payload: {username: string, password: string}) {}
}

export class LogInSuccessAction implements Action {
  readonly type = AppActionEnums.LoginSuccess;
  constructor(public payload: User) {}
}

export class LogInFailureAction implements Action {
  readonly type = AppActionEnums.LoginFailure;
  constructor(public payload: User) {}
}

export class LogOutAction implements Action {
  readonly type = AppActionEnums.Logout;
}

export class LogOutSuccessAction implements Action {
  readonly type = AppActionEnums.LogoutSuccess;
}

export class RefreshCredsAction implements Action {
  readonly type = AppActionEnums.RefreshCreds;
}

export class RefreshCredsResetAction implements Action {
  readonly type = AppActionEnums.RefreshCredsReset;
}

export class RefreshCredsSuccessAction implements Action {
  readonly type = AppActionEnums.RefreshCredsSuccess;
}

export class RefreshCredsFailureAction implements Action {
  readonly type = AppActionEnums.RefreshCredsFailure;
  constructor(public payload: any) {}
}

export class UsersActions implements Action {
  readonly type = AppActionEnums.Users;
  constructor(public payload: User[]) {}
}

export class UEUserAction implements Action {
  readonly type = AppActionEnums.UEUser;
}

export class UEUserSuccessAction implements Action {
  readonly type = AppActionEnums.UEUserSuccess;
  constructor(public payload: User) {}
}

export class NoUEUserAction implements Action {
  readonly type = AppActionEnums.UEUserFailure;
  constructor(public payload: any) {}
}

export class CurrentDynamoUserAction implements Action {
  readonly type = AppActionEnums.CurrentDynamoUser;
  constructor(public payload: User) {}
}

export class CurrentDynamoUserSuccessAction implements Action {
  readonly type = AppActionEnums.CurrentDynamoUserSuccess;
  constructor(public payload: User) {}
}

export class CurrentDynamoUserFailureAction implements Action {
  readonly type = AppActionEnums.CurrentDynamoUserFailure;
  constructor(public payload: any) {}
}

export class ActiveAccountAction implements Action {
  readonly type = AppActionEnums.ActiveAccount;
  constructor(public account: string) {}
}

export class AllAccountsAction implements Action {
  readonly type = AppActionEnums.AllAccounts;
  constructor(public accounts: Account[]) {}
}

export class UserFeaturesAction implements Action {
  readonly type = AppActionEnums.UserFeatures;
  constructor(public features: UserFeatures) {}
}

export class DataSourcesAction implements Action {
  readonly type = AppActionEnums.DataSources;
  constructor(public account: string) {}
}

export class DataSourcesSuccessAction implements Action {
  readonly type = AppActionEnums.DataSourcesSuccess;
  constructor(public payload: DataSource[]) {}
}

export class DataSourcesFailureAction implements Action {
  readonly type = AppActionEnums.DataSourcesFailure;
  constructor(public payload: any) {}
}

export class LoadWidgetRegistryAction implements Action {
  readonly type = AppActionEnums.LoadWidgetRegistry;
  // TODO switch back
  // constructor(public payload: WidgetModel[]) {}
  constructor(public payload: any) {}
}


export class SaveBugPhotoAction implements Action {
  readonly type = AppActionEnums.SaveBugPhoto;
  constructor(public photo: PhotoModel) {}
}

export class SaveBugPhotoSuccessAction implements Action {
  readonly type = AppActionEnums.SaveBugPhotoSuccess;
  constructor(public photo: PhotoModel) {}
}

export class SaveBugPhotoFailureAction implements Action {
  readonly type = AppActionEnums.SaveBugPhotoFailure;
  constructor(public failure: any) {}
}

export class ReportBugAction implements Action {
  readonly type = AppActionEnums.ReportBug;
  constructor(public bug: BugModel) {}
}

export class ReportBugSuccessAction implements Action {
  readonly type = AppActionEnums.ReportBugSuccess;
  constructor(public bug: BugModel) {}
}


export class ReportBugFailureAction implements Action {
  readonly type = AppActionEnums.ReportBugFailure;
  constructor(public failure: any) {}
}


export class ForgotPasswordAction implements Action {
  readonly type = AppActionEnums.ForgotPassword;
  constructor(public username: string) {}
}

export class ForgotPasswordSuccessAction implements Action {
  readonly type = AppActionEnums.ForgotPasswordSuccess;
  constructor(public username: string) {}
}

export class ForgotPasswordFailureAction implements Action {
  readonly type = AppActionEnums.ForgotPasswordFailure;
  constructor(public error: any) {}
}

export class ConfirmationPasswordAction implements Action {
  readonly type = AppActionEnums.ConfirmationPassword;
  constructor(public payload: UserConfirmation) {}
}

export class ConfirmationPasswordSuccessAction implements Action {
  readonly type = AppActionEnums.ConfirmationPasswordSuccess;
  constructor(public payload: UserConfirmation) {}
}

export class ConfirmationPasswordFailureAction implements Action {
  readonly type = AppActionEnums.ConfirmationPasswordFailure;
  constructor(public error: any) {}
}

export class DiscoverSavedQueriesAction implements Action {
  readonly type = AppActionEnums.DiscoverSavedQueries;
  constructor(public account: string) {}
}

export class DiscoverSavedQueriesSuccessAction implements Action {
  readonly type = AppActionEnums.DiscoverSavedQueriesSuccess;
  constructor(public payload: DiscoverSavedQuery[]) {}
}

export class DiscoverSavedQueriesFailureAction implements Action {
  readonly type = AppActionEnums.DiscoverSavedQueriesFailure;
  constructor(public payload: any) {}
}

export class UpdateDiscoverSavedQueriesAction implements Action {
  readonly type = AppActionEnums.UpdateDiscoverSavedQueries;
  constructor(public account: string, public discoverSavedQueries: DiscoverSavedQuery[]) {}
}

export class UpdateDiscoverSavedQueriesSuccessAction implements Action {
  readonly type = AppActionEnums.UpdateDiscoverSavedQueriesSuccess;
  constructor(public payload: DiscoverSavedQuery[]) {}
}

export class UpdateDiscoverSavedQueriesFailureAction implements Action {
  readonly type = AppActionEnums.UpdateDiscoverSavedQueriesFailure;
  constructor(public payload: any) {}
}

export class UpdateEnigmaUWeeklyReportRecipientsAction implements Action {
  readonly type = AppActionEnums.UpdateEnigmaUWeeklyReportRecipients;
  constructor(public accountName: string, public enigmaUSettingsAndConfig: EnigmaUSettingsAndConfig) {}
}

export class UpdateEnigmaUWeeklyReportRecipientsSuccessAction implements Action {
  readonly type = AppActionEnums.UpdateEnigmaUWeeklyReportRecipientsSuccess;
  constructor(public payload: Account) {}
}

export class UpdateEnigmaUWeeklyReportRecipientsFailureAction implements Action {
  readonly type = AppActionEnums.UpdateEnigmaUWeeklyReportRecipientsFailure;
  constructor(public payload: any) {}
}

export class GetEnigmaUWeeklyReportRecipientsAction implements Action {
  readonly type = AppActionEnums.GetEnigmaUWeeklyReportRecipients;
  constructor(public accountName: string) {}
}

export class GetEnigmaUWeeklyReportRecipientsSuccessAction implements Action {
  readonly type = AppActionEnums.GetEnigmaUWeeklyReportRecipientsSuccess;
  constructor(public payload: Account) {}
}

export class GetEnigmaUWeeklyReportRecipientsFailureAction implements Action {
  readonly type = AppActionEnums.GetEnigmaUWeeklyReportRecipientsFailure;
  constructor(public payload: any) {}
}

export class UpdateEnigmaURemindersSentAction implements Action {
  readonly type = AppActionEnums.UpdateEnigmaURemindersSent;
  constructor(public accountName: string, public enigmaUSettingsAndConfig: EnigmaUSettingsAndConfig) {}
}

export class UpdateEnigmaURemindersSentSuccessAction implements Action {
  readonly type = AppActionEnums.UpdateEnigmaURemindersSentSuccess;
  constructor(public payload: Account) {}
}

export class UpdateEnigmaURemindersSentFailureAction implements Action {
  readonly type = AppActionEnums.UpdateEnigmaURemindersSentFailure;
  constructor(public payload: any) {}
}

export type AppActions =
  | ToggleDarkThemeAction
  | LoginAction
  | LogInSuccessAction
  | LogInFailureAction
  | LoginLoadRuntimeAppConfigAction
  | UsernameAction
  | UsernameResponseAction
  | UsernameNextAction
  | LoginLoadRuntimeAppConfigSuccessAction
  | LoginLoadRuntimeAppConfigCheckOnlySuccessAction
  | LoginLoadRuntimeAppConfigFailureAction
  | LoadRuntimeAppConfigAction
  | LoadRuntimeAppConfigSuccessAction
  | LoadRuntimeAppConfigFailureAction
  | SaveBugPhotoAction
  | SaveBugPhotoSuccessAction
  | SaveBugPhotoFailureAction
  | ReportBugAction
  | ReportBugSuccessAction
  | ReportBugFailureAction
  | RefreshCredsAction
  | RefreshCredsResetAction
  | RefreshCredsSuccessAction
  | RefreshCredsFailureAction
  | LogOutAction
  | LogOutSuccessAction
  | UsersActions
  | UEUserAction
  | UEUserSuccessAction
  | NoUEUserAction
  | CurrentDynamoUserAction
  | CurrentDynamoUserSuccessAction
  | CurrentDynamoUserFailureAction
  | ActiveAccountAction
  | AllAccountsAction
  | UserFeaturesAction
  | DataSourcesAction
  | DataSourcesSuccessAction
  | DataSourcesFailureAction
  | LoadWidgetRegistryAction
  | ConfirmationPasswordAction
  | ConfirmationPasswordSuccessAction
  | ConfirmationPasswordFailureAction
  | ForgotPasswordAction
  | ForgotPasswordSuccessAction
  | ForgotPasswordFailureAction
  | DiscoverSavedQueriesAction
  | DiscoverSavedQueriesSuccessAction
  | DiscoverSavedQueriesFailureAction
  | UpdateDiscoverSavedQueriesAction
  | UpdateDiscoverSavedQueriesSuccessAction
  | UpdateDiscoverSavedQueriesFailureAction
  | UpdateEnigmaUWeeklyReportRecipientsAction
  | UpdateEnigmaUWeeklyReportRecipientsSuccessAction
  | UpdateEnigmaUWeeklyReportRecipientsFailureAction
  | GetEnigmaUWeeklyReportRecipientsAction
  | GetEnigmaUWeeklyReportRecipientsSuccessAction
  | GetEnigmaUWeeklyReportRecipientsFailureAction
  | UpdateEnigmaURemindersSentAction
  | UpdateEnigmaURemindersSentSuccessAction
  | UpdateEnigmaURemindersSentFailureAction;
