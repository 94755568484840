import { Map, Feature } from 'ol';
import { Select } from 'ol/interaction';
import { Observable, Subject } from 'rxjs';
import { pointerMove } from 'ol/events/condition';

export interface FeatureEvent {
    feature: Feature;
    clientX: number;
    clientY: number;
}
export class InteractionCapabilities {
  // select interaction working on "pointermove"
  selectPointerMove: Select;

  selectSubject: Subject<FeatureEvent | null>;

  constructor(public map: Map) {
    this.selectPointerMove = new Select({
      condition: pointerMove
    });

    this.selectSubject = new Subject<FeatureEvent | null>();
  }

  addHoverInteraction() {
    this.map.addInteraction(this.selectPointerMove);

    const self = this;
    this.selectPointerMove.on('select', function(e) {

      const feature: Feature = e.target.getFeatures().item(0);
      if (feature) {
        const data: FeatureEvent = {
          feature: feature,
          clientX: (e as any).mapBrowserEvent.originalEvent.offsetX,
          clientY: (e as any).mapBrowserEvent.originalEvent.offsetY
        };
        self.selectSubject.next(data);
      } else {
        self.selectSubject.next(null);
      }
    });
  }

  getSelectedId(): Observable<FeatureEvent | null> {
    return this.selectSubject.asObservable();
  }

  removeHoverInteraction() {
    this.map.removeInteraction(this.selectPointerMove);
  }
}
