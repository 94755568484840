import { Component, Input } from '@angular/core';
import * as d3 from 'd3';
import { NxChartBaseDirective } from '../nx-chart-base/nx-chart-base.directive';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { SchemePalette } from 'src/app/shared/common-behaviors/scheme';
import { IWidgetInterface } from 'src/app/config/IWidgetInterface';
import { ChartType } from '@config';


@Component({
  selector: 'eng-tree-map',
  templateUrl: './tree-map.component.html',
  styleUrls: ['./tree-map.component.scss']
})
export class TreeMapComponent extends NxChartBaseDirective implements IWidgetInterface {
  chartType: ChartType = 'tree-map';

  @Input()
    scheme: SchemePalette;

  @Input()
    selectFirst = true;

  @Input()
  set data(data: any) {
    if (data && data.length > 0) {
      this.treemap = data;
      this.treemapProcess();
      this.hasInitialChildren = this.treemap && this.treemap[0].children && this.treemap[0].children.length > 0;
      if (this.selectFirst && this.treemapPath?.length > 0 && this.treemapPath[0]?.children?.length > 0) {
        const firstChild = this.treemapPath[0].children[0];
        this.treemapSelect({name: firstChild.name, value: firstChild.value});
      }
    }
  }
  get data(): any {
    return this.treemap;
  }

  treemap!: any[];
  treemapPath: any[] = [];
  sumBy = 'Size';

  hasInitialChildren: boolean;

  constructor(
    public appState: Store<AppState>) {
    super([-10, -30]);
  }

  treemapProcess(sumBy = this.sumBy) {
    this.sumBy = sumBy;
    // note had to target treemap object when not consuming mock data
    const children = this.treemap[0].treemap;
    const value = sumBy === 'Size' ? sumChildren(children) : countChildren(children);
    this.treemap = [children];
    this.treemapPath = [{ name: 'Top', children: [children], value }];

    function sumChildren(node: any): any {
      return (node.value = node.size || d3.sum(node.children, sumChildren));
    }

    function countChildren(node: any): any {
      return (node.value = node.children ? d3.sum(node.children, countChildren) : 1);
    }
  }

  treemapSelect(item: any) {
    let node;
    if (item.children) {
      const idx = this.treemapPath.indexOf(item);
      this.treemapPath.splice(idx + 1);
      this.treemap = this.treemapPath[idx].children;
      return;
    }
    node = this.treemap.find(d => d.name === item.name);
    if (node.children) {
      this.treemapPath.push(node);
      this.treemap = node.children;
    }
  }
}
