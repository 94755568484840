import { Injectable, OnDestroy } from '@angular/core';
import { Observable, throwError, Subscription } from 'rxjs';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { catchError, filter, map } from 'rxjs/operators';
import {
  QueryResponse,
  TimeQueryResponse,
  TimeQueryRequest
} from 'src/app/config/elk-config';
import {
  SeriesModel,
  ChartModel,
  bytesTemplate
} from 'src/app/shared/nx-chart-base/chart.model';
import {
  TreeMapTop,
  TreeMapBottom
} from 'src/app/shared/tree-map/tree-map.model';
import { AppState } from 'src/app/state/app.state';
import { Store, select } from '@ngrx/store';
import { getRuntimeAppConfig, getDataSources } from 'src/app/state/app.selectors';
import { cloneDeep } from 'lodash';
import { DataSource, SourceApiType } from 'src/app/models/account.model';
import * as changeCase from 'change-case';
import { AreaChartModel } from 'src/app/models/area-chart.model';
import { DatePipe } from '@angular/common';
import { BlockedIP } from 'src/app/models/blocked-ip.table.model';
import { TopActiveHost } from 'src/app/models/top-active-host.table.model';
import { HostList } from 'src/app/models/host-list.table.model';
import { AclBlocked } from 'src/app/models/acl-blocked.table.model';
import { AsaMessage } from 'src/app/models/asa-message.table.model';
import { BlockedPort } from 'src/app/models/blocked-port.table.model';
import { DestinationPoint } from 'src/app/models/destination-point.model';
import { AuthService } from '../ue/auth.service';
import { User as AuthUser } from 'oidc-client-ts';

@Injectable()
export class CloudElasticService implements OnDestroy {
  queryElkPostAPI!: string;
  configSub: Subscription;
  dataSourcesSub: Subscription;
  indexSources: DataSource[];
  defaultRoute: string;
  user: AuthUser | null;

  constructor(
    private _appStore: Store<AppState>,
    private _http: HttpClient,
    private _authService: AuthService
  ) {
    this.initializeClient();
  }

  async initializeClient() {
    this.user = await this._authService.getUser();
    if(this.user) {
      this.configSub = this._appStore
        .pipe(select(getRuntimeAppConfig))
        .subscribe(config => {
          if (config) {
            this.queryElkPostAPI = `${config.apiGatewayUrl}/getelkdata`;
            this.defaultRoute = config.region;
          }
        });
  
      this.dataSourcesSub = this._appStore.pipe(
        select(getDataSources),
        filter(dataSources => !!dataSources && dataSources?.length > 0)
      ).subscribe(dataSources => {
        if (dataSources && dataSources.length > 0) {
          this.indexSources = dataSources;
        }
      });
    }
  }

  ngOnDestroy() {
    this.configSub.unsubscribe();
    this.dataSourcesSub.unsubscribe();
  }

  /** Going to be deprecated soon */
  fetchTotalHitsData(query: any): Observable<QueryResponse> {
    const newQuery = this._setSourceIndexOnQuery(query.query);
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(newQuery),
        this._httpOptions
      )
      .pipe(
        // TODO find out what type the reponse is
        map((res: any) => {
          const resp: QueryResponse = {
            name: query.name,
            value: res.body.resp.hits.total.value
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchSeverityHitsData(
    query: any
  ): Observable<TimeQueryResponse> {
    const newQuery = this._setSourceIndexOnQuery(query.query);
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(newQuery),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let chart: any;
          if (
            res &&
            res.body &&
            res.body.resp &&
            res.body.resp.aggregations &&
            res.body.resp.aggregations.top_hits
          ) {
            chart = res.body.resp.aggregations.top_hits.buckets.map(
              (bucket: any) => {
                return {
                  name: bucket.key,
                  value: bucket.doc_count
                };
              }
            );
          } else {
            chart = null;
            throw new Error('response from post fetch severity hits is missing top_hits');
          }

          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              piegrid: chart,
              table: res.body.resp.hits.hits
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchHitsOverTimeData(
    query: any
  ): Observable<TimeQueryResponse> {
    const newQuery = this._setSourceIndexOnQuery(query.query);

    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(newQuery),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let series: SeriesModel[];
          let chart: ChartModel | null;
          if (
            res &&
            res.body &&
            res.body.resp &&
            res.body.resp.aggregations &&
            res.body.resp.aggregations.hits_over_time
          ) {
            series = res.body.resp.aggregations.hits_over_time.buckets.map(
              (bucket: any) => {
                const newDate = new Date(bucket.key_as_string);
                return {
                  name: newDate,
                  value: bucket.doc_count
                };
              }
            );
            chart = {
              name: query.name,
              series: series
            };
          } else {
            chart = null;
            throw new Error('response from post fetch hits over time is missing hits_over_time');
          }

          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              line: chart ? [chart] : [],
              table: res.body.resp.hits.hits
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchHitsOverTimeAmpData(
    query: any
  ): Observable<TimeQueryResponse> {
    const newQuery = this._setSourceIndexOnQuery(query.query);

    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(newQuery),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let series: SeriesModel[];
          let chart: ChartModel | null;
          if (
            res &&
            res.body &&
            res.body.resp &&
            res.body.resp.aggregations &&
            res.body.resp.aggregations['2'] &&
            res.body.resp.aggregations['2'].buckets
          ) {
            series = res.body.resp.aggregations['2'].buckets.map(
              (bucket: any) => {
                const newDate = new Date(bucket.key_as_string);
                return {
                  name: newDate,
                  value: bucket['1'].value
                };
              }
            );
            chart = {
              name: query.name,
              series: series
            };
          } else {
            chart = null;
            throw new Error('response from post fetch hits over time is missing hits_over_time');
          }

          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              line: chart ? [chart] : [],
              table: res.body.resp.hits.hits
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchHitsOverTimeUmbrellaData(
    query: any
  ): Observable<TimeQueryResponse> {
    const newQuery = this._setSourceIndexOnQuery(query.query);

    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(newQuery),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let series: SeriesModel[];
          let chart = new Array<AreaChartModel>();
          if (
            res &&
            res.body &&
            res.body.resp &&
            res.body.resp.aggregations &&
            res.body.resp.aggregations['2'] &&
            res.body.resp.aggregations['2'].buckets
          ) {
            series = res.body.resp.aggregations['2'].buckets.map(
              (bucket: any) => {
                const newDate = new Date(bucket.key_as_string);
                return {
                  name: newDate,
                  value: bucket['1'].value
                };
              }
            );
            chart.push({
              name: query.name,
              series: series
            });
          } else {
            chart = new Array<AreaChartModel>();
            throw new Error('response from post fetch hits over time is missing hits_over_time');
          }

          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              areachart: chart,
              table: res.body.resp.hits.hits
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchDiscoverData(
    query: any
  ): Observable<TimeQueryResponse> {

    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(query.query),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          if (
            res &&
            res.body &&
            res.body.resp &&
            res.body.resp.hits &&
            res.body.resp.hits.hits
          ) {
          } else {
            throw new Error('response does not include hits of hits');
          }

          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              table: res.body.resp.hits.hits
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchTotalDiscoveredData(
    query: any
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(query.query),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchEventOverTime(
    query: any
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(query.query),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let chart: any;
          if (
            res?.body?.resp?.aggregations &&
            res.body.resp.aggregations['2']?.buckets
          ) {
            chart = res.body.resp.aggregations['2'].buckets.map(
              (bucket: any) => {
                return {
                  name: new DatePipe('en-US').transform(bucket.key_as_string, 'yyyy-MM-dd h:mm:ss')?.toString() ?? '',
                  value: bucket.doc_count
                };
              }
            );
          } else {
            chart = null;
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              bar: chart,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchSeveritiesData(
    query: TimeQueryRequest
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          const severity = query.query?.body?.query?.bool?.filter[0]?.term['severity.keyword'] ?? query.name;
          const tree: TreeMapTop = {
            name: severity,
            children: []
          };

          if (res.body.resp.hits.hits.length > 0) {
            res.body.resp.hits.hits.forEach((hit: any) => {
              const child = tree.children.find(
                childOf => childOf.name === hit._source.event_type
              );
              if (child) {
                (child as TreeMapBottom).size++;
              } else {
                tree.children.push({
                  name: hit._source.event_type,
                  size: 1
                });
              }
            });
          }
          // sort highest size value first
          tree.children = (tree.children as TreeMapBottom[]).sort((a, b) =>
            a.size < b.size ? 1 : -1
          );

          const resp: TimeQueryResponse = {
            name: severity,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              tree: tree,
              table: res.body.resp.hits.hits
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchEndpointsSendingData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    const newQuery = this._setSourceIndexOnQuery(query.query);
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(newQuery),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {

          let val = 0;

          if (
            res &&
            res.body &&
            res.body.resp &&
            res.body.resp.aggregations &&
            res.body.resp.aggregations['1']
          ) {
            val = res.body.resp.aggregations['1'].value;
          } else {
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              numbercard: val,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchTotalEventsData(
    query: TimeQueryRequest
  ): Observable<TimeQueryResponse> {
    const newQuery = this._setSourceIndexOnQuery(query.query);

    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(newQuery),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {

          let val = 0;

          if (
            res?.body?.resp?.hits?.total
          ) {

            val = res.body.resp.hits.total.value;
          } else {
            throw new Error('Does not contain proper hit');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              numbercard: val,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchUserAuthFailuresData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    const newQuery = this._setSourceIndexOnQuery(query.query);
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(newQuery),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {

          let val = 0;

          if (
            res?.body?.resp?.hits?.total
          ) {
            val = res.body.resp.hits.total.value;
          } else {
            throw new Error('Does not contain proper hit');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              numbercard: val,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchTotalRemoteCommandsData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    const newQuery = this._setSourceIndexOnQuery(query.query);
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(newQuery),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {

          let val = 0;

          if (
            res?.body?.resp?.aggregations &&
            res.body.resp.aggregations['2']?.buckets &&
            res.body.resp.aggregations['2'].buckets['Remote commands']
          ) {
            val = res.body.resp.aggregations['2'].buckets['Remote commands'].doc_count;
          } else {
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              numbercard: val,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchTopEventsData(
    query: any
  ): Observable<TimeQueryResponse> {
    const newQuery = this._setSourceIndexOnQuery(query.query);
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(newQuery),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {

          let chart: any;

          if (
            res?.body?.resp?.aggregations &&
            res.body.resp.aggregations['topEventBuckets']?.buckets
          ) {
            chart = res.body.resp.aggregations['topEventBuckets'].buckets.map(
              (bucket: any) => {
                return {
                  name: bucket.key,
                  value: bucket.doc_count
                };
              }
            );
          } else {
            chart = null;
            throw new Error('Does not contain proper aggregation');
          }

          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              piechart: chart,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchSourcesProviderNamesData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    const newQuery = this._setSourceIndexOnQuery(query.query);
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(newQuery),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {

          let chart: any;

          if (
            res?.body?.resp?.aggregations &&
            res.body.resp.aggregations['2']?.buckets
          ) {
            chart = res.body.resp.aggregations['2'].buckets.map(
              (bucket: any) => {
                return {
                  name: bucket.key,
                  value: bucket.doc_count
                };
              }
            );
          } else {
            chart = null;
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              piechart: chart,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchAuthActivityBreakdownData (
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    const newQuery = this._setSourceIndexOnQuery(query.query);
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(newQuery),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {

          let chart: any;

          if (
            res?.body?.resp?.aggregations &&
            res.body.resp.aggregations['2']?.buckets
          ) {
            chart = res.body.resp.aggregations['2'].buckets.map(
              (bucket: any) => {
                return {
                  name: bucket.key,
                  value: bucket.doc_count
                };
              }
            );
          } else {
            chart = null;
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              piechart: chart,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchLogonActivityOverTimeData(
    query: TimeQueryRequest
  ): Observable<TimeQueryResponse> {
    const newQuery = this._setSourceIndexOnQuery(query.query);
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(newQuery),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let chart = new Array<AreaChartModel>();
          if (
            res?.body?.resp?.aggregations &&
            res.body.resp.aggregations['2'] &&
            res.body.resp.aggregations['2']?.buckets?.length > 0
          ) {
            // TODO: Should we loop the obeject "res.body.resp.aggregations['2'].buckets"?
            res.body.resp.aggregations['2'].buckets.forEach((bucketItem: any) => {
              if (bucketItem['3']) {
                const chartItem = {} as AreaChartModel;
                chartItem.name = bucketItem.key;
                chartItem.series = bucketItem['3'].buckets.map(
                  (bucket: any) => {
                    return {
                      // Change this implemetnation of Date format
                      name: new DatePipe('en-US').transform(bucket.key_as_string, 'yyyy-MM-dd'),
                      value: bucket.doc_count
                    };
                  }
                );
                chart.push(chartItem);
              }
            });
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              areachart: chart,
              table: res?.body?.resp?.hits?.hits
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchEventsOverTimeData(
    query: TimeQueryRequest
  ): Observable<TimeQueryResponse> {
    const newQuery = this._setSourceIndexOnQuery(query.query);
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(newQuery),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let chart = new Array<AreaChartModel>();
          if (
            res?.body?.resp?.aggregations &&
            res.body.resp.aggregations['2'] &&
            res.body.resp.aggregations['2']?.buckets?.length > 0
          ) {
            // TODO: Should we loop the obeject "res.body.resp.aggregations['2'].buckets"?
            res.body.resp.aggregations['2'].buckets.forEach((bucketItem: any) => {
              if (bucketItem['3']) {
                const chartItem = {} as AreaChartModel;
                chartItem.name = new DatePipe('en-US').transform(bucketItem.key_as_string, 'yyyy-MM-dd')?.toString() ?? '';
                chartItem.series = bucketItem['3'].buckets.map(
                  (bucket: any) => {
                    return {
                      // Change this implemetnation of Date format
                      name: bucket.key,
                      value: bucket.doc_count
                    };
                  }
                );
                chart.push(chartItem);
              }
            });
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              stackedverticalchart: chart,
              table: res?.body?.resp?.hits?.hits
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchEventData(
    query: any
  ): Observable<TimeQueryResponse> {

    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let val = 0;
          if (
            res &&
            res.body &&
            res.body.resp &&
            res.body.resp.hits &&
            res.body.resp.hits.total
          ) {
            val = res.body.resp.hits.total.value;
          } else {
            throw new Error('response does not include hits of hits');
          }

          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              numbercard: val,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchTopUserAlertsData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let chart: any;

          if (
            res?.body?.resp?.aggregations &&
            res.body.resp.aggregations['2']?.buckets
          ) {
            chart = res.body.resp.aggregations['2'].buckets.map(
              (bucket: any) => {
                return {
                  name: bucket.key,
                  value: bucket.doc_count
                };
              }
            );
          } else {
            chart = null;
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              piechart: chart,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchCloudfrontResultTypeData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let chart: any;
          if (
            res?.body?.resp?.aggregations &&
            res.body.resp.aggregations['2']?.buckets
          ) {
            chart = res.body.resp.aggregations['2'].buckets.map(
              (bucket: any) => {
                return {
                  name: bucket.key,
                  value: bucket.doc_count
                };
              }
            );
          } else {
            chart = null;
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              piechart: chart,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchCloudfrontTotalHitsData(
    query: any
  ): Observable<TimeQueryResponse> {

    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let val = 0;
          if (
            res &&
            res.body &&
            res.body.resp &&
            res.body.resp.hits &&
            res.body.resp.hits.total
          ) {
            val = res.body.resp.hits.total.value;
          } else {
            throw new Error('response does not include hits of hits');
          }

          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              numbercard: val,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchCloudfrontHittingFirewallData(
    query: any
  ): Observable<TimeQueryResponse> {

    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let val = 0;
          if (
            res &&
            res.body &&
            res.body.resp &&
            res.body.resp.aggregations &&
            res.body.resp.aggregations['1']
          ) {
            val = res.body.resp.aggregations['1'].value;
          } else {
            throw new Error('Does not contain proper aggregation');
          }

          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              numbercard: val,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchCloudfrontHitsPerDayData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let chart: any;
          if (
            res?.body?.resp?.aggregations &&
            res.body.resp.aggregations['2']?.buckets
          ) {
            chart = res.body.resp.aggregations['2'].buckets.map(
              (bucket: any) => {
                return {
                  name: new DatePipe('en-US').transform(bucket.key_as_string, 'yyyy-MM-dd')?.toString() ?? '',
                  value: bucket.doc_count
                };
              }
            );
          } else {
            chart = null;
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              bar: chart,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchCloudfrontBlockedIPsData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let tableData = new Array<BlockedIP>();
          if (
            res?.body?.resp?.aggregations &&
            res.body.resp.aggregations['2']?.buckets
          ) {
            res.body.resp.aggregations['2'].buckets.forEach((bucket: any) => {
              tableData.push(
                {
                  'c-ip.keyword': bucket.key,
                  'x-edge-detailed-result-type.keyword': bucket['3']?.buckets[0]?.key ?? '',
                  Count: bucket.doc_count
                } as BlockedIP
              );
            });
          } else {
            tableData = new Array<BlockedIP>();
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              tableData: tableData,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  private handleError(error: any) {
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  private get _httpOptions(): { headers: HttpHeaders; observe: any } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('idToken')}`
      }),
      observe: 'response'
    };
  }

  fetchStartedProvidersData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let chart: any;
          if (
            res?.body?.resp?.aggregations &&
            res.body.resp.aggregations['2']?.buckets
          ) {
            chart = res.body.resp.aggregations['2'].buckets.map(
              (bucket: any) => {
                return {
                  name: bucket.key,
                  value: bucket.doc_count
                };
              }
            );
          } else {
            chart = null;
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              piechart: chart,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchTotalEngineStartedData(
    query: any
  ): Observable<TimeQueryResponse> {

    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let val = 0;
          if (
            res?.body?.resp?.aggregations['2']?.buckets['event.code: 400']?.doc_count
          ) {
            val = res.body.resp.aggregations['2'].buckets['event.code: 400'].doc_count;
          } else {
            throw new Error('Does not contain proper aggregation');
          }

          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              numbercard: val,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchUniqueHostsData(
    query: any
  ): Observable<TimeQueryResponse> {

    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let val = 0;
          if (
            res?.body?.resp?.aggregations['1']
          ) {
            val = res.body.resp.aggregations['1'].value;
          } else {
            throw new Error('Does not contain proper aggregation');
          }

          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              numbercard: val,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchTopActiveHostsData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let tableData = new Array<TopActiveHost>();
          if (
            res?.body?.resp?.aggregations &&
            res.body.resp.aggregations['2']?.buckets
          ) {
            res.body.resp.aggregations['2'].buckets.forEach((bucket: any) => {
              tableData.push(
                {
                  'host.name': bucket.key,
                  Count: bucket.doc_count
                } as TopActiveHost
              );
            });
          } else {
            tableData = new Array<TopActiveHost>();
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              tableData: tableData,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchEngineAndCommandStartedData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let chart = new Array<AreaChartModel>();
          if (
            res?.body?.resp?.aggregations &&
            res.body.resp.aggregations['2']?.buckets
          ) {
            chart.push({ name: 'Command started', series: new Array() } as AreaChartModel);
            chart.push({ name: 'Engine started', series: new Array() } as AreaChartModel);
            res.body.resp.aggregations['2'].buckets.forEach((bucketItem: any) => {
              chart.find(x => x.name === 'Command started')?.series.push(
                {
                  name: new DatePipe('en-US').transform(bucketItem.key_as_string, 'HH:mm:ss'),
                  value: bucketItem['4'].buckets['Command started'].doc_count
                }
              );
              chart.find(x => x.name === 'Engine started')?.series.push(
                {
                  name: new DatePipe('en-US').transform(bucketItem.key_as_string, 'HH:mm:ss'),
                  value: bucketItem['4'].buckets['Engine started'].doc_count
                }
              );
            });
          } else {
            chart = new Array<AreaChartModel>();
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              line: chart,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchMostChangedFileData(
    query: any
  ): Observable<TimeQueryResponse> {

    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let val = 0;
          let name = query.name;
          if (
            res?.body?.resp?.aggregations['2']?.buckets[0]
          ) {
            val = res.body.resp.aggregations['2'].buckets[0].doc_count;
            name = res.body.resp.aggregations['2'].buckets[0].key;
          }

          const resp: TimeQueryResponse = {
            name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              numbercard: val,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchNewUserAccountCreatedQueryData(
    query: any
  ): Observable<TimeQueryResponse> {

    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let val = 0;
          if (
            res?.body?.resp?.hits?.total
          ) {
            val = res.body.resp.hits.total.value;
          } else {
            throw new Error('Does not contain proper aggregation');
          }

          const resp: TimeQueryResponse = {
            name: 'Total',
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              numbercard: val,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchAuthenticationFailuresQueryData(
    query: any
  ): Observable<TimeQueryResponse> {

    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let val = 0;
          if (
            res?.body?.resp?.hits?.total
          ) {
            val = res.body.resp.hits.total.value;
          } else {
            throw new Error('Does not contain proper aggregation');
          }

          const resp: TimeQueryResponse = {
            name: 'Total',
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              numbercard: val,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchChangedPasswordQueryData(
    query: any
  ): Observable<TimeQueryResponse> {

    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let val = 0;
          if (
            res?.body?.resp?.hits?.total
          ) {
            val = res.body.resp.hits.total.value;
          } else {
            throw new Error('Does not contain proper aggregation');
          }

          const resp: TimeQueryResponse = {
            name: 'Total',
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              numbercard: val,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchEventsOverTimeECSData(
    query: TimeQueryRequest
  ): Observable<TimeQueryResponse> {
    const newQuery = this._setSourceIndexOnQuery(query.query);
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(newQuery),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let chart: any;
          if (
            res?.body?.resp?.aggregations &&
            res.body.resp.aggregations['2']?.buckets
          ) {
            chart = res.body.resp.aggregations['2'].buckets.map(
              (bucket: any) => {
                return {
                  name: new DatePipe('en-US').transform(bucket.key_as_string, 'yyyy-MM-dd h:mm:ss')?.toString() ?? '',
                  value: bucket.doc_count
                };
              }
            );
          } else {
            chart = null;
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              bar: chart,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchSystemEventHistogramData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let chart: any;
          if (
            res?.body?.resp?.aggregations &&
            res.body.resp.aggregations['2']?.buckets
          ) {
            chart = res.body.resp.aggregations['2'].buckets.map(
              (bucket: any) => {
                return {
                  name: new DatePipe('en-US').transform(bucket.key_as_string, 'yyyy-MM-dd')?.toString() ?? '',
                  value: bucket.doc_count
                };
              }
            );
          } else {
            chart = null;
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              bar: chart,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchHostListQueryData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let tableData = new Array<HostList>();
          if (
            res?.body?.resp?.aggregations &&
            res.body.resp.aggregations['2']?.buckets
          ) {
            res.body.resp.aggregations['2'].buckets.forEach((bucket: any) => {
              tableData.push(
                {
                  Hostname: bucket.key,
                  'Uptime(days)': '0.0',
                  OS: bucket['3']?.hits?.hits[0]['fields']?.['system.audit.host.os.name'][0] ?? '',
                  Version: bucket['4']?.hits?.hits[0]['fields']?.['system.audit.host.os.version'][0] ?? '',
                  'Host ID': '',
                  Status: bucket['6']?.hits?.hits[0]['_source']?.message ?? ''
                } as HostList
              );
            });
          } else {
            tableData = new Array<HostList>();
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              tableData: tableData,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchEventActionsData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let chart = new Array<AreaChartModel>();
          if (
            res?.body?.resp?.aggregations &&
            res.body.resp.aggregations['2']?.buckets
          ) {
            res.body.resp.aggregations['2'].buckets.forEach((bucket: any) => {
              const newDate = new Date(bucket.key_as_string);
              const name = bucket['3'].buckets[0].key;
              const serie = {
                name: newDate,
                value: bucket['3'].buckets[0].doc_count
              };

              if (chart.find(x => x.name === name)) {
                chart.find(x => x.name === name)?.series.push(
                  serie
                );
              } else {
                const singleValue = { name, series: new Array<SeriesModel>() } as AreaChartModel;
                singleValue.series.push(serie);
                chart.push(singleValue);
              }
            });

          } else {
            chart = new Array<AreaChartModel>();
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              areachart: chart,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchOSDistributionData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let chart: any;
          if (
            res?.body?.resp?.aggregations &&
            res.body.resp.aggregations['2']?.buckets
          ) {
            chart = res.body.resp.aggregations['2'].buckets.map(
              (bucket: any) => {
                return {
                  name: bucket.key,
                  value: bucket.doc_count,
                };
              }
            );
          } else {
            chart = null;
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              advancedPiechart: chart,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchEventCategoriesData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let chart: any;
          if (
            res?.body?.resp?.aggregations &&
            res.body.resp.aggregations['2']?.buckets
          ) {
            chart = res.body.resp.aggregations['2'].buckets.map(
              (bucket: any) => {
                return {
                  name: bucket.key,
                  value: bucket.doc_count,
                };
              }
            );
          } else {
            chart = null;
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              advancedPiechart: chart,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchUniqueDeniedData(
    query: any
  ): Observable<TimeQueryResponse> {

    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let val = 0;
          let name = query.name;
          if (
            res?.body?.resp?.aggregations['38796693-38c0-49f5-8f9e-d5b8474bedc9']?.value
          ) {
            val = res.body.resp.aggregations['38796693-38c0-49f5-8f9e-d5b8474bedc9']?.value;
            name = 'Outside The United States';
          }

          const resp: TimeQueryResponse = {
            name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              numbercard: val,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchVpnActivityData(
    query: any
  ): Observable<TimeQueryResponse> {

    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let val = 0;
          let name = query.name;
          if (
            res?.body?.resp?.hits?.total?.value
          ) {
            val = res.body.resp.hits.total.value;
            name = 'VPN Connections Captured';
          }

          const resp: TimeQueryResponse = {
            name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              numbercard: val,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchSuccessfulConnectionsData(
    query: any
  ): Observable<TimeQueryResponse> {

    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let val = 0;
          let name = query.name;
          if (
            res?.body?.resp?.aggregations['6279c2d8-d9c9-4ddd-b196-b1257e74dce0']?.value
          ) {
            val = res.body.resp.aggregations['6279c2d8-d9c9-4ddd-b196-b1257e74dce0']?.value;
            name = 'Outside The United States';
          }

          const resp: TimeQueryResponse = {
            name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              numbercard: val,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchTopACLBlockedData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let tableData = new Array<AclBlocked>();
          if (
            res?.body?.resp?.aggregations &&
            res.body.resp.aggregations['2']?.buckets
          ) {
            res.body.resp.aggregations['2'].buckets.forEach((bucket: any) => {
              tableData.push(
                {
                  'ACL ID': bucket.key,
                  Count: bucket.doc_count
                }
              );
            });
          } else {
            tableData = new Array<AclBlocked>();
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              tableData: tableData,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchGeographicBreakdownData(
    query: TimeQueryRequest
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          const country = 'Countries';
          const tree: TreeMapTop = {
            name: country,
            children: []
          };

          if (res?.body?.resp?.aggregations['2']?.buckets) {
            res.body.resp.aggregations['2'].buckets.forEach((_country: any) => {
              const _children: TreeMapTop = {
                name: _country.key,
                children: []
              };
              _country['3'].buckets.forEach((_city: any) => {
                _children.children.push({
                  name: _city.key,
                  size: _city.doc_count
                });
              });

              tree.children.push(_children);
            });
          }

          const resp: TimeQueryResponse = {
            name: country,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              tree,
              table: res?.body?.resp?.hits?.hits
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchTopBlockedSourceIPsData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let chart = new Array<AreaChartModel>();
          if (
            res?.body?.resp?.aggregations['2']?.buckets
          ) {
            res.body.resp.aggregations['2'].buckets.forEach(
              (bucket: any) => {
                if (bucket['3']?.buckets[0]) {
                  const _bucket = bucket['3'].buckets[0];
                  chart.push({
                    name: _bucket.key,
                    series: [{
                      name: bucket.key,
                      value: _bucket.doc_count
                    }]
                  });
                }
              }
            );
          } else {
            chart = new Array<ChartModel>();
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              stackedverticalchart: chart,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchTopASAMessagesData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let tableData = new Array<AsaMessage>();
          if (
            res?.body?.resp?.aggregations['2']?.buckets
          ) {
            res.body.resp.aggregations['2'].buckets.forEach((bucket: any) => {
              tableData.push(
                {
                  ID: bucket.key,
                  Count: bucket.doc_count,
                  Severity: bucket['4']?.hits?.hits[0]?.fields['log.level'][0],
                  'Sample message': bucket['1']?.hits?.hits[0]?.fields['event.original'][0]
                }
              );
            });
          } else {
            tableData = new Array<AsaMessage>();
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              tableData: tableData,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchPortsBlockedASAData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let tableData = new Array<BlockedPort>();
          if (
            res?.body?.resp?.aggregations['d4f9cbe6-6354-479b-ba6d-08661f94e158']?.buckets
          ) {
            res.body.resp.aggregations['d4f9cbe6-6354-479b-ba6d-08661f94e158'].buckets.forEach((bucket: any) => {
              const country = bucket.key;
              bucket['8871d98e-6dce-4d11-ac4f-6e6795fe8d53'].buckets.forEach((ipBucket: any) => {
                const ip = ipBucket.key;
                ipBucket['59b93652-c316-461c-95c9-bc42933b1e7e'].buckets.forEach((portBucket: any) => {
                  const port = portBucket.key;
                  portBucket['0045f2b4-a198-4090-a316-efcfe5d4d07e'].buckets.forEach((protocolBucket: any) => {
                    const protocol = protocolBucket.key;
                    protocolBucket['a3cd5a25-7ff6-4bb7-8a8f-12949e954c02'].buckets.forEach((eventOutcomeBucket: any) => {
                      tableData.push(
                        {
                          'Source Country': country,
                          'Source IP': ip,
                          'Source Port': port,
                          Protocol: protocol,
                          'Event Outcome': eventOutcomeBucket.key,
                          'Event Count': eventOutcomeBucket.doc_count
                        }
                      );
                    });
                  });
                });
              });
            });
          } else {
            tableData = new Array<BlockedPort>();
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              tableData: tableData,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchFirewallEventsData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let chart = new Array<AreaChartModel>();
          if (
            res?.body?.resp?.aggregations['2']?.buckets
          ) {
            res.body.resp.aggregations['2'].buckets.forEach(
              (bucket: any) => {
                bucket['3'].buckets.forEach((_bucket: any) => {
                  const name = new DatePipe('en-US').transform(_bucket.key_as_string, 'yyyy-MM-dd h:mm:ss')?.toString() ?? '';
                  const item = chart.find(x => x.name === name);
                  if (item) {
                    item.series.push({
                      name: bucket.key,
                      value: _bucket.doc_count
                    });
                  } else {
                    chart.push({
                      name: name,
                      series: [{
                        name: bucket.key,
                        value: _bucket.doc_count
                      }]
                    });
                  }
                });
              }
            );
          } else {
            chart = new Array<ChartModel>();
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              stackedverticalchart: chart,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  // Packet Beat
  fetchNetworkEventsData(
    query: any
  ): Observable<TimeQueryResponse> {

    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let val = 0;
          let name = query.name;
          if (
            res?.body?.resp?.hits?.total?.value
          ) {
            val = res?.body?.resp?.hits?.total?.value;
            name = 'Count';
          }

          const resp: TimeQueryResponse = {
            name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              numbercard: val,
              table: res?.body?.resp?.hits?.hits ?? null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchDNSQueriesData(
    query: any
  ): Observable<TimeQueryResponse> {

    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let val = 0;
          let name = query.name;
          if (
            res?.body?.resp?.hits?.total?.value
          ) {
            val = res?.body?.resp?.hits?.total?.value;
            name = 'Count';
          }

          const resp: TimeQueryResponse = {
            name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              numbercard: val,
              table: res?.body?.resp?.hits?.hits ?? null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchHTTPTransactionsData(
    query: any
  ): Observable<TimeQueryResponse> {

    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let val = 0;
          let name = query.name;
          if (
            res?.body?.resp?.hits?.total?.value
          ) {
            val = res?.body?.resp?.hits?.total?.value;
            name = 'Count';
          }

          const resp: TimeQueryResponse = {
            name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              numbercard: val,
              table: res?.body?.resp?.hits?.hits ?? null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchTLSSessionsData(
    query: any
  ): Observable<TimeQueryResponse> {

    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let val = 0;
          let name = query.name;
          if (
            res?.body?.resp?.hits?.total?.value
          ) {
            val = res?.body?.resp?.hits?.total?.value;
            name = 'Count';
          }

          const resp: TimeQueryResponse = {
            name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              numbercard: val,
              table: res?.body?.resp?.hits?.hits ?? null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchSourceTrafficBreakdownData(
    query: TimeQueryRequest
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          const cities = 'Cities';
          const tree: TreeMapTop = {
            name: cities,
            children: []
          };

          if (res?.body?.resp?.aggregations['c731c5d7-e3b9-4c5c-9971-00ea8b19b5a4']?.buckets) {
            res.body.resp.aggregations['c731c5d7-e3b9-4c5c-9971-00ea8b19b5a4'].buckets.forEach((_cities: any) => {
              const _children: TreeMapBottom = {
                name: _cities.key,
                size: _cities.doc_count
              };
              tree.children.push(_children);
            });
          }

          const resp: TimeQueryResponse = {
            name: cities,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              tree,
              table: res?.body?.resp?.hits?.hits
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchTopDestinationsData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let chart: any;
          if (
            res?.body?.resp?.aggregations['672e8cf6-0ea7-4a54-ad05-3fec1434e844']?.buckets
          ) {
            chart = res.body.resp.aggregations['672e8cf6-0ea7-4a54-ad05-3fec1434e844'].buckets.map(
              (bucket: any) => {
                return {
                  name: bucket.key,
                  value: bucket.doc_count
                };
              }
            );
          } else {
            chart = null;
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              piechart: chart,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchTrafficBreakdownData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let chart = new Array<AreaChartModel>();
          if (
            res?.body?.resp?.aggregations['9a1bbd07-4e94-46f2-b4e0-57e6dc340a91']?.buckets
          ) {
            res.body.resp.aggregations['9a1bbd07-4e94-46f2-b4e0-57e6dc340a91'].buckets.forEach(
              (bucket: any) => {
                bucket['148f8c9e-dc97-4cfc-984e-3f926ff2bdaa'].buckets.forEach((_bucket: any) => {
                  const name = new DatePipe('en-US').transform(_bucket.key_as_string, 'yyyy-MM-dd h:mm:ss')?.toString() ?? '';
                  const item = chart.find(x => x.name === name);
                  if (item) {
                    item.series.push({
                      name: bucket.key,
                      value: _bucket.doc_count
                    });
                  } else {
                    chart.push({
                      name: name,
                      series: [{
                        name: bucket.key,
                        value: _bucket.doc_count
                      }]
                    });
                  }
                });
              }
            );
          } else {
            chart = new Array<ChartModel>();
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              stackedverticalchart: chart,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchTransactionsOverTimeData(
    query: TimeQueryRequest
  ): Observable<TimeQueryResponse> {
    const newQuery = this._setSourceIndexOnQuery(query.query);
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(newQuery),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let chart: AreaChartModel[] = [{
            name: 'Unique Flows',
            series: new Array<any>()
          }];
          if (
            res?.body?.resp?.aggregations['2']?.buckets
          ) {
            res.body.resp.aggregations['2'].buckets.forEach((bucketItem: any) => {
              chart[0].series.push({
                name: new DatePipe('en-US').transform(bucketItem.key_as_string, 'yyyy-MM-dd h:mm:ss')?.toString() ?? '',
                value: bucketItem['1']?.value ?? 0
              });
            });
          } else {
            chart = new Array<AreaChartModel>();
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              areachart: chart,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchTopHTTPRequestsData(
    query: any
  ): Observable<TimeQueryResponse> {

    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          const table = Array<any>();
          if (
            res?.body?.resp?.aggregations['3']?.buckets
          ) {
            res.body.resp.aggregations['3'].buckets.forEach((bucket: any) => {
              table.push(
                {
                  'url.full': bucket.key,
                  Count: bucket.doc_count
                }
              );
            });
          }

          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              tableData: table,
              table: res?.body?.resp?.hits?.hits ?? null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchDNSTopRequestsData(
    query: any
  ): Observable<TimeQueryResponse> {

    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          const table = Array<any>();
          if (
            res?.body?.resp?.aggregations['2']?.buckets
          ) {
            res.body.resp.aggregations['2'].buckets.forEach((bucket: any) => {
              table.push(
                {
                  'Question': bucket.key,
                  Count: bucket.doc_count
                }
              );
            });
          }

          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              tableData: table,
              table: res?.body?.resp?.hits?.hits ?? null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchNetworkTrafficBtwHostsData(
    query: any
  ): Observable<TimeQueryResponse> {

    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          const table = Array<any>();
          if (
            res?.body?.resp?.aggregations['3']?.buckets
          ) {
            res.body.resp.aggregations['3'].buckets.forEach((bucket: any) => {
              const sourceIp = bucket.key;
              bucket['4'].buckets.forEach((_bucket: any) => {
                table.push(
                  {
                    'Source IP': sourceIp,
                    'Destination IP': _bucket.key,
                    'Source Bytes': bytesTemplate(_bucket['1'].value),
                    'Destination Bytes': bytesTemplate(_bucket['2'].value)
                  }
                );
              });
            });
          }

          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              tableData: table,
              table: res?.body?.resp?.hits?.hits ?? null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchUniqueFQDNData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let chart = new Array<AreaChartModel>();
          if (
            res?.body?.resp?.aggregations['2']?.buckets
          ) {
            res.body.resp.aggregations['2'].buckets.forEach(
              (bucket: any) => {
                chart.push({
                  name: bucket.key,
                  series: [{
                    name: 'Unique Dudomain Count',
                    value: bucket['1'].value
                  }]
                });
              }
            );
          } else {
            chart = new Array<ChartModel>();
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              groupedverticalchart: chart,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchBytesTransferredData(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let chart = new Array<AreaChartModel>();
          if (
            res?.body?.resp?.aggregations['2']?.buckets
          ) {
            res.body.resp.aggregations['2'].buckets.forEach(
              (bucket: any) => {
                chart.push({
                  name: bucket.key,
                  series: [{
                    name: 'Bytes Out',
                    value: bucket['1'].value
                  }, {
                    name: 'Bytes In',
                    value: bucket['3'].value
                  }]
                });
              }
            );
          } else {
            chart = new Array<ChartModel>();
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              groupedverticalchart: chart,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchOutboundConnectionAttempts(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let markers = new Array<[number, number]>();
          if (
            res?.body?.resp?.aggregations?.destSplit?.buckets
          ) {
            res.body.resp.aggregations.destSplit.buckets.forEach(
              (marker: any) => {
                const _markers = (marker.key as string).split(',')
                  .map(function(item) {
                    return parseFloat(item.trim());
                  });
                markers.push([_markers[0], _markers[1]]);
              }
            );
          } else {
            markers = new Array<[number, number]>();
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              mapMarkers: markers,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  fetchOutboundConnectionAttemptsDestination(
    query: TimeQueryRequest,
  ): Observable<TimeQueryResponse> {
    return this._http
      .post<any>(
        this.queryElkPostAPI,
        JSON.stringify(this._setSourceIndexOnQuery(query.query)),
        this._httpOptions
      )
      .pipe(
        map((res: any) => {
          let markers = new Array<DestinationPoint>();
          if (
            res?.body?.resp?.aggregations?.entitySplit?.buckets
          ) {
            res.body.resp.aggregations.entitySplit.buckets.forEach(
              (marker: any, index: number) => {
                const fields = marker.entityHits?.hits?.hits[0]?.fields;
                const geoLoc = (fields['destination.geo.location'][0] as string).split(',')
                  .map(function(item) {
                    return parseFloat(item.trim());
                  });
                markers.push({
                  id: index.toString(),
                  hostName: fields['host.name'] ? fields['host.name'][0] : '',
                  ip: marker.key,
                  domain: fields['destination.domain'] ? fields['destination.domain'][0] : '',
                  latitude: geoLoc[0],
                  longitude: geoLoc[1],
                  isoCode: fields['destination.geo.country_iso_code'] ? fields['destination.geo.country_iso_code'][0] : '',
                }
                );
              }
            );
          } else {
            markers = new Array<DestinationPoint>();
            throw new Error('Does not contain proper aggregation');
          }
          const resp: TimeQueryResponse = {
            name: query.name,
            time: query.time,
            body: {
              total: res?.body?.resp?.hits?.total?.value,
              mapMarkers: markers,
              table: res.body.resp.hits.hits ? res.body.resp.hits.hits : null
            }
          };
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  private _setSourceIndexOnQuery(query: any): any {
    const deepCloneQuery = cloneDeep(query);
    const sourceApiType = deepCloneQuery.sourceApiType;
    delete deepCloneQuery.sourceApiType;
    let adjusting = false;

    if (!deepCloneQuery) {
      throw new Error('missing query in cloud elastic service');
    }

    if ( !sourceApiType ) {

      throw new Error('missing source on original query in cloud elastic service');
    }

    if ( !this.indexSources ) {
      throw new Error('data sources did not load in time in cloud elastic service');
    }

    const foundSource = this.indexSources.find(indexSource => indexSource.type === sourceApiType);
    if (foundSource) {
      switch (foundSource.type) {
      case SourceApiType.CloudfrontLogs:
        deepCloneQuery.index = `${foundSource.identifier}.cloudfront-logs`;
        break;
      case SourceApiType.WindowLogs:
        deepCloneQuery.index = 'winlogbeat-*';
        break;
      case SourceApiType.LinuxLogs:
        deepCloneQuery.index = 'auditbeat-*';
        break;
      case SourceApiType.FileBeatLinuxLogs:
        deepCloneQuery.index = 'filebeat-*';
        break;
      case SourceApiType.PacketBeat:
        deepCloneQuery.index = 'packetbeat-*';
        break;
      default:
        const fixSourceType = changeCase.snakeCase(foundSource.type).toLowerCase();
        deepCloneQuery.index = `${foundSource.identifier}.${fixSourceType}`;
        break;
      }
      adjusting = true;
    }

    // check if query index was not set above
    if (!adjusting) {
      throw new Error('source from query does not map to index sources in state');
    }

    return deepCloneQuery;
  }
}
