import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/ue/auth.service';
import { ToasterService } from '../services/ue/toaster.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private readonly _authService: AuthService, private toastr: ToasterService,
    private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          //this._authService.logout();
          this.toastr.showToastr('Session Expired', 500);
          localStorage.setItem('currentRoute', this.router.url);
          window.location.href = '/';
          //location.reload();
        }
        const error = (err.error && err.error.message) || err.statusText;
        return throwError(error);
      })
    );
  }
}
