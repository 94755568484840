import { Injectable, OnDestroy } from '@angular/core';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import gql from 'graphql-tag';
import { from, Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { getRuntimeAppConfig } from 'src/app/state/app.selectors';
import { AppConfig } from 'src/app/config/app.config';
import {
  Account,
  DataSource,
  DiscoverSavedQuery,
  EnigmaUSettingsAndConfig,
} from 'src/app/models/account.model';
import {
  LogAlertIntegration,
  LogAlertRule,
} from 'src/app/views/log-insights/state/log-insights.state';
import { takeWhile } from 'rxjs/operators';
import * as fromAppSelectors from '../../state/app.selectors';
import { AuthService } from '../ue/auth.service';
import { User as AuthUser } from 'oidc-client-ts';

@Injectable({
  providedIn: 'root',
})
export class AppSyncAccountsService implements OnDestroy {
  client!: AWSAppSyncClient<any>;

  private _config!: AppConfig;

  credsChecked = true;
  refreshedSub!: Subscription;

  user: AuthUser | null;

  constructor(
    private _appStore: Store<AppState>,
    private _authService: AuthService
  ) {
    this.setupClient();
    this.checkForCredsRefresh();
  }

  ngOnDestroy() {
    if (this.refreshedSub) {
      this.refreshedSub.unsubscribe();
    }
  }

  async setupClient() {
    this.user = await this._authService.getUser();
    if (this.user) {
      this._appStore.pipe(select(getRuntimeAppConfig)).subscribe((config) => {
        if (config) {
          this._config = config;
          this.client = new AWSAppSyncClient({
            url: this._config.appSyncUrl,
            region: this._config.region,
            auth: {
              type: AUTH_TYPE.OPENID_CONNECT,
              // Get the currently logged in users credential.
              jwtToken: this.user?.access_token as string,
            },
            disableOffline: true,
          });
        }
      });
    } else {
      if (this.client) {
        this.client.resetStore();
        this.client.clearStore();
      }
    }
  }

  checkForCredsRefresh() {
    this.refreshedSub = (
      this._appStore.pipe(
        takeWhile(() => this.credsChecked),
        select(fromAppSelectors.getRefreshedCreds)
      ) as Observable<boolean>
    ).subscribe((refreshed: boolean) => {
      if (refreshed) {
        // console.log('refreshed from appsync-accounts service', refreshed)

        this.setupClient();

        this.credsChecked = false;
        if (window.performance) {
          // console.log('window.performance works fine on this browser');
        }
        if (performance.navigation.type === 1) {
          // console.log( 'This page is reloaded' );
        } else {
          // console.log( 'This page is not reloaded');
        }
      }
    });
  }

  getAccountSources(accountName: string): Observable<DataSource[]> {
    return from(this.getAccountSourcesHandler(accountName));
  }

  async getAccountSourcesHandler(accountName: string): Promise<any> {
    const getAccount = `query GetEnigmaGlassAccount($accountname: String!) {
        getEnigmaGlassAccounts(accountName: $accountname) {
                accountName
                sources {
                  apiKey
                  type
                  identifier
                  tenant
                  overrideDefaultTimeframe
                }
            }
          }`;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.query({
        query: gql`
          ${getAccount}
        `,
        variables: {
          accountname: accountName,
        },
        fetchPolicy: 'no-cache',
      });

      return transactionComplete.data.getEnigmaGlassAccounts.sources;
    } catch (err) {
      console.log(err);
    }
  }

  getAccountLogAlertRules(accountName: string): Observable<LogAlertRule[]> {
    return from(this.getAccountLogAlertRulesHandler(accountName));
  }

  async getAccountLogAlertRulesHandler(accountName: string): Promise<any> {
    const getAccount = `query GetEnigmaGlassAccount($accountname: String!) {
        getEnigmaGlassAccounts(accountName: $accountname) {
                logAlertRules {
                  description
                  name
                  ruleId
                  source
                  filter {
                    filterType
                    key
                    value
                    allow
                  }
                  captureKeys
                }
            }
          }`;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.query({
        query: gql`
          ${getAccount}
        `,
        variables: {
          accountname: accountName,
        },
        fetchPolicy: 'no-cache',
      });

      return transactionComplete.data.getEnigmaGlassAccounts.logAlertRules;
    } catch (err) {
      console.log(err);
    }
  }

  getAccountLogAlertIntegration(
    accountName: string
  ): Observable<LogAlertIntegration[]> {
    return from(this.getAccountLogAlertIntegrationHandler(accountName));
  }

  async getAccountLogAlertIntegrationHandler(
    accountName: string
  ): Promise<any> {
    const getAccount = `query GetEnigmaGlassAccount($accountname: String!) {
        getEnigmaGlassAccounts(accountName: $accountname) {
                logAlertIntegrations {
                  type
                  webhookUrl
                }
            }
          }`;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.query({
        query: gql`
          ${getAccount}
        `,
        variables: {
          accountname: accountName,
        },
        fetchPolicy: 'no-cache',
      });

      return transactionComplete.data.getEnigmaGlassAccounts
        .logAlertIntegrations;
    } catch (err) {
      console.log(err);
    }
  }

  listAccounts(): Observable<Account[] | null> {
    return from(this.listAccountsHandler());
  }

  async listAccountsHandler(): Promise<Account[] | null> {
    const listAccounts = `query ListAccounts($limit: Int) {
      listEnigmaGlassAccounts(limit: $limit) {
        accounts {
          accountName
          enigmaUSettingsAndConfig {
            uSecureAPIKey
            enigmaUID
            demoEnigmaU
            enigmaUWeeklyReportRecipients {
              email
              monday
              wednesday
              friday
            }
            remindersSent {
              learnerId
              timestamp
            }
          }
          features {
            mainMenu {
              showNetworkActivity
              showLogonActivity
              showDiscover
              showAlerts
              showReports
              showDataManagement
              showEnigmaApps
              showLogInsight
              showUserBehaviorMonitoring
              showIkeTool
              showSecurityEvents
              showNetworkActivityDashboard
              showPowershell
              showAuditBeat
              showWatcherAlerts
              showFileBeat
              showPacketBeat
              showRules
              showEnigmas
              showUSecure
              showEnigmasDB
              showEnigmaU
            }
            discover {
               tableExports
            }
          }
        }
      }
    }`;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.query({
        query: gql`
          ${listAccounts}
        `,
        variables: {
          limit: 1000, // TODO prob shouldn't have a limit on this
        },
        fetchPolicy: 'no-cache',
      });
      return transactionComplete.data.listEnigmaGlassAccounts.accounts;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  getEnigmaUWeeklyReportRecipients(
    accountName: string
  ): Observable<Account | null> {
    return from(this.getEnigmaUWeeklyReportRecipientsHandler(accountName));
  }

  async getEnigmaUWeeklyReportRecipientsHandler(
    accountName: string
  ): Promise<Account | null> {
    const getEnigmaUWeeklyReportRecipients = `query GetEnigmaUWeeklyReportRecipients($accountName: String!) {
      getEnigmaUWeeklyReportRecipients(accountName: $accountName) {
        accountName
        enigmaUSettingsAndConfig {
          uSecureAPIKey
          enigmaUID
          demoEnigmaU
          enigmaUWeeklyReportRecipients {
            email
            monday
            wednesday
            friday
          }
          remindersSent {
            learnerId
            timestamp
          }
        }
      }
    }`;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.query({
        query: gql`
          ${getEnigmaUWeeklyReportRecipients}
        `,
        variables: {
          accountName,
        },
        fetchPolicy: 'no-cache',
      });
      console.log(transactionComplete);
      return transactionComplete.data.getEnigmaUWeeklyReportRecipients;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  getDiscoverSavedQueries(
    accountName: string
  ): Observable<DiscoverSavedQuery[] | null> {
    return from(this.getDiscoverSavedQueriesHandler(accountName));
  }

  async getDiscoverSavedQueriesHandler(
    accountName: string
  ): Promise<DiscoverSavedQuery[] | null> {
    const getDiscoverSavedQueries = `query GetDiscoverSavedQueries($accountname: String!) {
      getEnigmaGlassAccounts(accountName: $accountname) {
        accountName
        discoverSavedQueries {
          name
          conditionals {
            operator
            term
          }
        }
      }
    }`;
    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.query({
        query: gql`
          ${getDiscoverSavedQueries}
        `,
        variables: {
          accountname: accountName,
        },
        fetchPolicy: 'no-cache',
      });
      return transactionComplete.data.getEnigmaGlassAccounts
        .discoverSavedQueries;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  updateDiscoverSavedQueries(
    accountName: string,
    discoverSavedQueries: DiscoverSavedQuery[]
  ): Observable<DiscoverSavedQuery[]> {
    return from(
      this.updateDiscoverSavedQueriesHandler(accountName, discoverSavedQueries)
    );
  }

  async updateDiscoverSavedQueriesHandler(
    accountName: string,
    discoverSavedQueries: DiscoverSavedQuery[]
  ): Promise<DiscoverSavedQuery[]> {
    const updateDiscoverSavedQueriesMutation = gql`
      mutation UpdateDiscoverSavedQueries(
        $accountName: String!
        $discoverSavedQueries: [DiscoverSavedQueryInput]!
      ) {
        updateDiscoverSavedQueries(
          accountName: $accountName
          discoverSavedQueries: $discoverSavedQueries
        ) {
          name
          conditionals {
            operator
            term
          }
        }
      }
    `;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.mutate({
        mutation: updateDiscoverSavedQueriesMutation,
        variables: {
          accountName,
          discoverSavedQueries,
        },
        fetchPolicy: 'no-cache',
      });
      return transactionComplete.data.updateDiscoverSavedQueriesMutation;
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  updateEnigmaUWeeklyReportRecipientsQueries(
    accountName: string,
    enigmaUSettingsAndConfig: EnigmaUSettingsAndConfig
  ): Observable<Account> {
    return from(
      this.updateEnigmaUWeeklyReportRecipientsQueriesHandler(
        accountName,
        enigmaUSettingsAndConfig
      )
    );
  }

  async updateEnigmaUWeeklyReportRecipientsQueriesHandler(
    accountName: string,
    enigmaUSettingsAndConfig: EnigmaUSettingsAndConfig
  ): Promise<Account> {
    const updateEnigmaUWeeklyReportRecipientsMutation = gql`
      mutation UpdateEnigmaUWeeklyReportRecipients(
        $accountName: String!
        $enigmaUSettingsAndConfig: EnigmaUSettingsAndConfigInput!
      ) {
        updateEnigmaUWeeklyReportRecipients(
          accountName: $accountName
          enigmaUSettingsAndConfig: $enigmaUSettingsAndConfig
        ) {
          accountName
          enigmaUSettingsAndConfig {
            uSecureAPIKey
            enigmaUID
            demoEnigmaU
            enigmaUWeeklyReportRecipients {
              email
              monday
              wednesday
              friday
            }
            remindersSent {
              learnerId
              timestamp
            }
          }
        }
      }
    `;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.mutate({
        mutation: updateEnigmaUWeeklyReportRecipientsMutation,
        variables: {
          accountName,
          enigmaUSettingsAndConfig,
        },
        fetchPolicy: 'no-cache',
      });
      return transactionComplete.data.updateEnigmaUWeeklyReportRecipients;
    } catch (err) {
      console.log(err);
      return {} as Account;
    }
  }

  updateEnigmaURemindersSent(
    accountName: string,
    enigmaUSettingsAndConfig: EnigmaUSettingsAndConfig
  ): Observable<Account> {
    return from(
      this.updateEnigmaURemindersSentHandler(
        accountName,
        enigmaUSettingsAndConfig
      )
    );
  }

  async updateEnigmaURemindersSentHandler(
    accountName: string,
    enigmaUSettingsAndConfig: EnigmaUSettingsAndConfig
  ): Promise<Account> {
    const updateEnigmaUWeeklyReportRecipientsMutation = gql`
      mutation UpdateEnigmaUWeeklyReportRecipients(
        $accountName: String!
        $enigmaUSettingsAndConfig: EnigmaUSettingsAndConfigInput!
      ) {
        updateEnigmaUWeeklyReportRecipients(
          accountName: $accountName
          enigmaUSettingsAndConfig: $enigmaUSettingsAndConfig
        ) {
          accountName
          enigmaUSettingsAndConfig {
            uSecureAPIKey
            enigmaUID
            demoEnigmaU
            remindersSent {
              learnerId
              timestamp
            }
            enigmaUWeeklyReportRecipients {
              email
              monday
              wednesday
              friday
            }
          }
        }
      }
    `;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.mutate({
        mutation: updateEnigmaUWeeklyReportRecipientsMutation,
        variables: {
          accountName,
          enigmaUSettingsAndConfig,
        },
        fetchPolicy: 'no-cache',
      });
      return transactionComplete.data.updateEnigmaUWeeklyReportRecipients;
    } catch (err) {
      console.log(err);
      return {} as Account;
    }
  }

  updateLogAlertRules(
    accountName: string,
    logAlertRules: LogAlertRule[]
  ): Observable<LogAlertRule[]> {
    return from(this.updateLogAlertRulesHandler(accountName, logAlertRules));
  }

  async updateLogAlertRulesHandler(
    accountName: string,
    logAlertRules: LogAlertRule[]
  ): Promise<LogAlertRule[]> {
    const updateLogAlertRulesMutation = gql`
      mutation UpdateLogAlertRules(
        $accountName: String!
        $logAlertRules: [LogAlertRuleInput]!
      ) {
        updateLogAlertRules(
          accountName: $accountName
          logAlertRules: $logAlertRules
        ) {
          description
          name
          ruleId
          source
          filter {
            filterType
            key
            value
            allow
          }
          captureKeys
        }
      }
    `;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.mutate({
        mutation: updateLogAlertRulesMutation,
        variables: {
          accountName: accountName,
          logAlertRules: logAlertRules,
        },
        fetchPolicy: 'no-cache',
      });
      return transactionComplete.data.updateLogAlertRules;
    } catch (err) {
      console.log(err);
      return [];
    }
  }
}
