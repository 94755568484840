<div #wrapper class="eng-line-chart__wrapper">

    <ngx-charts-line-chart
    *ngIf="data && data.length > 0"
    [view]="view"
    [scheme]="scheme"
    [schemeType]="schemeType"
    [results]="data"
    [animations]="animations"
    [legend]="showLegend"
    [legendTitle]="legendTitle"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [autoScale]="autoScale"
    [timeline]="timeline"
    [showGridLines]="showGridLines"
    [curve]="curve"
    [rangeFillOpacity]="rangeFillOpacity"
    [roundDomains]="roundDomains"
    [tooltipDisabled]="tooltipDisabled"
    >
  </ngx-charts-line-chart>

  <eng-no-data *ngIf="!data || data.length === 0"></eng-no-data>
</div>

