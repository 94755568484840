import { Injectable, OnDestroy } from '@angular/core';
import { Observable, throwError, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../state/app.state';
import { getRuntimeAppConfig } from '../../state/app.selectors';
import { AppConfig, DomainModel, RUNTIME_CONFIG_API_URL } from 'src/app/config/app.config';
import { BugModel } from 'src/app/models/bug.model';
import { AuthService } from '../ue/auth.service';
import { User as AuthUser } from 'oidc-client-ts';

@Injectable()
export class GatewayService implements OnDestroy {
  gatewayAPI!: string;
  configSub: Subscription;
  user: AuthUser | null;

  constructor(
    private _appStore: Store<AppState>, 
    private _http: HttpClient,
    private _authService: AuthService
  ) {
    this.initializeClient();
  }

  async initializeClient() {
    this.user = await this._authService.getUser();
    if(this.user) {
      this.configSub = this._appStore
        .pipe(select(getRuntimeAppConfig))
        .subscribe(config => {
          if (config) {
            this.gatewayAPI = config.apiGatewayUrl;
          }
        });
    }
  }

  ngOnDestroy() {
    this.configSub.unsubscribe();
  }


  reportBug(bug: BugModel): Observable<BugModel> {
    return this._http
      .post<any>(
        `${this.gatewayAPI}/reportbug`,
        JSON.stringify(bug),
        this._httpOptionsNoAuth
      )
      .pipe(
        map((apiResponse: any) => {
          if (apiResponse && apiResponse.status && apiResponse.status === 200) {
            return bug;
          } else {
            // @Log this
            throw new Error(
              `bad status code of ${apiResponse.statusCode} from report bug`
            );
          }
        }),
        catchError(this.handleError)
      );
  }

  // runtimeConfig(potentialSubdomain?: string): Observable<AppConfig> {
  //   let host = window.location.host;
  //   // if [www, com] is part then remain to not be confused as the subdomain
  //   host = host.replace('www.', '');
  //   host = host.replace('.com', '');
  //   const domain: DomainModel = {
  //     domain: `${window.location.hostname}:${location.port}`,
  //     subdomain: potentialSubdomain
  //       ? potentialSubdomain
  //       : host.split('.')[1]
  //         ? host.split('.')[0]
  //         : ''
  //   };

  //   return this._http
  //     .post<any>(
  //       RUNTIME_CONFIG_API_URL,
  //       JSON.stringify(domain),
  //       this._httpOptionsNoAuth
  //     )
  //     .pipe(
  //       map((apiResponse: any) => {
  //         if (
  //           apiResponse &&
  //           apiResponse.status &&
  //           apiResponse.status === 200 &&
  //           apiResponse.body &&
  //           apiResponse.body.resp
  //         ) {
  //           return apiResponse.body.resp;
  //         } else {
  //           // @Log this
  //           throw new Error(
  //             `bad status code of ${apiResponse.statusCode} from runtime config`
  //           );
  //         }
  //       }),
  //       catchError(this.handleError)
  //     );
  // }

  private get _httpOptions(): { headers: HttpHeaders; observe: any } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        authorization: `${this.user?.id_token}`
      }),
      observe: 'response'
    };
  }

  private get _httpOptionsNoAuth(): { headers: HttpHeaders; observe: any } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      observe: 'response'
    };
  }

  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      //console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //   `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      // );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
