import { AppState, initialAppState } from './app.state';
import { AppActions, AppActionEnums } from 'src/app/state/app.actions';
import { DomainStandardEnv } from '../config/app.config';

export function reducer(
  state: AppState = initialAppState,
  action: AppActions
): AppState {
  switch (action.type) {
  case AppActionEnums.ToggleDarkTheme:
    return {
      ...state,
      darkTheme: action.payload,
    };
  case AppActionEnums.Username:
    return {
      ...state,
      loading: true,
    };
  case AppActionEnums.UsernameNext:
    return {
      ...state,
      loading: false,
      checkUsername: action.next,
    };
  case AppActionEnums.Login:
    return {
      ...state,
      loading: true,
    };
  case AppActionEnums.LoginSuccess:
    return {
      ...state,
      isAuthenticated: true,
      errorMessage: null,
      loading: false,
    };
  case AppActionEnums.ActiveAccount:
    return {
      ...state,
      activeAccount: action.account,
    };
  case AppActionEnums.AllAccounts:
    return {
      ...state,
      allAccounts: action.accounts,
    };
  case AppActionEnums.UserFeatures:
    return {
      ...state,
      features: action.features,
    };
  case AppActionEnums.DataSourcesSuccess:
    return {
      ...state,
      dataSources: action.payload,
    };
  case AppActionEnums.LoginFailure:
    return {
      ...state,
      errorMessage: 'Incorrect username and/or password.',
      loading: false,
    };
  case AppActionEnums.LoadRuntimeAppConfigSuccess:
    const identifier = action.config.identifier;
    const subdomain =
      identifier !== DomainStandardEnv.Dev &&
      identifier !== DomainStandardEnv.Prod
        ? identifier
        : '';
    return {
      ...state,
      runtimeConfig: action.config,
      subdomain: subdomain,
    };
  case AppActionEnums.LoginLoadRuntimeAppConfigSuccess:
    return {
      ...state,
      runtimeConfig: action.config,
    };
  case AppActionEnums.RefreshCredsSuccess:
    return {
      ...state,
      refreshedCreds: true,
    };
  case AppActionEnums.RefreshCredsReset:
    return {
      ...state,
      refreshedCreds: false,
    };
  case AppActionEnums.RefreshCredsFailure:
    return {
      ...state,
      errorMessage: 'Refreshing the creds failed',
      isAuthenticated: false,
      refreshedCreds: false,
    };
  case AppActionEnums.LogoutSuccess:
    const tempConfig = state.runtimeConfig;

    return {
      ...initialAppState, // TODO this will reset everything need to move auth out of appState
      ...{
        runtimeConfig: tempConfig,
      },
    };
  case AppActionEnums.UEUserSuccess:
    return {
      ...state,
      isAuthenticated: true,
      sub: action.payload.sub as string,
    };
  case AppActionEnums.CurrentDynamoUserSuccess:
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload,
    };
  case AppActionEnums.LoadWidgetRegistry:
    return {
      ...state,
      widgetRegistry: action.payload,
    };
  case AppActionEnums.ReportBug:
    return {
      ...state,
      bugSending: true,
    };
  case AppActionEnums.ReportBugSuccess:
    return {
      ...state,
      bugSending: false,
    };
  case AppActionEnums.ReportBugFailure:
    return {
      ...state,
      bugSending: false,
    };
  case AppActionEnums.ForgotPassword:
    return {
      ...state,
      showForgotPasswordDialog: false,
    };
  case AppActionEnums.ForgotPasswordSuccess:
    return {
      ...state,
      showForgotPasswordDialog: true,
    };
  case AppActionEnums.ForgotPasswordFailure:
    return {
      ...state,
      showForgotPasswordDialog: false,
    };
  case AppActionEnums.DiscoverSavedQueries:
    return {
      ...state,
      loadingDiscoverSavedQueries: true,
    };
  case AppActionEnums.DiscoverSavedQueriesSuccess:
    return {
      ...state,
      discoverSavedQueries: action.payload,
      loadingDiscoverSavedQueries: false,
    };
  case AppActionEnums.DiscoverSavedQueriesFailure:
    return {
      ...state,
      discoverSavedQueries: [],
      loadingDiscoverSavedQueries: false,
    };
  case AppActionEnums.UpdateDiscoverSavedQueries:
    return {
      ...state,
      loadingDiscoverSavedQueries: true,
    };
  case AppActionEnums.UpdateDiscoverSavedQueriesSuccess:
    return {
      ...state,
      discoverSavedQueries: action.payload,
      loadingDiscoverSavedQueries: false,
    };
  case AppActionEnums.UpdateDiscoverSavedQueriesFailure:
    return {
      ...state,
      discoverSavedQueries: [],
      loadingDiscoverSavedQueries: false,
    };
  case AppActionEnums.UpdateEnigmaUWeeklyReportRecipients:
    return {
      ...state,
      loading: true,
    };
  case AppActionEnums.UpdateEnigmaUWeeklyReportRecipientsSuccess:
    const allAccounts = state.allAccounts;
    const currentAccount = allAccounts.find(
      (x) => x.accountName === action.payload.accountName
    );
    if (currentAccount && currentAccount.enigmaUSettingsAndConfig)
      currentAccount.enigmaUSettingsAndConfig =
        action.payload.enigmaUSettingsAndConfig;
    return {
      ...state,
      allAccounts,
      loading: false,
    };
  case AppActionEnums.UpdateEnigmaUWeeklyReportRecipientsFailure:
    return {
      ...state,
      loading: false,
    };
  case AppActionEnums.UpdateEnigmaUWeeklyReportRecipients:
    return {
      ...state,
      loading: true,
    };
  case AppActionEnums.UpdateEnigmaUWeeklyReportRecipientsSuccess:
    const accounts = state.allAccounts;
    const activeAccount = accounts.find(
      (x) => x.accountName === action.payload.accountName
    );
    if (activeAccount && activeAccount.enigmaUSettingsAndConfig)
      activeAccount.enigmaUSettingsAndConfig =
        action.payload.enigmaUSettingsAndConfig;
    return {
      ...state,
      allAccounts: [...accounts],
      loading: false,
    };
  case AppActionEnums.UpdateEnigmaUWeeklyReportRecipientsFailure:
    return {
      ...state,
      loading: false,
    };
  case AppActionEnums.UpdateEnigmaURemindersSent:
    return {
      ...state,
      loading: true,
    };
  case AppActionEnums.UpdateEnigmaURemindersSentSuccess:
    const enigmaUAccounts = state.allAccounts;
    const enigmaUActiveAccount = enigmaUAccounts.find(
      (x) => x.accountName === action.payload.accountName
    );
    if (enigmaUActiveAccount && enigmaUActiveAccount.enigmaUSettingsAndConfig)
      enigmaUActiveAccount.enigmaUSettingsAndConfig =
        action.payload.enigmaUSettingsAndConfig;
    return {
      ...state,
      allAccounts: [...enigmaUAccounts],
      loading: false,
    };
  case AppActionEnums.UpdateEnigmaURemindersSentFailure:
    return {
      ...state,
      loading: false,
    };
  default:
    return state;
  }
}
