import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvancedPieChartComponent } from './advanced-pie-chart.component';
import { RichUiModule } from '@richUi';
import { NoDataModule } from '../no-data/no-data.module';

@NgModule({
  declarations: [AdvancedPieChartComponent],
  imports: [
    CommonModule,
    RichUiModule,
    NoDataModule,
  ],
  exports: [AdvancedPieChartComponent]
})
export class AdvancedPieChartModule { }
