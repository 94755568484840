<mat-card engTheme class="dashboard-tile__wrapper"
[ngClass]="{
  'dashboard-tile__wrapper--small': isSmall && !fitContainer,
  'dashboard-tile__wrapper--large': isLarge && !fitContainer,
  'dashboard-tile__wrapper--medium': isMedium && !fitContainer,
  'dashboard-tile__wrapper--small-grid': isGrid && !fitContainer,
  'dashboard-tile-height__wrapper--small': smallHeight,
  'row-span-2x': rowSpan === 2
}">
  <mat-card-title>
    <h3 *ngIf="title" class="dashboard-tile__title"
      [ngClass]="{
        'dashboard-tile__title--fit': fitContainer,
        'dashboard-tile__title--small': isSmall && fitContainer,
        'dashboard-tile__title--large': isLarge && fitContainer,
        'dashboard-tile__title--medium': isMedium && fitContainer,
        'dashboard-tile-height__title--small': smallHeight
      }">
        {{ title }} <span *ngIf="subtitle">({{ subtitle }})</span>
    </h3>
    <ng-content *ngIf="!title" class="dashboard-tile__title" select="[header]"></ng-content>
    <button
      mat-icon-button
      color="accent"
      *ngIf="hasMenu"
      matTooltip="widget options"
      class="timeframe-menu__icon"
      [matMenuTriggerFor]="optionMenu"
    >
      <mat-icon aria-label="This icon menu button is for filtering timeframe"
        >more_vert</mat-icon
      >
    </button>
  </mat-card-title>
  <mat-card-content>
    <div class="dashboard-tile__content" [ngClass]="{'dashboard-tile__content--fit': fitContainer}">
      <ng-content></ng-content>
    </div>
  </mat-card-content>
</mat-card>

<mat-menu #optionMenu="matMenu">
  <button *ngIf="timeFrameSelection" mat-menu-item [matMenuTriggerFor]="timeframeMenu">
    Choose Timeframes
  </button>
  <button *ngIf="discoverable" mat-menu-item (click)="emitDiscover()">{{discoverButtonText}}</button>
</mat-menu>

<mat-menu #timeframeMenu="matMenu">
  <button
    mat-menu-item
    *ngFor="let item of timeframeMenuItems"
    (click)="emitTimeframe(item.value)"
    [ngClass]="{ 'timeframe-menu-item--active': item.selected }"
  >
    {{ item.value }}
  </button>
</mat-menu>
