import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { PasswordDialogComponent } from 'src/app/shared/password-dialog/password-dialog.component';
import { AreYouSureDialogComponent, AreYouSureDialogData } from 'src/app/shared/are-you-sure-dialog/are-you-sure-dialog.component';
import { FoundABugDialogComponent } from 'src/app/shared/dialogs/found-a-bug';
import { ConfirmForgotPasswordDialogComponent, ConfirmForgotPasswordDialogData } from '@shared';

@Injectable()
export class DialogService {

  constructor(
    private _dialog: MatDialog
  ) { }

  foundABugDialog(): Observable<string> {
    return new Observable(observer => {
      const dialogRef = this._dialog.open(FoundABugDialogComponent, {
        //maxWidth: '96vw'
        width: '30%',
        height: '55%',
      });

      dialogRef.afterClosed().subscribe(() => {
        observer.complete();
      });
    });
  }

  passwordDialog(firstName: string): Observable<string> {
    return new Observable(observer => {
      const dialogRef = this._dialog.open(PasswordDialogComponent, {
        data: {
          firstName: firstName,
          password: ''
        }
      });

      dialogRef.afterClosed().subscribe(data => {
        observer.next(data.password);
        observer.complete();
      });
    });
  }

  areYouSureDialog(message: string): Observable<boolean> {
    return new Observable(observer => {
      const dialogRef = this._dialog.open(AreYouSureDialogComponent, {
        data: <AreYouSureDialogData>{
          message: message,
          confirm: false
        }
      });

      dialogRef.afterClosed().subscribe((data: AreYouSureDialogData) => {
        observer.next(data.confirm);
        observer.complete();
      });
    });
  }

  confirmForgotPasswordDialog(username: string): Observable<boolean> {
    return new Observable(observer => {
      const dialogRef = this._dialog.open(ConfirmForgotPasswordDialogComponent, {
        data: <ConfirmForgotPasswordDialogData>{
          username: username
        }
      });

      dialogRef.afterClosed().subscribe(() => {
        observer.complete();
      });
    });
  }
}
