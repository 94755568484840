import { Component, ViewChild, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../app/state/app.state';
import * as AppActions from './state/app.actions';
import * as fromAppSelectors from './state/app.selectors';
import { Observable, Subject, Subscription } from 'rxjs';
import { UserRole, DialogService } from './services';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { takeWhile, take } from 'rxjs/operators';
import { initialWidgetRegistry } from './config/widget.registry';
import { LoadRuntimeAppConfigAction } from './state/app.actions';
import { getRuntimeAppConfig, getDarkThemeState } from './state/app.selectors';
import { DomSanitizer } from '@angular/platform-browser';
import { UserTheme } from './models/user.model';
import { IconsName } from './models/enums/icons-name.enum';
import { AuthService } from './services/ue/auth.service';
import { User } from 'oidc-client-ts';
import { Router } from '@angular/router';
import { AdminService } from './services/admin/admin.service';
import { UEAPIService } from './services/ue/api.service';
import { environment } from 'src/environments/environment';
import { SpinnerService } from './services/ue/spinner.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'eng-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav', { static: true }) sidenav!: MatSidenav;

  open: boolean;

  componentActive = true;
  isLoading: Subject<boolean> = this.spinner.loader;

  isAuthenticated!: Observable<boolean>;
  userRole!: UserRole;

  animationStartSub!: Subscription;
  animationEndSub!: Subscription;
  authenticationSub!: Subscription;
  sidenavSubscription!: Subscription;
  sessionLengthAlertSubscription!: Subscription;

  SESSION_TIMEOUT_KEY = 'sessionTimeoutKey';

  currentUser: boolean;
  currentUserSub: Subscription;
  private readonly subscription: Subscription[] = [];
  iconsName = IconsName;
  // TODO: Read this value from config
  useFilledIcons = true;

  isDarkTheme: boolean;

  user: User | null;
  public isLoggedIn: Observable<boolean>;
  displayTopBar: boolean = false;

  constructor(
    private store: Store<AppState>,
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
    private _dialogService: DialogService,
    private readonly _authService: AuthService,
    public router: Router,
    public adminService: AdminService,
    private authService: AuthService,
    private apiService: UEAPIService,
    private spinner: SpinnerService,
  ) {
    this.loadIcons();
  }

  ngOnInit() {
     this.loadIcons();

    this.isLoggedIn = this.adminService.isLoggedIn();
    this.open = localStorage.getItem('userDetails') ? true : false;
    this.currentUser = localStorage.getItem('userDetails') ? false : true;

    this.activeAccountListeners();
    this._authService.getUser().then((user) => {
      if (user) {
        this.user = user;
        this.getRuntimeConfig();
        this.loadUser();
      }
    });

    const getRuntimeAppSubscription = this.store
      .pipe(
        select(getRuntimeAppConfig)
      )
      .subscribe(config => {
        if (config && this.user) {
          this.store.dispatch(new AppActions.UEUserAction());
        }
      });
    this.subscription.push(getRuntimeAppSubscription);
  }

  activeAccountListeners() {
    const accountSubscription = this.store.pipe(
      takeWhile(() => this.componentActive),
      select(fromAppSelectors.getActiveAccount)
    ).subscribe(account => {
      if (account) {
        this.store.dispatch(new AppActions.DataSourcesAction(account));
      }
    });

    this.subscription.push(accountSubscription);

    const userSubscription = this.store.pipe(
      takeWhile(() => this.componentActive),
      select(fromAppSelectors.getUser)
    ).subscribe(user => {
      if (user && user.accounts) {
        const accountFromStorage = localStorage.getItem('activeAccount');
        const defaultAccount: string = user.accounts[0];
        const account = accountFromStorage ? accountFromStorage : defaultAccount;
        this.store.dispatch(new AppActions.ActiveAccountAction(account));
      }
    });
    this.subscription.push(userSubscription);
  }

  loadUser() {
    const currentUserSubscription = this.store.pipe(
      takeWhile(() => this.componentActive),
      select(fromAppSelectors.getUser)
    ).subscribe(currentUser => {
      if (currentUser) {
        this.currentUser = true;
        this.store.dispatch(new AppActions.LoadWidgetRegistryAction(initialWidgetRegistry));
        this.open = (sessionStorage.length != 0) ? true : false;
        this.store.dispatch(new AppActions.ToggleDarkThemeAction(currentUser.theme === UserTheme.DARK));
        currentUser.theme === UserTheme.DARK ? this.setDarkTheme() : this.setLightTheme();
      } else {
        this.currentUser = false;
        this.open = (sessionStorage.length != 0) ? true : false;
      }
      this.loadTheme();
    });
    this.subscription.push(currentUserSubscription);
  }

  loadTheme() {
    const darkThemeSub = this.store.pipe(select(getDarkThemeState)).subscribe(darkTheme => {
      if (darkTheme) {
        this.setDarkTheme();
      } else {
        this.setLightTheme();
      }
    });
    this.subscription.push(darkThemeSub);
  }

  setDarkTheme() {
    this.isDarkTheme = true;
    const body = document.getElementsByTagName('body')[0];
    if (body.classList.contains('eng-theme--light')) {
      body.classList.remove('eng-theme--light');
    }
    body.classList.add('eng-theme--dark');
  }


  setLightTheme() {
    this.isDarkTheme = false;
    const body = document.getElementsByTagName('body')[0];
    if (body.classList.contains('eng-theme--dark')) {
      body.classList.remove('eng-theme--dark');
    }
    document.body.classList.add('eng-theme--light');
  }

  getRuntimeConfig() {
    if (this.user) {
      this.store.dispatch(new LoadRuntimeAppConfigAction());
    }
  }

  ngOnDestroy() {
    this.subscription.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
    this.animationStartSub.unsubscribe();
    this.animationEndSub.unsubscribe();
    this.sidenavSubscription.unsubscribe();
    this.authenticationSub.unsubscribe();
    this.sessionLengthAlertSubscription.unsubscribe();
    this.componentActive = false;
  }

  loadIcons() {
    Object.values(IconsName).forEach(iconName =>
      this._matIconRegistry.addSvgIcon(
        iconName,
        this._domSanitizer.bypassSecurityTrustResourceUrl(
          `../assets/icons/${iconName}${this.useFilledIcons ? '-filled' : ''}.svg`
        )
      )
    );
  }

  foundABug() {
    const dialogServiceSubscription = this._dialogService
      .foundABugDialog()
      .pipe(take(1))
      .subscribe();
    this.subscription.push(dialogServiceSubscription);
  }
}
