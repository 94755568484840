import { TimeframeType } from './timeframe-type';

export interface Account {
  accountName: string;
  associatedCompany?: string;
  createDate?: string;
  creatorUN?: string;
  modifyDate?: string;
  ownerUN?: string;
  sources: DataSource[];
  stackManager?: string;
  summary?: string;
  features?: UserFeatures;
  discoverSavedQueries: DiscoverSavedQuery[];
  enigmaUSettingsAndConfig: EnigmaUSettingsAndConfig;
}

export interface EnigmaUSettingsAndConfig {
  demoEnigmaU: boolean;
  uSecureAPIKey: string;
  enigmaUID: string;
  enigmaUWeeklyReportRecipients: EnigmaUWeeklyReportRecipient[];
  remindersSent: ReminderSent[];
}

export interface EnigmaUWeeklyReportRecipient {
  email: string;
  monday: boolean;
  wednesday: boolean;
  friday: boolean;
}

export interface ReminderSent {
  learnerId: String;
  timestamp: Date;
}

export interface DataSource {
  type: SourceApiType;
  apiKey: string;
  identifier: string;
  tenant: string;
  overrideDefaultTimeframe: TimeframeType;
}

export interface DataSourceDisplay extends DataSource {
  displayValue: string;
}

export enum SourceApiType {
  CiscoAmp = 'CiscoAmp',
  CiscoUmbrella = 'CiscoUmbrella',
  WindowLogs = 'WindowLogs',
  CloudfrontLogs = 'CloudfrontLogs',
  LinuxLogs = 'LinuxLogs',
  FileBeatLinuxLogs = 'FileBeatLinuxLogs',
  PacketBeat = 'PacketBeat',
  DNS = 'DNS Log',
  CONN = 'CONN Log',
}

export interface UserFeatures {
  mainMenu?: MainMenu;
  discover?: Discover;
}

export interface MainMenu {
  showNetworkActivity: boolean;
  showLogonActivity: boolean;
  showDiscover: boolean;
  showAlerts: boolean;
  showReports: boolean;
  showDataManagement: boolean;
  showEnigmaApps: boolean;
  showLogInsight: boolean;
  showUserBehaviorMonitoring: boolean;
  showIkeTool: boolean;
  showSecurityEvents: boolean;
  showNetworkActivityDashboard: boolean;
  showPowershell: boolean;
  showAuditBeat: boolean;
  showWatcherAlerts: boolean;
  showFileBeat: boolean;
  showPacketBeat: boolean;
  showRules: boolean;
  showEnigmas: boolean;
  showUSecure: boolean;
  showEnigmasDB: boolean;
  showEnigmaU: boolean;
}

export interface Discover {
  tableExports: boolean;
}

export interface DiscoverSavedQuery {
  id: string,
  query: string;
  queryName: string;
}

export interface DiscoverQueryConditional {
  operator: DiscoverOperatorEnum;
  term: string;
}

export enum DiscoverOperatorEnum {
  AND = 'AND',
  OR = 'OR',
}
