import { Injectable } from '@angular/core';
import { GeoMapLayer } from './geo-map-layer.model';
import { BingGeoMapLayer } from './bing-geo-map-layer.model';

@Injectable({
  // we declare that this service should be created
  // by the root application injector.
  providedIn: 'root'
})
export class MapConfigService {
  getGeoMapLayers(): GeoMapLayer[] {
    return [
      {
        name: 'positron',
        url: 'http://localhost:8080/styles/positron/{z}/{x}/{y}.png'
      },
      {
        name: 'dark-matter',
        url: 'http://localhost:8080/styles/dark-matter/{z}/{x}/{y}.png'
      },
      {
        name: 'bright',
        url: 'http://localhost:8080/styles/osm-bright/{z}/{x}/{y}.png'
      },
      {
        name: 'basic',
        url: 'http://localhost:8080/styles/klokantech-basic/{z}/{x}/{y}.png'
      }
    ];
  }

  getBingGeoMapLayers(): BingGeoMapLayer[] {
    return [
      {
        name: 'Aerial',
        value: 'Aerial'
      },
      {
        name: 'Aerial with labels',
        value: 'AerialWithLabels'
      },
      {
        name: 'Road (static)',
        value: 'Road'
      },
      {
        name: 'Road (dynamic)',
        value: 'RoadOnDemand'
      }
    ];
  }

  getBingDefaultLayer(): BingGeoMapLayer {
    return  {
      name: 'Road (static)',
      value: 'Road'
    };
  }

  getBingDarkLayer(): BingGeoMapLayer {
    return  {
      name: 'Aerial with labels',
      value: 'AerialWithLabels'
    };
  }

  getDefaultLayer(): GeoMapLayer {
    return {
      name: 'bright',
      url: 'http://localhost:8080/styles/osm-bright/{z}/{x}/{y}.png'
    };
  }

  getDarkLayer(): GeoMapLayer {
    return {
      name: 'dark-matter',
      url: 'http://localhost:8080/styles/dark-matter/{z}/{x}/{y}.png'
    };
  }
}
