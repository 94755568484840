import { Pipe, PipeTransform } from '@angular/core';
import {
  CourseResult,
  Grade,
  gradeToEnum,
  gradeToNumber,
  Learner,
  PolicyResult,
  riskScoreLevelToNumber,
  ScoreCard,
  ScoreCardType,
} from 'src/app/views/enigma-u/models/enigma-u.model';

@Pipe({
  name: 'learnerCardsSort',
})
export class LearnerCardsSortPipe implements PipeTransform {
  transform(learners: Learner[], filterBy: ScoreCard | null): Learner[] {
    if (filterBy) {
      switch (filterBy.scordCardType) {
      case ScoreCardType.RiskScore:
        return learners.sort(
          (a, b) =>
            riskScoreLevelToNumber(b.riskScoreLevel) -
            riskScoreLevelToNumber(a.riskScoreLevel)
        );
      case ScoreCardType.CoursesComplete:
        return learners.sort(
          (a, b) =>
            this.getGradeAvg(a.courseResults) -
            this.getGradeAvg(b.courseResults)
        );
      case ScoreCardType.CompanyAvgGrade:
        return learners.sort((a, b) =>
          Grade[gradeToEnum(this.getGradeAvg(b.courseResults))]
            .toString()
            .localeCompare(
              Grade[gradeToEnum(this.getGradeAvg(a.courseResults))].toString()
            )
        );
      case ScoreCardType.PoliciesComplete:
        return learners.sort(
          (a, b) =>
            this.getPoliciesAvg(b.policyResults) -
            this.getPoliciesAvg(a.policyResults)
        );
      }
    }
    return learners.sort((a, b) => a.name.localeCompare(b.name));
  }

  private getGradeAvg(courseResults: CourseResult[]): number {
    if (courseResults.length === 0) return 0;
    const total = courseResults.length;
    let totalAvg = 0;
    courseResults.forEach((result: any) => {
      totalAvg += gradeToNumber(result.grade);
    });
    const avg = ((totalAvg / total) * 100)?.toFixed(1);
    return !isNaN(parseFloat(avg)) ? parseFloat(avg) : 0;
  }

  private getPoliciesAvg(policyResults: PolicyResult[]): number {
    if (policyResults.length === 0) return 0;
    const total = policyResults.length;
    const completedTotal =
      policyResults.filter((x: PolicyResult) => x.signed)?.length ?? 0;
    const avg = ((completedTotal / total) * 100)?.toFixed(2);
    return !isNaN(parseFloat(avg)) ? parseFloat(avg) : 0;
  }
}
