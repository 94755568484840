<div #wrapper class="map__wrapper">
    <eng-map geoLayer="positron" [rowSpan]="rowSpan">
      <eng-baseball-card #card>
          <div>
            <span>Host Name:</span>
            {{ nodeDetail && nodeDetail.hostName }}
          </div>
          <div>
            <span>Destination Ip:</span>
            {{ nodeDetail && nodeDetail.ip }}
          </div>
          <div>
            <span>Destination Domain:</span>
            {{ nodeDetail && nodeDetail.domain }}
          </div>
          <div>
            <span>Destination ISO Code:</span>
            {{ nodeDetail && nodeDetail.isoCode }}
          </div>
          <div>
            <span>Latitude:</span>
            {{ nodeDetail ? nodeDetail.latitude : null }}
          </div>
          <div>
            <span>Longitude:</span>
            {{ nodeDetail ? nodeDetail.longitude : null }}
          </div>
      </eng-baseball-card>
    </eng-map>
</div>