import { Injectable, OnDestroy } from '@angular/core';
import { Observable, throwError, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../state/app.state';
import { getRuntimeAppConfig } from '../../state/app.selectors';
// import {Md5} from 'ts-md5/dist/md5';
import { PhotoModel } from 'src/app/models/photo.model';


@Injectable()
export class PhotoService implements OnDestroy {
  photosAPI!: string;
  configSub: Subscription;

  constructor(private _appStore: Store<AppState>, private _http: HttpClient) {
    this.configSub = this._appStore
      .pipe(select(getRuntimeAppConfig))
      .subscribe(config => {
        if (config) {
          this.photosAPI = config.apiGatewayUrl;
        }
      });
  }

  ngOnDestroy() {
    this.configSub.unsubscribe();
  }

  makePhotoHash(toBeHashed: string): string {
    console.log(toBeHashed);
    const hash: string =''; //Md5.hashStr(toBeHashed) as string;
    return hash;
  }

  savePhoto(photoPayload: PhotoModel): Observable<PhotoModel> {
    return this._http
      .post<any>(
        `${this.photosAPI}/upload-photo`,
        JSON.stringify(photoPayload),
        this._httpOptions
      )
      .pipe(
        map((apiResponse: any) => {
          if (apiResponse && apiResponse.body && apiResponse.body.hashOfPhoto) {
            const photoResp: PhotoModel = {
              hashOfPhoto: apiResponse.body.hashOfPhoto
            };
            return photoResp;
          } else {
          // @Log this
            throw new Error('hashOfPhoto missing in save photo response');
          }

        }),
        catchError(this.handleError)
      );
  }

  saveBugPhoto(photoPayload: PhotoModel): Observable<PhotoModel> {
    return this._http
      .post<any>(
        `${this.photosAPI}/upload-bug-photo`,
        JSON.stringify(photoPayload),
        this._httpOptionsNoAuth
      )
      .pipe(
        map((apiResponse: any) => {
          if (apiResponse && apiResponse.body && apiResponse.body.hashOfPhoto) {
            const photoResp: PhotoModel = {
              hashOfPhoto: apiResponse.body.hashOfPhoto
            };
            return photoResp;
          } else {
          // @Log this
            throw new Error('hashOfPhoto missing in save photo response');
          }

        }),
        catchError(this.handleError)
      );
  }

  private get _httpOptionsNoAuth(): { headers: HttpHeaders; observe: any } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response'
    };
  }

  private get _httpOptions(): { headers: HttpHeaders; observe: any } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        authorization: `${localStorage.getItem('platnoIdToken')}`
      }),
      observe: 'response'
    };
  }

  private handleError(error: any) {
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
