import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[engFullscreen]'
})
export class FullscreenDirective {

  @HostBinding('class.eng-fullscreen__mode')
  @Input()
  get engFullscreen(): boolean {
    return this._engFullscreen;
  }
  set engFullscreen(bool: boolean) {
    this._engFullscreen = bool;
  }
  private _engFullscreen!: boolean;

}
