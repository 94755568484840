<div #wrapper class="eng-bubble-chart__wrapper">

    <div class="bubble-chart__content">
        <div *ngIf="nxBubbleItems.length > 0 else messageTemplate">
            <ngx-charts-bubble-chart
            [view]="view"
            [results]="nxBubbleItems"
            [animations]="true"
            [showGridLines]="showGridLines"
            [legend]="showLegend"
            [legendTitle]="legendTitle"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [autoScale]="autoScale"
            [scheme]="scheme"
            [roundDomains]="roundDomains"
            [tooltipDisabled]="tooltipDisabled"
            [minRadius]="minRadius"
            [maxRadius]="maxRadius"
            >
          </ngx-charts-bubble-chart>
        </div>
    
        <ng-template #messageTemplate>
            <span>Nothing to display, data is older then {{subtitle}}!</span>
        </ng-template>
    </div>
</div>
