import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BubbleChartComponent } from './bubble-chart.component';
import { RichUiModule } from '@richUi';

@NgModule({
  imports: [
    CommonModule,
    RichUiModule
  ],
  declarations: [BubbleChartComponent],
  exports: [BubbleChartComponent]
})
export class BubbleChartModule { }
