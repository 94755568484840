import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapWidgetComponent } from './map-widget.component';
import { MapModule } from '../map/map.module';
import { BaseballCardModule } from '@shared';


@NgModule({
  declarations: [MapWidgetComponent],
  imports: [
    CommonModule,
    MapModule,
    BaseballCardModule
  ]
})
export class MapWidgetModule { }
