import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LineChartComponent } from './line-chart.component';
import { RichUiModule } from '@richUi';
import { NoDataModule } from '../no-data/no-data.module';

@NgModule({
  declarations: [LineChartComponent],
  imports: [
    CommonModule,
    RichUiModule,
    NoDataModule
  ],
  exports: [LineChartComponent]
})
export class LineChartModule { }
