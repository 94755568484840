<div #wrapper class="eng-number-card__wrapper">

  <ngx-charts-number-card
    *ngIf="data && ready"
    [view]="view"
    [scheme]="scheme"
    [cardColor]="cardColor"
    [results]="data"
    [animations]="animations"
    [valueFormatting]="valueFormatting"
    >
  </ngx-charts-number-card>
</div>
