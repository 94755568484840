import { Component, Input } from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
  selector: 'eng-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss']
})
export class NoDataComponent {

  @Input()
    noDataMessage = 'no data to display';

  @Input()
  set parenthesis(bool: boolean) {
    this._parenthesis = coerceBooleanProperty(bool);
  }
  get parenthesis(): boolean {
    return this._parenthesis;
  }
  private _parenthesis = true;

  @Input()
    tooltipText = 'try expanding the timeframe';

  @Input()
  set showTooltip(bool: boolean) {
    this._showTooltip = coerceBooleanProperty(bool);
  }
  get showTooltip(): boolean {
    return this._showTooltip;
  }
  private _showTooltip = true;

  @Input()
  set paddingTop(bool: boolean) {
    this._paddingTop = coerceBooleanProperty(bool);
  }
  get paddingTop(): boolean {
    return this._paddingTop;
  }
  private _paddingTop = true;
}
