import { Map, Feature } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { LineString } from 'ol/geom';
import { Style, Stroke } from 'ol/style';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { getVectorContext } from 'ol/render';

declare const arc: any;

export class LineCapabilities {
  readonly POINTS_PER_MS = 0.1;
  flightsSource: VectorSource;
  flightsLayer: VectorLayer<VectorSource>;

  constructor(public map: Map) {
    this.flightsSource = new VectorSource({
      wrapX: false
    });
    const self = this;
    this.flightsLayer = new VectorLayer({
      source: this.flightsSource,
      style: function(feature) {
        // if the animation is still active for a feature, do not
        // render the feature with the layer style
        if (feature.get('finished') && feature.get('color')) {
          return self.getLineStyle(feature.get('color'));
        } else {
          return self.getLineStyle('#fff');
        }
      }
    });
    map.addLayer(this.flightsLayer);
    this.flightsLayer.on('postrender', function(event: any) {
      self.animateFlights(event);
    });
  }

  drawArc(
    fromLat: number,
    fromLon: number,
    toLat: number,
    toLon: number,
    id?: string,
    color?: string
  ) {

    // create an arc circle between the two locations
    const arcGenerator = new arc.GreatCircle(
      { x: fromLon, y: fromLat },
      { x: toLon, y: toLat }
    );

    const arcLine = arcGenerator.Arc(100, { offset: 10 });

    for (let i = 0; i < arcLine.geometries.length; i++) {
      const line = new LineString(arcLine.geometries[i].coords);
      line.transform('EPSG:4326', 'EPSG:3857');

      const feature = new Feature({
        geometry: line,
        finished: false
      });

      feature.set('id', id);

      if (color) {
        feature.set('color', color);
      }

      // set from and to point of lineString
      const fromCoord: Coordinate = [fromLat, fromLon];
      const toCoord: Coordinate = [toLat, toLon];
      feature.set('fromPoint', fromCoord);
      feature.set('toPoint', toCoord);

      feature.set('start', new Date().getTime());

      this.flightsSource.addFeature(feature);
    }
  }

  animateFlights(event: any) {
    const vectorContext = getVectorContext(event);
    const frameState = event.frameState;
    const features = this.flightsSource.getFeatures();
    for (let i = 0; i < features.length; i++) {
      const feature = features[i];
      if (feature.get('color')) {
        vectorContext.setStyle(this.getLineStyle(feature.get('color')));
      } else {
        vectorContext.setStyle(this.getLineStyle());
      }
      if (!feature.get('finished')) {
        // only draw the lines for which the animation has not finished yet
        const coords = (feature.getGeometry() as LineString).getCoordinates();
        const elapsedTime = frameState.time - feature.get('start');
        const elapsedPoints = elapsedTime * this.POINTS_PER_MS;

        if (elapsedPoints >= coords.length) {
          feature.set('finished', true);
        }

        const maxIndex = Math.min(elapsedPoints, coords.length);
        const currentLine = new LineString(coords.slice(0, maxIndex));

        // directly draw the line with the vector context
        vectorContext.drawGeometry(currentLine);
      }
    }
    // tell OpenLayers to continue the animation
    this.map.render();
  }

  getLineStyle(color?: string): Style {
    return new Style({
      stroke: new Stroke({
        color: color ?? '#12aef8',
        width: 2
      })
    });
  }
}
