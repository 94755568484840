import { Directive, HostBinding } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { select } from '@ngrx/store';
import * as fromApp from '../../../state/app.selectors';
import { AppState } from 'src/app/state/app.state';

@Directive({
  selector: '[engTheme]'
})
export class ThemeDirective implements OnInit, OnDestroy {
  @HostBinding('class.eng-theme--dark')
  set isDark(bool: boolean) {
    this._isDark = bool;
  }
  get isDark(): boolean {
    return this._isDark;
  }
  private _isDark!: boolean;

  componentActive = true;

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.store.pipe(
      takeWhile(() => this.componentActive),
      select(fromApp.getDarkThemeState)).subscribe(
      darkTheme => this.isDark = darkTheme
    );
  }

  ngOnDestroy() {
    this.componentActive = false;
  }
}
