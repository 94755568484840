import { Component, Input } from '@angular/core';
import { NxChartBaseDirective } from 'src/app/shared/nx-chart-base/nx-chart-base.directive';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { SchemePalette } from 'src/app/shared/common-behaviors/scheme';
import { ChartType } from '@config';

@Component({
  selector: 'eng-pie-grid',
  templateUrl: './pie-grid.component.html',
  styleUrls: ['./pie-grid.component.scss']
})
export class PieGridComponent extends NxChartBaseDirective {
  chartType: ChartType = 'pie-grid';

  @Input()
    scheme: SchemePalette;

  @Input()
  set data(data: any[]) {
    this._data = data;
    if (this.data) {
      this._sortBySeverity();
    }
  }
  get data(): any[] {
    return this._data;
  }
  private _data!: any[];

  alertItems: any = [];

  constructor(
    public appState: Store<AppState>
  ) {
    super([20, 0]);
  }

  private _sortBySeverity () {
    const prs = ['High', 'Medium', 'Low'];

    this.data.sort(function ( a, b ) {
      const x = prs.indexOf(a.name);
      const y = prs.indexOf(b.name);

      if ( x < y ) {
        return -1;
      }
      if ( x > y ) {
        return 1;
      }
      return 0;
    });
  }

  pieTooltipText({ data }: any): string {
    return `<span class="tooltip-label">${data.name.toLocaleString()}</span>`;
  }

  select(data: any) {
    console.log('Item clicked', data);
  }
}
