import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService } from '@services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Component({
  selector: 'eng-alert',
  template: '<ng-content></ng-content>'
})
export class AlertComponent implements OnInit, OnDestroy {
  
  private subscription!: Subscription;
  private horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  private verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(
    private alertService: AlertService,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.subscription = this.alertService.getMessage().subscribe(message => {
      if (message && message.text) {
        this.snackBar.open(message.text, 'dismiss', {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
