export interface CompanyRiskScoreHistory {
  id: string;
  data: RiskScore[];
}

export interface RiskScore {
  date: Date;
  riskScore: number;
}

export interface ScoreCard {
  scordCardType: ScoreCardType;
  name: string;
  score: string;
  tooltip?: string;
}

export enum ScoreCardType {
  RiskScore,
  CoursesComplete,
  CompanyAvgGrade,
  PoliciesComplete,
}

export interface CourseParticipation {
  learners: Learner[];
}

export interface Learner {
  id: string;
  name: string;
  email: string;
  courseResults: CourseResult[];
  policyResults: PolicyResult[];
  gapAnalysisResults: CourseResult[];
  companyId: string;
  riskScoreLevel: RiskScoreLevel;
}

export interface CourseResult {
  id?: string;
  finished?: boolean;
  outstanding?: boolean;
  score?: number;
  grade?: Grade;
  course: Course;
  finishDate?: Date;
  enrollDate?: Date;
  startDate?: Date;
}

export interface Course {
  name?: string;
  displayName: string;
  difficulty?: number;
}

export enum Grade {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
}

export enum RiskScoreLevel {
  VeryLow = 'veryLow',
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  VeryHigh = 'veryHigh',
}

export const riskScoreLevelToNumber = (
  riskScoreLevel: RiskScoreLevel
): number => {
  switch (riskScoreLevel) {
  case RiskScoreLevel.VeryLow:
    return 1;
  case RiskScoreLevel.Low:
    return 2;
  case RiskScoreLevel.Medium:
    return 3;
  case RiskScoreLevel.High:
    return 4;
  case RiskScoreLevel.VeryHigh:
    return 5;
  default:
    return 0;
  }
};

export interface LearnerPolicy {
  learners: Learner[];
}

export interface PolicyResult {
  policyId: string;
  signed: Date;
}

export const gradeToEnum = (total: number): Grade => {
  if (total >= 0.9) return Grade.A;
  if (total >= 0.8) return Grade.B;
  if (total >= 0.7) return Grade.C;
  if (total >= 0.6) return Grade.D;
  if (total >= 0.5) return Grade.E;
  return Grade.F;
};

export const gradeToNumber = (grade: Grade | undefined): number => {
  switch (grade) {
  case Grade.A:
    return 0.9;
  case Grade.B:
    return 0.8;
  case Grade.C:
    return 0.7;
  case Grade.D:
    return 0.6;
  case Grade.E:
    return 0.5;
  case Grade.F:
    return 0.4;
  default:
    return 0;
  }
};

export const difficultyToString = (difficulty: number): string => {
  switch (difficulty) {
  case 1:
    return 'Very Low';
  case 2:
    return 'Low';
  case 3:
    return 'Medium';
  case 4:
    return 'High';
  case 5:
    return 'Very High';
  default:
    return '';
  }
};

export interface RadarDataInput {
  serie: {
    name?: string;
    data: number[];
  };
  categories: string[];
}

export const enumFromValue = <T extends Record<string, string>>(
  val: string,
  _enum: T
) => {
  const enumName = (Object.keys(_enum) as Array<keyof T>).find(
    (k) => _enum[k] === val
  );
  if (!enumName) throw Error();
  return _enum[enumName];
};
