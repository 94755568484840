import { Injectable, OnDestroy } from '@angular/core';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import gql from 'graphql-tag';
import {
  from,
  Observable,
  BehaviorSubject,
  combineLatest,
  Subscription,
} from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import {
  getRuntimeAppConfig,
  getDataSources,
} from 'src/app/state/app.selectors';
import { AlertService } from '../alert/alert.service';
import { AppConfig } from 'src/app/config/app.config';
import { AuthService } from '../ue/auth.service';
import { User as AuthUser } from 'oidc-client-ts';

@Injectable({
  providedIn: 'root',
})
export class AppSyncLiveDataService implements OnDestroy {
  client!: AWSAppSyncClient<any>;

  liveDataSub = new BehaviorSubject<string | null>(null);

  user: AuthUser | null;

  private _config!: AppConfig;

  private _dataSubscriptions: Subscription[] = [];
  private _internalDataSubscriptions: ZenObservable.Subscription[] = [];

  constructor(
    private alertService: AlertService,
    private _appStore: Store<AppState>,
    private _authService: AuthService
  ) {
    this.initializeClient();
  }

  async initializeClient() {
    this.user = await this._authService.getUser();
    if(this.user) {
      const appConfig = this._appStore.pipe(select(getRuntimeAppConfig));
      const dataSources = this._appStore.pipe(select(getDataSources));
      combineLatest(appConfig, dataSources).subscribe(
        ([
          config,
          _sources
        ]) => {
          // make sure to unsubscribe and reset
          this.unsubscribeAndResetSubscriptions();
  
          if (config) {
            this._config = config;
            // console.log('we are authencticated and have config: ', authenticated, this._config);
            this.client = new AWSAppSyncClient({
              url: this._config.appSyncUrl,
              region: this._config.region,
              auth: {
                type: AUTH_TYPE.OPENID_CONNECT,
                // Get the currently logged in users credential.
                jwtToken: this.user?.id_token as string,
              },
              disableOffline: true,
            });
          }
        }
      );
    } else {
      if (this.client) {
        this.unsubscribeAndResetSubscriptions();
        this.client.resetStore();
        this.client.clearStore();
      }
    }
  }

  liveData(): Observable<string | null> {
    return this.liveDataSub.asObservable();
  }

  subscribeLiveData(source: string): Observable<any> {
    return from(this.subscriptionLivedataHandler(source));
  }

  async subscriptionLivedataHandler(source: string): Promise<any> {
    const subscriptionLive = `
        subscription onUpdateLiveData{
            onUpdateLiveData(source: '${source}'){
                source
                latestDate
            }
        }`;

    await this.client.hydrated();
    try {
      const observable = await this.client.subscribe({
        query: gql`
          ${subscriptionLive}
        `,
      });

      const self = this;

      const realtimeResults = (data: any) => {
        self.alertService.success(
          `Real time data coming in from source ${data.data.onUpdateLiveData.source}`
        );
        self.liveDataSub.next(data.data.onUpdateLiveData.source);
      };

      this._internalDataSubscriptions.push(
        observable.subscribe({
          next: realtimeResults,
          complete: console.log,
          error: console.log,
        })
      );

      return null;
    } catch (err) {
      console.log(err);
    }
  }

  unsubscribeAndResetSubscriptions() {
    this._dataSubscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    // reset sub array
    this._dataSubscriptions = [];

    this._internalDataSubscriptions.forEach((sub) => {
      sub.unsubscribe();
    });

    // reset internal sub array
    this._internalDataSubscriptions = [];
  }

  ngOnDestroy() {
    this.unsubscribeAndResetSubscriptions();
  }
}
