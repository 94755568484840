import { AfterContentInit, AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ChartType } from '@config';
import { BaseballCardComponent, MapComponent } from '@shared';
import { FeatureEvent, InteractionCapabilities, PointCapabilities } from '@visualizations';
import { Subscription } from 'rxjs';
import { DestinationPoint } from 'src/app/models/destination-point.model';

export interface MarkerDetail { id: string; longitude: number; latitude: number; }

@Component({
  selector: 'eng-map-widget',
  templateUrl: './map-widget.component.html',
  styleUrls: ['./map-widget.component.scss']
})
export class MapWidgetComponent implements AfterViewInit, AfterContentInit {

  chartType: ChartType = 'map-widget';

  @ViewChild(MapComponent, {static: true})
    engMap!: MapComponent;

  @ViewChild('wrapper', {static: true})
    wrapper!: ElementRef;
  wrapperHeight!: number;
  wrapperWidth!: number;

  @ViewChild(BaseballCardComponent, {static: true})
    card!: BaseballCardComponent;

  offset: number[] = [0, 0];
  initialOffset: number[] = [0, 0];

  @Input()
    rowSpan = 1;

  @Input()
  set data(_data: DestinationPoint[]) {
    if (_data) {
      this.applyMarkers(_data);
      this._data = _data;
    }
  }
  private _data = new Array<DestinationPoint>();

  nodeDetail: DestinationPoint | null;

  pointCapabilities!: PointCapabilities;
  interactionCapabilities!: InteractionCapabilities;

  hoverSubscription!: Subscription;

  constructor() { }

  ngAfterContentInit() {
    this.updateView();
  }

  ngAfterViewInit() {
    this.pointCapabilities = new PointCapabilities(this.engMap.map);
    this.interactionCapabilities = new InteractionCapabilities(this.engMap.map);
    this.interactionCapabilities.addHoverInteraction();
    this.hoverSubscription = this.interactionCapabilities
      .getSelectedId()
      .subscribe((event: FeatureEvent | null) => {
        const id = event?.feature?.getId()?.toString();
        if (event && id) {
          this.populateNodeDetail(id);
          this.card.setPosition(event.clientX, event.clientY);
          this.card.showCard = true;
        } else {
          this.card.showCard = false;
        }
      });
  }

  private populateNodeDetail(id: string) {
    this.nodeDetail = this._data.find(x => x.id === id) ?? null;
  }

  updateView() {
    this.wrapper.nativeElement.style.width = this.wrapper.nativeElement.offsetWidth;
  }

  applyMarkers(markers: DestinationPoint[]) {
    this.pointCapabilities.removePoints();
    markers.map((marker) => {
      this.pointCapabilities.addPoint({
        lat: marker.latitude,
        lon: marker.longitude,
        id: marker.id
      });
    });
  }

}
