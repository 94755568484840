import { Injectable } from '@angular/core';
import { DataSourceType } from '@config';
import { MockDataService, CloudElasticService, AppSyncWatcherAlertsService } from '@services';

export type MockPropertiesType = 'fetchCognitoLoginData' | 'fetchCloudfrontEdgeLocationsData' |
  'fetchCloudfrontCoreBundleMissCountData' | 'fetchEndpointsBeingMonitoredData' | 'fetchUniqueIpsData' |
  'fetchUserAuthFailuresData' | 'fetchExcessiveLoginsData';

export type ElasticPropertiesType = 'fetchTotalHitsData' | 'fetchSeverityHitsData' |
  'fetchHitsOverTimeData' | 'fetchDiscoverData' | 'fetchSeveritiesData' |
  'fetchEndpointsSendingData' | 'fetchTotalEventsData' | 'fetchUserAuthFailuresData' | 'fetchTotalRemoteCommandsData' |
  'fetchTopEventsData' | 'fetchSourcesProviderNamesData' | 'fetchLogonActivityOverTimeData' | 'fetchEventsOverTimeData' |
  'fetchAuthActivityBreakdownData' | 'fetchEventData' | 'fetchTopUserAlertsData' |
  'fetchCloudfrontResultTypeData' | 'fetchCloudfrontTotalHitsData' | 'fetchCloudfrontHittingFirewallData'|
  'fetchCloudfrontHitsPerDayData' | 'fetchCloudfrontBlockedIPsData' | 'fetchHitsOverTimeAmpData' | 'fetchHitsOverTimeUmbrellaData' |
  'fetchStartedProvidersData' | 'fetchTotalEngineStartedData' | 'fetchUniqueHostsData' | 'fetchTopActiveHostsData' | 
  'fetchEngineAndCommandStartedData' | 'fetchMostChangedFileData' | 'fetchNewUserAccountCreatedQueryData' | 
  'fetchAuthenticationFailuresQueryData' | 'fetchChangedPasswordQueryData' | 'fetchEventsOverTimeECSData' | 
  'fetchSystemEventHistogramData' | 'fetchHostListQueryData' | 'fetchEventActionsData' | 'fetchOSDistributionData' | 
  'fetchEventCategoriesData' | 'fetchUniqueDeniedData' | 'fetchVpnActivityData' | 'fetchSuccessfulConnectionsData' | 
  'fetchTopACLBlockedData' | 'fetchGeographicBreakdownData' | 'fetchTopBlockedSourceIPsData' | 'fetchTopASAMessagesData' | 
  'fetchPortsBlockedASAData' | 'fetchFirewallEventsData' | 'fetchNetworkEventsData' | 'fetchDNSQueriesData' | 'fetchHTTPTransactionsData' |
  'fetchTLSSessionsData' | 'fetchSourceTrafficBreakdownData' | 'fetchTopDestinationsData' | 'fetchTrafficBreakdownData' | 
  'fetchTransactionsOverTimeData' | 'fetchTopHTTPRequestsData' | 'fetchDNSTopRequestsData' | 'fetchNetworkTrafficBtwHostsData' | 
  'fetchUniqueFQDNData' | 'fetchBytesTransferredData' | 'fetchOutboundConnectionAttempts' | 'fetchOutboundConnectionAttemptsDestination';

export type AppSyncPropertiesType = 'getWatcherAlertsToday';

export type ServiceType = CloudElasticService | MockDataService | AppSyncWatcherAlertsService;

@Injectable()
export class DataConnectionFactory {

  constructor(private cloudElasticService: CloudElasticService,
      private mockService: MockDataService,
      private appSyncWatcherAlertsService: AppSyncWatcherAlertsService
  ) { }

  public getDataServiceConnection<T extends ServiceType>(type: DataSourceType): T {
    switch (type) {
    case DataSourceType.Elastic:
      return this.cloudElasticService as T;
    case DataSourceType.Appsync:
      return this.appSyncWatcherAlertsService as T;
    case DataSourceType.Mock:
      return this.mockService as T;
    default:
      throw new Error('Wrong data connection type chosen');
    }
  }
}
