<div class="eng-map__wrapper">
  <div id="{{ id }}" class="map" [ngClass]="{'row-span-1x': _rowSpan === 1, 'row-span-2x': _rowSpan === 2}">
    <button matTooltip="Choose map imagery" class="eng-map-menu__icon icon--right" [matMenuTriggerFor]="layerMenu" mat-icon-button>
      <mat-icon class="fa fa-align-left" aria-label="This icon menu button is for choosing imagery"></mat-icon>
    </button>
    <ng-content></ng-content>
  </div>

  <mat-menu #layerMenu="matMenu">
    <button mat-menu-item *ngFor="let geo of bingGeoMapLayers" (click)="applyTileLayer(geo.value)">{{geo.name}}</button>
  </mat-menu>
</div>