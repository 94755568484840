<div #wrapper class="eng-tree-map__wrapper">
  <div *ngIf="hasInitialChildren" class="tree-path__wrapper">
    <ng-container *ngFor="let item of treemapPath; let last = last">
      <button
        mat-button
        [class.active]="last"
        [disabled]="last"
        (click)="treemapSelect(item)"
      >
        {{ item.name }} ({{ item.value }})
      </button>
      <span *ngIf="!last"> / </span>
    </ng-container>
  </div>

  <ngx-charts-tree-map
    *ngIf="hasInitialChildren"
    [view]="view"
    [scheme]="scheme"
    [results]="treemap"
    [animations]="animations"
    [tooltipDisabled]="tooltipDisabled"
    (select)="treemapSelect($event)"
  >
  </ngx-charts-tree-map>
</div>


<eng-no-data *ngIf="!hasInitialChildren"
></eng-no-data>