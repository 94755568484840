import { Injectable, OnDestroy } from '@angular/core';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import gql from 'graphql-tag';
import {
  from,
  Observable,
  BehaviorSubject,
  Subscription
} from 'rxjs';
import {
  UpdateEmailRecipientsInput,
  UpdateIkeToggledInput,
  UpdateSlackWebhookUrlInput,
  Watcher,
  WatcherMutationEvent
} from 'src/app/views/rules/watcher-alerts.model';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import {
  getRuntimeAppConfig,
} from 'src/app/state/app.selectors';
import { AppConfig } from 'src/app/config/app.config';
import { AuthService } from '../ue/auth.service';
import { User as AuthUser } from 'oidc-client-ts';

@Injectable({
  providedIn: 'root'
})
export class AppSyncWatchersService implements OnDestroy {
  client!: AWSAppSyncClient<any>;

  watchers: BehaviorSubject<Watcher | null> =
    new BehaviorSubject<Watcher | null>(null);

  clientReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  user: AuthUser | null;

  private _config!: AppConfig;

  private _appSyncWatchersSub!: Subscription;

  constructor(
    private _appStore: Store<AppState>,
    private _authService: AuthService
  ) {
    this.initializeClient();
  }

  async initializeClient() {
    this.user = await this._authService.getUser();
    if(this.user) {
      this._appStore.pipe(select(getRuntimeAppConfig)).subscribe((config) => {
        if (config) {
          this._config = config;
          this.client = new AWSAppSyncClient({
            url: this._config.appSyncUrl,
            region: this._config.region,
            auth: {
              type: AUTH_TYPE.OPENID_CONNECT,
              // Get the currently logged in users credential.
              jwtToken: this.user?.access_token as string,
            },
            disableOffline: true
          });
          this.clientReady.next(true);
        }
      });
    } else {
      if (this.client) {
        // console.log('we have client and are no longer authenticated, reset and clear app sync client: ', authenticated, config);
        this.client.resetStore();
        this.client.clearStore();
      }
    }
  }

  getWatchers(
    accountName: string,
    watcherId: string
  ): Observable<Watcher[] | null> {
    if (watcherId) {
      return from(this.getWatcherByIdHandler(watcherId));
    }
    return from(this.getWatchersHandler(accountName));
  }

  async getWatchersHandler(accountName: string): Promise<Watcher[] | null> {
    const getWatchers = `query getWatchers($accountName: String) {
      getWatchers(accountName: $accountName) {
        items {
          name
          severity
          watcherId
          accountName
          description
          index
          matchPhrases {
              name
              value
              operation
          }
          scheduledInterval
          watcherStatus
          tenant
          threshold
          slackWebhookUrl
          emailRecipients
          ikeToggled
          createdAt
          modifiedDate
        }
      }
    }`;

    await this.client?.hydrated();
    try {
      const transactionComplete: any = await this.client.query({
        query: gql`
          ${getWatchers}
        `,
        variables: {
          accountName
        },
        fetchPolicy: 'no-cache'
      });
      return transactionComplete.data.getWatchers.items;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  async getWatcherByIdHandler(watcherId: string): Promise<Watcher[] | null> {
    const getWatcherById = `query getWatcherById($watcherId: ID!) {
      getWatcherById(watcherId: $watcherId) {
        name
        severity
        watcherId
        accountName
        description
        index
        matchPhrases {
            name
            value
            operation
        }
        scheduledInterval
        watcherStatus
        tenant
        threshold
        slackWebhookUrl
        emailRecipients
        ikeToggled
        createdAt
        modifiedDate
      }
    }`;

    await this.client?.hydrated();
    try {
      const transactionComplete: any = await this.client.query({
        query: gql`
          ${getWatcherById}
        `,
        variables: {
          watcherId
        },
        fetchPolicy: 'no-cache'
      });
      return transactionComplete.data.getWatcherById;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  mutateWatcher(input: WatcherMutationEvent) {
    return from(this.mutateWatcherHandler(input));
  }

  async mutateWatcherHandler(
    input: WatcherMutationEvent
  ): Promise<Watcher | null> {
    const mutateWatcher = `mutation mutateWatcher($input: WatcherMutationEvent!) {
      mutateWatcher(input: $input) {
        name
        severity
        watcherId
        accountName
        description
        index
        matchPhrases {
            name
            value
            operation
        }
        scheduledInterval
        watcherStatus
        tenant
        threshold
        slackWebhookUrl
        emailRecipients
        ikeToggled
        createdAt
        modifiedDate
      }
    }`;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.mutate({
        mutation: gql`
          ${mutateWatcher}
        `,
        variables: {
          input: input
        },
        fetchPolicy: 'no-cache'
      });
      return transactionComplete.data.mutateWatcher;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  updateWatcherSlackWebhookUrl(input: UpdateSlackWebhookUrlInput) {
    return from(
      this.updateWatcherSlackWebhookUrlHandler(
        input.accountName,
        input.watcherId,
        input.slackWebhookUrl
      )
    );
  }

  async updateWatcherSlackWebhookUrlHandler(
    accountName: string,
    watcherId: string,
    slackWebhookUrl: string
  ): Promise<Watcher | null> {
    const updateWatcherSlackWebhookUrl = 
    `mutation updateWatcherSlackWebhookUrl($accountName: String!, $watcherId: ID!, $slackWebhookUrl: String!) {
      updateWatcherSlackWebhookUrl(accountName: $accountName, watcherId: $watcherId, slackWebhookUrl: $slackWebhookUrl) {
        name
        severity
        watcherId
        accountName
        description
        index
        matchPhrases {
            name
            value
            operation
        }
        scheduledInterval
        watcherStatus
        tenant
        threshold
        slackWebhookUrl
        emailRecipients
        ikeToggled
        createdAt
        modifiedDate
      }
    }`;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.mutate({
        mutation: gql`
          ${updateWatcherSlackWebhookUrl}
        `,
        variables: {
          accountName,
          watcherId,
          slackWebhookUrl
        },
        fetchPolicy: 'no-cache'
      });
      return transactionComplete.data.updateWatcherSlackWebhookUrl;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  updateWatcherEmailRecipients(input: UpdateEmailRecipientsInput) {
    return from(
      this.updateWatcherEmailRecipientsHandler(
        input.accountName,
        input.watcherId,
        input.emailRecipients
      )
    );
  }

  async updateWatcherEmailRecipientsHandler(
    accountName: string,
    watcherId: string,
    emailRecipients: string[]
  ): Promise<Watcher | null> {
    const updateWatcherEmailRecipients = 
    `mutation updateWatcherEmailRecipients($accountName: String!, $watcherId: ID!, $emailRecipients: [String]!) {
      updateWatcherEmailRecipients(accountName: $accountName, watcherId: $watcherId, emailRecipients: $emailRecipients) {
        name
        severity
        watcherId
        accountName
        description
        index
        matchPhrases {
            name
            value
            operation
        }
        scheduledInterval
        watcherStatus
        tenant
        threshold
        slackWebhookUrl
        emailRecipients
        ikeToggled
        createdAt
        modifiedDate
      }
    }`;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.mutate({
        mutation: gql`
          ${updateWatcherEmailRecipients}
        `,
        variables: {
          accountName,
          watcherId,
          emailRecipients
        },
        fetchPolicy: 'no-cache'
      });
      return transactionComplete.data.updateWatcherEmailRecipients;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  updateWatcherIkeToggled(input: UpdateIkeToggledInput) {
    return from(
      this.updateWatcherIkeToggledHandler(
        input.accountName,
        input.watcherId,
        input.ikeToggled
      )
    );
  }

  async updateWatcherIkeToggledHandler(
    accountName: string,
    watcherId: string,
    ikeToggled: boolean
  ): Promise<Watcher | null> {
    const updateWatcherIkeToggled = `mutation updateWatcherIkeToggled($accountName: String!, $watcherId: ID!, $ikeToggled: Boolean!) {
      updateWatcherIkeToggled(accountName: $accountName, watcherId: $watcherId, ikeToggled: $ikeToggled) {
        name
        severity
        watcherId
        accountName
        description
        index
        matchPhrases {
            name
            value
            operation
        }
        scheduledInterval
        watcherStatus
        tenant
        threshold
        slackWebhookUrl
        emailRecipients
        ikeToggled
        createdAt
        modifiedDate
      }
    }`;

    await this.client.hydrated();
    try {
      const transactionComplete: any = await this.client.mutate({
        mutation: gql`
          ${updateWatcherIkeToggled}
        `,
        variables: {
          accountName,
          watcherId,
          ikeToggled
        },
        fetchPolicy: 'no-cache'
      });
      return transactionComplete.data.updateWatcherIkeToggled;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  ngOnDestroy() {
    this._appSyncWatchersSub.unsubscribe();
  }
}
