export interface AppConfig {
  identifier: string;
  appSyncUrl: string;
  apiGatewayUrl: string;
  userPoolId: string;
  clientId: string;
  region: string;
}

export interface DomainModel {
  domain: string;
  subdomain: string;
}

export enum DomainStandardEnv {
  Prod = 'enigmaglass.com',
  Dev = 'localhost',
}

export const RUNTIME_CONFIG_API_URL =
  'https://ykn6edtnu0.execute-api.us-east-1.amazonaws.com/dev/api/lookup-runtime-config';
