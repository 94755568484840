import { Component, Input } from '@angular/core';
import { BreadCrumbItem } from 'src/app/models/bread-crumb-item';

@Component({
  selector: 'eng-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss']
})
export class BreadCrumbComponent {

  @Input()
    items: BreadCrumbItem[];

  constructor() { }

}
