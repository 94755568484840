import { Pipe, PipeTransform } from '@angular/core';
import { groupBy } from 'lodash';
import { Dictionary } from 'lodash';
import { WidgetModel } from 'src/app/config/widget.registry';

@Pipe({name: 'splitByRow'})
export class SplitByRowPipe implements PipeTransform {
  transform(userWidgetRegistry: WidgetModel[]): Dictionary<WidgetModel[]> | null {
    return userWidgetRegistry ?
      groupBy(userWidgetRegistry, widget => widget?.config?.position[0]) :
      null;
  }
}
