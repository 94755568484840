<div #wrapper class="eng-bar-chart__wrapper">
  <ngx-charts-bar-vertical
    *ngIf="data && data.length > 0"
    [view]="view"
    [scheme]="scheme"
    [results]="data"
    [animations]="animations"
    schemeType="quantile"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [legendTitle]="legendTitle"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [tooltipDisabled]="tooltipDisabled"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [showGridLines]="showGridLines"
    [barPadding]="barPadding"
    [roundDomains]="roundDomains"
    [roundEdges]="roundEdges"
    [showDataLabel]="showDataLabel"
    >
    <!-- [customColors]="barCustomColors()" -->
  </ngx-charts-bar-vertical>

  <eng-no-data *ngIf="!data || data.length === 0"></eng-no-data>
</div>
