<div class="footer">
  <form class="time-range-form" [formGroup]="timeRangeForm" (ngSubmit)="onSubmit()">
    <mat-form-field class="time-filter-form-field">
      <input  matInput 
              type="time" 
              placeholder="Start Time" 
              formControlName="startTime"
              ng-model-options="{ timezone: 'utc' }">
    </mat-form-field>
  
    <mat-form-field class="time-filter-form-field">
      <input  matInput 
              type="time" 
              placeholder="End Time" 
              formControlName="endTime"
              ng-model-options="{ timezone: 'utc' }">
    </mat-form-field>
    <div *ngIf="timeRangeForm.invalid">
      <mat-error>
        Start time cannot be prior to end time.
      </mat-error>
    </div>
    <button mat-raised-button color="accent" 
            class="time-filter-form-submit-btn" 
            type="submit" 
            full>Submit
    </button>
  </form>
</div>