import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardTileComponent } from './dashboard-tile.component';
import { RichUiModule } from '@richUi';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [DashboardTileComponent],
  imports: [
    CommonModule,
    RichUiModule,
    MatCardModule
  ],
  exports: [DashboardTileComponent]
})
export class DashboardTileModule { }
