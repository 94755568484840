import { Router, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { getDataSources } from 'src/app/state/app.selectors';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class SourceGuardService implements CanActivate {

  constructor(public router: Router, private appStore: Store<AppState>) {}

  canActivate(): Observable<boolean> {
    return this.appStore.pipe(
      select(getDataSources),
      filter(sources => !!sources && sources?.length > 0),
      map(sources => {
        if (
          sources && sources.length > 0
        ) {
          return true;
        } else {
          return false;
        }
      })
    ) as Observable<boolean>;
  }
}
