import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of, Subscription, forkJoin } from 'rxjs';
import { tap, map, switchMap, catchError, take } from 'rxjs/operators';

import { AlertService } from '@services';
import * as appActions from './app.actions';
import { Store, select, Action } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { AppSyncAccountsService } from '../services/app-sync/app-sync-accounts.service';
import { getAllAccounts, getRuntimeAppConfig } from './app.selectors';
import { AppSyncUserService } from '../services/app-sync/app-sync-user.service';
import { AppConfig, DomainStandardEnv } from '../config/app.config';
import { UsernameNextActionType } from '../views/login/login.component';
import { GatewayService } from '../services/gateway/gateway.service';
import { BugModel } from '../models/bug.model';
import { PhotoModel } from '../models/photo.model';
import { PhotoService } from '../services/photo/photo.service';
import { DataSource, Account, UserFeatures, DiscoverSavedQuery } from '../models/account.model';
import { AuthService } from '../services/ue/auth.service';
// import { v4 as uuid } from 'uuid';

@Injectable()
export class AppEffects {
  constructor(
    private actions: Actions,
    private router: Router,
    private alertService: AlertService,
    private store: Store<AppState>,
    private accountsService: AppSyncAccountsService,
    private _AppSyncUserService: AppSyncUserService,
    private _gatewayService: GatewayService,
    private _photoService: PhotoService,
    private _authService: AuthService
  ) {
    this.configSub = this.store
      .pipe(select(getRuntimeAppConfig))
      .subscribe((config) => {
        if (config) {
          this.defaultRoute = config.region;
        }
      });
  }
  configSub: Subscription;
  defaultRoute: string;
  
  LogInSuccess: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(appActions.AppActionEnums.LoginSuccess),
    map(() => {
      return new appActions.UEUserAction();
    })
  ));

  UEUser: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(appActions.AppActionEnums.UEUser),
    switchMap(() => {
      return this._authService.getUser().then((user: any) => {
        if (user) {
          return new appActions.UEUserSuccessAction(user);
        } else {
          return new appActions.NoUEUserAction(user);
        }
      });
    })
  ));

  UEUserSuccess: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(appActions.AppActionEnums.UEUserSuccess),
    map((action: appActions.UEUserSuccessAction) => {
      return new appActions.CurrentDynamoUserAction(action.payload);
    })
  ));

  
  CurrentDynamoUser: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(appActions.AppActionEnums.CurrentDynamoUser),
    map((action: appActions.CurrentDynamoUserAction) => action.payload),
    switchMap((payload: any) => {
      const getUser = this._AppSyncUserService.getUser(payload.profile.sub);
      const allAccts = this.accountsService.listAccounts();
      const userAndAccounts$ = forkJoin([getUser, allAccts]);
      return userAndAccounts$.pipe(
        map(([userResponse, acctsResponse]) => {
          this.store.dispatch(new appActions.AllAccountsAction(acctsResponse as Account[]));
          return new appActions.CurrentDynamoUserSuccessAction(userResponse);
        }),
        catchError((err) =>
          of(new appActions.CurrentDynamoUserFailureAction(err))
        )
      );
    })
  ));

  
  CurrentDynamoUserSuccess: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(appActions.AppActionEnums.CurrentDynamoUserSuccess),
    map((action: appActions.CurrentDynamoUserSuccessAction) => {
      const accountName = action.payload.accounts && action.payload.accounts[0];
      return new appActions.DataSourcesAction(<string>accountName);
    })
  ));

  
  ToggleDarkTheme = createEffect(() => this.actions.pipe(
    ofType(appActions.AppActionEnums.ToggleDarkTheme),
    map((action: appActions.ToggleDarkThemeAction) => action.payload),
    tap((payload: any) => {
      const theme = payload ? 'DARK' : 'LIGHT';
      this._AppSyncUserService.updateUserTheme(theme);
    })
  ), { dispatch: false });

  
  DataSources: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(appActions.AppActionEnums.DataSources),
    map((action: appActions.DataSourcesAction) => action.account),
    switchMap((accountName) => {
      return this.accountsService.getAccountSources(accountName).pipe(
        map((sources: DataSource[]) => {
          if (sources && sources.length > 0) {
            return new appActions.DataSourcesSuccessAction(sources);
          } else {
            return new appActions.DataSourcesFailureAction(false);
          }
        }),
        catchError((err) => of(new appActions.DataSourcesFailureAction(err)))
      );
    })
  ));

  
  DataSourcesSuccess: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(appActions.AppActionEnums.DataSourcesSuccess),
    tap(() => {
      if (this.router.url.includes('login')) {
        this.router.navigateByUrl(this.defaultRoute);
      } else {
        this.router.navigateByUrl(this.router.url);
      }
    })
  ), { dispatch: false });

  DiscoverSavedQueries: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(appActions.AppActionEnums.DiscoverSavedQueries),
    map((action: appActions.DiscoverSavedQueriesAction) => action.account),
    switchMap((accountName) => {
      return this.accountsService.getDiscoverSavedQueries(accountName).pipe(
        map((queries: DiscoverSavedQuery[]) => {
          if (queries && queries.length > 0) {
            return new appActions.DiscoverSavedQueriesSuccessAction(queries);
          } else {
            return new appActions.DiscoverSavedQueriesFailureAction(false);
          }
        }),
        catchError(err => of(new appActions.DiscoverSavedQueriesFailureAction(err)))
      );
    })
  ));

  UpdateDiscoverSavedQueries: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(appActions.AppActionEnums.UpdateDiscoverSavedQueries),
    switchMap((action: appActions.UpdateDiscoverSavedQueriesAction) => {
      return this.accountsService.updateDiscoverSavedQueries(action.account, action.discoverSavedQueries).pipe(
        map((queries: DiscoverSavedQuery[]) => {
          if (queries && queries.length > 0) {
            return new appActions.DiscoverSavedQueriesSuccessAction(queries);
          } else {
            return new appActions.DiscoverSavedQueriesFailureAction(false);
          }
        }),
        catchError(err => of(new appActions.DiscoverSavedQueriesFailureAction(err)))
      );
    })
  ));

  UpdateEnigmaUWeeklyReportRecipients: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(appActions.AppActionEnums.UpdateEnigmaUWeeklyReportRecipients),
    switchMap((action: appActions.UpdateEnigmaUWeeklyReportRecipientsAction) => {
      return this.accountsService.updateEnigmaUWeeklyReportRecipientsQueries(action.accountName, action.enigmaUSettingsAndConfig).pipe(
        map((account: Account) => {
          if (account) {
            return new appActions.UpdateEnigmaUWeeklyReportRecipientsSuccessAction(account);
          } else {
            return new appActions.UpdateEnigmaUWeeklyReportRecipientsFailureAction(false);
          }
        }),
        catchError(err => of(new appActions.UpdateEnigmaUWeeklyReportRecipientsFailureAction(err)))
      );
    })
  ));

  GetEnigmaUWeeklyReportRecipients: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(appActions.AppActionEnums.GetEnigmaUWeeklyReportRecipients),
    switchMap((action: appActions.GetEnigmaUWeeklyReportRecipientsAction) => {
      return this.accountsService.getEnigmaUWeeklyReportRecipients(action.accountName).pipe(
        map((account: Account) => {
          if (account) {
            return new appActions.GetEnigmaUWeeklyReportRecipientsSuccessAction(account);
          } else {
            return new appActions.GetEnigmaUWeeklyReportRecipientsFailureAction(false);
          }
        }),
        catchError(err => of(new appActions.GetEnigmaUWeeklyReportRecipientsFailureAction(err)))
      );
    })
  ));

  UpdateEnigmaURemindersSent: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(appActions.AppActionEnums.UpdateEnigmaURemindersSent),
    switchMap((action: appActions.UpdateEnigmaURemindersSentAction) => {
      return this.accountsService.updateEnigmaURemindersSent(action.accountName, action.enigmaUSettingsAndConfig).pipe(
        map((account: Account) => {
          if (account) {
            return new appActions.UpdateEnigmaURemindersSentSuccessAction(account);
          } else {
            return new appActions.UpdateEnigmaURemindersSentFailureAction(false);
          }
        }),
        catchError(err => of(new appActions.UpdateEnigmaURemindersSentFailureAction(err)))
      );
    })
  ));
  
  ActiveAccount: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(appActions.AppActionEnums.ActiveAccount),
    tap(async (action: appActions.ActiveAccountAction) => {
      const allAccounts = await this.store.select(getAllAccounts).pipe(take(1)).toPromise();
      if (allAccounts) {
        const currentAcct = allAccounts.find(
          (a) => a.accountName === action.account
        );
        const features =
          currentAcct && currentAcct.features
            ? currentAcct.features
            : ({} as UserFeatures);
        this.store.dispatch(new appActions.UserFeaturesAction(features));
      }
    })
  ), { dispatch: false });

  
  AuthFailure: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(
      appActions.AppActionEnums.LoginFailure,
      appActions.AppActionEnums.RefreshCredsFailure
    ),
    tap((err) => {
      this.alertService.error((err as any).payload.message);
    })
  ), { dispatch: false });

  
  public LogOut: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(appActions.AppActionEnums.Logout),
    tap(() => {
      this._authService.logout();
      this.store.dispatch(new appActions.LogOutSuccessAction());
    })
  ), { dispatch: false });

  
  UsernameResponse: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(appActions.AppActionEnums.UsernameResponse),
    map((action: appActions.UsernameResponseAction) => {
      if (action.payload.code === 'UserNotFoundException') {
        return new appActions.UsernameNextAction(UsernameNextActionType.SIGNUP);
      } else if (action.payload.code === 'NotAuthorizedException') {
        return new appActions.UsernameNextAction(
          UsernameNextActionType.CONTINUE
        );
      } else {
        return new appActions.UsernameNextAction(
          UsernameNextActionType.CONTINUE
        );
      }
    })
  ));

  
  // LoadRuntimeAppConfig$: Observable<any> = createEffect(() => this.actions.pipe(
  //   ofType(appActions.AppActionEnums.LoadRuntimeAppConfig),
  //   switchMap(() => {
  //     return this._gatewayService.runtimeConfig().pipe(
  //       map((config: AppConfig) => {
  //         return new appActions.LoadRuntimeAppConfigSuccessAction(config);
  //       }),
  //       catchError((err) =>
  //         of(new appActions.LoadRuntimeAppConfigFailureAction(err))
  //       )
  //     );
  //   })
  // ));

  
  // LoginLoginLoadRuntimeAppConfig$: Observable<any> = createEffect(() => this.actions.pipe(
  //   ofType(appActions.AppActionEnums.LoginLoadRuntimeAppConfig),
  //   map((action: appActions.LoginLoadRuntimeAppConfigAction) => action),
  //   switchMap((action) => {
  //     // TODO put this in an email check function
  //     // let emailDomain = '';
  //     // const un = action.user && action.user.userName as string;
  //     // if (un.includes('@')) {
  //     //   emailDomain = un.substring(
  //     //     un.lastIndexOf('@') + 1,
  //     //     un.lastIndexOf('.com')
  //     //   );
  //     // }

  //     // console.log('emailDomain: ', emailDomain);
  //     // return this._gatewayService.runtimeConfig(emailDomain).pipe(
  //     return this._gatewayService.runtimeConfig().pipe(
  //       map((config: AppConfig) => {
  //         return action.checkOnly
  //           ? new appActions.LoginLoadRuntimeAppConfigCheckOnlySuccessAction(
  //             config,
  //             action.user
  //           )
  //           : new appActions.LoginLoadRuntimeAppConfigSuccessAction(
  //             config,
  //             action.user
  //           );
  //       }),
  //       catchError((err) =>
  //         of(new appActions.LoginLoadRuntimeAppConfigFailureAction(err))
  //       )
  //     );
  //   })
  // ));

  
  LoginLoadRuntimeAppConfigCheckOnlySuccess: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(appActions.AppActionEnums.LoginLoadRuntimeAppConfigCheckOnlySuccess),
    tap((action: appActions.LoginLoadRuntimeAppConfigCheckOnlySuccessAction) => {
      const route = `login?un=${action.user.userName}`;
      this._navigateAfterCompleteAuthAndResources(action.config.identifier, route);
    })
  ), { dispatch: false });

  
  LoginLoadRuntimeAppConfigSuccess: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(appActions.AppActionEnums.LoginLoadRuntimeAppConfigSuccess),
    map((action: appActions.LoginLoadRuntimeAppConfigSuccessAction) => {
      return new appActions.UsernameAction(action.user);
    })
  ));


  
  SaveBugPhoto: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(appActions.AppActionEnums.SaveBugPhoto),
    switchMap((action: appActions.SaveBugPhotoAction) => {
      return this._photoService.saveBugPhoto(action.photo).pipe(
        map((photo: PhotoModel) => {
          return new appActions.SaveBugPhotoSuccessAction(photo);
        }),
        catchError((err) => of(new appActions.SaveBugPhotoFailureAction(err)))
      );
    })
  ));

  
  SaveBugPhotoSuccess: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(appActions.AppActionEnums.SaveBugPhotoSuccess),
    // tap(success => {
    //   console.log('save photo success', success)
    // })
  ), { dispatch: false });

  
  ReportBug: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType(appActions.AppActionEnums.ReportBug),
    switchMap((action: appActions.ReportBugAction) => {
      return this._gatewayService.reportBug(action.bug).pipe(
        map((bug: BugModel) => {
          return new appActions.ReportBugSuccessAction(bug);
        }),
        catchError((err) => of(new appActions.ReportBugFailureAction(err)))
      );
    })
  ));

  
  ReportBugSuccess: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(appActions.AppActionEnums.ReportBugSuccess),
    // tap(success => {
    //   // console.log('save photo success', success)
    // })
  ), { dispatch: false });

  
  LogsFailure: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(
      appActions.AppActionEnums.SaveBugPhotoFailure,
      appActions.AppActionEnums.LoginLoadRuntimeAppConfigFailure,
      appActions.AppActionEnums.LoadRuntimeAppConfigFailure,
      appActions.AppActionEnums.ReportBugFailure,
      appActions.AppActionEnums.ForgotPasswordFailure,
      appActions.AppActionEnums.ConfirmationPasswordFailure
    ),
    tap(() => {
      // const message = action.type ? action.type : 'Failure in app effects';
      // const attrs = {
      //   // TODO get uuid working
      //   issueId: uuid(),
      //   stackTrace: JSON.stringify(action)
      // };
      // TODO implement custom logging service
      // this._newRelicService.customError(message, attrs);
    })
  ), { dispatch: false });



  private _navigateAfterCompleteAuthAndResources(
    subdomain: string,
    route?: string
  ) {
    console.log(
      'subdomain: ',
      subdomain,
      ', route: ',
      route,
      ', host: ',
      window.location.host
    );

    if (subdomain && !window.location.host.includes(subdomain)) {
      // if we are logging in and subdomain is not set then reroute to subdomain url
      console.log(
        'navigating to ',
        `https://${subdomain}.${DomainStandardEnv.Prod}/${route}`
      );
      window.location.href = `https://${subdomain}.${DomainStandardEnv.Prod}/${route}`;
    } else {
      console.log('navigating to ', route);
      if (route) {
        this.router.navigateByUrl(route);
      }
    }
  }
}
