import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AreYouSureDialogData {
  confirm: boolean;
  message: string;
}

@Component({
  selector: 'eng-are-you-sure-dialog',
  templateUrl: 'are-you-sure-dialog.component.html',
  styleUrls: ['are-you-sure-dialog.component.scss']
})
export class AreYouSureDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<AreYouSureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AreYouSureDialogData
  ) {}

  sure() {
    this.data.confirm = true;
    this.dialogRef.close(this.data);
  }

  no() {
    this.dialogRef.close(this.data);
  }
}
