import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItemButtonComponent } from './menu-item-button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [
    MenuItemButtonComponent
  ],
  exports: [
    MenuItemButtonComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule
  ]
})
export class MenuItemButtonModule { }
