import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducer } from './state/app.reducer';
import { initialAppState } from 'src/app/state/app.state';
import { AppRoutes } from './app.routes';
import { AppEffects } from './state/app.effects';

import { environment } from 'src/environments/environment';

import { ApolloModule } from 'apollo-angular';

import {
  ErrorInterceptor,
  AuthGuardService,
  AuthGuardLoadService,
  FeaturesGuard,
  FakeBackendInterceptor,
  SourceGuardService,
  HttpAuthInterceptor,
} from '@route';

import {
  CloudElasticService,
  MockDataService,
  DataConnectionFactory,
  GatewayService,
  DialogService,
  AppSyncLiveDataService,
  ElasticQueryService,
  USecureService,
} from '@services';

import {
  FooterModule,
  TopBarModule,
  AlertModule,
  AreYouSureDialogModule,
  PasswordDialogModule,
  ConfirmForgotPasswordDialogModule,
  SideNavModule,
  FoundABugDialogModule,
  ImageCropDialogModule,
  TilesModule,
  IFrameDialogModule,
  TableModule,
  BreadCrumbModule,
} from '@shared';

// Angular Material Modules
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { LandingService } from './services/home/landing.service';
import { AccountGuard } from './guards/account.guard';
import { EnigmaAiInsightsComponent } from './views/enigma-ai-insights/enigma-ai-insights.component';
import { InternalNetworkTrafficComponent } from './views/enigma-ai-insights/internal-network-traffic/internal-network-traffic.component';
import { EnigmaAiLabelComponent } from './views/enigma-ai-label/enigma-ai-label.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProgressSpinnerModule } from './shared/progress-spinner/progress-spinner.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UeApiInterceptor } from './route/interceptor/ue-api.interceptor';
import { EnigmaDestinationJobComponent } from './views/enigma-destination-job/enigma-destination-job.component';
import { TimeOutDialogComponent } from './shared/dialogs/time-out-dialog/time-out-dialog.component';
import { NgOtpInputModule } from 'ng-otp-input';


const SERVICES = [
  CloudElasticService,
  MockDataService,
  DataConnectionFactory,
  ElasticQueryService,
  GatewayService,
  DialogService,
  AppSyncLiveDataService,
  LandingService,
  USecureService,
];

const ROUTE = [
  AuthGuardService,
  AuthGuardLoadService,
  FeaturesGuard,
  SourceGuardService,
];

const MATERIAL = [
  MatInputModule,
  MatSidenavModule,
  MatButtonModule,
  MatIconModule,
  MatTableModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
];

const SHARED = [
  FooterModule,
  TopBarModule,
  AlertModule,
  AreYouSureDialogModule,
  PasswordDialogModule,
  ConfirmForgotPasswordDialogModule,
  SideNavModule,
  FoundABugDialogModule,
  ImageCropDialogModule,
  TilesModule,
  IFrameDialogModule,
  TableModule,
  BreadCrumbModule,
  MatProgressBarModule,
  NgOtpInputModule,
];

const GUARDS = [AccountGuard];

@NgModule({
  declarations: [AppComponent, TimeOutDialogComponent],
  providers: [
    ...ROUTE,
    ...SERVICES,
    ...GUARDS,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // provider used to create fake backend
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FakeBackendInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UeApiInterceptor,
      multi: true,
    },
    HttpAuthInterceptor
  ],
  bootstrap: [AppComponent],
  imports: [
    StoreModule.forRoot({ app: reducer }, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
      initialState: { app: initialAppState },
    }),
    ToastrModule.forRoot(),
    EffectsModule.forRoot([AppEffects]),
    StoreDevtoolsModule.instrument({
      name: 'APO ngrx devtools',
      maxAge: 25,
      logOnly: !environment.production,
    }),
    BrowserModule,
    FormsModule,
    AppRoutes,
    HttpClientModule,
    BrowserAnimationsModule,
    ...MATERIAL,
    ...SHARED,
    ApolloModule,
    ProgressSpinnerModule
  ]
})
export class AppModule {
  constructor() { }
}
