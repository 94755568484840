import { Pipe, PipeTransform } from '@angular/core';
import * as changeCase from 'change-case';

enum CaseName {
  camelCase = 'camelCase',
  capitalCase = 'capitalCase',
  constantCase = 'constantCase',
  dotCase = 'dotCase',
  headerCase = 'headerCase',
  noCase = 'noCase',
  paramCase = 'paramCase',
  pascalCase = 'pascalCase',
  pathCase = 'pathCase',
  sentenceCase = 'sentenceCase',
  snakeCase = 'snakeCase'
}

@Pipe({ name: 'changeCase' })
export class ChangeCasePipe implements PipeTransform {
  transform(value: string, caseName: CaseName): string {
    if (value) {
      switch (caseName) {
      case CaseName.camelCase:
        return changeCase.camelCase(value);
      case CaseName.capitalCase:
        return changeCase.capitalCase(value);
      case CaseName.constantCase:
        return changeCase.constantCase(value);
      case CaseName.dotCase:
        return changeCase.dotCase(value);
      case CaseName.headerCase:
        return changeCase.headerCase(value);
      case CaseName.noCase:
        return changeCase.noCase(value);
      case CaseName.paramCase:
        return changeCase.paramCase(value);
      case CaseName.pascalCase:
        return changeCase.pascalCase(value);
      case CaseName.pathCase:
        return changeCase.pathCase(value);
      case CaseName.sentenceCase:
        return changeCase.sentenceCase(value);
      case CaseName.snakeCase:
        return changeCase.snakeCase(value);
      default:
      }
    }
    return value;
  }
}
