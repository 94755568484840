import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';

export const fadeEnterAnimation = trigger('enter', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('1s', style({ opacity: 1 }))
  ])
]);

export const fadeEnterAnimationFast = trigger('enterFast', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('200ms', style({ opacity: 1 }))
  ])
]);
