import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent } from './map.component';
import {
  MatMenuModule,
  MatButtonModule,
  MatIconModule,
  MatTooltipModule,
  StyleModule
} from '@richUi';

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    StyleModule
  ],
  declarations: [MapComponent],
  exports: [MapComponent]
})
export class MapModule {}
