import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AppComponent } from '../../app.component';
import * as AppActions from '../../state/app.actions';
import { AppState } from 'src/app/state/app.state';
import { Store, select } from '@ngrx/store';
import { getUser, getActiveAccount, getRuntimeAppConfig } from 'src/app/state/app.selectors';
import { Subscription } from 'rxjs';
import { MatSelectChange } from '@angular/material/select';
import { takeWhile, take, first } from 'rxjs/operators';
import * as fromAppSelectors from '../../state/app.selectors';
import { User, DialogService } from '@services';
import { UEAPIService } from 'src/app/services/ue/api.service';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin/admin.service';
import { AuthService } from 'src/app/services/ue/auth.service';

type ThemeType = 'Light' | 'Dark';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'eng-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['top-bar.component.scss']
})
export class TopBarComponent implements OnDestroy, OnInit {
  nextTheme: ThemeType = 'Dark';
  componentActive = true;
  tooltipMessage: string;
  accounts: string[];
  activeAccount: string;
  defaultRoute: string;
  initials: string;
  activeAccountSub: Subscription;
  configSub: Subscription;
  liveDataSub: Subscription;
  userSub: Subscription;

  constructor(
    public app: AppComponent,
    private _store: Store<AppState>,
    private _dialogService: DialogService,
    private apiService: UEAPIService,
    private router: Router,
    private _authService: AuthService,
  ) {
    this.userHandler();

    this.activeAccountSub = this._store.pipe(
      select(getActiveAccount)
    ).subscribe(account => {
      if (account) {
        this.activeAccount = account;
      }
    });

    this.configSub = this._store.pipe(
      select(getRuntimeAppConfig)
    ).subscribe(config => {
      if (config) {
        this.defaultRoute = config.region;
      }
    });

    const userProfile: any = JSON.parse(localStorage.getItem('userProfile') as string);

    this.buildInitials({
      givenName: userProfile?.firstName || '',
      familyName: userProfile?.lastName || '',
      email: userProfile?.email || ''
    });

  }

  ngOnInit() {
    this._store
      .pipe(
        takeWhile(() => this.componentActive),
        select(fromAppSelectors.getUser)
      )
      .subscribe(() => {
        this.componentActive = false;
      });
  }

  buildInitials(user: any) {
    if (user.givenName && user.familyName) {
      this.initials = `${user.givenName[0]}${user.familyName[0]}`.toUpperCase();
    } else if (user.givenName) {
      this.initials = `${user.givenName[0]}`.toUpperCase();
    } else if (user.email) {
      this.initials = user.email.slice(0, 2).toUpperCase();
    } else {
      this.initials = 'U.';
    }
  }

  ngOnDestroy() {
    if (this.activeAccountSub) {
      this.activeAccountSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
    if (this.liveDataSub) {
      this.liveDataSub.unsubscribe();
    }
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }

  userHandler() {
    this.userSub = this._store.pipe(select(getUser)).subscribe(user => {
      if (user && user.accounts) {
        this.accounts = user.accounts;
      }
    });
  }

  changeAccount(change: MatSelectChange) {
    this._store.dispatch(new AppActions.ActiveAccountAction(change.value));
  }

  logout() {
    this.router.navigate(['logout']);
  }

  foundABug() {
    this._dialogService
      .foundABugDialog()
      .pipe(take(1))
      .subscribe();
  }
}
