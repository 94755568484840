import { Component, OnInit, Input, AfterContentInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { OnDestroy } from '@angular/core';
import * as fromApp from '../../state/app.selectors';
import { select } from '@ngrx/store';
import { NxChartBaseDirective } from '../nx-chart-base/nx-chart-base.directive';
import { AppState } from 'src/app/state/app.state';
import { SchemePalette } from 'src/app/shared/common-behaviors/scheme';
import { ChartType } from '@config';

type TimeframeType = 'month' | 'quarter';

@Component({
  selector: 'eng-number-card',
  templateUrl: './number-card.component.html',
  styleUrls: ['./number-card.component.scss']
})
export class NumberCardComponent extends NxChartBaseDirective
  implements OnInit, AfterContentInit, OnDestroy {
  @Input()
    scheme: SchemePalette;

  cardColor = '#232837';

  chartType: ChartType = 'number-card';

  @Input()
  set data(data: any) {
    if (data) {
      // TODO if data is not put it in one
      this._numberCardData = [...[data]];
      setTimeout(() => {
        this.ready = true;
        this.updateView();
      });
    }
  }
  get data(): any {
    return this._numberCardData;
  }
  private _numberCardData: any;

  timeframe: TimeframeType = 'month';
  ready = false;
  delay = 200;

  constructor(
    protected _appState: Store<AppState>
  ) {
    super([0, 0]);
  }

  ngOnInit() {
    this._appState.pipe(
      takeWhile(() => this.componentActive),
      select(fromApp.getDarkThemeState)).subscribe(darkTheme => {
      this.cardColor = darkTheme ? '#232837' : '#EEF2F6' ;
    }
    );
  }

  valueFormatting(inputObj: any) {
    if (inputObj) {
      if (inputObj.value > inputObj.data?.value) {
        return inputObj.data.value;
      }
      return inputObj.value;
    }
    return 0;
  }
}
